.trip-planner-view-wrapper {
  position: relative;
  padding: 0 26px;
  margin-top: -3rem;
  // bread crumb
  .bread-crumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #707170;
    padding: 24px 0;
    .link {
      color: #d4a655;
    }
  }
  .trip-planner-header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    padding: 16px 24px;
    background-color: #fff;
    border-radius: 8px;
    gap: 50px;
    .item {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background-color: #EAECF080;
      border: 1px solid #98A2B333;
      border-radius: 50%;
      svg {
        width: 50%;
        height: 50%;
      }
    }
  }
  // tabs
  .trip-planner-tabs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    color: #707170;
    // overflow-x: auto;
    // overflow-y: visible;
    // border-bottom: 1px solid #dddcdc;
    padding: 0;

    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 100%;
      white-space: pre;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      gap: 8px;

      &.active {
        border-bottom: 2px solid #d2ab67 !important;
        color: #d2ab67;
        cursor: default;

        svg path {
          stroke: #d2ab67;
        }
      }
      &.disabled {
        cursor: default;
      }
    }
  }

  .trip-planner-content {
    display: flex;
    flex-direction: column;
    padding: 16px 26px;
    background-color: #fff;
    border: 1px solid #F7F6F6;
    border-radius: 8px;
    gap: 26px
  }

  .fix_place_arrow {
    right: 10px !important;
    [dir="rtl"] & {
      right: unset !important;
    }
  }
  .control-field__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 26.24px;
    color: #707170;
  }
  .textarea-input {
    padding: 10px 16px;
    border: 1px solid #DDDCDC;
    color: #050001;
    width: 100%;
    border-radius: 6px;
  }
  .summery-checkBox {
    display: flex;
    align-items: center;
    filter: invert(1%) hue-rotate(190deg) brightness(1.2);
    user-select: none;
    .form-check-label {
      font-size: 16px !important;
      color: #707170;
      font-weight: 400;
    }
  }
  .radio-group {

    input[type="radio"] {
      position: relative;
      min-width: 16px;
      height: 16px;
      margin: 0;
      color: #DDDCDC;
      border: 1px solid #DDDCDC;
      border-radius: 50%;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
    }

    input[type="radio"]::before {
      position: absolute;
      content: "";
      width: 65%;
      height: 65%;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      scale: 0;
      transform: translate(-50%, -50%);
      box-shadow: inset 50px 50px #D4A655;
    }

    input[type="radio"]:checked::before {
      scale: 1;
    }
    input[type="radio"]:checked {
      border: 1px solid #D4A655;
    }

  }
  .exclude-type-wraper  {
    padding: 20px;
    background-color: #FBFBFA;

    .week-days {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 360px;
      width: 100%;
      margin-top: 12px;
      gap: 20px;
    }
    .summery-checkBox input {
      width: 18px;
      height: 18px;
      border-radius: 4px;
    }
  }

  .add-row-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    color: #050001;
    gap: 10px;

    svg {
      width: 32px;
      height: 32px;
      background-color: #EED9BA;
      border-radius: 6px;
    }
  }
  .delete-row-btn svg path {
    stroke: #EA5455;
  }

  .itinerary-days-preview {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    max-width: 480px;
    padding: 24px;
    border: 1px solid #F7F6F6;
    background-color: #fff;
    transform: translateX(120%);
    transition: transform .35s ease;
    z-index: 1000;
    overflow: auto;
    [dir="rtl"] & {
      left: 0;
      right: unset;
      transform: translateX(-120%);
    }
    &.opened {
      transform: translateX(0);
      transition: transform .35s ease;
    }

    .close-preview-btn {
      position: absolute;
      top: 16px;
      left: -33px;
      width: 36px;
      height: 36px;
      border-radius: 50px 0px 0px 50px;
      background-color: inherit;
    }
    
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 40.42px;
    }
    // .preview-content {
    //   padding: 16px;
    //   border: 1px solid #DDDCDC;
    //   border-radius: 8px;
    //   background-color: inherit;
    // }
    .product-itinerary-container {
      padding: 16px;
      background-color: #fff;
    }
    .itinerary-timeline {
      padding: 0;
    }
    .info-list {
      padding: 0 32px !important;
    }
    .itinerary-segment:last-child .segment-point::after {
				content: "" !important;
				position: absolute !important;
				top: 26px !important;
				width: 2px !important;
        height: calc(100% - 26px) !important;
				background-color: #DDDCDC !important;
				z-index: 0;
    }
    .segment-time {
      display: none;
    }
  }
  .save-pricing {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    padding: 12px 32px;
    border: 1px solid #D4A655;
    color: #D4A655;
    font-size: 16px;
    font-weight: 600;
    
    &:disabled {
      background: #979797 !important;
      opacity: 0.6;
      color: #050001;
      cursor: not-allowed;
    }
  }
  .apply-to-all {
    border: 1px solid #DBDADE;
    color: #707170;
        padding: 12px 16px;
  }
}


.trip-planner-icon-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 40px;
	height: 40px;
	border: 1px solid #dbdade;
	border-radius: 50%;

	svg {
		flex-basis: 65%;
		height: 65%;
	}

	&.flight {
		background-color: #00cfe814;
		border: 1px solid #00cfe829;
	}
	&.hotel {
		background-color: #ff9f4314;
		border: 1px solid #ff9f4329;
	}
	&.transportation {
		background-color: #ea545514;
		border: 1px solid #ea545529;
	}
	&.attraction {
		background-color: #7367f014;
		border: 1px solid #7367f030;
		svg {
			height: 50%;
		}
	}
}

// summery details
.summery-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .no-gutters
  .control-field__select
  div[class$='-control']
  div[class$='-ValueContainer'] {
    gap: 5px;
    max-height: unset;
  }
  div[class$=-control] {
    max-height: unset !important;
  }
  .control-field {
    height: auto;
  }
  .summery-items {
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    .summery-item {
      flex-basis: calc(33% - .5rem);
    }

  }

  .summery-title {
    font-size: 16px;
    font-weight: 400;
    color: #050001;
    line-height: 29.98px;
  }

  .summery-details-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    background-color: #d4a655;
    border-radius: 4px;
    padding: 8px 79px;
    font-size: 20px;
    font-weight: 700;
  }
}
//generate pdf model
.generate-pdf-model{
  text-align: start;
  .generate-pdf-img{
    border: 2px solid rgba(225, 225, 225, 0.3);
  }
  h5{
    color: rgba(112, 113, 112, 1);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    margin-top: 16px;
    span{
      font-size: 14px;
      font-weight: 700;
      color: #d4a655;
    }
  }
  .upload-header-images{
    border: 1px dashed rgba(219, 218, 222, 1) !important;
    border-radius: 6px ;
    background-color: #fff !important;
    height: 120px !important;

    
  }
  .textarea {
    label{
      font-size: 14px;
      font-weight: 400;
      color: #707170 ;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    textarea{
      padding: 8px 16px;
      border-radius: 6px;
      border: 1px solid #dddcdc;
      // background-color: #fefeff;
      &::placeholder{
        color: rgba(221, 220, 220, 1);
        font-size: 14px;
      font-weight: 400;
      }
    }
		
	}
  .logo{
    .image-uploader__plus-btn{
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid rgba(247, 246, 246, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      top: 69% !important;
    }
    .img-holder {
      width: 100% !important;
      height: 100% !important;
      border-radius: 50% !important;
    }
    .image-uploader__outline{
      width: 8rem !important;
      height: 8rem !important;
      padding: 0 !important;
      border-radius: 50% !important;

    }

  }
  .logo-text{
    color: #707170 ;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
  }
  .modal-header h5{
    color:#050001 !important;
    font-size: 16px !important;
  }
  .modal-header {
    padding-top: 25px !important;
    padding-bottom: 6px !important;

}
}

@media screen and (max-width: 992px) {
	.summery-details-wrapper .summery-items .summery-item {
		flex-basis: 28%;
	}
}

@media screen and (max-width: 600px) {
	.summery-details-wrapper .summery-items .summery-item {
		flex-basis: 45%;
	}
}
