.tds-view-visa {
	.tds-container {
		margin-inline: 42px;
	}
	.tds-nav-bar {
		width: 100%;
		height: 200px;
		padding: 132px 107px 0px 107px;
		h3 {
			color: white;
		}
	}
	.tds-title {
		margin: 1em;
		font-size: 20px;
		color: #707170;
	}
	.tds-visa-details-container {
		padding: 32px 26px;
		.tds-details-title {
			font-size: 16px;
			color: #707170;
		}

		.tds-details-subtitle {
			font-size: 16px;
			color: #050001;
		}

		.status-class {
			width: fit-content;
			border-radius: 4px;
			padding: 5px 10px;
			text-transform: capitalize;

			&.pending, &.waiting {
				background-color: rgba(251, 136, 30, 0.16);
				color: #FB881E;
			}

			&.cancelled, &.expired, &.rejected {
				background-color: rgba(234, 84, 85, 0.16);
				color: #EA5455;
			}
			&.issued {
				background-color: rgba(27, 174, 112, 0.16);
				color: #1BAE70;
			}
		}
		
		.visa-details {
			display: flex;
			margin-block: 24px;
		}
	}

	.tds-passenger-list {
		padding: 24px;

		.table-container{
			border-radius: 6px;
			overflow: hidden;
		}
		.clear-button {
			button {
				background: #faf4eb;
				border: 1px solid #eed9ba;
				color: #FFA500;
				padding-block: 8px;
				margin-bottom: 4px;
			}
		}

		.table-update {
			thead{
				background-color: #F7F6F6;
				th{
					border-top: none;
					color: #707170;
				}
			}
		}
	}
	.tds-search {
		width: 282px;
		.control-field__label {
			font-size: 14px;

			color: #707170;
		}
	}
}


.tds-visa-container {
	background-color: #FBFBFA;

	.intro {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		height: 100vh;
		gap: 16px;

		.intro-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			max-width: 300px;
			width: 100%;
			padding: 20px 16px;
			border-radius: 24px;
			border: 1px solid #F7F6F6;
			background-color: #FFF;
			gap: 16px;
			font-size: 20px;
			font-weight: 600;
			color: #050001;

			svg {
				height: 120px;
			}
		}
	}

	.verify-btn {
		padding: .6rem 1.3rem;
		margin-top: 1.85rem;
		background-color: #FAF4EB;
		color: #FFA500;
		border-radius: 6px;
		border: 1px solid #EED9BA;
		font-weight: 700;
	}

	.stepper-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		
		svg {
			height: 60px !important;
		}
	}
	
	.active-step {
		color: #0A2FA6;
		font-weight: bold;
		&.stroke path {
			stroke: #0A2FA6;
		}
		&.fill path {
			fill: #0A2FA6;
		}
	} 

	.tds-visa-input {
		.control-field {
			height: auto;
			margin-bottom: 0 !important;
		}
		.control-field__label {
			width: 100%;
			font-size: 15px;
			font-weight: normal;
			text-align: start;
			// color: #4B465C90;
		}
	}

	.base-border {
		border: 1px solid #DBDADE;
		border-radius: 6px;
	}

	.visa-total-price {
		font-weight: bold;
		color: #0A2FA6;
	}

	.font-grey-400 {
		color: #707170;
	}

	.font-grey-900 {
		color: #050001;
	}

	.visa-destination-services.active .visa-radio-input::after {
		background-color: #0A2FA6;
	}

	.visa-destination-services.active .visa-radio-input, 
	.visa-destination-services.active {
		border-color: #0A2FA6 !important;
	}

	.visa-type-price {
		background-color: #0A2FA6
	}

	.orange-color {
		color: #FFA500 !important;
	}

	.select-visa-btn {
		border-color: #1BAE70;
		color: #1BAE70;
		&:hover {
			background-color: #1BAE70;
			border-color: #1BAE70;	
			color: #fff;
		}
	}

	.custom-control-input:checked ~ .custom-control-label::before {
		border-color: #1BAE70 !important;
    background-color: #1BAE70 !important;
	}

	.custom-color {
		color: #FFA500 !important;
	}
	.custom-border-color {
		border-color: #FFA500 !important;
	}

	.member-photo button {
		color: #FFA500;
	}
	.btn {
		border-radius: 8px;
	}
	.tds-details-subtitle {
		display: none;
	}
}

.tds-view-visa {
	.member-photo button {
		color: #FFA500;
	}
	.btn {
		border-radius: 8px;
	}

}

.tds-generic-btn {
	padding: 8px 32px;
	border-radius: 8px;
	font-size: 16px; 
	font-weight: 600;
	background-color: #1BAE70;
	color: #fff !important;
	text-transform: capitalize;

	&:focus {
		box-shadow: 0 0 0 0.1rem rgba(27, 174, 112, 0.25);
	}
	&:hover {
		color: #fff;
	}
	svg {
		color: #fff;
	}
}
.tds-text-blue {
	color: #0A2FA6;
}
.tds-text-green {
	color: #1BAE70;
}
.tds-bg-blue {
	background-color: #0A2FA6;
}

.tds-visa-token-modal {
	.control-field__label {
		color: #707170;
	}

	.custom-color {
		color: #FFA500 !important;
	}
	.custom-border-color {
		border-color: #FFA500 !important;
	}
	.modal-title {
		width: 100%;
		text-align: center;
	}
}

.tds-payment-modal {
	.item-payment{
		&.pay-active {
			border-color: #0A2FA6;
			background-color: #0A2FA610;
		}
		.raido-label input {
			accent-color: #0A2FA6 !important;
		}
	}
}

.tds-modal {
	.visa-add-manual button{
		border-color: #1BAE70;
		color: #1BAE70;
	}
	.visa-add-mrz {
		border-color: #0A2FA6;
		background-color: #0A2FA610;
	}
}

.tds-change-btn {
	font-weight: 500;
	font-size: 14px;
	text-decoration: underline;
	color: #1BAE70;
	border: 0;
	background-color: transparent;
}
.tds-change-btn:disabled {
	opacity: .3;
	color: #050001;
}

.tds-prev-btn {
	background-color: #F7F6F6;
	color: #707170;
	font-weight: 500;
	font-size: 1rem;
}

[dir="rtl"] .btn svg {
	transform: scale(-1, 1);
}

// @media screen and (max-width: 767px) {
// 	.tds-visa-container .intro {

// 	}
// }
