.list-container {
	max-width: 1300px;

	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.set-w-15 {
	width: 15% !important;
}

.set-w-11 {
	width: 13% !important;
}
.txt-blue {
	color: #0c3b5c;
	font-weight: 700;
}
.bg-details {
	background-color: #faf2e8;
}
.bg-overveiw {
	background-color: #f1f4f7;
}
.custom-icon-size {
	font-size: 14px !important;
}
.custom-icon-color {
	color: #cb9a51;
}
.terms-color {
	color: #0c3b5c !important;
}
.payment-box {
	background-color: #f7f7f7;
	border-radius: 10px;
}
.must-box {
	background-color: #cfead5;
	color: #28a745;
	border-radius: 10px;
}
.clear-filter {
	border: 1px solid #666666;
	font-size: 15px;
}
[dir="ltr"] .hotel-found {
	border-left: 3px solid #cb9a51;
	padding-left: 8px;
}
[dir="rtl"] .hotel-found {
	border-right: 3px solid #cb9a51;
	padding-right: 8px;
}
.error-model-title {
	font-size: 17px;
	font-weight: 600;
	color: #1b1e21;
	word-break: break-word;
}
.pointer {
	cursor: pointer;
}

.fix-title {
	white-space: nowrap;
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.inventory-packages-reports-button {
	display: flex;
	flex-direction: column;
	gap: 15px;

	.btn-inventory-invoices {
		display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px 16px 12px 16px;
    border: 1px solid #D4A655;
    background: #D4A655;
    color: #FFF;
    border-radius: 6px;
		text-transform: capitalize;
	}
}
.inventory-container {
	display: flex;
	flex-direction: column;
	padding: 24px;
	border-radius: 8px;
	background-color: #fff;
	gap: 20px;

	.nav {
		flex-wrap: nowrap;
		background-color: transparent;
		justify-content: center;
		gap: 8px;
		// overflow-x: auto;
	}


	.nav-link {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: unset;
		padding: 4px 20px;
		border-radius: 65px;
		border: 1px solid #F7F6F6;
		background-color: #FFFFFF;
		color: #505050;
		font-weight: 500;
		text-transform: capitalize;
		gap: 8px;
		&.active {
			color: #fff;
			background-color: #D4A655;
			border: 1px solid #D4A655;

			svg path {
				stroke: #fff;
			}
		}
	}
	.status {
		padding: 5px 10px;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		line-height: 100%;
		text-wrap: nowrap;
		margin: 0;
		text-align: center;
		text-transform: capitalize;

		&.pending, &.waiting, &.tentative {
			color: #D28D19;
			background-color: #FFA00029;
		}

		&.confirmed, &.paid, &.approved, &.issued, &.definite {
			color: #019D4C;
			background-color: #019D4C29;
		}

		&.canceled, &.expired, &.danger {
			color: #D71A21;
			background-color: #D71A2129;
		}

	}
}