.theme-7 {
	.theme_flight_section {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.bg_col {
			width: 35%;
			background-color: rgb(var(--web-page-primary-color));
			border-top-left-radius: 12px;
			border-bottom-left-radius: 12px;
			img {
				position: relative;
				width: 100%;
				height: auto;
				border-radius: 12px;
				display: inline-block;
				margin-inline-start: -4rem;
				top: -3rem;
			}
		}
		.content_col {
			width: 65%;
			padding-inline-start: 3rem;
			padding-inline-end: 8rem;
			.heading {
				.title {
					font-size: 1.5rem;
					font-weight: 700;
					line-height: 1.5;
				}
				.heading_text {
					font-size: 1rem;
					color: #737373;
					line-height: 1.5;
				}
			}
		}
		.flight-service-card {
			padding: 16px;

			.header {
				padding: 0;
				padding-bottom: 16px;
				border: 0;
				border-bottom: 1px solid #e5e5e5;
				margin-bottom: 16px;
			}

			.cta-btn {
				padding: 8px 24px;
				color: #fff !important;
				background-color: rgba(var(--web-page-primary-color));
			}

			.destination-count {
				position: relative;
				display: flex;
				align-items: center;
				padding: 6px 24px;
				color: rgba(var(--web-page-primary-color));
				background-color: rgba(var(--web-page-primary-color), 0.08);
				border: 1px solid rgba(var(--web-page-primary-color), 0.16);
				gap: 6px;

				&:hover .addition-destinations-list {
					display: flex;
					width: 150%;
					flex-direction: column;
					gap: 12px;
				}
			}
			.destination-port {
				display: flex;
				justify-content: space-evenly;
				color: #050001;
				gap: 24px !important;
				.port-country {
					font-weight: 400;
					font-size: 14px;
				}

				.arrow {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 32px;
					height: 32px;
					min-width: 32px;
					min-height: 32px;
					background-color: rgba(var(--web-page-primary-color), 0.08);
					border-radius: 50%;
					svg {
						width: 75% !important;
						height: 75%;
					}
				}
			}

			.addition-destinations-list {
				display: none;
				position: absolute;
				width: 100%;
				top: 38px;
				left: 0;
				padding: 16px;
				border: 1px solid #dddcdc;
				border-radius: 8px;
				background-color: #ffffff;
				z-index: 3;
				svg path {
					stroke: rgba(var(--web-page-primary-color));
				}
				.destination-port {
					gap: 16px !important;
					justify-content: space-between;
					align-items: center;
					.font-weight-bold {
						width: 90px;
					}
				}
			}
		}
		.upload-file-container {
			position: relative;
			// min-height: 250px;
			display: flex;
			align-items: center;
			justify-content: center;
			&::after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(112, 113, 112, 0.7);
				z-index: 2;
			}
			label {
				margin-bottom: 0;
				width: 100%;
				height: 100%;
				
				// img {
				// 	// top: 0;
				// 	// margin-inline-start: 0;
				// 	// width: 100%;
				// 	// height: 100%;
				// 	// object-fit: cover;
				// }
				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50% , -50%);
					z-index: 3;
				}
			}
		}

		@media screen and (max-width: 1199px) {
			.content_col {
				width: 50%;
				padding-inline-end: 4rem !important;
			}
			.bg_col {
				width: 50%;
				padding: 0.5rem;
				border-radius: 0;
				img {
					top: 0;
					margin-inline-start: 0;
				}
			}
		}
		@media screen and (max-width: 991px) {
			flex-direction: column-reverse;
			gap: 1.5rem;
			.bg_col {
				width: 100%;
			}
			.content_col {
				width: 100%;
				margin-bottom: 1.5rem;
				padding-inline-start: 1rem !important;
				padding-inline-end: 1rem !important;
			}
		}
	}
}

html {
	&:dir(rtl) {
		.theme-7 {
			.theme_flight_section {
				.bg_col {
					border-top-right-radius: 12px;
					border-bottom-right-radius: 12px;
					border-top-left-radius: 12px;
					border-bottom-left-radius: 12px;
				}
			}
		}
	}
}
