@media (min-width: 0px) and (max-width: 600px) {
  .main-web-builder {
    .col-12 {
      padding: 0;
    }
  }
	.web-builder-preview {
    // overflow: hidden;
    &.theme-2 .web-builder-hero {
      height: 450px;
      .content-layout-theme-2 {
        top: 55%;
      }
    }
    

    .web-builder-hero-content {
      width: 100%;
      padding: .8rem 1rem !important;
      .header {
        font-size: 1.4rem;
      }
      .search-form-web-builder {
        flex-wrap: wrap;
        padding: 8px 7px;
        gap: 4px;
        .search-form-select {
          min-width: auto;
          width: calc(50% - 4px);
        }
      }
      .search-form-button {
        width: 100%;
      }
    }
    /** ****************************************** about us ******************************************/
    .wb-our-service {
      padding: 30px 10px;
      .services-content {
        max-width: 100%;
        padding: 0;
      }
      .web-builder-content-our-service {
        padding: 0;
        grid-template-columns: repeat(2, 1fr) !important; 

        .web-builder-content-our-service_card {
          padding: 0;
        }
      }
    }
    /** ****************************************** about us ******************************************/
    .web-builder-content-about-us {
      display: flex;
      flex-direction: column;
      .about-us-details {
        text-align: center !important;
      }
      .web-builder-filled-btn {
        margin: 0 auto;
      }
    }
    /** ****************************************** our packages ******************************************/
    .web-builder-content-our-packages-with-slider {
      .our-packages-header {
        text-align: center;
        justify-content: center;
        .btn {
          display: none;
        }
      }
      .btn-mobile {
        display: block !important;
        text-align: center;
        margin: 24px auto;
        font-size: 1rem;
        font-weight: 500 !important;
        border-radius: 4px !important;
      }
    }
    .all-web-builder-our-service-images {
      .our-service-item {
        flex-basis: 100% !important;
      }
    }
    /** ****************************************** hotels ******************************************/
    .web-builder-content-hotels {
      background-color: #F5F8FC;
      .hotels-grid-view {
        padding: 0;
        grid-template-columns: repeat(auto-fit, 100%) !important;
      }
    }

    /** ****************************************** FAQ ******************************************/
    .faq-detials {
      grid-template-columns: repeat(auto-fill, 100%) !important;
    }
    .faq-with-image {
      .service-header {
        text-align: center;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(var(--web-page-primary-color), 0.08);
        margin-bottom: 1rem;
        font-size: 24px;
      }
      .faq-questions-container {
        order: 1;
      }
      .faq-image {
        height: auto !important;
      }
    }
    .faq-column-view {
      .services-content {
        text-align: center;
      }
      .faq-questions-container {
        grid-template-columns: repeat(auto-fill, 100%);
      }
    }
    /** ****************************************** Contact us ******************************************/
    .web-builder-content-contact-us-with-image {
      .container {
        padding: 0;
      }
      .contact-us-layout {
        padding: 32px 12px;
        flex-direction: column-reverse;
        gap: 36px;
      }
      .contact-us-data, .contact-us-image {
        width: 100% !important;
      }
    }
    .web-builder-content-contact-us {
      padding: 0;
      margin-top: 1rem;

      .contact-us-layout {
        padding: 32px 12px;
        border-radius: 0;
      }
    }
    /** ****************************************** Footer ******************************************/
    .footer-copyrights {
      text-align: center;
    }

    /****************** generics *************************/
    .service-top-section {
      // padding: 24px 0;
      .services-content {
        max-width: 100%;
      }
    }
    .web-builder-content-our-packages-with-slider, 
    .web-builder-content-hotels,
    .web-builder-content-tours {
      padding: 24px;
      margin-top: 1rem;
    }

    /** ****************************************** result page ******************************************/
    .search-bread-crumb {
      padding: 16px;
    }
    .all-search-result {
      position: relative;
      padding: 24px 16px;
      .search-filter {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding: 12px;
        background-color: #fff;
        z-index: 9;
        transform: translateX(-100%);
        transition: transform .35s ease-in;

        .reset {
          margin: 1rem 0;
        }
        &.opened {
          transform: translateX(0);
          transition: transform .35s ease-in;
        }
        .close-filter-btn svg {
          width: 15px;
          height: 15px;
          path {
            stroke: rgba(var(--web-page-primary-color));
          }
        }
      }
      .header {
        flex-wrap: wrap;
        gap: 10px;
      }
      .result-item {
        width: 100% !important;
      }
      .filter-mobile-btn {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        bottom: 16px;
        right: 20px;
        background-color: rgba(var(--web-page-primary-color));
        
      }
    }


  }



}
