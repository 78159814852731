.theme-10 {

	// ****************************** header and hero ****************************** //
	.wb-hero {
		.hero-container {
			&::before {
				background: rgba(var(--web-page-primary-color), 0.32);
			}
		}

		.hero-content-container {
			padding: 24px !important;
			width: 100% !important;
			max-width: 600px;
			background: #2222227a;
			border-radius: 4px;
		}
	}

	.header-nav {
		display: flex;
		align-items: center;
		gap: 42px;

		.main-nav {
			gap: 26px;
		}
	}

	&.web-builder-preview .wb-header {
		padding: 16px 5em;
	}

	.services-content {
		text-align: center;
		padding-bottom: 1.6rem;
	}

	// ****************************** our services section ****************************** //
	&.web-builder-preview .wb-our-service {
		top: -100px;
		padding: 0 16px;
	}

	.wb-our-service {
		position: relative;
		background-color: transparent;
		z-index: 3;
		padding: 80px 16px;

		.service-cards-container {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
			gap: 16px;
			padding: 24px;
		}

		.service-card {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 16px;
			text-align: center;
			background-color: #ffffff;
			border-radius: 8px;
			box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
			gap: 10px;

			.title {
				font-weight: 700;
				color: rgba(var(--web-page-primary-color));
			}

			.icon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 56px;
				height: 56px;
				background-color: rgba(var(--web-page-primary-color), 0.08);
				border: 1px solid rgb(var(--web-page-primary-color));
				border-radius: 50%;

				svg {
					width: 50%;
					height: 50%;
				}

				svg path {
					stroke: rgb(var(--web-page-primary-color));
				}

				svg path {
					stroke: rgba(var(--web-page-primary-color));
				}
			}
		}
	}

	// ****************************** about us section ********************************** //
	#aboutus-section {}

	// ****************************** our pacakges section ****************************** //
	.wb-our-packages {
		background-color: rgba(var(--web-page-primary-color), 0.16);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 25px 0;
		background-blend-mode: luminosity;
		// height: 800px;

		.container {
			display: grid;
			grid-template-columns: 1.5fr 3fr;
			align-items: center;
			gap: 32px;
			border: 1px soild;

		}

		.services-content {
			margin: 0;
			max-width: 100%;
			text-align: start;
		}

		.service-top-section {
			padding: 0;
			flex-direction: column;

			.add-element-box {
				max-width: 100%;
			}

			.service-header,
			.service-body {
				color: rgb(var(--web-page-primary-color));
			}
		}

		.packages-cards-container {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
			gap: 24px;
		}

		.package-card {
			position: relative;
			display: flex;
			flex-direction: column;
			height: auto !important;
			padding: 0;
			border-radius: 10px;
			border: 1px solid #E2E8F0;
			background-color: #ffffff;
			box-shadow: 0px 0px 14px 0px #0000000D;
			transition: border .2s ease-in-out;
			gap: 8px;
			overflow: hidden;

			.card-img {
				width: 100%;
				height: 170px;
				overflow: hidden;

				img {
					width: 100%;
					height: 250px;
					object-fit: cover;

				}
			}

			.card-content-top {
				display: flex;
				justify-content: space-between;
				// flex-wrap: wrap;
				padding: 0 20px;
				color: #050001;
				gap: 16px;
			}

			.title {
				display: inline-block;
				font-size: 22px;
				font-weight: 700;
				max-width: 140px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

			.location {
				color: #707170;
				margin-bottom: 10px;
			}

			.price {
				font-size: 26px;
				line-height: 22px;
				font-weight: 700;
				color: rgb(var(--web-page-primary-color));

				.currency {
					font-size: 14px;
					font-weight: 400;
				}
			}

			.card-content-bottom {
				margin: 0 20px 8px;
				padding-top: 8px;
				border-top: 1px solid #E2E8F0;
				// border-top: 1px solid #000;
				color: #707170;
			}

			svg path {
				stroke: #707170;
			}
		}
	}

	// ****************************** hotels section ************************************ //
	.wb-hotels {
		.hotels-cards-container {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
			gap: 20px;
		}

		.hotel-card {
			position: relative;
			width: 100%;
			height: auto !important;
			padding: 0;
			border-radius: 10px;
			background-color: #ffffff;
			border: 1px solid rgba(226, 232, 240, 1);
			box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
			overflow: hidden;

			.card-img {
				width: 100%;
				height: 170px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scale(1);
					transform-origin: center center;
					transition: all 0.5s linear;
					backface-visibility: visible;
				}
			}

			.card-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				padding: 10px 16px;
				color: rgb(var(--web-page-primary-color));
				gap: 8px;
			}

			.location {
				padding-bottom: 10px;
				color: #707170;

				svg path {
					stroke: rgb(var(--web-page-primary-color));
				}
			}

			.price {
				display: flex;
				gap: 4px;
				align-items: baseline;
				padding-top: 10px;
				border-top: solid thin #e2e8f0;
				color: rgba(var(--web-page-primary-color));

				.amount {
					font-size: 24px;
					font-weight: 700;
				}

				.currency {
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
	}

	// ****************************** tours section ************************************* //
	.wb-tours {
		.tours-cards-container {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(calc(50% - 16px), 1fr));
			gap: 16px;
		}

		.tour-card {
			padding: 24px;
			margin: 0;
			max-width: 100%;
			background-size: cover !important;
			background-position: center !important;
			background-repeat: no-repeat;
			box-shadow: 0px 2px 16px 0px #00000014;
			border: none;
			border-radius: 8px;
			min-height: 350px;

			.card-content {
				position: relative;
				display: flex;
				flex-direction: column;
				width: 100%;
				color: #fbfbfa;
				z-index: 3;
				margin-top: auto;
				gap: 8px;

				.title {
					font-weight: 600;
					font-size: 28px;
				}

				.price {
					background-color: rgba(var(--web-page-primary-color));
					width: fit-content;
					padding: 7px 16px;
					border-radius: 32px;
					font-size: 16px;
					// line-height: 27px;

					.amount {
						font-size: 26px;
						font-weight: 700;
						padding: 0px 6px;
					}

					.currency {
						font-size: 14px;
						font-weight: 500;
					}
				}

				svg path {
					stroke: #ffffff;
				}
			}
		}
	}

	// ****************************** flight section ************************************ //
	.wb-flights {
		padding-block: 100px;
		background-color: rgba(var(--web-page-primary-color), 0.24);
		background-image: url("../../../../assets/images/webBuilder/theme10-flight-bg.jpg");
		background-size: cover;
		background-position: center;

		.service-top-section {
			padding-block: 0;
		}

		.services-content {

			.service-header,
			.service-body {
				color: #ffffff !important;
			}
		}

		.flights-cards-container {
			grid-template-columns: repeat(2, 1fr) !important;
		}

		.flight-service-card {
			display: flex;
			flex-direction: column;
			padding: 16px;
			text-align: center;
			color: #ffffff;
			border-radius: 8px;
			border: 1px solid #E5E5E5;
			backdrop-filter: blur(4px);
			background-color: rgba(var(--web-page-primary-color), 0.64);
			gap: 18px;

			.header {
				padding: 0;
				border: 0;
				padding-bottom: 16px;
				border-bottom: 1px solid #e5e5e5;
				background-color: transparent;
				color: #ffffff;

				h3 {
					font-weight: 700;
					font-size: 20px;
					color: #ffffff;
				}
			}

			.cta-btn {
				padding: 8px 24px;
				color: #fff !important;
				background-color: rgba(var(--web-page-primary-color));
			}

			.destination-count {
				position: relative;
				display: flex;
				align-items: center;
				padding: 6px 24px;
				color: rgba(var(--web-page-primary-color));
				background-color: #ffffff;
				border: 1px solid rgba(var(--web-page-primary-color), 0.16);
				gap: 6px;

				&:hover .addition-destinations-list {
					opacity: 1;
					visibility: visible;
				}
			}

			.destination-port {
				display: flex;
				justify-content: space-evenly;
				color: #ffffff;
				gap: 24px !important;

				.port-country {
					font-weight: 400;
					font-size: 14px;
					color: #ffffff;
				}

				.arrow {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 32px;
					height: 32px;
					min-width: 32px;
					min-height: 32px;
					background-color: #E1EDF5;
					border-radius: 50%;

					svg {
						width: 75% !important;
						height: 75%;
					}
				}
			}

			.addition-destinations-list {
				position: absolute;
				display: flex;
				flex-direction: column;
				width: 150%;
				top: 38px;
				inset-inline-end: 0;
				padding: 16px;
				border: 1px solid #dddcdc;
				border-radius: 8px;
				background-color: #ffffff;
				gap: 12px;
				opacity: 0;
				visibility: hidden;
				z-index: 3;

				svg path {
					stroke: rgba(var(--web-page-primary-color));
				}

				.destination-port {
					gap: 16px !important;
					justify-content: space-between;
					align-items: center;
					color: rgba(var(--web-page-primary-color));

					.font-weight-bold {
						width: 90px;
					}

					.port-country {
						color: #000000;
					}
				}
			}

			.price {
				color: #ffffff;
			}
		}

	}

	// ****************************** faq section *************************************** //
	.wb-faqs {
		padding: 80px 0;

		.faq-container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			align-items: center;
			gap: 30px;
		}

		.faq-questions .h4,
		.faq-questions .body {
			color: rgb(var(--web-page-primary-color));
		}

		.faqs-question-wrapper {
			padding: 16px;
			margin-bottom: 20px;
			border-radius: 16px;
			border: 1px solid rgb(var(--web-page-primary-color));
			text-align: center;

			.faqs-container-question p {
				font-size: 20px;
				font-weight: 700;
				color: rgba(var(--web-page-primary-color));
				margin: 0 auto;
			}

			.faqs-container-body {
				margin-top: 16px;
				font-weight: 400;
				color: #363636B2;
			}
		}


		.faq-image {
			position: relative;
			width: 100%;
			height: 100%;
			max-height: 360px;
			border-radius: 6px;
			overflow: hidden;

			.abstract-bg {
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				position: absolute;
				top: 0;
				inset-inline-start: 0;
				width: 200px;
				height: 200px;
				z-index: 0;
				opacity: 0.32;
			}

			img {
				position: relative;
				width: 100%;
				height: 100%;
				top: 40px;
				inset-inline-start: 40px;
				object-fit: cover;
				z-index: 1;
			}
		}

		.faq-btn {
			border: none !important;
			background-color: transparent !important;
		}

		.upload-file-container {
			position: relative;
			width: 100%;
			height: 100%;

			label {
				width: 100%;
				height: 100%;
			}
		}
	}

	// ****************************** contact us section ******************************** //
	.wb-contact-us {
		padding: 70px;
		// padding-inline: 70px;

		.contact-form-holder {
			display: flex;
			// align-items: flex-end;
			position: relative;
			padding: 24px;
			width: 100%;
			margin: 0 auto;
			max-width: 800px;
			margin-inline-start: auto;
			background-color: #F9F9F9;

			// background-color: #ba0f0f;
			box-shadow: 0px 2px 12px 0px #00000014;
			gap: 40px;
		}

		.t-title {
			font-weight: 700;
			color: rgb(var(--web-page-primary-color));
		}

		.t-body {
			color: #707170;
			margin-bottom: 1rem;
		}

		.contact-us-form {

			width: 60%;
			margin-inline-start: auto;
			padding: 24px;

			box-shadow: 0px 4px 12px 0px #00000014;

			.textarea-field {
				background-color: #ffffff;
				border: 1px solid #ebebeb;
				border-radius: 0.3125rem;
				font-size: 13px;
				width: 100%;
				padding: 10px;

				&::placeholder {
					color: #dddcdc !important;
					font-weight: 600;
				}

				svg path {
					stroke: rgba(var(--web-page-primary-color));
				}
			}

			.submit-btn {
				padding: 14px 0;
				font-weight: 700;
				font-size: 16px;
				width: 100%;
				background-color: rgba(var(--web-page-primary-color));
				color: #ffffff;
			}
		}

		.contact-us-image {
			position: relative;
			bottom: 0;
			width: 100%;
			max-width: 280px;

			.upload-file-container {
				height: 100%;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}

			label {
				height: 100%;
				margin-bottom: 0;
			}

		}
	}

	// ****************************** footer section ************************************ //
	.footer-container {
		justify-content: flex-start;
		background: rgba(var(--web-page-primary-color));

		.footer-content {
			display: flex;
			align-items: flex-start;
			// justify-content: space-between;
			gap: 120px;
		}
		.footer-title {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 24px;
			font-family: Cairo;
			letter-spacing: 0.02em;
			line-height: 44.98px;
		}

		.footer-main-data {
			display: flex;
			flex-direction: column;
			max-width: 450px;
			gap: 20px;
		}

		.logo-container img {
			width: 200px;
			height: auto;
			max-height: 60px;
		}

		.social-media {
			display: flex;
			gap: 1rem;
			justify-content: flex-start;
			align-items: center;
			
			.text {
				font-size: 1.3rem;
				line-height: 1.5;
				font-weight: 600;
			}

			.links {
				display: flex;
				gap: 0.5rem;
				justify-content: flex-start;
				align-items: center;
			}
		}

		.links-list {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			margin-inline-start: auto;
			gap: 20px;

			.text-secondary-color {
				color: #ffffff !important;
			}
		}

		.contact-info {
			display: flex;
			flex-direction: column;
			margin-inline-start: auto;
			gap: 20px;
			
			svg {
				width: 26px;
				height: 26px;
				path {
					stroke: #ffffff;
				}
			}
		}
		
	}

	// generic  class in theme 10


	.title {
		display: inline-block;
		font-size: 20px;
		font-weight: 600;
		max-width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.empty-service::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(112, 113, 112, 0.7) !important;
		z-index: 3;
	}

	.add-service-btn {
		top: 50%;
		z-index: 4;
	}

	.services-content {
		max-width: 100% !important;

		.service-header {
			font-size: 28px;
			font-weight: 700;
			color: rgb(var(--web-page-primary-color));
		}

		.service-body {
			font-size: 16px;
			font-weight: 500;
			color: #707170;
		}
	}

	@media screen and (max-width: 600px) {
		.wb-faqs {
			.faq-container {
				display: flex;
				flex-direction: column-reverse;
			}
		}

		.wb-tours {
			.tours-cards-container {
				grid-template-columns: 1fr;
			}
		}
	}

	.web-builder-page-contianer .services-content {
		display: none;
	}
}