// market search intro
.new-market-service-box {
	position: relative;
	max-width: 500px;
	padding: 16px;
	background-color: #ffffff;
	border-radius: 24px;
	border: 1px solid #f7f6f6;
	color: #707170;

	&:hover .main-img-container img {
		transform: scale(1.5, 1.5);
		transition: transform 1.5s ease;
	}

	.main-img-container {
		width: 100%;
		border-radius: 12px;
		max-height: 214px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			transform: scale(1, 1);
			transition: transform 1.5s ease;
		}
	}

	.title {
		margin: 1rem 0;
		font-size: 22px;
		font-weight: 600;
		line-height: 33.68px;
		color: #050001;
	}

	.available-services {
		padding-top: 10px;
		border-top: 1px solid #f7f6f6;
		h3 {
			// font-size: 14px;
			font-weight: 400;
			line-height: 26.24px;
		}

		.services-list {
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
		}

		.service-item {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 6px 8px;
			background-color: #fefeff;
			border: 1px solid #f7f6f6;
			border-radius: 8px;
			font-size: 14px;

			.service-new-item {
				position: absolute;
				top: -5px;
				inset-inline-end: 5px;
				padding: 3px 6px;
				font-size: 10px;
				font-weight: 600;
				line-height: 8px;
				text-align: center;
				background-color: #ea5455;
				color: #fff;
				border-radius: 26px;
			}
		}
	}
	.btn-start-service {
		width: 100%;
		margin-top: 1rem;
		padding: 10px 0;
		font-size: 16px;
		font-weight: 700;
		line-height: 29.98px;
		background-color: #d4a655;
		color: #fefeff;
		border-radius: 8px;
	}
}

.view-services-wrapper {
	margin: -$main-container-padding;
}

// market search bar
.search-services-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 250px;
	// padding: 40px 0;
	background-position: center;
	background-size: cover;

	min-height: calc(100vh - 181px);
	padding: 0;

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		opacity: 0.6;
		z-index: 0;
	}

	&.global-wrapper {
		margin-top: -32px;
		margin-inline: -32px;
	}

	.services-list {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 10px;
		align-items: center;
		width: fit-content;
		padding: 8px 10px;
		color: #dddcdc;
		border: 1px solid #dddcdc;
		border-radius: 8px;
		margin: 0 auto;
		z-index: 2;

		.service-item {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 12px 16px;
			font-size: 18px;
			font-weight: 600;
			cursor: pointer;
		}

		.active-item {
			background-color: #fff;
			color: #d4a655;
			font-weight: 700;
			border-radius: 4px;
			cursor: default;
		}
	}

	.service-form-item {
		margin-top: 1.4rem;
		padding: 0.4rem 1rem;
		background-color: #fff;
		border-radius: 10px;
		border-bottom: 1px solid #f7f6f6;

		.allotment-form {
			display: flex;
			align-items: center;
			// margin: -1.2rem 1rem;
		}

		.form-item {
			width: 20%;
			border-right: 0.5px solid #f7f6f6;
			[dir="rtl"] & {
				border-left: 0.5px solid #f7f6f6;
			}
			padding-block: 10px;
			label {
				margin-bottom: 0px !important ;
				padding-inline: 6px;
				color: #707170;
				font-size: 14px;
				font-weight: 400;
			}
			.control-field {
				margin-block: 0 !important;
				border: none !important;
				svg {
					width: 15px;
					path {
						fill: #d4a655;
					}
				}
			}
			.control-field__select {
				width: 85% !important;
				padding-inline: 10px;
				color: #050001;
				font-size: 16px;
				font-weight: 600;
			}
		}
		.btn-yellow {
			width: 18%;
			height: 40px;
			padding: 0px 32px;
			margin-inline: 12px;
			border-radius: 8px;
			background: #d4a655;
			color: #fefeff;
			font-size: 18px;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 6px;
		}
		[class$=-placeholder] {
			color: #707170;
			opacity: 0.5;
		}
	}

	.button-collapse {
		position: relative;

		.pax-container {
			position: absolute;
			background: white;
			z-index: 2;
			left: 0px;
			right: 0px;
			margin-inline: 0px;
			top: 50px;
			padding: 15px;
			border: 1px solid #f6f5f5;
			box-shadow: 2px 2px 2px #f1f1f1;

			.adult-pax {
				display: flex;
				justify-content: space-between;
				width: 100%;
				p {
					color: #000;
					font-size: 15px;
					span {
						color: #5e778d;
						font-size: 15px;
					}
				}
				.action {
					display: flex;
					gap: 25px;
					button {
						color: #ce933f;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						border: 2px solid #ce933f;
						background-color: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 3px;
						font-weight: 600;
						cursor: pointer;

						span {
							margin-bottom: 3px;
						}
					}
					button:disabled {
						color: #d5dae3;
						border: 2px solid #d5dae3;
					}
					.adultNum {
						font-size: 18px;
						font-weight: 600;
					}
				}
			}
			.childrensAge {
				.p_children {
					color: #5e778d;
					font-size: 15px;
				}
			}
			.children-age-select {
				p {
					font-size: 15px;
					font-weight: 500;
				}
				select {
					border: none;
					color: #ce933f;
				}
			}

			.childrensAge {
				.p_children {
					color: #5e778d;
					font-size: 15px;
				}
			}

			.children-age-select {
				p {
					font-size: 15px;
					font-weight: 500;
				}

				select {
					border: none;
					color: #ce933f;
				}
			}
		}
	}
}

// what we sell
.selling-services-container {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	padding: 16px 26px;
	background-color: #ffffff;
	border: 1px solid #f7f6f6;
	border-radius: 12px;

	.title {
		padding: 24px 0 20px 0;
		font-size: 24px;
		font-weight: 600;
		line-height: 16px;
		text-align: center;
		color: #050001;
	}

	.selling-services-filter {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;
		color: #707170;
		padding: 10px 0;

		.filter-item {
			padding: 6px 16px;
			background-color: #fefeff;
			border-radius: 8px;
			cursor: pointer;

			&.active {
				background-color: #d4a655;
				color: #fefeff;
				cursor: default;
			}
		}
	}

	.selling-services-cards-container {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
	}

	.selling-service-card {
		display: flex;
		flex-direction: column;
		border: 1px solid #f7f6f6;
		background-color: #fff;
		border-radius: 8px;
		padding: 16px;
		width: calc(25% - 15px);

		&:hover .card-img-container img {
			transform: scale(1.5, 1.5);
			transition: transform 1.5s ease;
		}

		&:hover .card-img-container .view-overlay {
			opacity: 1;
			visibility: visible;
			transition: opacity 0.8s ease, visibility 0.8s ease;
		}

		.card-title {
			color: #050001;
			font-size: 18px;
			font-weight: 700;
			line-height: 120%;
			margin-top: 16px;
			margin-bottom: 0;
		}

		.card-img-container {
			position: relative;
			max-width: 324px;
			height: 200px;
			border-radius: 8px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				transform: scale(1, 1);
				transition: transform 1.5s ease;
			}

			.view-overlay {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 10px 32px;
				border-radius: 8px;
				border: none;
				background-color: #d4a655;
				color: #fff;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 18px;
				font-weight: 600;
				line-height: 20px;
				z-index: 2;
				opacity: 0;
				visibility: hidden;
				cursor: pointer;
				transition: opacity 0.8s ease, visibility 0.8s ease;
			}
		}

		.empty-rate {
			color: #dddcdc;
		}

		.card-content {
			margin-bottom: 16px;
			font-size: 14px;
			font-weight: 400;
			color: #050001;

			.service-type {
				color: #d4a655;
				font-size: 14px;
				font-weight: 400;
				line-height: 16px;
			}

			.location {
				display: flex;
				align-items: center;
				margin: 8px 0;
				color: #050001;
				font-size: 14px;
				font-weight: 500;
				line-height: 168.4%;
			}

			.rating i:not(:first-child) {
				margin-inline-start: 4px;
			}
		}

		.card-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0 0 0;
			background-color: transparent;
			border-top: 1px solid #f7f6f6;
			margin-top: auto;

			.additional-info {
				display: flex;
				justify-content: center;
				align-items: center;
				// max-width: 160px;
				gap: 4px;
				padding: 0 8px;
				font-size: 14px;
				font-weight: 600;
				border-radius: 39px;

				&.discount {
					height: 32px;
					background: rgba(1, 157, 76, 0.08);
					color: #019d4c;
				}

				&.visa-warning {
					color: #f78c0f;
				}
			}

			.price-container {
				color: #050001;
				font-size: 14px;
				font-weight: 400;

				.price {
					color: #222222;
					font-size: 24px;
					font-weight: 600;
					line-height: 100%;
				}
				.price-for {
					color: #707170;
				}
			}
		}
	}
}

// packags
.new-market-packages-countries {
	.react-multi-carousel-track {
		display: flex;
		// gap: 16px;

		padding: 16px 0;
	}

	.react-multiple-carousel__arrow {
		background: #f7f6f6;

		&::before {
			color: #222222;
		}
		&:hover {
			background: #f7f6f6;
		}
	}

	.new-market-package-card {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 140px;
		padding: 16px 0;
		border-radius: 8px;
		border: 1px solid#F7F6F6;
		background: #fff;
		text-align: center;
		overflow: hidden;
		transition: border 0.8s ease;

		&:hover {
			border: 1px solid#EED9BA;
			transition: border 0.8s ease;
		}

		.title {
			color: #050001;
			text-align: center;
			font-size: 18px;
			font-weight: 700;
			margin: 16px 0 18px 0;
		}

		.content {
			display: flex;
			align-items: center;
			color: #050001;
			font-size: 14px;
			font-weight: 400;
			margin-top: auto;
		}
	}
}

.new-market-packages {
	.search-bar {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 16px 26px 16px 24px;
		border-radius: 8px;
		border: 1px solid #f7f6f6;
		background-color: #fff;
		gap: 10px;

		.package-search-field {
			min-width: calc(24% - 10px);
			width: auto;

			.control-field {
				height: auto;
			}
		}

		.search-button {
			font-size: 16px;
			font-weight: 700;
			padding: 7px 50px;
			margin-top: 18px;
			border-radius: 8px;
			color: #fefeff;
			background-color: #d4a655;
		}
	}

	.selling-service-card {
		width: calc(33.33% - 12px);
	}

	.package-header {
		display: flex;
		margin: 24px 0;

		.title {
			font-size: 18px;
			font-weight: 700;
			line-height: 168.4%;
			color: #050001;
		}
	}

	.packages-filters {
		color: #050001;

		.reset-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 6px 16px;
			border-radius: 6px;
			border: 1px solid#EED9BA;
			background-color: #faf4eb;
			color: #6a4d1b;
			font-size: 18px;
			font-weight: 600;
			line-height: 16px;
		}

		.filter-title {
			font-size: 20px;
			font-weight: 700;
		}

		.content {
			padding: 16px;
			border-radius: 8px;
			border: 1px solid #f7f6f6;
			background-color: #fff;

			.pax-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 38px;
				height: 38px;
				border-radius: 50%;
				border: 1px solid #eed9ba;
				color: #d4a655;
				background-color: #fff;
				font-size: 18px;

				&.increase {
					background-color: #faf4eb;
				}

				&:disabled {
					color: #dddcdc;
					background-color: #fff;
					border: 1px solid #ebebeb;
				}
			}

			.toggle-parent span {
				color: #050001;
			}

			.count {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 38px;
				height: 38px;
				margin: 4px 8px;
				border-radius: 8px;
				border: 1px solid #dddcdc;
				color: #707170;
			}

			.price-range {
				.multi-range-slider {
					border: none !important;
					box-shadow: none !important;
				}

				.multi-range-slider .bar-inner {
					background-color: #121;
					height: 2px;
				}

				.multi-range-slider .bar-right,
				.multi-range-slider .bar-left {
					background-color: #fba50d;
					height: 2px;
					padding: 0;
				}

				.multi-range-slider .thumb {
					background-color: #d4a655 !important;
				}

				.multi-range-slider .thumb::before {
					content: "";
					box-shadow: none !important;
					border: none !important;
				}

				.multi-range-slider .labels {
					margin-inline: -10px;
				}

				.multi-range-slider .label {
					color: #121;
					font-size: 14px;
				}
			}

			.custom-checkbox .custom-control-input {
				position: absolute;
				left: 8px;
				z-index: -1;
				width: 14px;
				height: 14px;
				opacity: 0;
				top: 6px;
			}
			.custom-control-input:checked ~ .custom-control-label::before,
			.custom-control-label::before {
				top: 6px;
				width: 14px;
				height: 14px;
				border-radius: 4px;
				right: 0;
				border: 1px solid #050001;
			}

			.custom-control-label::after {
				top: 6px;
				width: 14px;
				height: 14px;
				border-radius: 4px;
				right: 0;
			}

			.custom-control-input:focus ~ .custom-control-label::before {
				box-shadow: none;
				background-color: transparent;
			}
		}
	}
}

[dir="rtl"] .custom-control-label::before {
	right: -1.5rem !important;
}
[dir="rtl"] .custom-control-label::after {
	right: -1.5rem !important;
}

.package-details-container {
	position: relative;

	&.market-place-packages .packages-navigation {
		top: 74px;
	}

	.packages-navigation {
		position: sticky;
		top: 0px;
		display: flex;
		padding: 20px 24px;
		justify-content: center;
		align-items: center;
		gap: 20px;
		border-bottom: 1px solid#F7F6F6;
		background-color: #fff;
		margin-bottom: 0px;
		z-index: 99;

		li {
			display: flex;
			padding: 12px;
			align-items: center;
			gap: 10px;
			font-size: 18px;
			font-weight: 600;
			color: #707170;
			cursor: pointer;

			&.active {
				font-size: 18px;
				color: #6a4d1b;
				border-bottom: 2px solid #6a4d1b;
				cursor: default;
			}
		}
	}

	.packages-dates-filter {
		position: sticky;
		top: 0px;
		display: flex;
		padding: 20px 24px;
		justify-content: center;
		align-items: center;
		gap: 16px;
		border-bottom: 1px solid#F7F6F6;
		background-color: #fff;
		margin-bottom: 0px;
		z-index: 99;
		.date {
			background-color: #f4f4f4;
			padding: 16px 20px;
			border-radius: 4px;
			border: 1px solid #7071701A;
			height: 44px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			p{
				color: #707170;
				font-weight: 400;
			}
		}
	}

	.package-title {
		font-size: 24px;
		font-weight: 700;
		color: #050001;
	}

	.public-page-preview-services {
		border-radius: 12px;

		.service-container {
			margin-top: 0 !important;
			margin-inline: 0 !important;
		}
	}

	.package-item {
		margin-top: 23px;
		padding: 32px;
		border: 1px solid#F7F6F6;
		background-color: #fff;
		border-radius: 12px;

		.package-view-container {
			display: flex;
			flex-direction: column;
			gap: 20px;
			padding: 24px 20px;
			border-radius: 4px;
			border: 1px solid #dddcdc;
			margin-top: 24px;

			.package-view-img {
				width: 220px;
				height: 116px;
				border-radius: 2px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.title {
				font-size: 20px;
				color: #050001;
			}

			.package-view-info {
				display: flex;
				flex-direction: column;
				gap: 8px;
				font-weight: 400;
				color: #707170;

				svg {
					width: 18px;
				}

				.rating i:not(:first-child) {
					margin-inline-start: 4px;
				}

				.empty-rate {
					color: #dddcdc;
				}
			}

			.package-view-tabs {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 32px;
				font-size: 18px;
				font-weight: 400;
				background-color: #f5f8fc;
				color: #707170;

				li {
					padding: 12px;
					cursor: pointer;
					border-bottom: 2px solid transparent;

					&.active {
						font-weight: 600;
						color: #1e85ff;
						border-bottom: 2px solid #1e85ff;
						cursor: default;
					}
				}
			}

			&.attraction .package-view-tabs {
				background-color: rgba(238, 217, 186, 0.3);

				.active {
					color: #d4a655;
					border-bottom: 2px solid #d4a655;
				}
			}

			.tab-content {
				color: #707170;
				line-height: 168.4%;
			}
		}
		&.required-docs {
			border: 1px solid rgba(212, 166, 85, 0.3);
			background-color: #faf4eb;
			.package-title {
				font-size: 18px;
			}
		}
	}
	.package-pricing {
		.add-room-btn {
			color: #d4a655;
			font-weight: 600;
			font-size: 16px;
			&:disabled {
				background: transparent !important;
				color: #707170;
			}
		}
		.hotels-pricing {
			.header {
				display: flex;
				padding: 12px 0;
				justify-content: flex-end;
				gap: 20px;

				span {
					display: flex;
					padding: 12px 16px;

					flex-direction: column;
					justify-content: center;
					align-items: center;
					min-width: 40%;
					gap: 8px;
					border-radius: 8px 8px 0px 0px;
					border: 1px solid rgba(20, 82, 124, 0.1);
					background-color: rgba(20, 82, 124, 0.1);
					color: #14527c;
					font-size: 18px;
					font-weight: 600;
				}
			}
			.content {
				padding: 16px;
				border: 1px solid#F7F6F6;
				color: #050001;
				hr {
					border-color: #f7f6f6;
				}
				.serivces {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}

				.service-item {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 1.1rem;
					padding: 1.1rem 0 0 0;
					border-top: 1px solid #f7f6f6;
				}
			}
		}
	}
	.checkout-btn {
		padding: 12px 16px;
		border-radius: 6px;
		border: 1px solid #eed9ba;
		background-color: #019d4c;
		color: #fff;
		font-size: 18px;
		font-weight: 600;
	}
	.text-success {
		color: #019d4c;
	}
	.view-price {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px 16px;
		border-radius: 6px;
		border: 1px solid #eed9ba;
		background-color: #D4A655;
		font-size: 18px;
		font-weight: 600;
		color: white;
		height: 44px;
		flex-basis: content;
	}
}

.product-itinerary-container {
	position: relative;
	border: 1px solid #dddcdc;
	border-radius: 8px;
	padding: 2px;
	margin-top: 24px;

	.product-itinerary-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px;
		background-color: #f7f6f6;
		color: #050001;

		h3 {
			font-size: 18px;
			font-weight: 700;
		}
	}

	.itinerary-timeline {
		padding: 16px;
		background-color: #fff;

		.itinerary-segment {
			position: relative;
			display: flex;
			gap: 28px;
			margin-bottom: 16px;
			color: #050001;

			.segment-point {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background-color: #faf4eb;
				aspect-ratio: 1;

				span {
					width: 50%;
					height: 50%;
					border-radius: 50%;
					background-color: #d4a655;
				}
			}

			&:not(:last-child) .segment-point::after {
				content: "";
				position: absolute;
				top: 26px;
				width: 2px;
				height: calc(100% - 26px);
				background-color: #dddcdc;
				z-index: 0;
			}

			.segment-time {
				font-size: 14px;
				font-weight: 400;
				color: #707170;
			}

			.segment-details {
				display: flex;
				flex-direction: column;
				padding: 20px;
				gap: 10px;
				border-radius: 8px;
				background-color: #fbfbfa;
				flex-grow: 1;

				.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					aspect-ratio: 1;
				}

				.info-list {
					list-style: square;
					margin-top: 8px;
					padding: 0 18px;

					li::marker {
						font-size: 12px;
					}
				}
				.more-details-btn {
					font-size: 14px;
					color: #1e85ff;
					font-weight: 400;
					width: fit-content;
					padding: 0;
				}
			}
		}
	}
	.external .icon {
		border: 1px solid rgba(0, 207, 232, 0.16);
		background-color: rgba(0, 207, 232, 0.08);
		width: 44px;
		height: 44px;
		border-radius: 50%;
	}

	.internal .icon {
		border: 1px solid rgba(234, 84, 85, 0.16);
		background-color: rgba(234, 84, 85, 0.08);
		width: 44px;
		height: 44px;
		border-radius: 50%;
	}

	.hotel .icon {
		border: 1px solid rgba(255, 159, 67, 0.16);
		background-color: rgba(255, 159, 67, 0.08);
		width: 44px;
		height: 44px;
		border-radius: 50%;
	}

	.attraction .icon {
		border: 1px solid #7367f0;
		background-color: rgba(115, 103, 240, 0.08);
		width: 44px;
		height: 44px;
		border-radius: 50%;
	}
}

.global-visa-form {
	display: flex;
	// align-items: center;
	gap: 10px;
	// margin: -1.2rem 1rem;

	.global-visa-form-item {
		position: relative;
		width: 16.667%;
		padding-inline: 10px;
		margin-block: 10px;
		border-radius: 8px;
		border-right: 1px solid #f7f6f6;

		&.country {
			border-radius: 8px;
			background-color: #f5f5f5;
			div[class$="-IndicatorsContainer"] {
				display: none;
			}
		}

		label {
			margin: 0;
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			color: #707170;
		}
		.control-field__select {
			margin: 0 5px;
		}

		.control-field {
			flex-wrap: nowrap;
			height: auto;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			padding-inline-start: 0 !important;
			border: 0 !important;
			align-items: center;
			img {
				width: 20px;
				height: 20px;
				margin: 0 !important;
			}
			svg {
				width: 15px;
			}
			svg path {
				fill: #d4a655;
			}
		}
		div[class$="-placeholder"],
		div[class$="-singleValue"] {
			color: #050001;
			font-size: 16px;
			font-weight: 600;
		}
	}
}

.global-visa-details {
	.bread-crumb {
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		color: #f5b950;

		.country-name {
			color: #707170;
		}
	}
	.visa-wrapper {
		display: flex;
		flex-direction: column;
		padding: 32px 26px;
		gap: 12px;
		border-radius: 8px;
		border: 1px solid#F7F6F6;
		background-color: #fff;
		margin-top: 24px;

		.header-title {
			color: #050001;
			font-size: 18px;
			font-weight: 600;
			line-height: 24px;
		}
		.font-grey {
			font-size: 16px;
			font-weight: 600;
			line-height: 22px;
			color: #707170;
		}
		.direction-initial {
			direction: initial !important;
		}
	}
	.package-details {
		flex-direction: column;
		.row {
			display: flex;
			gap: 24px;
			width: 100%;
		}
		.item {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 32px;
			gap: 10px;
			border-radius: 8px;
			background-color: #f5f5f5;
			flex-grow: 1;
		}
	}

	.visa-terms {
		ol {
			list-style: none;
			color: #000;
			font-size: 18px;
			font-weight: 400;
			line-height: 32px;
		}
	}

	.visa-apply {
		.control-field {
			flex-wrap: nowrap;
			align-items: center;
		}
		.visa-label {
			margin: 0;
			font-size: 16px;
			font-weight: 600;
			color: #707170;
		}
		div[class$="-placeholder"],
		div[class$="-singleValue"] {
			color: #050001;
			font-size: 16px;
			font-weight: 600;
		}
		div[class$="-indicatorContainer"] svg path {
			fill: #050001;
		}
		.control-field__select {
			margin: 0 5px;
		}

		.calc-price-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 7px 16px;
			gap: 8px;
			border-radius: 8px;
			background-color: #d4a655;
			color: #fefeff;
			font-size: 18px;
			font-weight: 600;
			margin-top: 28px;
		}
		.visa-checkout {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding: 16px 26px;
			gap: 10px;
			border-radius: 8px;
			background-color: #f5f5f5;
			flex-grow: 1;
			margin: 0 auto;

			.info {
				position: relative;
				display: flex;
				width: 320px;
				padding: 0;
				gap: 20px;

				.item {
					display: flex;
					align-items: center;
					flex-grow: 1;
					border-inline-end: 2px solid #dbdade;
				}

				p {
					position: relative;
					display: flex;
					padding: 0 8px;
					font-size: 14px;
					font-weight: 600;
					line-height: 18px;
					color: #f78c0f;
				}
				// d::after {
				// 	content: "";
				// 	position: absolute;
				// 	height: 32px;
				// 	width: 1px;
				// 	background-color: #DBDADE;
				// 	inset-inline-end: 0px;
				// }

				svg {
					width: 20px;
					flex: 0 0 20px;
				}

				svg path {
					stroke: #f78c0f;
				}
			}

			.price {
				display: flex;
				flex-direction: column;
				padding: 0 10px;
				font-size: 14px;
				font-weight: 400;
				color: #707170;
				.total {
					color: #050001;
					line-height: 124%;
					font-size: 24px;
					font-weight: 600;
				}
				.per-visa {
					color: #019d4c;
				}
			}
			.checkout-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 8px;
				margin-top: 6px;
				border-radius: 8px;
				background: #d4a655;
				font-size: 18px;
				font-weight: 600;
				color: #fefeff;
				svg path {
					stroke: #fefeff;
				}
			}
			.terms-modal-btn {
				border: 0;
				background-color: transparent;
				padding: 0;
				color: #2969ff;
			}
			.checkbox-input {
				accent-color: #d4a655;
			}
		}
	}
}

.terms-conditions-modal {
	h4 {
		width: 100%;
		text-align: center;
		padding: 12px 0;
	}
	[dir="rtl"] & .modal-body {
		text-align: right !important;
	}
}

[dir="rtl"] .visa-apply .arrow-icon {
	transform: scale(-1, 1) !important;
}
[dir="rtl"] .visa-checkout .checkout-btn svg {
	transform: scale(1, 1) !important;
}

@media (max-width: 1200px) {
	.search-services-wrapper {
		.services-list {
			width: 90%;
			font-size: 16px;
		}
	}
}
@media (max-width: 992px) {
	.view-services-wrapper {
		.service-form-item .allotment-form {
			margin: 0;
			flex-wrap: wrap;
			.form-item {
				width: 100%;
			}
		}

		.global-visa-form {
			flex-wrap: wrap;
			margin: initial;
		}

		.global-visa-form-item {
			width: calc(50% - 5px);
			border-right: 0;
		}
	}
}

@media (max-width: 776px) {
	.selling-services-container {
		.selling-service-card {
			width: calc(50% - 12px);

			.card-img-container {
				height: 140px;
			}
		}
	}

	.new-market-packages .search-bar .package-search-field {
		width: 100%;
	}

	.view-services-wrapper.service-form-item .allotment-form {
		.form-item {
			width: 100%;
		}
	}

	.global-visa-details .visa-apply .visa-checkout {
		width: 100%;
		.price {
			padding: 0;
		}
	}

	.global-visa-form-item {
		width: 100% !important;
	}
}

@media (max-width: 576px) {
	.view-services-wrapper {
		margin-top: 0;
	}
	.search-services-wrapper {
		.services-list {
			width: 100%;
			font-size: 16px;
		}
	}

	.selling-services-container {
		.selling-service-card {
			width: 100%;
		}

		.card-img-container {
			max-width: 100% !important;
		}
	}
	.global-visa-form {
		.global-visa-form-item {
			.control-field {
				padding-inline-start: 0.5rem !important;
			}
			label {
				color: #fff;
			}
		}
	}
}


.mp-transfer-response {
	margin: -32px;

	.details-wrapper {
		padding: 32px;
		max-width: 1320px;
		margin: 0 auto;
	}

	.transfer-response-header {
		padding: 32px 0;

		form {
			padding: 20px;
			background-color: #ffffff;
			border-radius: 20px;
		}
	}
	.transfer-response-details {
		display: flex;
		align-items: center;
		padding: 10px;
		background-color: #ffffff;
		box-shadow: 0px 1px 12px 0px #0000000A;
		border-radius: 6px;
		color: #707170;
		gap: 24px;

		h1 {
			font-size: 18px;
			color: #222222;
		}

		img {
			width: 150px;
			height: 120px;	
			object-fit: cover;
			border: 1px solid #DDDCDC;
			border-radius: 6px;
		}

		svg {
			width: 18px;
			height: 18px;

			path {
				stroke: #707170;
			}
		}
	}
	.transfers-list {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	
	.list-item {
		display: flex;
		justify-content: space-between;
		padding: 10px;
		background-color: #FFFFFF;
		border-radius: 6px;
		box-shadow: 0px 1px 12px 0px #0000000A;
		gap: 24px;
		color: #222222;

		h2 {
			align-self: center;
			font-weight: 600;
			font-size: 18px;
		}

		img {
			width: 150px;
			height: 110px;	
			object-fit: cover;
			border-radius: 8px;
		}
		.item-spec {
			display: flex;
			font-weight: 600;
			gap: 32px;

			.tick-circle path {
				stroke: #019D4C;
			}
		}
		.item-quantity {
			display: flex;
			align-items: center;
			padding-inline: 16px;
			border-inline-start: 0.5px solid #DDDCDC;
			gap: 10px
		}
		.text-light {
			font-size: 15px;
			font-weight: 400;
			line-height: 30px;
			color: #707170 !important;
		}
	}

	.summary-wrapper, .checkout-wrapper {
		padding: 16px;
		background-color: #FFFFFF;
		border-radius: 8px;
		box-shadow: 0px 1px 12px 0px #00000014;

		.text-light {
			font-size: 15px;
			font-weight: 400;
			// line-height: 1.2;
			color: #98A2B3 !important;
		}

	}
	
	.summary-item {
		display: flex;
		align-items: center;
		gap: 10px;

		img {
			width: 80px;
			height: 80px;	
			object-fit: cover;
			border-radius: 8px;
		}

	}
	.price-text {
		font-weight: 700;
		font-size: 18px;
		color: #D4A655 !important;
	}

	.quantity-input {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;

		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 38px;
			height: 38px;
			font-size: 24px;
			font-weight: 700;
			border: 1px solid #EED9BA;
			border-radius: 50%;

			&:focus {
				box-shadow: 0 0 0 0.2rem rgba(112, 113, 112, 0.15);
			}	

			&:disabled {
				border: 1px solid #979797 !important;
				background: #979797 !important;
				svg path {
					stroke: #DDDCDC;
				}
			}
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 6px 16px;
			font-weight: 400;
			font-size: 18px;
			border: 1px solid #DDDCDC;
			border-radius: 8px;
		}
		.increase-btn {
			background-color: #FFFBF4;
		}
	}

	.checkout-wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;

		svg path {
			stroke-width: 3px;
		}

		.book-btn {
			width: 100%;
			padding: 11px 32px;
			background-color: #D4A655;
			color: #fff;
			font-size: 16px;
			font-weight: 700;
			border-radius: 8px;
			border: none;
		}
		.price {
			font-size: 16px;
			font-weight: 400;
			color: #0A3352;
		}
		.price .amount {
			font-size: 24px;
			font-weight: 600;
		}
		.total-title {
			color: #0A3352;
			font-size: 14px;
			font-weight: 600;
		}
		.text-caption {
			font-size: 16px !important;
		}

		input[type=checkbox] {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			margin-top: 6px;
			appearance:none;
			border-radius:10%;
			border: 1px solid #D4A655;
			box-shadow: none;
			font-size: 1em;
		}

		input[type=checkbox]:checked:after {
			content: '\2713';
			color:white;
		}
		input[type='checkbox']:checked {
			background-color: #D4A655;
		}
	}

	.terms-condition  {
		color: #112233;

		.btn  {
			font-size: 16px !important;
			color: #1E85FF;
			margin: 0;
		}

	}
}

.mp-transportation-terms-modal {
	text-align: start;
	.modal-title {
		padding-block: 16px;
		width: 100%;
		text-align: center;
	}
	li {
		list-style: disc;
	}
}