.request__page-container {
	--primary: #d4a655;
	--primary_rgb: 212, 166, 85;
	position: relative;
	.navigation__links {
		display: flex;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		.navigation__link {
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #dddcdc;
			padding: 0.5rem 1.2rem;
			color: #000000;
			border-radius: 6px;
			font-weight: 600;
			transition: all 0.3s ease-in-out;
			&:hover {
				color: var(--primary);
				border-color: var(--primary);
				background-color: rgba(var(--primary_rgb), 0.2);
			}
			&.active {
				color: var(--primary);
				border-color: var(--primary);
				&:hover {
					background-color: rgba(var(--primary_rgb), 0.2);
				}
			}
		}
	}
	.reset-btn {
		background-color: rgba(var(--primary_rgb), 0.2);
		border: 1px solid var(--primary);
	}
	.control-field-wrapper {
		background-color: #ffffff;
		border: 1px solid #ebebeb;
		border-radius: 0.3125rem;
		font-size: 12px;
		position: relative;
		.control-field {
			@extend .control-field-wrapper;
			border: none;
			height: auto !important;
		}
		.control-field__label {
			padding: 3px 10px;
			font-size: 14px;
		}
		.add-new-btn {
			position: absolute;
			top: 50%;
			right: 12px;
			transform: translateY(-50%);
			cursor: pointer;
			[dir="rtl"] & {
				right: unset !important;
				left: 12px !important;
			}
		}
	}
	.tag {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #f3f3f3;
		padding: 4px 6px 4px 6px;
		gap: 10px;
		border-radius: 3px;
	}
	.upload-file-label {
		font-size: 1rem;
		font-weight: 600;
		color: #000000;
	}
	.upload-file-component-input {
		background-color: #ffffff;
		justify-content: flex-start;
		padding-right: 1rem;
		padding-left: 1rem;
	}
	.mirror-rtl:is([dir="rtl"] *){
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}
	.text-start{
		text-align: start !important;
	}
}

.request__page-modal {
	position: fixed;
	inset: 0px;
	background-color: rgba(255, 255, 255, 0.75) !important;
	z-index: 10000 !important;
	.ReactModal__Content {
		max-width: 700px !important;
		z-index: 10000 !important;
		margin-right: auto !important;
		margin-left: auto !important;
    inset: 40px 40px auto 40px !important;
    max-height: 92vh !important;
		scrollbar-width: 0;
		scrollbar-color: transparent;
		scrollbar-track-color: transparent;
		&:hover {
			scrollbar-width: 4px;
			scrollbar-color: #c59a52;
			scrollbar-track-color: #fff7e9;
			&::-webkit-scrollbar {
				display: block;
				opacity: 1;
				visibility: visible;
			}
		}
		&::-webkit-scrollbar {
			width: 4px;
			display: none;
			opacity: 0;
			visibility: hidden;
		}
		&::-webkit-scrollbar-track {
			border-radius: 5px;
			width: 4px;
			background-color: #fff7e9;
		}
		&::-webkit-scrollbar-thumb {
			width: 4px;
			background-color: #c59a52;
			border-radius: 5px;
		}
    .loader__container{
      background-color: #fffffff0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10000;
    }
	}
}


// Custom nav styles for requests link

.main-nav-container{
	position: relative;
	.float-nav-link{
		border: 1px solid #d4a655;
		border-radius: 8px;
		.item:not(.active){
			color: #d4a655;
		}
		.item.active{
			svg{
				path{
					stroke: #ffffff;
				}
			}
		}
	}
	@media screen and (min-width: 1400px) {
		padding-inline-end: 8.75rem;
		.float-nav-link{
			position: absolute;
			inset-inline-end: 1.5rem;
		}	
	}
}