.wallet-wrraper {
	//background: url(../../../assets/images/wallet/wallet-ground.png);
	padding-top: 1.5rem;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	// height: 60vh !important;
	// width: 100% !important;
	.current-balance-box {
		.current-head {
			margin-bottom: 0.2rem;
			color: #324356;
		}
		.current-price {
			font-size: $text-caption + 38;
			margin: 0;
			color: #324356;
			margin-right: 1rem;
		}
		.select-balance {
			height: 30px;
			background-color: #0184fe;
			color: #fff;
			border: none;
			width: 15%;
			border-radius: 3px;
			cursor: pointer;
		}
	} //current-balance-box

	.add-credit-box {
		.current-head {
			color: #324356;
			margin: 0;
		}
		.btn-success {
			margin: 0.3rem 0.5rem 0.3rem 0rem;
			padding: 1rem;
		}
	} //add-cerdit-box
}

.arrows-box {
	display: flex;
	justify-content: center;
	flex-direction: column-reverse;
	padding: 0 0.5rem;
	i {
		margin: -3px;
	}
}
.triangle {
	position: absolute;
	top: 30px;
	// top: 15px;
	//right: -9px;
	/* width: 9px; */
	border-top: 12px solid #cb9a51;
	border-right: 3px solid transparent;
	border-left: 10px solid transparent;
	//transform: rotateZ(40deg);

	[dir="rtl"] & {
		left: -9px;
		transform: rotateZ(220deg);
	}
	[dir="ltr"] & {
		right: -9px;
		// right: unset;
		// left: 22%;
		/* left: 404px; */
		transform: rotateZ(40deg);
	}
}
.main-filter-payment {
	.main-label {
		position: relative;
		margin-bottom: 0;
		width: 100%;
	}
	.main-input-search {
		border: 1px solid $gray-200;
		height: 35px;
		font-size: $text-caption - 1;
		padding: 0 0.3rem;
		width: 100%;
		outline: none;
		margin: 0 0.2rem;
		color: #757575;
		border-radius: 5px;
		overflow: hidden !important;
		box-shadow: none;
	}
	.icx,
	i {
		position: absolute;
		bottom: 11px;
		right: 2px;
		font-size: $text-body;
		color: $gray-300;
		[dir="rtl"] & {
			right: auto;
			left: 2px;
		}
	}

	.reset-filter {
		display: flex;
		align-items: center;
	}
} //main-filter

//Summary
.recharts-wrapper {
	// position: absolute !important;
	right: 0;
	[dir="rtl"] & {
		right: auto;
		left: 0;
	}
}

.modal-transaction {
	.modal-header {
		.close {
			margin: -1rem 0 !important;
		}
	}
}

.BalanceContent {
	padding: 12px !important;
}

.walletBalanceHeader {
	font-size: 25px;
	color: #324356;
	font-family: "Cairo";
	font-weight: 700;
}
.walletBalanceContent {
	background-color: #fff;
	border-radius: 10px;
	border: 1px solid #dbe6f1;
	padding: 24px;
}

.walletBalanceAmountContent {
	background-color: #deeaf3;
	border: 1px solid #dbe6f1;
	border-radius: 10px;
	align-items: center;
	padding: 35px 65px;
}

.walletBalanceAmountTitle {
	font-family: "Cairo";
	font-size: 28px;
	font-weight: 600;
	color: #324356;
	padding: 0px;
	margin: 0px;
}
.upload-images {
	background-color: #f7f7f7;
	border: 2px dashed #cb9a51;
	border-radius: 5px;
	height: 70px;
	small {
		vertical-align: middle;
		p {
			margin-top: 20px;
		}
		img {
			margin-top: 5px;
		}
	}
	.chose {
		background: #cb9a51;
		position: absolute;
		right: 10px;
		top: 20px;
		cursor: pointer;
		color: white;

		[dir="rtl"] & {
			right: auto;
			left: 10px;
		}
	}
	#uploadFile {
		display: block;
		width: 100%;
		text-transform: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
	}

	[dir="rtl"] & {
		.text-left {
			text-align: right !important;
		}
	}
}
.walletBalanceAmountNumber {
	font-weight: 400;
	color: #324356;
	padding: 0px;
	margin: 0px;
}
.AmountNumber {
	font-size: 20px;
}
.walletBalanceAmountPound {
	font-size: 14px;
}
.to {
	position: absolute;
	z-index: 1;
	top: 8% !important;
	left: 47% !important;
}
.d-hiden {
	visibility: hidden;
}
.text-yellow {
	color: #cb9a51 !important;
}
.font-15 {
	font-size: 15px;
}
.WalletBoxs {
	margin: 50px 0;
}
.date-filter {
	input {
		margin-top: 9px;
		padding: 25px 0 0 0;
		border: #fff;
		border-radius: 3px;
		width: 100%;
		color: #adadad;
	}

	input[type="date"]::-webkit-calendar-picker-indicator {
		cursor: pointer;
		border-radius: 4px;
		margin-right: 2px;
		opacity: 0.8;
		filter: invert(0.5);
	}
}
//wallet-icon
.activeWallet {
	background-color: #018027 !important;
}
.hiden {
	display: none !important;
}
.wallet-icons-header {
	.dropdown-item:hover {
		background-color: #faf2e8 !important;
	}

	.btn-wallet-icon:hover {
		background-color: #cb9a51 !important;
		h6 {
			color: #fff !important;
		}
	}
}
.interest.wallet-tabs .nav .nav-item {
	min-height: 55px;
	width: 40% !important;
	text-align: center;
	margin-bottom: 0px !important;
	background: #ebebeb !important;
}
.wallet-tabs .active .text {
	color: #fff !important;
}
// .bg-wallets {
// 	background-color: #cb9a51 !important;
// }

.wallet-wrraper .filter-update .control-field__body .date-icon {
	top: 0%;
}
@media print {
	.wallet-icon {
		display: none !important;
	}
}
@media (min-width: 800px) and (max-width: 1250px) {
	.box-wallet-tab {
		span {
			color: #0c3b5c;
			font-weight: 400;
			font-size: 14px;
		}
	}
	.nav-tabs {
		button {
			font-size: 15px;
			padding: 8px !important;
		}
	}
	.interest .nav .nav-item {
		min-height: 55px;
		width: 35% !important;
		text-align: center;
		margin-bottom: 0px !important;
		background: #ebebeb !important;
	}
	.wallet-tabs {
		width: 100% !important;
	}
	.main-filter-payment {
		width: 108% !important;
	}
	.nav-item {
		width: 100% !important;
	}
	.box-wallet-tab {
		.title-wallet {
			font-size: 14px;
		}
		h4 {
			font-size: 13px;
		}
		span {
			color: #0c3b5c;
			font-weight: 700 !important;
			font-size: 14px !important;
		}
	}
	.amount-safa {
		width: 100% !important;
	}
	.name-wallet {
		font-size: 15px;
	}
	.main-tabs1 {
		.w-95 {
			width: 99% !important;
		}
	}
}
.no-data-color {
	color: #999999 !important;
}
.text-field-option {
	.no-gutters {
		position: absolute !important;
		top: 0px !important;
		width: 78% !important;
		height: 100% !important;
	}
}
.w-35 {
	width: 35% !important;
}
@media (min-width: 1200px) and (max-width: 1400px) {
	.interest .nav .nav-item {
		min-height: 55px;
		width: 30% !important;
		text-align: center;
		margin-bottom: 0px !important;
		background: #ebebeb !important;
	}

	.nav-tabs {
		button {
			font-size: 15px;
			padding: 11px !important;
		}
	}
}
