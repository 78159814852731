.credit-card-head {
	color: $dark-blue;
	position: relative;
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	&:after {
		content: "";
		position: absolute;
		border: 2px dashed $gray-300;
		width: 35%;
		bottom: 8px;
		right: 0;
	}
	&::before {
		content: "";
		position: absolute;
		border: 2px dashed $gray-300;
		width: 35%;
		bottom: 8px;
		left: 0;
	}
} //credit-card-head

.credit-card-section {
	text-align: center;

	.credit-payment {
		width: 500px;
		min-height: 300px;
		background: $white;
		margin: 1rem auto;
		border-radius: 5px;
		border: 1px solid $gray-200;
		.payment-img-box {
			margin: 1rem auto;
		}
		.full-input {
			width: auto;
			background: $white;
			label {
				text-align: var(--left);
			}
		}
		.national-num {
			width: 80px;
			border-radius: 0px 5px 5px 0px;
			display: flex;
			input {
				text-align: center;
			}
		} //national-input
		.input-number {
			width: 100%;
			border-radius: 5px 0px 0px 5px;
		}
		.form-check {
			text-align: left;
			font-size: $text-caption;
			.form-check-label {
				cursor: pointer;
			}
			.form-check-input {
				margin-top: 0;
			}
			a {
				text-decoration: underline;
			}
		}
		.img-bank-box {
			margin: 1rem auto;
		}
	} //credit-payment
	.credit-buttons {
		display: flex;
		justify-content: space-between;
		width: 500px;
		margin: auto;
		margin-bottom: 1rem;
		.submit-btn {
			padding: 0.375rem 4.75rem;
		}
		
	}
} //credit-card-section

.payment-successful {
	text-align: center;
	padding-top: 3rem;
	.text-success {
		font-weight: bold;
	}
}
