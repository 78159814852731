.crm {
	.cs-requests {
		margin: 16px !important;
		background-color: #fff;
		padding: 14px 32px 32px 32px;
		border-radius: 8px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
		.title {
			h4 {
				font-size: 24px;
				font-weight: 700;
				color: rgba(5, 0, 1, 1);
				margin-block: 5px;
			}
			hr {
				color: #dddcdc;
			}
		}
		.filter {
			display: flex;
			align-items: center;
			background-color: #fff;
			border-radius: 8px;
			padding: 10px 16px;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
			border: rgba(221, 220, 220, 1);
			margin-inline: 16px;
			button.btn {
				background-color: rgba(212, 166, 85, 1);
				border-radius: 4px;
				padding: 16px;
				font-size: 16px;
				font-weight: 700;
				color: #fff;
				width: 110px;

				padding: 7px 20px 7px 20px;
			}
			.row {
				margin-inline: 0 !important;
			}
		}
		.table-cs-request {
			margin-top: 16px;
			border: 1px solid #dddcdc;
			border-radius: 8px;
			overflow: hidden;
			margin-inline: 16px;
			.table {
				margin-bottom: 0 !important;
				thead {
					background: #f7f6f6;
					color: #707170;
					th {
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: 1px;
						border-block: none !important;
					}
				}
				tbody {
					td {
						color: #707170;
						font-size: 16px;
						vertical-align: middle;
						.view {
							display: inline-block;
							background-color: rgba(212, 166, 85, 0.08);
							width: 32px;
							height: 32px;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							[dir="rtl"] & {
								transform: rotate(180deg);
							}
						}
						.new {
							border: 1px solid rgba(30, 133, 255, 1);
							color: rgba(30, 133, 255, 1);
							background-color: rgba(235, 244, 255, 1);
							text-align: center;
							border-radius: 8px;
							padding: 4px;
							font-size: 16px;
						}
						.accepted {
							border: 1px solid rgba(1, 157, 76, 1);
							color: rgba(1, 157, 76, 1);
							background-color: rgba(242, 255, 248, 1);
							text-align: center;
							border-radius: 8px;
							padding: 4px;
							font-size: 16px;

						}
						.pending {
							border: 1px solid rgba(255, 159, 67, 1);
							color: rgba(255, 159, 67, 1);
							background-color: rgba(255, 251, 246, 1);
							text-align: center;
							border-radius: 8px;
							padding: 4px;
							font-size: 16px;

						}
						.rejected {
							border: 1px solid rgba(234, 84, 85, 1);
							color: rgba(234, 84, 85, 1);
							background-color: rgba(255, 249, 249, 1);
							text-align: center;
							border-radius: 8px;
							padding: 4px;
							font-size: 16px;

						}
					}
				}
			}
		}
	
	}
	.no-result {
		h4 {
			color: rgba(0, 0, 0, 1);
			font-size: 28px;
			font-weight: 700;
			margin-bottom: 18px;
		}
		p {
			color: #707170;
			font-size: 16px;
		}
	}
	.customer-info{
		border-radius: 8px;
		padding: 16px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
		margin-block: 12px;
		background-color: #fff;
		.info-item{
			border-right:1px solid rgba(221, 220, 220, 0.32);
			p{
				color:#707170;
				font-size: 18px;
				font-weight: 600;


			}
			span{
				color:#050001;
				font-size: 14px;
				font-weight: 400;

			}

		}
	}
	.operation-tabs .item, .operation-tabs .active {
		flex-direction: row !important;
		align-items: center;
	}
}
.multi-select-crm-cs{
	.control-field ,
.control-field__select div[class$=-control],
.control-field__select div[class$=-control] div[class$=-ValueContainer]{
	height: auto !important;
	max-height: unset !important

}
}	
.cs-requests-info,.cs-requests-info-more{
	border-radius: 8px;
	padding: 16px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
	margin-block: 12px;
	background-color: #fff;
	.row{
		row-gap: 16px;
	}
	.info-item{
		border-right:1px solid rgba(221, 220, 220, 0.32);
		p{
			color:#707170;
			font-size: 18px;
			font-weight: 600
		}
		span{
			color:#050001;
			font-size: 16px;
			font-weight: 400;

		}

	}
	h6{
		color: #707170;
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 16px;
	}
	.crm-details-date-history{
		font-size: 16px;
		font-weight: 400;
		color:rgba(112, 113, 112, 1)
	}
	.new {
		border: 1px solid rgba(30, 133, 255, 1);
		color: rgba(30, 133, 255, 1);
		background-color: rgba(235, 244, 255, 1);
		text-align: center;
		border-radius: 8px;
		padding: 4px;
		font-size: 16px;
	}
	.accepted {
		border: 1px solid rgba(1, 157, 76, 1);
		color: rgba(1, 157, 76, 1);
		background-color: rgba(242, 255, 248, 1);
		text-align: center;
		border-radius: 8px;
		padding: 4px;
		font-size: 16px;

	}
	.pending {
		border: 1px solid rgba(255, 159, 67, 1);
		color: rgba(255, 159, 67, 1);
		background-color: rgba(255, 251, 246, 1);
		text-align: center;
		border-radius: 8px;
		padding: 4px;
		font-size: 16px;

	}
	.rejected {
		border: 1px solid rgba(234, 84, 85, 1);
		color: rgba(234, 84, 85, 1);
		background-color: rgba(255, 249, 249, 1);
		text-align: center;
		border-radius: 8px;
		padding: 4px;
		font-size: 16px;

	}
}
.cs-requests-info-more{
	.row{
		margin-bottom: 16px;
	}
	
	.btn-inprgres{
		border: 1px solid #FF9F43;
		padding: 8px;
		border-radius: 8px;
		background-color: #fff;
		color: #FF9F43;
		font-size: 16px;
		font-weight: 500;
	}
}
.btn-action{
	display: flex;
	gap:15px;
	.btn{
		padding: 7px 30px 7px 30px			;
		border-radius: 4px;
		color:#fff;
		font-size: 14px;
		font-weight: 700;
		width: 180px;
	}
	.btn-accept{
		background-color: rgba(1, 157, 76, 1);
	}
	.btn-reject{
		background-color: rgba(234, 84, 85, 1);
	}
}
.cs-request-log-tab{
	.operation-tabs{
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		.item {
			flex-direction: row;
			padding: 24px 35px !important;
		}
		
	}
	.call-log-buttons{
		.btn-ingoing,.btn-outgoing{
			padding: 10px 20px 10px 20px	!important;
			font-size: 16px;
			font-weight: 700;
		}
		.btn-outgoing{
			border:2px solid #ea5455;
			
		}
	}
	label{
		font-size: 16px;
		font-weight: 500;
		color: #707170;
	}
	textarea{
		border: 1px solid #DDDCDC;
		border-radius: 6px;
		padding: 16px;
	}
	.btn-color{
		background-color: #D4A655 !important		;
		padding: 8px 32px 8px 32px		;
		font-size: 16px;
		font-weight: 700;
		color: #FEFEFF;
	}
	.call-logs-history{
		h3{
			color: #222222;
			font-size: 18px;
			font-weight: 700;
			border-block: 1px solid #DDDCDC52;
			padding: 17px 0;
			margin-block: 12px;
		}
		.crm-details-text-secondary{
			color: #707170;
			font-size: 18px;
			font-weight: 600;
		}
		.crm-details-text-secondary-date{
			color: #707170;
			font-size: 18px;
			font-weight: 400;
		}
	}
	.head{
		p{
			span{
				color: #707170;
				font-size: 18px;
				font-weight: 600;
			}
		}
		.crm-details-text-secondary{
			color: #707170;
			font-size: 18px;
			font-weight: 400;
		}
		
	}
	.descrip.crm-details-text-secondary{
		color: #222222;
		font-size: 14px;
		font-weight: 400;
	}
	}
	
.view-customers-crm{
	margin: 16px !important;
		background-color: #fff;
		padding: 14px 32px 32px 32px;
		border-radius: 8px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
		.title {
			h4 {
				font-size: 24px;
				font-weight: 700;
				color: rgba(5, 0, 1, 1);
				margin-block: 5px;
			}
			hr {
				color: #dddcdc;
			}
		}
	.customer-info{
		border-radius: 8px;
		padding: 16px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
		margin-block: 12px;
		background-color: #fff;
		.info-item{
			border-right:1px solid rgba(221, 220, 220, 0.32);
			p{
				color:#707170;
				font-size: 18px;
				font-weight: 600;


			}
			span{
				color:#050001;
				font-size: 14px;
				font-weight: 400;

			}

		}
	}
	.crm-table{
		display: revert !important;
		border-collapse: separate !important;
		border-spacing: 0 10px !important;
		th,td{
			border-bottom:none !important;
			border-top:none !important;
			vertical-align: middle
		}
	}
}
.crm-cs-requests-details{
	.summery-details{
		background-color: #fff;
		padding: 14px 32px 32px 32px;
		border-radius: 8px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
	}
	.crm-tabs-left{
		padding: 0 !important;
	}
	.crm-tabs-right{
		background-color: #fff;
		padding: 14px 32px 32px 32px;
		border-radius: 8px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
	}
}
//models
.cs-request-add-model{
.add-request{
	.radio-box{
		display: flex;
		align-items: center;
		background-color: rgba(251, 251, 250, 1);
		border-radius: 8px;
		margin-top: 25px;
    padding: 10px;
		.box{
			gap:6px;
			accent-color: #b17a1f;
			input{
				width: 18px;
    height: 18px;
			}
		}
		label{
			font-size: 16px;
			font-weight: 400;
			color:rgba(112, 113, 112, 1);
			margin-bottom: 0 !important;

		}
	}
	.add-request{
		background-color: rgba(212, 166, 85, 1);
		color: #fff;
		width: 100%;
		font-size: 16px;
		font-weight: 700;
		margin-top: 28px;
		padding-block: 8px;
	}
	.control-field__label{
		color: rgba(112, 113, 112, 1);
	}
	.input-group.control-field, .control-field{
		border: 1px solid #DDDCDC ;
    border-radius: 6px !important;
	}
}	
}
.cs-reject-package-modal{
	.modal-content{
		border: none !important;
	}
.modal-title{
	text-align: justify;
	padding: 7px;
	span{
		font-size: 20px !important;
		font-weight: 500 !important;
	}
}
h3{
	color: #050001	;
	font-size: 20px;
		font-weight: 500;
}
p{
	color: #707170	;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 12px;
}
.btn-nxt{
	width: 180px;
	background-color: #D4A655 !important;
	padding-block: 8px;
	font-size: 16px;
	font-weight: 700;
}
}
.text-green {
	color: rgba(1, 157, 76, 1);
}
.text-yellow {
	color: #d4a655;
}
.bg-light-danger {
	background-color: rgba(234, 84, 85, 0.08);
}
