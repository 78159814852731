.pricing-box {
	padding: 0 0.5rem;
	font-size: $text-caption;

	.radios {
		display: flex;
		padding: 0 0.8rem;
		font-size: $text-headline;

		>div {
			// padding-#{var(--right)}: 6rem;
			padding-block-start: 6rem;

			input,
			label {
				cursor: pointer;
			}
		}
	}
}

//Adut price
.adult-price {
	.head-price {
		font-size: $text-caption;
		background: #979797;
		padding: 0.25rem 1rem;
		margin: 0 0.3rem;
		border-radius: 0.25rem;
		text-align: center;
		color: $white;
	}

	.head-price-height {
		padding: 1.125rem 1rem;
		height: 52px;
		font-size: 14px;
	}

	//Room Count
	.room-count {
		width: 100%;
		display: flex;
		justify-content: flex-start;

		.room-item {
			padding: 0.1rem 0.2rem;
			margin: 0.3rem;
			color: $dark-gray;
			background: #EBEBEB;
			// width: 10.5%;
			text-align: center;
			font-size: $text-caption;
			width: 100%;
		}
	}

	.room-item .invalid {
		border: 1px solid #ea1414 !important;
		color: #ea1414;
	}

	//Room Price
	.room-price {
		width: 100%;
		display: flex;
		justify-content: flex-start;

		.room-item {
			margin: 0.3rem;
			color: $dark-gray;
			// width: 10%;
			width: 100%;

			height: 45px;
			border-radius: 4px;
			text-align: center;
			overflow: hidden;
			border: 1px solid $gray-150;
			display: flex;
			align-items: center;
			justify-content: center;

			input {
				border: none;
				width: 100%;
				height: 100%;
				text-align: center;
				color: $dark-gray;
				font-weight: 700;

				// input placeholder
				&::placeholder {
					color: #acacac;
					font-weight: normal;
				}

				&:focus {
					border: 1px solid #1777fb;
				}
			}

			.bg-primary {
				background-color: #1777fb3e !important;
				color: #1777fb;

				&::placeholder {
					color: #1777fb;
					font-weight: 700;
				}
			}
		}
	}
}

.head-preakdown {
	font-size: $text-caption;
	background: $dark-gray;
	padding: 0.25rem;
	border-radius: 0.25rem;
	text-align: center;
	color: $white;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

//Extra Price
.extra-price {
	.extra-head {
		display: flex;
		justify-content: flex-start;

		// height: 100%;
		.head-item {
			padding: 0.5rem 1rem;
			margin: 0 0.5rem;
			color: $white;
			background: #979797;
			border-radius: 5px;
			text-align: center;
			font-size: $text-caption;
			width: 100%;

			// text-transform: uppercase;
			// flex: auto;
			@media (max-width: 1920px) and (min-width: 1500px) {
				font-size: 14px;
				// padding: 0.5rem 1.2rem;
			}
		}

		// .head-item-product {
		// 	width: 50%;
		// }
	}
}

//Extra Input
.extra-input {
	.room-item {
		// width: 30% !important;
		margin: 0 0.5rem !important;
	}
}

//Currency Price
.currency-price {
	display: flex;
	justify-content: space-between;
	//height: 50px;
	align-items: center;
	background: #fff !important;
	border: 1px solid #EBEBEB;
	padding: 0.36rem;
	color: $dark-gray;
	border-radius: 0.25rem;

	select {
		width: 55px;
		color: #acacac;
		border: 1px solid $gray-400;
		border-radius: 5px;
	}

	h6 {
		text-transform: capitalize;
	}
}

// .full-package,
// .package-breakdown {
//   display: none;
// }

//Start Package Breakdown
.question-mark {
	color: $white;
	background: $gray-400;
	display: inline-block;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	margin: 0 0.4rem;
}

.img-circle-wrapper {
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	line-height: 35px;
	background-color: #EBEBEB;
	margin: 0px 5px;
	padding: 10px;
	color: $white;

	i,
	.icx {
		font-size: $text-title3 - 3;
	}

	img {
		width: 25px;
		height: 25px;
		object-fit: contain;
	}
}

.standard-expenses {
	display: flex;
	align-items: center;

	.add-icon {
		margin: 0 0.5rem;
		cursor: pointer;
		width: 10%;

		i {
			width: 25px;
			height: 25px;
			line-height: 25px;
			background: $green;
			color: $white;
			text-align: center;
			border-radius: 50%;
		}
	}

	.control-field {
		width: 90%;
	}

	input {
		max-width: 98px;

		@media (max-width: 1920px) and (min-width: 1500px) {
			max-width: 195px;
		}
	}

	select {
		position: absolute;
		right: 0.5rem;
		width: 55px;
		color: #acacac;
		border: 1px solid $gray-400;
		border-radius: 2px;

		[dir="rtl"] & {
			right: auto;
			left: 55px;
		}
	}
}

//End Package Breakdown

input[type="text"]:disabled {
	background: $gray-150;
}

.w-90p {
	width: 90px !important;
}

.product-build__product-header {
	.product-name-item.gold-title {
		font-weight: 700;
		font-size: 18px;
	}

	.pro-name-itinerary {
		font-size: 18px !important;
		color: #686d71 !important;
		line-height: .9 !important;
		margin-bottom: 8px;
	}

}

.pricing-radio-field {
	appearance: none;
	width: 15px;
	height: 15px;
	border: 1px solid #EBEBEB;
	border-radius: 10%;
	outline: 1px solid #EBEBEB;
	cursor: pointer;
}

.pricing-radio-field:checked {
	background: #CB9A51;
}

.pricing-select-currency {
	padding: 4px;
	border: 1px solid #EBEBEB;
	border-radius: 4px;
	color: #2D2D2D
}

.form-check {
	.form-check-label {
		color: #525252 !important
	}

}

.product-build-pricing-content {
	background-color: #fff !important;
	width: 94% !important;
	border: 1px solid #DFDFDF;
	border-radius: 10px;
}

.text-light-gray {
	color: #686d71 !important;
}

.bg-light-green {
	background-color: #E7F5EA !important;
}

.bg-dark-blue {
	background-color: #0C3B5C !important;
}

.p-12-4 {
	padding: 12px 4px !important;
}

.bg-light-gray {
	background-color: #EBEBEB !important;
}

.border-none {
	border: none !important;
}

.Pricing_Box {
	background-color: #fff;
	width: 100%;
	padding: 1.5rem 1rem;
}

.ProductName {
	font-weight: bold;
	color: #000;
	font-size: 24px;

	span {
		color: #CB9A51;
		font-size: 16px;
	}
}

.Pricing_header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1rem;
	font-weight: 600;
	margin-bottom: 2rem;

	.left_col {
		display: flex;
		align-items: center;
		gap: 1rem;

		.tabs_box {
			display: flex;
			border: 1px solid #eae7e7;
			border-radius: 3px;
			padding: 4px;
			align-items: center;
			gap: 5px;

			.active {
				padding: 2px;
				background: #faf4eb;
				border: 1px solid #f5e8d6;
				border-radius: 3px;
			}
		}
	}

	.right_col {
		display: flex;
		align-items: center;
		gap: 1rem;
		justify-content: flex-end;
		width: 25%;
		label {
			padding-bottom: 0px;
		}
	}
}

.fullpackage_tabel {
	width: 80%;
	border: 2px solid #eee;
	margin: auto;
	border-radius: 5px;
	padding: 5px;
}

.head_table {
	display: flex;
	column-gap: 10px;
	text-align: center;
}

.type_hotel {
	flex: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #dddcdc;
	padding: 2rem 1rem;
	border-radius: 3px;
}


.head_left {
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: 5px;
}

.total_p {
	background-color: #dddcdc;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	height: 50%;
}

.adult_child {
	display: flex;
	justify-content: space-around;
	height: 50%;
	gap: 5px;

	.adult {
		background-color: #f7f6f6;
		width: 100%;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.child {
		background-color: #f7f6f6;
		width: 100%;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.body_table {
	display: flex;
	flex-direction: column;
	padding: 10px 0px;
	row-gap: 10px;
}

.row_type {
	display: flex;
	column-gap: 10px;
}

.info_type {
	display: flex;
	border-radius: 5px;
	flex: 3;
	padding: 5px 0.5rem;
	border: 1px solid rgba(112, 113, 112, 0.10);
	align-items: center;
	justify-content: space-evenly;
	
	
	
	.one_line{
		width: 30px;
		height: 1px;
		border: 1px dashed #707170;
}
}

.info_text {
		border-radius: 20px;
		border: 1px solid var(--f-6-ebda, #EED9BA);
		background: var(--faf-4-eb, #FAF4EB);
		color: #D4A655;
		padding: 0px 5px;
		margin-inline-end: 5px;
}

.count_box {
	display: flex;
	gap: 5px;
	flex: 1;

	.count {
		border: 1px solid rgba(112, 113, 112, 0.10);
		border-radius: 5px;
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}



.saving_box {
	display: flex;
	padding: 16px 0px;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	margin-top: 2rem;
	.discount_box {
		display: flex;
		align-items: center;
		gap: 6px;

		label {
			margin-bottom: 0px;
			font-size: 16px;
		}
	}

	.btn_savePrice {
		display: flex;
		height: 40px;
		padding: 0px 22px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 8px;
		border: 1px solid #D4A655;
		color: #D4A655;
		font-weight: 700;
		font-size: 16px;
	}
}

.publish_btn {
	display: flex;
	width: 200.667px;
	height: 50px;
	padding: 0px 32px;
	justify-content: center;
	align-items: center;
	background-color: #D4A655;
	color: #fff;
	border-radius: 8px;
	font-weight: 700;
	font-size: 16px;
	margin-inline-start: auto;
}


.left_row {
	white-space: nowrap;
	padding: 5px 1rem;
	border-radius: 4px;
	border: 1px solid rgba(112, 113, 112, 0.10);
	background: var(--f-7-f-6-f-6, #F7F6F6);
	display: flex;
	align-items: center;
	flex: 1;
	gap: 5px;
}

.right_pack {
	flex: 3;
}

.breackdown_Box {
	display: grid;
	grid-template-columns: repeat(8,1fr);
	text-align: center;
	gap: 10px;
	border: 2px solid #eee;
	border-radius: 5px;
		padding: 8px 8px 16px 8px;
	>div{
		padding: 5px;
	}
	.d_rowmady{
		background-color: #dddcdc;
    border-radius: 3px;
		border: 1px solid #dddcdc;
	}
	.rowmady{
		background-color: #f7f6f6;
    border-radius: 3px;
		border: 1px solid rgba(112, 113, 112, 0.10);
	}
	.ghameq{
		background-color: #707170;
    border-radius: 3px;
		border: 1px solid #707170;
		color: #fff;

	}
	.Service_type{
		grid-column: 1/3;
		grid-row: 1/3;
		display: flex;
		justify-content: center;
		align-content: center;
    flex-wrap: wrap;
    font-weight: 700;

	}
	.total_Price{
		grid-column: 3/9;
	}
	.adult_label{
		grid-column: 3/7;
	}
	.adult_count{
		grid-column: 3/7;
	}
	.adult_count,.child_count,.Infant_count{
		border: 1px solid rgba(112, 113, 112, 0.1);
    border-radius: 5px;
	}
	.left_row{
		grid-column: 1/3;
		padding-inline-start: 10px;
	}
	.hotels_side{
		grid-row: 5/7;
		justify-content: center;
	}
	.Infant_count{
		grid-row: 6/8;
		grid-column: 7/8;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.last{
		grid-column: 8/9;
	}
	.fire_drop{
		color: #D4A655;
		margin-inline-end: 5px;
		display: inline-block;
		text-decoration: underline;
	}
}

.product-builder-pricing {
	.pricing-type-wrapper {
		flex-basis: 100%;
	}
}