.theme-7 {
	.theme_faq_section {
		position: relative;
		padding: 3rem;
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		background-attachment: fixed;
		min-height: 350px;
		border-radius: 10px;
		box-shadow: 0 0 5px #aeaeaece;
		overflow: hidden;
		.img-bg {
			position: absolute;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			height: 100%;
			max-height: 100%;
			width: 100%;
			// background-color: #050001;
			object-fit: fill;
			top: 0px;
			left: 0px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				position: relative;
				// left: -3rem;
			}
		}
		.upload-file-container {
			border: 1px soild #2222227a;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(255, 255, 255, 0.5);
			border-radius: 50%;
			z-index: 4;
			display: flex;
			align-items: center;
			justify-content: center;
			label {
				margin-bottom: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
			}
		}
		.update-hero-layout {
			display: flex;
			position: absolute;
			background-color: #fff;
			width: max-content;
			border-radius: 4px;
			padding: 5px 8px;
			left: 15px;
			bottom: 15px;
			z-index: 2;
			bottom: 5px;
			.hero-content-layout {
				background-color: #fff;
				border: none;
			}
		}
		@media screen and (max-width: 991px) {
			padding: 2rem;
		}
		@media screen and (max-width: 575px) {
			padding: 1.5rem;
		}
	}
	.theme_faq_card {
		position: relative;
		border-radius: 1px;
		padding: 1.5rem;
		background-color: #ffffff;
		border-radius: 10px;
		z-index: 4;
		@media screen and (max-width: 575px) {
			background-color: #ffffffd0;
		}
	}
}
