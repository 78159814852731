.T-newLanding {
	.container-custom-land {
		max-width: 1470px;
		margin: auto !important;
	}
	.newLanding {
		background-color: #f4eee8;
		.safa-logo {
			width: 200px;
		}
		.section-1 {
			.react-3d-carousel .slider-container .slider-content .slider-single {
				cursor: e-resize;
			}
			[dir="rtl"] & {
				.carousel-box {
					.react-3d-carousel .slider-container .slider-content {
						left: 0 !important;
					}
				}
			}
		}
		.about-area {
			[dir="rtl"] & {
				.bottom-info li {
					display: flex;
					gap: 15px;
				}
			}
		}
		.section-2 {
			background-color: #fff;
			padding: 100px 0 !important;
		}
		.section-3 {
			padding: 70px 0 !important;
			background-color: #f9f9f9;
			.site-heading {
				h2 {
					font-size: 35px;
					font-weight: 700;
					margin-bottom: 35px;
				}
			}
			.accordion {
				.card {
					margin-bottom: 12px;
					padding: 4px;
					background: transparent;
					border: none !important;
					.card-body {
						flex: 1 1 auto;
						min-height: 1px;
						padding: 1.25rem;
						background: #fff;
						border: 1px solid #eee;
					}
					.card-header {
						padding: 25px 15px;
						border: 1px solid #eee;
						cursor: pointer;
						background: #fff;
						h4 {
							font-weight: 700;
						}
					}
				}
			}
		}
		.btn-agent-login {
			border-radius: 30px;
			background-color: #cb9a51;
			padding: 12px 37px;
			color: #fff !important;
			font-size: 15px;
			font-weight: 700;
			margin: 10px 0;
		}
		.btn-agent-lang {
			border-radius: 30px;
			background-color: transparent;
			border: 1px solid #cb9a51;
			padding: 12px 30px;
			color: #cb9a51 !important;
			font-size: 15px;
			font-weight: 700;
			margin: 10px 0;
			transition: all 0.5s ease;
		}
		.btn-agent-lang:hover {
			background-color: #cb9a51;
			color: #ffffff !important;
			// border: 1px solid #fff !important;
		}
		.btn-landPage {
			background: transparent linear-gradient(180deg, #b58236 0%, #cb9a51 100%)
				0% 0% no-repeat padding-box;
			color: white;
			display: inline-block;
			font-weight: 400;
			text-align: center;
			width: 100%;
			vertical-align: middle;
			-webkit-user-select: none;
			user-select: none;
			background-color: transparent;
			border: 1px solid transparent;
			padding: 0.375rem 2.95rem;
			font-size: 1rem;
			line-height: 1.5;
			transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
				border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		}
		.section-1 {
			height: 90vh !important;
			background-color: #f4eee8;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: 50px;
			//padding: 250px 0;
			.btn.circle {
				padding: 15px 40px;
				font-size: 13px;
				font-weight: 700;
			}
			.content p {
				font-size: 16px;
				line-height: 25px;
			}
		}
		/*** Start About**/
		.about-area .default.info h4 {
			color: #cb9a51;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 10px;
		}

		.about-area .default.info h2 {
			font-weight: 600;
			line-height: 1.4;
			margin-bottom: 20px;
		}

		@media (max-width: 992px) {
			.attr-nav > ul > li:last-child > a {
				font-size: 10px !important;
			}

			.attr-nav > ul > li > a {
				font-size: 10px !important;
			}
		}

		.about-area .bottom-info span {
			display: table;
			padding-top: 2px;
		}

		.about-area .default.info h3 {
			font-weight: 600;
			margin-bottom: 20px;
		}

		.about-area .default.info > a {
			margin-top: 10px;
		}

		.about-area .bottom-info li {
			color: #666666;
			display: block;
			font-family: "Poppins", sans-serif;
			font-weight: 400;
			letter-spacing: 1px;
			margin-bottom: 15px;
			text-transform: capitalize;
		}

		.about-area .bottom-info li i {
			background: #cb9a51 none repeat scroll 0 0;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			color: #ffffff;
			float: left;
			font-size: 12px;
			height: 20px;
			line-height: 20px;
			margin-right: 15px;
			margin-top: 5px;
			position: relative;
			text-align: center;
			width: 20px;
			z-index: 1;
		}

		.about-area .bottom-info li:last-child {
			margin: 0;
		}

		.about-area .bottom-info li i::after {
			background: #cb9a51 none repeat scroll 0 0;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			content: "";
			height: 28px;
			left: 0;
			margin-left: -4px;
			margin-top: -4px;
			opacity: 0.5;
			position: absolute;
			top: 0;
			width: 28px;
			z-index: -1;
		}

		.about-area .services-info .item {
			background: #ffffff none repeat scroll 0 0;
			-moz-box-shadow: 0 0 10px #cccccc;
			-webkit-box-shadow: 0 0 10px #cccccc;
			-o-box-shadow: 0 0 10px #cccccc;
			box-shadow: 0 0 10px #cccccc;
			margin-top: 30px;
			padding: 30px;
		}

		.about-area .services-info {
			margin-top: -30px;
		}

		.about-area .services-info .item i {
			color: #cb9a51;
			display: inline-block;
			font-size: 60px;
			margin-bottom: 25px;
			position: relative;
			z-index: 1;
		}

		.about-area .services-info .item h4 {
			font-weight: 600;
			text-transform: capitalize;
		}

		.about-area .services-info .item p {
			margin: 0;
		}

		.about-area .services-info .single-item:nth-child(1) .item,
		.about-area .services-info .single-item:nth-child(4) .item {
			background: #cb9a51 none repeat scroll 0 0;
		}

		.about-area .services-info .single-item:nth-child(1) .item i,
		.about-area .services-info .single-item:nth-child(4) .item i,
		.about-area .services-info .single-item:nth-child(1) .item h4,
		.about-area .services-info .single-item:nth-child(4) .item h4,
		.about-area .services-info .single-item:nth-child(1) .item p,
		.about-area .services-info .single-item:nth-child(4) .item p {
			color: #fff;
		}

		.about-area .about-items .about-content h4 {
			font-weight: 600;
			text-transform: uppercase;
			color: #cb9a51;
		}

		.about-area .about-items h2 {
			font-weight: 600;
			margin-bottom: 25px;
		}

		.about-area .top-features {
			margin-top: 50px;
		}

		.about-area .about-items p {
			margin-bottom: 0;
		}

		.about-area .top-features .item {
			padding: 50px 37px;
			transition: all 0.35s ease-in-out;
		}

		.about-area .top-features .single-item:nth-child(2) .item,
		.about-area .top-features .single-item:hover .item {
			box-shadow: 0 0 10px #cccccc;
			border-radius: 5px;
		}

		.about-area .top-features.active-all .single-item .item {
			box-shadow: 0 0 10px #cccccc;
			border-radius: 5px;
		}

		.about-area .top-features .item > i {
			display: inline-block;
			color: #cb9a51;
			font-size: 60px;
			margin-bottom: 25px;
		}

		.about-area .top-features .item > i {
			display: inline-block;
			background: linear-gradient(
				90deg,
				rgba(19, 146, 195, 1) 32%,
				rgba(74, 196, 243, 1) 82%
			);
			-webkit-background-clip: text;
			-moz-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.about-area .top-features .item img {
			max-width: 100px;
			margin-bottom: 30px;
			height: 80px;
		}

		.about-area .top-features .item h4 {
			text-transform: capitalize;
			font-weight: 600;
		}

		.about-area .top-features .item a {
			text-transform: capitalize;
			font-weight: 600;
			margin-top: 15px;
			display: inline-block;
			color: #666666;
			text-decoration: underline;
		}

		.about-area .top-features .item a:hover {
			color: #cb9a51;
		}

		.about-area .thumb-left img {
			margin-left: -20%;
			max-width: 120%;
		}

		.about-area .thumb-left {
			padding-right: 50px;
		}

		.about-area {
			position: relative;
			z-index: 1;
		}

		.about-area .shape {
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: -1;
			opacity: 0.07;
		}

		/** End About**/
		/** Start Service**/
		.services-area .services-box .single-item {
			margin-bottom: 30px;
		}

		.services-area .services-box .item {
			padding: 50px 30px;
			background: rgba(255, 255, 255, 0.3);
			overflow: hidden;
			position: relative;
			z-index: 1;
			border: 1px solid rgba(255, 255, 255, 1);
		}

		.services-area .services-box .item::after {
			position: absolute;
			left: 0;
			bottom: -100%;
			content: "";
			height: 100%;
			width: 100%;
			background: rgba(74, 196, 243, 1);
			z-index: -1;
			transition: all 0.35s ease-in-out;
		}

		.services-area .services-box .item:hover::after {
			bottom: 0;
		}

		.services-area .services-box .item i,
		.services-area .services-box .item h5,
		.services-area .services-box .item p {
			transition: all 0.35s ease-in-out;
		}

		.services-area .services-box .item:hover i,
		.services-area .services-box .item:hover h5,
		.services-area .services-box .item:hover p {
			color: #ffffff;
		}

		.services-area .services-box .item i {
			display: inline-block;
			font-size: 60px;
			margin-bottom: 30px;
			color: #cb9a51;
		}
		.services-area .services-box .item h5 {
			text-transform: capitalize;
			font-weight: 700;
			font-size: 16px;
			margin-bottom: 20px;
		}
		.services-area .services-box .item p {
			margin-bottom: 0;
		}

		/* ============================================================== 
			 # How it works
	=================================================================== */

		.work-process-area {
			position: relative;
		}

		.works-process-items {
			position: relative;
			z-index: 1;
			margin-top: 10px;
		}

		.works-process-items .item {
			padding: 0 15px;
		}

		.works-process-items .single-item {
			position: relative;
			z-index: 1;
		}

		.works-process-items .single-item::after {
			position: absolute;
			left: 100%;
			top: 41px;
			content: "";
			//background-image: url(assets/img/direction-sign.png);
			height: 50px;
			width: 100px;
			background-repeat: no-repeat;
			margin-right: -100px;
			background-size: contain;
			margin-left: -50px;
			opacity: 0.3;
		}

		.works-process-items .single-item:last-child::after {
			display: none;
		}

		.works-process-items .item .info h4 {
			font-weight: 600;
			text-transform: capitalize;
		}

		.works-process-items .item .info span {
			color: #437eeb;
			display: block;
			font-family: "Poppins", sans-serif;
			margin-bottom: 10px;
		}

		.works-process-area .works-process-items .info p {
			margin: 0;
		}

		.works-process-items .item .icon {
			position: relative;
			text-align: center;
			z-index: 1;
			display: inline-block;
			width: 120px;
		}

		.works-process-items .item .icon span {
			background: #ffffff;
			position: absolute;
			right: -10px;
			top: -10px;
			height: 40px;
			line-height: 40px;
			width: 40px;
			z-index: 1;
			color: #cb9a51;
			font-weight: 800;
			font-size: 14px;
			border-radius: 50%;
			box-shadow: 0 0 10px #cccccc;
		}

		.works-process-items .item .icon i {
			background: #4ac5f3;

			background: linear-gradient(to bottom right, #4ac5f3, #c1edfe);
			border-radius: 20px;
			color: #ffffff;
			font-size: 50px;
			height: 120px;
			line-height: 120px;
			text-align: center;
			width: 120px;
			z-index: 1;
			display: inline-block;
			border: 3px solid #ffffff;
			box-shadow: 0 0 10px #cccccc;
			margin-bottom: 30px;
		}

		.works-process-items .item .info {
			line-height: 0;
			position: relative;
			text-align: center;
		}

		.works-process-items .item .info h4 {
			font-weight: 600;
			text-transform: capitalize;
			font-size: 20px;
		}

		.works-process-items .item .info p {
			margin: 0;
			margin-bottom: -5px;
		}

		.works-process-items .item .info {
			text-align: center;
		}
		/** End Service**/
		/*******************Start Footer********************************/
		footer .f-item p {
			color: #999999;
		}

		footer .f-item > h3,
		footer .f-item > h4 {
			font-weight: 600;
			margin-bottom: 25px;
			position: relative;
			text-transform: capitalize;
			z-index: 1;
		}

		footer .f-item .address .icon,
		footer .f-item .address .info {
			display: table-cell;
			vertical-align: middle;
		}

		footer .f-item > a {
			margin-top: 10px;
		}

		footer .f-item .address .info {
			padding-left: 15px;
		}

		.footer-bottom .col-md-6:first-child {
			padding-left: 0;
		}

		.footer-bottom .col-md-6:last-child {
			padding-right: 0;
		}

		footer .f-item .address .info h5 {
			font-weight: 600;
			margin-bottom: 0;
			text-transform: uppercase;
			color: #232323;
		}

		footer .f-item .address li i {
			background: #edf5ff none repeat scroll 0 0;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			color: #cb9a51;
			font-size: 25px;
			height: 60px;
			line-height: 60px;
			text-align: center;
			width: 60px;
		}

		footer .f-item .address li span {
			color: #999999;
		}

		footer .f-item .address li {
			display: block;
			margin-top: 15px;
		}

		footer .f-item .address li:first-child {
			margin-top: 0;
		}

		footer .f-item > img {
			margin-bottom: 25px;
		}

		footer .f-item.link li {
			display: block;
		}

		footer .f-item.link li a {
			color: #999999;
			display: block;
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 10px;
			padding-bottom: 10px;
			text-transform: capitalize;
		}

		footer .f-item.link li a:hover {
			color: #cb9a51;
		}

		footer .footer-bottom {
			border-top: 1px solid #e7e7e7;
			padding-top: 30px;
		}

		footer .footer-bottom p {
			color: #232323;
			margin: 0;
			text-transform: capitalize;
			line-height: 40px;
		}

		footer .f-item.link li:last-child a {
			margin: 0;
			padding: 0;
		}

		footer .footer-bottom p a {
			color: #cb9a51;
			text-transform: capitalize;
		}

		footer.color-yellow .footer-bottom p a {
			color: #ff9800;
		}

		.footer-bottom h4 {
			font-weight: 400;
			margin: 0;
			text-transform: uppercase;
		}

		.footer-bottom .link li {
			display: inline-block;
		}

		.footer-bottom .link li a {
			color: #999999;
			display: inline-block;
			font-weight: 500;
			line-height: 40px;
			margin-left: 10px;
			padding-left: 15px;
			position: relative;
			text-transform: uppercase;
			z-index: 1;
		}

		.footer-bottom .link li a::before {
			background: #999999 none repeat scroll 0 0;
			content: "";
			height: 12px;
			left: 0;
			position: absolute;
			top: 15px;
			width: 1px;
		}

		.footer-bottom .link li:first-child a {
			margin: 0;
			padding: 0;
		}

		.footer-bottom .link li:first-child::before {
			display: none;
		}

		.footer-bottom .link li:first-child a::before {
			display: none;
		}

		.footer-bottom .link li a:hover {
			color: #cb9a51;
		}

		/*******************End Footer*******************************/
	}

	.banner-area.auto-height,
	.banner-area.auto-height div {
		height: auto;
		min-height: 100%;
	}

	.banner-area.shadow,
	.banner-area .shadow {
		box-shadow: inherit !important;
	}

	.banner-area .box-cell {
		display: table-cell;
		vertical-align: middle;
		width: 100%;
	}

	.banner-area .box-table {
		display: table;
		width: 100%;
	}

	.banner-area .box-cell,
	.banner-area .box-cell div {
		height: auto;
	}

	.banner-area {
		position: relative;
		overflow: hidden;
		z-index: 1;
	}

	.banner-area .wavesshape {
		bottom: -2px;
		left: 0;
		position: absolute;
		right: 0;
		top: auto;
		width: 100%;
		z-index: -1;
	}

	.banner-area.shape-index .wavesshape {
		z-index: -1;
	}

	.banner-area .wavesshape.shape {
		bottom: -50px;
	}

	.banner-area .content {
		overflow: hidden;
		position: relative;
		z-index: 9;
	}

	.banner-area.wavesshape {
		position: relative;
		z-index: 1;
	}

	.banner-area.triangle-shadow {
		position: relative;
		z-index: 1;
	}

	.banner-area.triangle-shadow::before {
		border-bottom: 0 solid transparent;
		border-right: 100vw solid #fff;
		border-top: 80px solid transparent;
		bottom: 0;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		width: 100%;
		z-index: 101;
	}

	.banner-area.shadow-inner .content {
		padding: 30px;
		position: relative;
		z-index: 1;
		overflow: hidden;
	}

	.banner-area .banner-carousel .owl-item.center img {
		transform: scale(1);
	}

	.banner-area .banner-carousel .owl-item img {
		transform: scale(0.9);
	}

	.banner-area .simple-video {
		position: relative;
	}

	.banner-area .simple-video .video-play-button {
		bottom: inherit;
		float: left;
		left: 0;
		margin-left: 30px;
		position: relative;
		top: 8px;
		transform: inherit;
		font-size: 20px;
		padding: 0;
	}

	.banner-area .simple-video a {
		float: left;
		text-align: center;
	}

	.banner-area .simple-video .content {
		overflow: inherit;
	}

	.banner-area .simple-video .video-play-button::after {
		height: 60px;
		width: 60px;
	}

	.banner-area .simple-video .video-play-button::before {
		height: 60px;
		width: 60px;
	}

	.banner-area .simple-video .video-play-button i {
		line-height: 40px;
	}

	.banner-area.shadow-inner .content::after {
		background: #1c1c1c none repeat scroll 0 0;
		content: "";
		height: 100%;
		left: 0;
		opacity: 0.5;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;
	}

	.banner-area.inc-dots .carousel-indicators {
		bottom: inherit;
		display: inline-block;
		left: auto;
		margin: -40px 0 0;
		position: absolute;
		right: 20px;
		top: 50%;
		width: auto;
	}

	.banner-area.inc-dots .carousel-indicators li {
		border: 3px solid #ffffff;
		display: block;
		height: 20px;
		margin: 10px 0;
		width: 20px;
	}

	.banner-area.inc-dots .carousel-indicators li.active,
	.banner-area.inc-dots .carousel-indicators li:hover {
		background: #cb9a51 none repeat scroll 0 0;
	}

	.banner-area.large-font .content h1 {
		font-size: 80px;
		font-weight: 800;
		line-height: 1;
		margin-bottom: 30px;
		text-transform: uppercase;
	}

	.banner-area.text-center p {
		padding: 0 10%;
	}

	.banner-area .content span {
		color: #cb9a51;
		font-weight: 700;
		font-size: 23px;
	}

	.banner-area.text-light.shadow.theme-hard .content span {
		color: #232323;
	}

	.banner-area.banner-top-padding .double-items {
		margin-top: -60px;
	}

	.banner-area.text-dark .content span {
		color: #ffffff;
	}

	.banner-area.large-font h4 {
		line-height: 1.4;
		margin-bottom: 30px;
	}

	.banner-area.middle-text.heading-uppercase .content h1 {
		font-size: 80px;
		font-weight: 800;
		line-height: 1;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	.banner-area.small-text .content h1 {
		font-size: 50px;
		font-weight: 600;
		margin-bottom: 25px;
		text-transform: capitalize;
	}

	.banner-area.normal-text .content h1 {
		font-size: 36px;
		font-weight: 600;
		margin-bottom: 25px;
		text-transform: capitalize;
	}

	.banner-area.normal-text .content a {
		margin-top: 15px;
	}

	.banner-area .banner-carousel {
		cursor: w-resize;
	}

	.banner-area .banner-carousel .owl-dots {
		margin-top: 20px !important;
	}

	.banner-area .banner-carousel .owl-dots .owl-dot span {
		background: #ffffff none repeat scroll 0 0;
		border: 3px solid #e7e7e7;
		height: 20px;
		width: 20px;
		-webkit-transition: all 0.35s ease-in-out;
		-moz-transition: all 0.35s ease-in-out;
		-ms-transition: all 0.35s ease-in-out;
		-o-transition: all 0.35s ease-in-out;
		transition: all 0.35s ease-in-out;
	}

	.banner-area .banner-carousel .owl-dots .owl-dot.active span,
	.banner-area .banner-carousel .owl-dots .owl-dot:hover span {
		border-color: #cb9a51;
	}

	.banner-area.small-text .content p {
		margin-bottom: 30px;
	}

	.banner-area.middle-text .content h4 {
		line-height: 30px;
	}

	.banner-area.middle-text .content h2 {
		color: #cb9a51;
		font-weight: 500;
		text-transform: capitalize;
	}

	.banner-area.middle-text .content h1 {
		font-size: 80px;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 25px;
		text-transform: capitalize;
	}

	.banner-area.middle-text .content a {
		margin-left: 3px;
		margin-right: 3px;
		margin-top: 10px;
	}

	.banner-area .content a {
		margin: 0 3px;
	}

	.banner-area.version-3 .content h1 {
		font-size: 80px;
		font-weight: 700;
		line-height: 1.1;
		margin-bottom: 25px;
		text-transform: uppercase;
	}

	.banner-area.version-3 .content h3 {
		font-size: 30px;
		font-weight: 600;
		margin-bottom: 30px;
	}

	.banner-area .right-info {
		text-align: center;
	}

	.banner-area.auto-height .double-items {
		overflow: hidden;
		padding-top: 160px;
	}

	.banner-area.auto-height.sticky-nav .double-items {
		padding-top: 90px;
	}

	.banner-area.auto-height .double-items .banner-carousel,
	.banner-area.auto-height .double-items .banner {
		margin-top: 50px;
	}

	.banner-area.auto-height .double-items .banner {
		position: relative;
	}

	.banner-area.auto-height .double-items .banner .overlay a {
		margin-top: -25px;
	}

	.banner-area .carousel-control {
		font-size: 40px;
		height: 50px;
		line-height: 50px;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 50px;
		margin-top: -25px;
		background: transparent;
		opacity: 1;
		z-index: 1;
	}

	.banner-area .carousel-control.shadow {
		background: transparent none repeat scroll 0 0;
		font-size: 20px;
		height: 50px;
		line-height: 50px;
		margin-top: -25px;
		opacity: 1;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 50px;
		z-index: 1;
		color: #ffffff;
		text-shadow: inherit;
	}

	.banner-area .carousel-control.shadow::after {
		background: #232323 none repeat scroll 0 0;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		content: "";
		height: 100%;
		left: 0;
		opacity: 0.5;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;
	}

	.btn.circle {
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		border-radius: 30px;
	}
	/**********************************************************************************/

	* {
		padding: 0;
		margin: 0;
	}

	html {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: visible;
	}

	body {
		font-family: "Open Sans", sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: 26px;
		overflow-x: hidden;
		background-color: #ffffff;
	}

	html,
	body,
	.wrapper {
		height: 100%;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #232323;
		font-weight: normal;
		line-height: 1.2;
		// font-family: "Poppins", sans-serif;
		letter-spacing: 0;
		margin: 0;
	}

	h1,
	h2,
	h3,
	h4 {
		margin-bottom: 15px;
	}

	h5,
	h5 {
		margin-bottom: 10px;
	}

	.h1,
	h1 {
		font-size: 36px;
	}
	.h2,
	h2 {
		font-size: 30px;
	}
	.h3,
	h3 {
		font-size: 24px;
	}
	.h4,
	h4 {
		font-size: 18px;
	}
	.h5,
	h5 {
		font-size: 14px;
	}
	.h6,
	h6 {
		font-size: 12px;
	}

	img {
		border: none;
		outline: none;
		max-width: 100%;
	}

	label {
		display: inline-block;
		font-weight: normal;
		margin-bottom: 5px;
		max-width: 100%;
	}

	a,
	.btn,
	button {
		transition: all 0.35s ease-in-out;
		-webkit-transition: all 0.35s ease-in-out;
		-moz-transition: all 0.35s ease-in-out;
		-ms-transition: all 0.35s ease-in-out;
		-o-transition: all 0.35s ease-in-out;
		outline: medium none;
	}
	.attr-nav {
		float: right;
		display: inline-block;
		margin-left: 0px !important;
	}

	a img,
	iframe {
		border: none;
	}

	p {
		color: #232323;
		line-height: 26px;
		margin: 0 0 15px;
		text-transform: none;
		font-weight: 400;
		text-align: justify;
	}
	.pnew {
		color: #232323;
		line-height: 26px;
		margin: 0 0 15px;
		text-transform: none;
		font-weight: 400;
		text-align: left !important;
	}
	hr {
		margin-top: 0;
		margin-bottom: 0;
		border: 0;
		border-top: 1px solid #eee;
	}

	pre {
		display: block;
		margin: 0 0 30px;
		padding: 9.5px;
		word-wrap: break-word;
		word-break: break-all;
		color: #333;
		border: 1px solid #ededed;
		border-radius: inherit;
		background-color: #f9f9f9;
		font-size: 13px;
		line-height: 1.42857143;
	}

	input:focus,
	textarea:focus,
	select:focus {
		outline: none;
		box-shadow: inherit;
	}

	ul {
		margin: 0;
		list-style-type: none;
	}

	a,
	a:active,
	a:focus,
	a:hover {
		outline: none;
		text-decoration: none;
		color: #232323;
	}

	a:hover {
		color: #232323;
	}

	a {
		transition: all 0.35s ease-in-out;
		-webkit-transition: all 0.35s ease-in-out;
		-moz-transition: all 0.35s ease-in-out;
		-ms-transition: all 0.35s ease-in-out;
		-o-transition: all 0.35s ease-in-out;
		text-decoration: none;
		font-family: "Poppins", sans-serif;
	}

	b,
	strong {
		font-weight: 900;
	}

	.btn.active,
	.btn:active {
		background-image: inherit !important;
	}
	.btn-border-light {
		border: 2px solid #ffffff !important;
	}

	.btn.btn-sm-pro.btn-border-light:hover,
	.btn.btn-sm-pro.btn-border-light:focus {
		background-color: #ffffff;
		color: #232323;
		border: 2px solid #ffffff !important;
	}

	.btn-dark {
		background-color: #232323;
		color: #ffffff;
		border: 2px solid #232323 !important;
	}

	.btn-dark.border {
		background-color: transparent;
		color: #232323;
		border: 2px solid #232323 !important;
	}

	.btn-dark.border:hover {
		background-color: #232323;
		color: #ffffff !important;
		border: 2px solid #232323 !important;
	}
	/***header****/
	header {
		position: relative;
	}

	header nav.navbar.border.bootsnav.navbar-fixed.no-background {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a {
		position: relative;
		z-index: 1;
		margin-left: 3px;
	}

	header
		nav.navbar.border.bootsnav.navbar-fixed.no-background
		.attr-nav
		ul
		li
		a {
		border: medium none;
	}

	header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a::after {
		background: #ffffff none repeat scroll 0 0;
		bottom: -2px;
		content: "";
		height: 3px;
		left: 0;
		position: absolute;
		width: 0;
		-webkit-transition: all 0.35s ease-in-out;
		-moz-transition: all 0.35s ease-in-out;
		-ms-transition: all 0.35s ease-in-out;
		-o-transition: all 0.35s ease-in-out;
		transition: all 0.35s ease-in-out;
	}

	header
		nav.navbar.border.bootsnav.navbar-fixed.no-background
		ul
		li
		a.active::after,
	header
		nav.navbar.border.bootsnav.navbar-fixed.no-background
		ul
		li
		a:hover::after {
		width: 100%;
	}

	header
		nav.navbar.border.bootsnav.navbar-fixed.no-background
		.attr-nav
		ul
		li
		a.active::after,
	header
		nav.navbar.border.bootsnav.navbar-fixed.no-background
		.attr-nav
		ul
		li
		a:hover::after {
		width: 0;
	}

	.attr-nav .social.right li {
		margin-left: 20px;
	}

	.attr-nav .social li a {
		font-size: 16px;
	}

	/* Navbar */
	nav.bootsnav.navbar-default.info-topbar .navbar-header {
		display: none;
	}

	nav.bootsnav.navbar-default.info-topbar ul li a {
		margin-right: 30px;
		padding: 35px 0;
	}

	nav.bootsnav.navbar-default.info-topbar.active-full ul li a {
		margin-right: 0;
		padding: 35px 20px;
	}

	.attr-nav > a.btn-theme.effect:hover,
	.attr-nav > a.btn-theme.effect,
	.attr-nav > a.btn-theme.effect:focus {
		background: #cb9a51 none repeat scroll 0 0;
		border: 2px solid #cb9a51;
		color: #ffffff !important;
	}

	nav.bootsnav.navbar-default.info-topbar.sticked ul li a {
		margin-right: 30px;
		padding: 35px 0;
	}

	.attr-nav.social li {
		display: inline-block;
		padding: 25px 0 !important;
		transition: all 0.35s ease-in-out;
		-webkit-transition: all 0.35s ease-in-out;
		-moz-transition: all 0.35s ease-in-out;
		-ms-transition: all 0.35s ease-in-out;
		-o-transition: all 0.35s ease-in-out;
	}

	.attr-nav.social li a {
		border: 1px solid #f4f4f4;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		color: #cb9a51;
		display: inline-block;
		font-size: 14px;
		height: 40px;
		line-height: 40px;
		margin-left: 10px;
		padding: 0 !important;
		text-align: center;
		width: 40px;
	}

	nav.navbar.bootsnav.sticked .attr-nav.social li {
		display: inline-block;
		padding: 20px 0 !important;
	}

	.site-heading h2 {
		display: inline-block;
		font-weight: 700;
		padding-bottom: 20px;
		position: relative;
		text-transform: uppercase;
	}

	.site-heading.clean h2 {
		padding-bottom: 0;
	}

	.site-heading.clean h2 span {
		color: #cb9a51;
	}

	.site-heading h2 span {
		color: #cb9a51;
	}

	.site-heading.barber h2 span {
		color: #bc9355;
	}

	.site-heading p {
		margin: 0;
	}

	.site-heading.clean h2::after,
	.site-heading.clean h2::before {
		display: none;
	}

	.site-heading h2::before {
		background: #cb9a51 none repeat scroll 0 0;
		bottom: 0;
		content: "";
		height: 2px;
		left: 50%;
		margin-left: -5px;
		position: absolute;
		width: 50px;
	}

	.bg-theme .site-heading h2::before,
	.bg-theme-hard .site-heading h2::before {
		background: #ffffff none repeat scroll 0 0;
	}

	.site-heading h2::after {
		background: #232323 none repeat scroll 0 0;
		bottom: 0;
		content: "";
		height: 2px;
		left: 50%;
		margin-left: -40px;
		position: absolute;
		width: 25px;
	}
}
.allbtn-box{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: 15px;
	gap: 20px;
.btn-app{
	background-color: #cb9a51;
	font-size: 1.5rem;
    padding: 16px 32px;
	img{
		width: 40px;
		height: 40px;
	}
	span{
		color: #fff !important;
		font-weight: normal !important;
	}

}
}
@media (max-width: 575px) {
	.hide-phone{
	display: none;
	}
	.allbtn-box{
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 10px;
		gap: 10px;
		width: 95vw;

	}
}