.web-builder-page-contianer {


  .web-builder-hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-align: center;

    h1 {
      font-size: 2rem;
      font-weight: 700;
      color: #fff;
    }

    .update-hero-layout {
      position: absolute;
      display: flex;
      background-color: white;
      width: max-content;
      border-radius: 4px;
      padding: 5px 8px;
      left: 15px;
      bottom: 15px;
      z-index: 2;

      label {
        margin: 0;
      }

    }
  }

  .web-builder-content {
    padding: 2em 0;

    .web-builder-content-about-us {
      padding: 0;
      border-radius: 6px;
      background-color: #F5F8FC;
      box-shadow: 0px 4px 12px 0px #00000014;
      overflow: hidden;

      .about-media-image {
        display: flex;
        justify-content: flex-end;
      }

      .about-us-title {
        font-size: 2rem;
        line-height: 24px;
        font-weight: 600;
        color: #050001;
      }

      .about-us-details {
        padding: 0 1rem;
      }

      .text-body {
        margin-top: 1rem;
        font-weight: 400 !important;
        line-height: 33.73px;
        white-space: pre-line
      }
    }

    .all-web-builder-our-service-carousel {
      margin-top: 2rem;
    }

    .section-title {
      text-transform: capitalize;
      font-size: 22px;
      line-height: 24px;
      color: #050001;
    }

    .service-top-section {
      padding: 1rem;
    }

  }

  .web-builder-content-our-packages-with-image::before {
    content: none;
  }

  .hotels-grid-view {
    padding: 0 !important;
  }

  .all-web-builder-our-service-carousel {
    .service-slide {
      padding-inline-start: 0;
      padding-inline-end: 12px;
    }
  }

  .page-bread-crumb {
    text-align: center;
    color: #fff;

    a {
      color: #fff;
    }

    svg path {
      stroke: #fff;
    }
  }

  .wb-page-contact-us-form {
    width: calc(50% - 12px);

    .main-title {
      color: rgba(var(--web-page-primary-color));
      font-weight: 700;
      font-size: 18px;
    }

    .sub-title {
      color: #000;
      font-weight: 700;
      font-size: 24px;
    }

    .textarea-field {
      background-color: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 0.3125rem;
      font-size: 13px;
      width: 100%;
      padding: 10px;

      &::placeholder {
        color: #dddcdc !important;
        font-weight: 600;
      }

      svg path {
        stroke: rgba(var(--web-page-primary-color));
      }
    }

    .contact-us-form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-inline-start: auto;
      padding: 20px;
      border: 1px solid #E5E5E5;
      border-radius: 8px;

      .icon-wrapper {
        width: 44px;
        height: 40px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(var(--web-page-primary-color));
      }

      .form-title {
        @extend .main-title;
        color: #000;
      }



      .submit-btn {
        outline: auto;
        font-weight: 700;
        font-size: 16px;
        width: 100%;
        background-color: rgba(var(--web-page-primary-color));
        color: #ffffff;
        height: 48px;
        border-radius: 8px;
      }
    }
  }


}