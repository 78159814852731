.PrivacyPolicy{
  .lst-kix_7n24p97ezjr2-3 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_7n24p97ezjr2-4 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_bxq2hd72o4bw-6 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_bxq2hd72o4bw-8 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_bxq2hd72o4bw-5 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_bxq2hd72o4bw-3 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_7n24p97ezjr2-7 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_bxq2hd72o4bw-2 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_bxq2hd72o4bw-4 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_7n24p97ezjr2-5 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_7n24p97ezjr2-6 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_bxq2hd72o4bw-0 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_bxq2hd72o4bw-1 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_7n24p97ezjr2-0 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_7n24p97ezjr2-1 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_7n24p97ezjr2-2 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_7n24p97ezjr2-8 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_ewoztdy34bug-8 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_ewoztdy34bug-6 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_ewoztdy34bug-7 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_ewoztdy34bug-5 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_ewoztdy34bug-2 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_ewoztdy34bug-3 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_qnuvvlsuzoew-4 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_qnuvvlsuzoew-5 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_ewoztdy34bug-0 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_ewoztdy34bug-4 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_qnuvvlsuzoew-6 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_qnuvvlsuzoew-8 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_ewoztdy34bug-1 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_qnuvvlsuzoew-7 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_bxq2hd72o4bw-7 > li:before {
    content: "\0025cb  ";
  }
  
  ul.lst-kix_qnuvvlsuzoew-6 {
    list-style-type: none;
  }
  
  ul.lst-kix_qnuvvlsuzoew-7 {
    list-style-type: none;
  }
  
  ul.lst-kix_qnuvvlsuzoew-4 {
    list-style-type: none;
  }
  
  ul.lst-kix_qnuvvlsuzoew-5 {
    list-style-type: none;
  }
  
  ul.lst-kix_ao0gwnmf6zkx-1 {
    list-style-type: none;
  }
  
  ul.lst-kix_ao0gwnmf6zkx-2 {
    list-style-type: none;
  }
  
  ul.lst-kix_qnuvvlsuzoew-8 {
    list-style-type: none;
  }
  
  ul.lst-kix_ao0gwnmf6zkx-0 {
    list-style-type: none;
  }
  
  ul.lst-kix_ao0gwnmf6zkx-5 {
    list-style-type: none;
  }
  
  ul.lst-kix_ao0gwnmf6zkx-6 {
    list-style-type: none;
  }
  
  ul.lst-kix_ao0gwnmf6zkx-3 {
    list-style-type: none;
  }
  
  ul.lst-kix_ao0gwnmf6zkx-4 {
    list-style-type: none;
  }
  
  .lst-kix_qnuvvlsuzoew-3 > li:before {
    content: "\0025cf  ";
  }
  
  ul.lst-kix_ao0gwnmf6zkx-7 {
    list-style-type: none;
  }
  
  ul.lst-kix_ao0gwnmf6zkx-8 {
    list-style-type: none;
  }
  
  .lst-kix_88huk5lckt1-1 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_qnuvvlsuzoew-2 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_88huk5lckt1-0 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_88huk5lckt1-4 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_qnuvvlsuzoew-0 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_qnuvvlsuzoew-1 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_88huk5lckt1-3 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_88huk5lckt1-2 > li:before {
    content: "\0025a0  ";
  }
  
  ul.lst-kix_7n24p97ezjr2-8 {
    list-style-type: none;
  }
  
  ul.lst-kix_7n24p97ezjr2-7 {
    list-style-type: none;
  }
  
  ul.lst-kix_7n24p97ezjr2-6 {
    list-style-type: none;
  }
  
  ul.lst-kix_7n24p97ezjr2-5 {
    list-style-type: none;
  }
  
  ul.lst-kix_7n24p97ezjr2-4 {
    list-style-type: none;
  }
  
  .lst-kix_cvte9mtw6gjc-8 > li:before {
    content: "\0025a0  ";
  }
  
  ul.lst-kix_7n24p97ezjr2-3 {
    list-style-type: none;
  }
  
  ul.lst-kix_7n24p97ezjr2-2 {
    list-style-type: none;
  }
  
  ul.lst-kix_7n24p97ezjr2-1 {
    list-style-type: none;
  }
  
  .lst-kix_cvte9mtw6gjc-6 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_cvte9mtw6gjc-7 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_cvte9mtw6gjc-2 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_cvte9mtw6gjc-3 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_cvte9mtw6gjc-0 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_cvte9mtw6gjc-1 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_cvte9mtw6gjc-4 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_cvte9mtw6gjc-5 > li:before {
    content: "\0025a0  ";
  }
  
  ul.lst-kix_qnuvvlsuzoew-2 {
    list-style-type: none;
  }
  
  ul.lst-kix_qnuvvlsuzoew-3 {
    list-style-type: none;
  }
  
  ul.lst-kix_qnuvvlsuzoew-0 {
    list-style-type: none;
  }
  
  ul.lst-kix_qnuvvlsuzoew-1 {
    list-style-type: none;
  }
  
  ul.lst-kix_ewoztdy34bug-1 {
    list-style-type: none;
  }
  
  ul.lst-kix_ewoztdy34bug-0 {
    list-style-type: none;
  }
  
  ul.lst-kix_ewoztdy34bug-5 {
    list-style-type: none;
  }
  
  ul.lst-kix_7n24p97ezjr2-0 {
    list-style-type: none;
  }
  
  ul.lst-kix_ewoztdy34bug-4 {
    list-style-type: none;
  }
  
  ul.lst-kix_ewoztdy34bug-3 {
    list-style-type: none;
  }
  
  ul.lst-kix_ewoztdy34bug-2 {
    list-style-type: none;
  }
  
  ul.lst-kix_ewoztdy34bug-8 {
    list-style-type: none;
  }
  
  ul.lst-kix_ewoztdy34bug-7 {
    list-style-type: none;
  }
  
  ul.lst-kix_ewoztdy34bug-6 {
    list-style-type: none;
  }
  
  ul.lst-kix_bxq2hd72o4bw-0 {
    list-style-type: none;
  }
  
  .lst-kix_88huk5lckt1-8 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_ao0gwnmf6zkx-8 > li:before {
    content: "\0025a0  ";
  }
  
  ul.lst-kix_bxq2hd72o4bw-7 {
    list-style-type: none;
  }
  
  .lst-kix_88huk5lckt1-5 > li:before {
    content: "\0025a0  ";
  }
  
  .lst-kix_ao0gwnmf6zkx-7 > li:before {
    content: "\0025cb  ";
  }
  
  ul.lst-kix_bxq2hd72o4bw-8 {
    list-style-type: none;
  }
  
  ul.lst-kix_bxq2hd72o4bw-5 {
    list-style-type: none;
  }
  
  ul.lst-kix_bxq2hd72o4bw-6 {
    list-style-type: none;
  }
  
  ul.lst-kix_bxq2hd72o4bw-3 {
    list-style-type: none;
  }
  
  .lst-kix_88huk5lckt1-7 > li:before {
    content: "\0025cb  ";
  }
  
  ul.lst-kix_bxq2hd72o4bw-4 {
    list-style-type: none;
  }
  
  li.li-bullet-0:before {
    margin-left: 2pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  
  ul.lst-kix_bxq2hd72o4bw-1 {
    list-style-type: none;
  }
  
  .lst-kix_88huk5lckt1-6 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_ao0gwnmf6zkx-6 > li:before {
    content: "\0025cf  ";
  }
  
  ul.lst-kix_bxq2hd72o4bw-2 {
    list-style-type: none;
  }
  
  .lst-kix_ao0gwnmf6zkx-3 > li:before {
    content: "\0025cf  ";
  }
  
  ul.lst-kix_88huk5lckt1-8 {
    list-style-type: none;
  }
  
  ul.lst-kix_88huk5lckt1-7 {
    list-style-type: none;
  }
  
  ul.lst-kix_cvte9mtw6gjc-1 {
    list-style-type: none;
  }
  
  .lst-kix_ao0gwnmf6zkx-1 > li:before {
    content: "\0025cb  ";
  }
  
  .lst-kix_ao0gwnmf6zkx-5 > li:before {
    content: "\0025a0  ";
  }
  
  ul.lst-kix_cvte9mtw6gjc-0 {
    list-style-type: none;
  }
  
  ul.lst-kix_cvte9mtw6gjc-3 {
    list-style-type: none;
  }
  
  .lst-kix_ao0gwnmf6zkx-0 > li:before {
    content: "\0025cf  ";
  }
  
  .lst-kix_ao0gwnmf6zkx-4 > li:before {
    content: "\0025cb  ";
  }
  
  ul.lst-kix_cvte9mtw6gjc-2 {
    list-style-type: none;
  }
  
  ul.lst-kix_cvte9mtw6gjc-5 {
    list-style-type: none;
  }
  
  ul.lst-kix_88huk5lckt1-2 {
    list-style-type: none;
  }
  
  ul.lst-kix_cvte9mtw6gjc-4 {
    list-style-type: none;
  }
  
  ul.lst-kix_88huk5lckt1-1 {
    list-style-type: none;
  }
  
  ul.lst-kix_cvte9mtw6gjc-7 {
    list-style-type: none;
  }
  
  ul.lst-kix_88huk5lckt1-0 {
    list-style-type: none;
  }
  
  ul.lst-kix_cvte9mtw6gjc-6 {
    list-style-type: none;
  }
  
  ul.lst-kix_88huk5lckt1-6 {
    list-style-type: none;
  }
  
  ul.lst-kix_cvte9mtw6gjc-8 {
    list-style-type: none;
  }
  
  ul.lst-kix_88huk5lckt1-5 {
    list-style-type: none;
  }
  
  ul.lst-kix_88huk5lckt1-4 {
    list-style-type: none;
  }
  
  .lst-kix_ao0gwnmf6zkx-2 > li:before {
    content: "\0025a0  ";
  }
  
  ul.lst-kix_88huk5lckt1-3 {
    list-style-type: none;
  }
  
  ol {
    margin: 0;
    padding: 0;
  }
  
  table td,
  table th {
    padding: 0;
  }
  
  .c4 {
    margin-left: 36pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 24pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  .c15 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
  }
  
  .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Arial";
    font-style: normal;
  }
  
  .c6 {
    background-color: #ffffff;
    padding-top: 24pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  .c9 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 24pt;
    font-family: "Arial";
    font-style: normal;
  }
  
  .c16 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 21pt;
    font-family: "Arial";
    font-style: normal;
  }
  
  .c2 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  .c7 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Arial";
    font-style: normal;
  }
  
  .c8 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 30pt;
    font-family: "Arial";
    font-style: normal;
  }
  
  .c0 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  .c12 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  .c11 {
    background-color: #ffffff;
    // max-width: 451.4pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  
  .c3 {
    padding: 0;
    margin: 0;
  }
  
  .c5 {
    font-size: 12pt;
    font-weight: 700;
  }
  
  .c14 {
    color: inherit;
    text-decoration: inherit;
  }
  
  .c13 {
    font-size: 12pt;
  }
  
  .c10 {
    height: 11pt;
  }
  
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
  }
  
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
  }
  
  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
}