.theme-4 {
	// ****************************** header and hero ****************************** //
	.header-nav {
		display: flex;
		align-items: center;
		gap: 42px;

		.main-nav {
			gap: 26px;
		}
	}
	&.web-builder-preview .wb-header {
		padding: 16px 5em;
	}

	.hero-content-container {
		padding: 24px !important;
		width: 100% !important;
		max-width: 600px;
		background: #2222227a;
		border-radius: 4px;
		.header {
			color: rgba(var(--web-page-primary-color));
		}
	}

	.services-content {
		text-align: center;
		padding-bottom: 1.6rem;
		.service-header {
			position: relative;
			&::before {
				content: "";
				position: absolute;
				top: -1rem;
				background-color: rgba(var(--web-page-primary-color));
				left: 50%;
				transform: translateX(-50%);
				width: 100px;
				height: 4px;
			}
		}
	}

	// ****************************** our services ****************************** //
	.travel-services-container {
		margin: 0 auto;
		padding: 80px 16px;
	}

	.travel-services-wrapper {
		display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
		padding: 24px;
		background-color: rgba(var(--web-page-primary-color), 0.08);
	}

	.travel-service-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.travel-service-image-container {
		position: relative;
		width: 128px;
		height: 128px;
		margin-bottom: 16px;
		overflow: hidden;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.add-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 3;
		}
		.upload-file-container {
			border: 1px soild #2222227a;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(255, 255, 255, 0.5);
			border-radius: 50%;
			z-index: 4;
			display: flex;
			align-items: center;
			justify-content: center;
			label {
				margin-bottom: 0;
				height: 100%;
				display: flex;
				align-items: center;				
			}
		}

		& .overlay::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(255, 255, 255, 0.5);
			border-radius: 50%;
			z-index: 2;
		}
	}

	.travel-service-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.travel-service-title {
		font-size: 1.125rem;
		font-weight: 700;
		margin-bottom: 8px;
	}

	.travel-service-description {
		font-size: 1rem;
		color: #4b5563;
	}

	.web-builder-content-about-us {
		// padding-inline-start: 0;
		background-color: rgba(var(--web-page-primary-color), 0.08);
		
		.about-us-details {
			padding: 24px;
			.h4 {
				position: relative;
				margin-bottom: 2rem !important;
				// color: rgba(var(--web-page-primary-color));
				// filter: grayscale(.6);
				// border-bottom: 4px solid rgba(141, 198, 63, 1)
			}
		}

		.about-us-title {
			display: none;
		}
		.about-media-container {
			margin-inline-start: -15px;
			img {
				width: 100%;
			}
		}

		.web-builder-filled-btn {
			margin: 0 auto;
		}

	}

	// flights
	// .available-flights-container {
	// 	max-width: 1200px;
	// 	margin: 0 auto;
	// 	padding: 20px;
	// 	font-family: Arial, sans-serif;
	// }

	// .available-flights-title {
	// 	font-size: 24px;
	// 	font-weight: bold;
	// 	margin-bottom: 20px;
	// 	text-align: center;
	// 	color: #333;
	// }

	// .flight-cards-grid {
	// 	display: grid;
	// 	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	// 	gap: 20px;
	// }

	// .flight-card {
	// 	background-color: white;
	// 	border-radius: 8px;
	// 	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	// 	padding: 16px;
	// 	border-bottom: 3px solid rgba(var(--web-page-primary-color));
	// }

	// .flight-route {
	// 	display: flex;
	// 	align-items: center;
	// 	margin-bottom: 12px;
	// 	color: #666;
	// }

	// .flight-icon {
	// 	margin-right: 8px;
	// 	font-size: 18px;
	// 	color: rgba(var(--web-page-primary-color));
	// }

	// .route-text {
	// 	font-size: 14px;
	// }

	// .flight-arrow {
	// 	margin: 0 8px;
	// 	color: rgba(var(--web-page-primary-color));
	// }

	// .package-name {
	// 	font-size: 18px;
	// 	font-weight: 1000;
	// 	margin-bottom: 12px;
	// 	color: #333;
	// }

	// .flight-details {
	// 	display: flex;
	// 	justify-content: space-between;
	// 	align-items: center;
	// }

	// .price-info {
	// 	display: flex;
	// 	flex-direction: column;
	// }

	// .price-label {
	// 	font-size: 16px;
	// 	color: #666;
	// 	margin-bottom: 4px;
	// }

	// .price-value {
	// 	font-size: 22px;
	// 	font-weight: bold;
	// 	color: #333;
	// }

	// .price-currency {
	// 	font-size: 16px;
	// 	font-weight: normal;
	// }

	// .book-now-button {
	// 	background-color: white;
	// 	color: rgba(var(--web-page-primary-color));
	// 	border: 1px solid rgba(var(--web-page-primary-color));
	// 	padding: 8px 16px;
	// 	border-radius: 4px;
	// 	cursor: pointer;
	// 	transition: all 0.3s;
	// 	font-weight: bold;
	// 	font-size: 14px;
	// }

	// .book-now-button:hover {
	// 	background-color: rgba(var(--web-page-primary-color));
	// 	color: white;
	// }

	// .flight-route {
	// 	display: flex;
	// 	align-items: center;
	// 	margin-bottom: 12px;
	// 	color: #666;
	// }

	// .flight-icon {
	// 	margin-right: 8px;
	// 	font-size: 18px;
	// 	color: rgba(var(--web-page-primary-color));
	// }

	// .route-text {
	// 	font-size: 14px;
	// }

	// .flight-arrow {
	// 	margin: 0 8px;
	// 	color: rgba(var(--web-page-primary-color));
	// }
	// about us section
	.who-we-are-container {
		display: flex;
		max-width: 1200px;
		margin: 0 auto;
	}

	.who-we-are-text-section {
		flex: 1;
		padding: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.who-we-are-title {
		font-size: 24px;
		color: #666;
		margin-bottom: 20px;
	}

	.who-we-are-text {
		font-size: 16px;
		line-height: 1.6;
		color: #333;
		max-width: 80%;
	}

	.who-we-are-image-section {
		flex: 1;
	}

	.who-we-are-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 768px) {
		.who-we-are-container {
			flex-direction: column;
		}

		.who-we-are-image-section {
			height: 300px;
		}
	}

	// .arrow-icon {
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;

	// 	background-color: rgba(var(--web-page-primary-color));
	// 	color: rgba(var(--web-page-primary-color));

	// 	svg {
	// 		width: 26px;
	// 	}
	// 	svg path {
	// 		fill: rgba(var(--web-page-primary-color));
	// 		stroke: rgba(var(--web-page-primary-color));
	// 	}
	// }

	// packages section
	.package-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: stretch;
		column-gap: 48px;
		row-gap: 32px;

		// for edit mode
		.our-service-item, .edit-box-container {
			height: 100%;
		}

		.package-card-holder {
			position: relative;
			display: flex;
			border-width: 1px 1px 4px 1px;
			border-style: solid;
			border-color: rgba(var(--web-page-primary-color));
			min-width: 356px;
			height: 100%;
			border-radius: 8px;
			overflow: hidden;

			.package-img {
				position: relative;
				width: 45%;
				max-width: 250px;
				min-width: 200px;
				min-height: 200px;
				max-height: 100%;
				img {
					width: 100%;
					height: 100%;
					min-height: inherit;
					object-fit: cover;
					position: absolute;
				}
			}
		}
		.card-caption-holder {
			padding: 18px 16px;
			flex-grow: 1;
			.card-title {
				font-size: 18px;
				font-weight: 700;
				color: #050001;
			}

			.price {
				padding: 3px 16px;
				gap: 10px;
				border-radius: 22px;
				background: rgba(var(--web-page-primary-color), 0.08);

				.label {
					font-size: 12px;
					font-weight: 500;
					line-height: 10px;
					color: rgba(var(--web-page-primary-color));
				}

				p {
					font-size: 20px;
					font-weight: 700;
					line-height: 20px;
					color: rgba(var(--web-page-primary-color));
					span {
						font-size: 16px;
					}
				}
			}
		}
	}

	// flight section
	.flights-cards-container {
		.flight-service-card {
			padding: 16px;

			.header {
				padding: 0;
				padding-bottom: 16px;
				border: 0;
				border-bottom: 1px solid #e5e5e5;
				margin-bottom: 16px;
			}

			.cta-btn {
				padding: 8px 24px;
				color: #fff !important;
				background-color: rgba(var(--web-page-primary-color));
			}

			.destination-count {
				position: relative;
				display: flex;
				align-items: center;
				padding: 6px 24px;
				color: rgba(var(--web-page-primary-color));
				background-color: rgba(var(--web-page-primary-color), 0.08);
				border: 1px solid rgba(var(--web-page-primary-color), 0.16);
				gap: 6px;

				&:hover .addition-destinations-list {
					display: flex;
					width: 150%;
					flex-direction: column;
					gap: 12px;
				}
			}
			.destination-port {
				display: flex;
				justify-content: space-evenly;
				color: #050001;
				gap: 24px !important;
				.port-country {
					font-weight: 400;
					font-size: 14px;
				}

				.arrow {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 32px;
					height: 32px;
					min-width: 32px;
					min-height: 32px;
					background-color: rgba(var(--web-page-primary-color), 0.08);
					border-radius: 50%;
					svg {
						width: 75% !important;
						height: 75%;
					}
				}
			}

			.addition-destinations-list {
				display: none;
				position: absolute;
				width: 100%;
				top: 38px;
				left: 0;
				padding: 16px;
				border: 1px solid #dddcdc;
				border-radius: 8px;
				background-color: #ffffff;
				z-index: 3;
				svg path {
					stroke: rgba(var(--web-page-primary-color));
				}
				.destination-port {
					gap: 16px !important;
					justify-content: space-between;
					align-items: center;
					.font-weight-bold {
						width: 90px;
					}
				}
			}
		}
	}

	// hotels and attractions section

	.hotel-container,
	.tours-container {
		.card-holder {
			position: relative;
			// min-width: 356px;
			padding: 8px;
			border-radius: 8px;
			border-width: 1px 1px 4px 1px;
			border-style: solid;
			border-color: rgba(var(--web-page-primary-color));
			gap: 8px;
			overflow: hidden;

			.service-img {
				width: 100%;
				height: 240px;
				border-radius: 8px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.card-caption-holder {
				padding: 8px;
				.card-title {
					font-size: 18px;
					font-weight: 700;
					color: #050001;
				}
				.location {
					font-size: 14px;
					font-weight: 500;
					color: #050001;
					margin-left: 6px;
				}
				.location-icon path {
					stroke: #707170;
				}
			}
			.card-action-holder {
				padding: 10px 0 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-top: 1px solid rgba(247, 246, 246, 1);
				.card-price {
					flex-grow: 1;
					.label {
						text-align: start;
						font-size: 14px;
						font-weight: 400;
						color: #707170;
					}
					.price {
						font-size: 24px;
						font-weight: 600;
						& + p {
							font-size: 14px;
							span {
								color: #707170;
							}
						}
					}
				}
				.book-btn {
					height: 48px;
					padding: 0px 32px 0px 32px;
					border-radius: 6px;
					// background: rgba(141, 198, 63, 1);
					background: rgba(var(--web-page-primary-color));
					color: white;
					font-size: 16px;
					font-weight: 700;
				}
			}
		}
	}

	// attractions section
	#tours-section,
	#hotels-section {
		position: relative;

		.services-content {
			max-width: 320px;
		}
		.service-header {
			margin-bottom: 2rem;
		}
	}
	.service-slide {
		padding-inline-start: 12px;
	}

	// faq section
	.faq-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 16px;
		.faq-card-holder {
			padding: 16px;
			gap: 8px;
			border-radius: 4px;
			border: 1px solid rgba(var(--web-page-primary-color));

			.faq-title {
				font-size: 16px;
				font-weight: 600;
				text-align: center;
				color: rgba(31, 31, 31, 1);
				line-height: 40px;
				margin-bottom: 8px;
			}
			.faq-desc {
				font-size: 14px;
				font-weight: 400;
				text-align: center;
				color: rgba(54, 54, 54, 0.7);
			}
		}
	}

	// contact us section
	.contact-container {
		padding-block: 2em;
		position: relative;

		// min-height: 50vh;
	}
	.contact-holder {
		padding-bottom: 80px;
		.img-bg {
			position: absolute;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			height: 65%;
			max-height: 500px;
			width: 100%;
			background-color: #050001;
			object-fit: fill;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.contact-form-holder {
			position: relative;
			width: 50%;
			margin: 24px auto;
			padding: 24px;
			max-width: 600px;
			backdrop-filter: blur(4px);
			background-color: rgba(var(--web-page-primary-color), 0.25);
			border-radius: 4px;
			top: 80px;
			z-index: 3;

			.title-holder {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				color: white;
				margin-bottom: 32px;

				.t-title {
					font-size: 28px;
					font-weight: 700;
					margin-bottom: 10px;
				}

				.t-body {
					color: #ffffff;
					font-size: 16px;
					font-weight: 600;
				}
			}

			.contact-us-form {
				width: 85%;
				margin: 0 auto;

				.textarea-field {
					background-color: #ffffff;
					border: 1px solid #ebebeb;
					border-radius: 0.3125rem;
					font-size: 13px;
					width: 100%;
					padding: 10px;
					&::placeholder {
						color: #dddcdc !important;
						font-weight: 600;
					}
				}
				.submit-btn {
					width: 100%;
					padding: 0px 32px;
					line-height: 30px;
					border-radius: 6px;
					background: rgba(var(--web-page-primary-color));
					color: white;
				}
			}
		}
		.upload-file-container {
			display: flex;
			// align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(112, 113, 112, 0.7);
			z-index: 0;
			label {
				margin-bottom: 0;
			}
		}
	}

	// footer
	.footer-container {
		background: rgba(var(--web-page-primary-color), 0.08);
		.footer-holder {
			padding: 23px 24px;
			width: 100%;


			.footer-photo {
				width: 100%;
				max-width: 160px;
			}
			.footer-top {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;
			}
			.footer-text {
				font-size: 14px;
				font-weight: 400;
				color: #363636b2 !important ;
			}

			.footer-link-holder {
				display: flex;
				row-gap: 15px;
				column-gap: 30px;
				align-items: center;
				margin-top: 32px;
				flex-wrap: wrap;
				justify-content: center;
			}

			.footer-social-holder {
				display: flex;
				margin: 10px 0px;
				gap: 16px;
				filter: grayscale(0.60);

				svg circle {
					fill: rgba(var(--web-page-primary-color));
					fill-opacity: 1;
				}
			}

			.footer-copy-right {
				border-top: 1px solid #e3eed5;
			}
		}
	}

	.empty-service::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(112, 113, 112, 0.7) !important;
		z-index: 2;
	}
	.add-service-btn {
		top: 50%;
		z-index: 3;
	}
	.wb-button-group-new {
		position: absolute;
		top: 120px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		gap: 370px;

		.react-multiple-carousel__arrow--right,
		.react-multiple-carousel__arrow--left {
			position: unset;
			// transform: scale(-1);
		}
	}
}
