.theme-7 {
	.theme_hotels_section {
		position: relative;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		.carousel-button-group{
			 .react-multiple-carousel__arrow--left{
				-webkit-transform: scaleX(-1);
				transform: scaleX(-1);
			 }
		}
		.abstract {
			position: absolute;
			top: 0;
			inset-inline-start: 0;
			width: 100%;
			height: 60%;
			min-height: 200px;
			z-index: 0;
			background-position: center 14%;
			background-size: 70%;
			background-repeat: no-repeat;
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			inset-inline-start: 0;
			width: 100%;
			height: 60%;
			min-height: 200px;
			background-color: rgb(var(--web-page-primary-color), 0.75);
			z-index: 1;
		}
		.section_content {
			position: relative;
			z-index: 2;
			margin-top: 2rem;
			.heading {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 1rem;
				color: #ffffff;
				@media screen and (max-width: 600px) {
					flex-direction: column;
					align-items: flex-start;
				}
				.title {
					font-size: 1.5rem;
					font-weight: 700;
					line-height: 1.5;
				}
				.heading_desc {
					margin-top: 0;
					margin-bottom: 0;
					font-size: 1rem;
					line-height: 1.2;
				}
				.heading_cta {
					a,
					button {
						position: relative;
						background-color: transparent;
						color: #ffffff;
						display: inline-block;
						padding: 0.6rem 1.2rem;
						border: 1px solid #ffffff;
						border-radius: 8px;
						overflow: hidden;
						span {
							position: relative;
							z-index: 1;
							transition: all 0.4s ease-in-out;
						}
						svg {
							path {
								transition: all 0.4s ease-in-out;
							}
						}
						&::before,
						&::after {
							content: "";
							position: absolute;
							top: 0;
							inset-inline-start: -100%;
							width: 50%;
							height: 100%;
							transition: all 0.4s ease-in-out;
							z-index: 0;
							background-color: #ffffff;
						}
						&::after {
							inset-inline-start: unset;
							inset-inline-end: -100%;
						}
						&:hover {
							color: rgb(var(--web-page-primary-color));
							svg {
								path {
									stroke: rgb(var(--web-page-primary-color));
								}
							}
							&::before {
								inset-inline-start: 0;
							}
							&::after {
								inset-inline-end: 0;
							}
						}
					}
				}
			}
		}
		.carousel-button-group {
			.react-multiple-carousel__arrow {
				background-color: transparent;
				width: fit-content !important;
				height: auto !important;
				min-width: auto !important;
				min-height: auto !important;
				bottom: 0;
				&::before {
					display: none;
				}
				svg {
					path {
						stroke: #000000;
					}
				}
				&.react-multiple-carousel__arrow--left {
					left: 40%;
					svg {
						-webkit-transform: scaleX(-1);
						transform: scaleX(-1);
					}
				}
				&.react-multiple-carousel__arrow--right {
					right: 40.5%;
				}
			}
		}
	}
	.theme_hotel_card {
		position: relative;
		background-color: #ffffff;
		padding: 0.5rem;
		box-shadow: 0 0 8px #cacaca;
		border-radius: 10px;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		margin-right: 0.4rem;
		margin-left: 0.4rem;
		.add-service-btn{
			position: absolute !important;
			top: 50% !important;
			left: 50% !important;
			-webkit-transform: translate(-50%, -50%) !important;
			transform: translate(-50%, -50%) !important;
		}
		.image {
			position: relative;
			overflow: hidden;
			border-radius: 10px;
			img {
				width: 100%;
				height: 160px;
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-fit: center;
				object-fit: center;
				border-radius: 10px;
				transition: all 0.3s ease-in-out;
			}
			&:hover {
				img {
					-webkit-transform: scale(1.2);
					transform: scale(1.2);
				}
			}
		}
		.content {
			position: relative;
			padding: 0.5rem 0.25rem 5.5rem 0.25rem;
			.location {
				font-size: 0.9rem;
				color: #919191;
				margin-bottom: 0.5rem;
				svg {
					display: inline-block;
					margin-inline-end: 0.25rem;
					width: 22px;
					height: auto;
					path {
						stroke: #919191;
					}
				}
			}
			.package_name {
				.package_title {
					font-size: 1.1rem;
					line-height: 1.5;
					font-weight: 700;
					color: #000000;
					a {
						display: inline-block;
						color: #000000;
						transition: all 0.3s ease-in-out;
						@include mixins.ellipsis;
						&:hover {
							color: rgb(var(--web-page-primary-color), 0.75);
						}
					}
				}
			}
			.package_footer {
				position: absolute;
				bottom: 0.5rem;
				right: 0.5rem;
				left: 0.5rem;
				border-top: 1px solid #dedede;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-top: 1rem;
				.pricing {
					.from_text {
						font-size: 0.85rem;
						font-weight: 700;
						line-height: 1.5;
					}
					.actual_price {
						margin: 0;
						font-weight: 700;
						font-size: 1rem;
						line-height: 1.5;
						color: rgb(var(--web-page-primary-color));
					}
				}
				.cta {
					height: 100%;
					.theme_btn {
						display: inline-block;
						padding: 0.25rem 0.6rem;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.theme_hotels_section {
			.carousel-button-group {
				.react-multiple-carousel__arrow {
					&.react-multiple-carousel__arrow--left {
						left: 39%;
					}
					&.react-multiple-carousel__arrow--right {
						right: 40%;
					}
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.theme_hotels_section {
			.carousel-button-group {
				.react-multiple-carousel__arrow {
					&.react-multiple-carousel__arrow--left {
						left: 36%;
					}
					&.react-multiple-carousel__arrow--right {
						right: 36.5%;
					}
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.theme_hotels_section {
			.carousel-button-group {
				.react-multiple-carousel__arrow {
					&.react-multiple-carousel__arrow--left {
						left: 32%;
					}
					&.react-multiple-carousel__arrow--right {
						right: 33.5%;
					}
				}
			}
		}
	}
	@media screen and (max-width: 575px) {
		.theme_hotels_section {
			.carousel-button-group {
				.react-multiple-carousel__arrow {
					&.react-multiple-carousel__arrow--left {
						left: 26%;
					}
					&.react-multiple-carousel__arrow--right {
						right: 27.5%;
					}
				}
			}
		}
	}
	@media screen and (max-width: 464px) {
		.theme_hotels_section {
			.carousel-button-group {
				.react-multiple-carousel__arrow {
					&.react-multiple-carousel__arrow--left {
						left: 15%;
					}
					&.react-multiple-carousel__arrow--right {
						right: 16.5%;
					}
				}
			}
		}
	}
}
