.crm-wrapper {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background: rgba(221, 220, 220, 0.2);
	gap: 10px;
	position: relative;

	// ** Actions
	.actions-wrapper {
		padding: 1rem;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
		display: flex;
		justify-content: space-between;
		align-items: center;

		.items-wrapper {
			display: flex;
			gap: 1rem;
			align-items: center;

			.w-350 {
				height: 48px;
			}
			.width-150 {
				width: 150px;
				height: 48px;
			}

			.item {
				display: flex;
				grid-gap: 6px;
				font-size: 18px;
				gap: 6px;
				align-items: center;
				justify-content: center;
				font-weight: 600;
				color: #707170;
				border-right: 1px solid rgba(221, 220, 220, 0.5);
				transition: none !important; 

				&:disabled {
					background: transparent !important;
					opacity: .55;
					border-right: 1px solid rgba(221, 220, 220, 0.5);
					transition: none !important;
				}

				h3 {
					font-size: 20px;
					font-weight: 600;
					color: #050001;
				}

				&:last-child {
					border-right: none;
				}
			}

			.active {
				background-color: #d4a655;
				color: #fff;
				font-size: 1rem;
				font-weight: 700;
			}
		}
	}

	// ** Filters
	.filter-wrapper {
		background-color: #fff;
		padding: 24px 16px;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);

		.filter-row {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.filter-items {
				display: flex;
				align-items: center;
				gap: 1rem;

				.control-field {
					width: 340px;
				}
			}

			.item {
				padding: 8px;
				white-space: nowrap;
				border-radius: 4px;
				font-size: 1rem;
				font-weight: 500;
				height: 44px;
			}

			.btn-filter-outline {
				color: #707170;
				background-color: #f8f8f8;
				border: 1px solid #dddcdc;
				padding: 0 23px;

				&.active {
					background-color: rgba(212, 166, 85, 0.08);
					color: #d4a655;
					border: 1px solid #d4a655;
				}

			}


			.btn-filter {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				background-color: #d4a655;
				color: #fff;
				padding: 0 1rem;
				border-radius: 6px;
			}

			.btn-offline {
				padding: 0.5rem 30px;
				color: #d4a655;
				background-color: rgba(212, 166, 85, 0.08);
				display: flex;
				border: 1px solid #d4a655;
				justify-content: center;
				align-items: center;
			}

			.btn-website {
				padding: 0.5rem 30px;
				color: #707170;
				background-color: #f8f8f8;
				border: 1px solid #dddcdc;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.advancedFilter-title {
			font-size: 20px;
			font-weight: 600;
			color: #050001;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				width: 100%;
				border: 1px solid rgba(221, 220, 220, 0.5);
				top: -10px;
				left: 0;
			}
		}

		.filter-buttons {
			display: flex;
			justify-content: end;
			align-items: center;
			gap: 8px;

			.btn-shared {
				border-radius: 6px;
				padding: 0 1rem;
				width: 203px;
				height: 48px;
				font-size: 18px;
				font-weight: 600;
			}

			.btn-apply {
				background-color: #d4a655;
				color: #fff;
			}

			.btn-clear {
				background-color: #f5f5f5;
				color: #515151;
			}
		}
	}

	// ** List
	.crm-table-wrapper {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		.table {
			border-collapse: separate;
			border-spacing: 0 10px;
			.table-tableHead {
				.tableHead {
					border-radius: 8px;
					background-color: rgba(212, 166, 85, 0.08);
					color: #d4a655;
					box-shadow: 0px 2px 12px 0px #00000014;
					th {
						font-weight: 600;
						vertical-align: middle;
					}
				}
			}
			[dir="rtl"] & .btn svg {
				transform: scale(-1, 1);
			}
			.table-tableBody {
				.tableBody {
					border-radius: 8px;
					background: #fff;
					box-shadow: 0px 2px 12px 0px #00000014;
					td {
						vertical-align: middle;
						.gap-2 {
							gap: 3px;
						}
						.table-text {
							font-weight: 500;
							color: #707170;
							font-size: 1rem;
							white-space: nowrap;
						}

						.text-success {
							color: #019d4c;
						}

						.text-orange {
							color: #d4a655;
						}

						.offline {
							border-radius: 8px;
							background: #F8F8F8;
							color: #707170;
							border: 1px solid #dddcdc;
							width: 78px;
							height: 40px;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.new {
							border-radius: 8px;
							background: #ebf4ff;
							color: #1e85ff;
							border: 1px solid #1e85ff;
							width: 78px;
							height: 40px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
		}
		.select-row-checkbox {
			position: relative;
			padding: 0;
			margin-top: 10px;
			border-radius: 2px;
			overflow: hidden;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 16px;
				height: 16px;
				padding: 0;
				filter: invert(1%) hue-rotate(192deg) brightness(1.2);
			}
		}

		.crm-table {
			display: flex;
			flex-direction: column;
			gap: 10px;

			.crm-table-head {
				border-radius: 8px;
				background-color: rgba(212, 166, 85, 0.08);
				color: #d4a655;
				box-shadow: 0px 2px 12px 0px #00000014;

				th {
					padding: 1rem;
					font-weight: 600;
					font-size: 1rem;
					color: #d4a655;
					text-align: justify;
					// width: 120px;
					white-space: nowrap;
					height: 40px;
					text-align: justify;
				}
			}

			.crm-table-body {
				.table-row {
					border-radius: 8px;
					background: #fff;
					box-shadow: 0px 2px 12px 0px #00000014;
					.table-column {
						width: 120px;
						white-space: nowrap;
						height: 40px;
						text-align: justify;
						.gap-2 {
							gap: 3px;
						}
						.table-text {
							font-weight: 500;
							color: #707170;
							font-size: 1rem;
							white-space: nowrap;
						}

						.text-success {
							color: #019d4c;
						}

						.text-orange {
							color: #d4a655;
						}

						.offline {
							border-radius: 8px;
							background: #F8F8F8;
							color: #707170;
							border: 1px solid #dddcdc;
							width: 78px;
							height: 40px;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.new {
							border-radius: 8px;
							background: #ebf4ff;
							color: #1e85ff;
							border: 1px solid #1e85ff;
							width: 78px;
							height: 40px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
		}
	}

	.crm-package-itinerary {
		position: absolute;
		display: none;
		// justify-content: space-evenly;
		width: 100%;
		bottom: calc(100% + 4px);
		left: 0;
		padding: 16px;
		border: 1px solid #DDDCDC;
		border-radius: 8px;
		background-color: #fff;
		gap: 20px;

		&.show {
			display: flex;
			flex-wrap: wrap;
		}

		.segement-point {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.point {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 20px;
				height: 20px;
				background-color: #FAF4EB;
				border-radius: 50%;
				
				&::after {
					content: "";
					width: 50%;
					height: 50%;
					background-color: #D4A655;
					border-radius: 50%;
				}
			}
			.line {
				position: absolute;
				width: 100%;
				border: 2px solid #DDDCDC
			}
		}
		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 44px;
			height: 44px;
			// flex-basis: 45px;
			border-radius: 50%;
			svg {
				width: 70%;
				height: 70%;
			}
			&.hotel {
				border: 1px solid #FF9F4329;
				background-color: #FF9F4314;
			}
			&.attraction {
				border: 1px solid #7367F0;
				background-color: #7367F014;
			}
			&.flight {
				border: 1px solid #00CFE829;
				background-color: #00CFE814;
				svg {
					width: 80%;
					height: 80%;
				}
				svg path {
					stroke: #00CFE8;
				}
			}
			&.flight.return svg {
				rotate: 70deg;
			}
		}
	}
}

.crm-table-wrapper .view-btn:hover > .crm-package-itinerary {
	display: flex;
	flex-wrap: wrap;
}

.crm-bg-row-low {
	background-color: #FAFAFA !important;
	box-shadow: 0px 2px 12px 0px #00000014 !important;
}

.crm-bg-row-medium {
	background-color: #FFFBEF !important;
	box-shadow: 0px 2px 12px 0px #00000014 !important;
}

.crm-bg-row-high {
	background-color: #FFF1F1 !important;
	box-shadow: 0px 2px 12px 0px #00000014 !important;
}

.crm-status-bg-pending {
	background-color: #FFFBF6 !important;
	border: 1px solid #FF9F43 !important;
	color: #FF9F43 !important;
}

.crm-status-bg-rejected {
	background-color: #FFF9F9 !important;
	border: 1px solid #EA5455 !important;
	color: #EA5455 !important;
}

.crm-status-bg-new {
	background-color: #EBF4FF !important;
	border: 1px solid #1E85FF !important;
	color: #1E85FF !important;
}

.crm-status-bg-accepted {
	background-color: #F2FFF8 !important;
	border: 1px solid #019D4C !important;
	color: #019D4C !important;
}

.opacity-1{
	opacity: 1 !important;
}