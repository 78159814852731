.team-management-tabs {
	.nav-tabs {
		display: flex;
		justify-content: center;
	}

	nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link {
		color: #000;
		border: none;
		padding: 1rem 2rem;
	}

	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		min-height: auto !important;
		background: transparent;
		color: #1777fb;
		border-bottom: 3px solid #1777fb;
		&::after {
			display: none;
		}
	}
}

.container-teamManagment {
	max-width: 1420px; /** 1320px*/
	margin-left: auto;
	margin-right: auto;
}

//Member List
.member-status {
	display: inline-block;
	text-align: center;
	border-radius: 5px;
	padding: 0.2rem 0.3rem;
	font-size: 12px;
	width: 70px;
	color: #fff;
}
.Active {
	background-color: $green;
}
.Pending,
.pending,
.Deactive {
	background-color: $red;
}
.Inactive {
	background-color: $orange;
}

//Branch Collpase
.branch-collapse {
	.branch-collabse-row {
		.col-sm-6 {
			padding: 0 auto !important;
			line-height: 0.5 !important;
		}
	}
	.button-collapse {
		background: #fcfcfc;
		border: 1px solid $gray-150;
	}
	.card-body {
		.head {
			padding: 0.5rem;
			p {
				font-size: 14px;
				&:first-child {
					color: #3f4f61;
				}
				&:last-child {
					color: $gray-300;
				}
			}
		}
	}
}

.modal-header {
	h5 {
		font-weight: 700;
		font-size: $text-body;
	}
}

.disableInput {
	input,
	.control-field {
		background-color: #e8e8e8 !important;
		color: #fff !important;
	}
}

.table-management {
	thead {
		background-color: $dark-gray;
		th {
			border-color: $dark-gray;
		}
	}
}
.team-management-tabs {
	button.btn-yellow:hover {
		background-color: #bf8d42;
		color: #ffffff;
	}
	.nav-tabs {
		justify-content: flex-start;
		.nav-link {
			border: 0px;
		}
		.nav-link.active {
			border-top: 1px solid #dfdfdf;
			border-left: 1px solid #dfdfdf;
			border-right: 1px solid #dfdfdf;
		}
		.nav-link.active .product-build-list-item span {
			// color: #cb9a51 !important;
			font-size: 16px !important;
			font-weight: 700 !important;
		}
	}
	.font-16 {
		font-size: 16px;
	}
	.manage-box {
		.member-head {
			border: 1px solid #d9d9da;
			border-top: 0px;
			border-bottom: 0px;
			button {
				padding: 9px 65px;
				font-size: 16px;
			}
		}
		.main-filter {
			border-bottom: 0px;
		}
	}
	.team-box {
		.button-collapse {
			background-color: #ebebeb;
			border-radius: 2px;
		}
		.card {
			border: 0 !important;
		}

		.fixHandleCreate.modal-dialog {
			position: absolute;
			left: 50%;
			top: 45%;
			transform: translate(-50%, -50%) !important;
		}
	}
}
.fixHandleCreate.modal-team.modal-dialog {
	position: absolute;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%) !important;
}
.text-blue-dark {
	color: #0c3b5c !important;
}

.font-18 {
	font-size: 18px;
}
.modal-team {
	button.btn-yellow:hover {
		background-color: #bf8d42;
		color: #ffffff;
	}
	.form-check-modal {
		filter: invert(1%) hue-rotate(192deg) brightness(1.2);
	}
}
@media (min-width: 320px) and (max-width: 425px) {
	.team-management-tabs {
		.product-build-list-item {
			span {
				display: none !important;
			}
		}
		.member-head {
			button {
				padding: 5px !important;
			}
		}
		.branch-collapse {
			margin: 5px 0 !important;
		}
	}
}

.permission-check-box-container{
	overflow-y: auto;
	height: 50vh;
}