$base-spacing: 10px;
$seat-width: 74px;


.buses-accommodation-container .btn:focus {
  box-shadow: 0 0 0 0.1rem rgba(203, 154, 81, 0.25) !important
}

.buses-accommodation-container {
  .btn:disabled {
    background: #F7F6F6 !important;
    color: #707170;
    stroke: #707170;
    fill: #707170;
    cursor: not-allowed;
    border: 1px solid transparent !important;
  }

  .add-bus-btn:disabled i {
    color: #DDDCDC !important;
  }

  .btn-filled {
    display: flex;
    // height: 50px;
    padding: 0px 32px;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    border-radius: 8px;
    background: #D4A655;
    color: #fff;
  }

  .btn-outline {
    display: flex;
    // height: 40px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid#D4A655;
    color: #D4A655;
    font-size: 1rem;

    &:hover {
      color: #D4A655;
    }

    &:disabled {
      background: #F7F6F6 !important;
      color: #707170;
      stroke: #707170;
      fill: #707170;
      cursor: not-allowed;
    }

    &:disabled svg,
    &:disabled path {
      stroke: #707170;
      // fill: #707170;
    }
  }

}



.travelers-container {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid#F7F6F6;
  background: #FFF;

  .header {
    font-size: 1.5rem;
    font-weight: 600;
    font-feature-settings: 'clig' off, 'liga' off;
    color: #050001;
  }

  .travelers-accommodated {
    display: flex;
    justify-content: space-between;
    margin: $base-spacing 0;
    padding: $base-spacing 0px;
    font-size: 1rem;
    font-weight: 500;
    color: #707170;
  }

  .travelers-search {
    display: flex;
    align-items: center;
    align-self: stretch;
    height: 50px;
    padding: 0px 16px;
    border-radius: 8px;
    border: 1px solid#DDDCDC;

    input {
      border: 0;
      margin: 0 8px;
      width: 100%;
      height: 100%;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .traveler-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $base-spacing;
    margin: $base-spacing 0;
    border-radius: 4px;
    background: #FBFBFA;
    border: 1px solid transparent;
    cursor: pointer;

    &.selected {
      border: 1px solid #D4A655;
      background: #FAF4EB;
    }

    .traveler-info {
      display: flex;
      align-items: center;

    }

    .seat-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      padding: 0px 1rem;
      border-radius: 100%;
      border: 1px solid #DDDCDC;
      background-color: #FEFEFF;
    }
  }


}

.bus-details-container {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid#F7F6F6;
  background: #FFF;

  div[class^="row"] {
    margin-right: -5px;
    margin-left: -5px;
  }

  .action-btns {
    display: flex;
    align-items: center;
    gap: 8px;
    // margin: $base-spacing 0;
    padding: $base-spacing 0;
  }

  .buses-tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: $base-spacing;
    gap: 8px;

    .bus-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid transparent;
      cursor: pointer;

      &.active {
        border: 1px solid #EED9BA;
        background-color: #FAF4EB;
        cursor: default;
  
        .btn {
          color: #6A4D1B;
          font-weight: 600;
          box-shadow: none !important;
        }
      }

    }
  }
}

[dir="rtl"] .seats-container {
  flex-direction: row-reverse;
  text-align: left !important;
}

.seats-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.6rem auto;
  width: 100%;
  max-width: calc(300px + 3rem); // 2 rems for the padding
  border-radius: 8px;
  border: 1px solid #DDDCDC;
  padding: 1.6rem 1.5rem;

  &.big-bus {
    max-width: calc(380px + 3rem); // 2 rems for the padding
  }

  .seat {
    position: relative;
    display: flex;
    flex-direction: column;
    direction: ltr;
    width: $seat-width;
    margin-bottom: $base-spacing;

    &:hover .unassign-seat-btn {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      transition: all .3s ease;
    }

    .unassign-seat-btn {
      position: absolute;
      bottom: -10px;
      right: -10px;
      border-radius: 6px;
      border: 1px solid #EED9BA;
      background: #FAF4EB;
      padding: 2px 4px !important;
      opacity: 0;
      visibility: hidden;
      transform: scale(.5);
      transition: all .3s ease;
    }
  }

  .seat-row-block {
    display: flex;
    flex: 100%;
  }

  .left-col {
    position: relative;
    display: flex;
    justify-content: flex-start;
  }

  .right-col {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
}

.seats-guide {
  display: flex;
  align-items: center;

  .circle {
    position: relative;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid #000;
    padding: 5px;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      height: 75%;
      width: 75%;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
    }
  }

  &.selected {
    border: none;
    .circle {
      border: 2px solid #D4A655;
    }
  }

  &.booked .circle::before {
    background-color: #007E3D;
  }

  &.disabled {
    background-color: transparent !important;

    .circle::before {
      background-color: #FF3131;
    }
  }
}

.seperator {
  margin: $base-spacing 0;
}


.mr-base {
  margin-right: $seat-width !important;
}


.text-grey-100 {
  color: #DDDCDC;
}

.text-grey-500 {
  color: #707170;
}

.fw-500 {
  font-weight: 500;
}

.w-fit-content {
  width: fit-content !important;
}

@media screen and (max-width: 1200px) {
  .seats-container {
    padding: 1.6rem .2rem;
  }
}

@media screen and (max-width: 1000px) {
  $seat-width: 54px;

  .seats-container {
    width: 300px;

    &.big-bus {
      width: 100%;
    }

    .mr-base {
      margin-right: $seat-width !important;
    }

    .seat {
      width: $seat-width;
    }
  }

}
@media screen and (max-width: 767px) { 
  $seat-width: 60px;
  .seats-container {
    width: 100%;
    margin: 1rem auto !important;
    padding: 1.6rem .6rem;

    &.big-bus {
      width: 100%;
      max-width: 380px;

    }

    .mr-base {
      margin-right: $seat-width !important;
    }

    .seat {
      width: $seat-width;
      padding: .4rem !important
    }
  }
}
@media screen and (max-width: 480px) {
  $seat-width: 64px;
  .seats-container {
    width: 100%;
    margin: 1rem 0 !important;
    

    &.big-bus {
      width: 100%;
    }

    .mr-base {
      margin-right: $seat-width !important;
    }

    .seat {
      width: $seat-width;
      padding: .4rem !important
    }
  }

}

@media print {
  .action-btns, 
  .seats-action-btns,
  .seats-guide,
  .bus-details .btn, 
  .remove-bus,
  .add-bus-btn {
    display: none !important;
  }

  .bus-tab:not(.bus-tab.active) {
    display: none;
  }
  
  .big-bus {
    max-width: 460px !important;
  }

  .bus-seats-container .col-md-4 {
    display: none;
  }

  .bus-seats-container .col-md-8 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin: 0 auto;
  }
  
  .traveler-item:not(.traveler-item:has(.seat-number)) {
    display: none;
  }
  

}