.marketplace-details {
	.mybooking-search-wrapper {
		min-height: 185px;
		padding: 60px 15px;
		background-size: cover;
		display: flex;
		background-position: center;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-top: -$main-container-padding;
		margin-inline: -$main-container-padding;
		
		.mybooking-grid-container {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-template-rows: auto;
			gap: 5px 5px;
			display: flex;
			justify-content: center;
		}

		.mybooking-input-wrapper {
			.form-group {
				margin-bottom: 0 !important;
			}
			.btn-primary {
				min-width: 80px !important;
				height: 60px;
			}
			.fa-plane {
				transform: rotateZ(-45deg);
			}
			&:first-child {
				flex: auto;
			}
			&:nth-child(4) {
				flex: auto;
			}
		}
		&.search-bar {
			.form-control-custom-input {
				[dir="rtl"] & {
					/* padding: 30px 45px 0px 10px */
				}
			}
			.form-control-custom-input {
				border-radius: 5px;
				margin-bottom: 0;
			}
			[dir="ltr"] & .mybooking-input-wrapper {
				.form-group i {
					left: 5px;
				}
			}
			button {
				border-radius: 5px;
				font-size: 1rem;
			}
		}
	}
}

.mybooking-input-wrapper {
	// change button style
	.btn-primary {
		min-width: 120px !important;
		height: 100%;
		background-color: $blue !important;
		outline: none !important;
		border-color: $blue;
		border-radius: 0;

		.fa-search {
			font-size: $text-title3;
		}
	}

	.btn:focus,
	.btn:active {
		outline: none !important;
		box-shadow: none;
	}
	// end button style
	.form-group {
		// margin-bottom: 0px !important;
		position: relative;

		label {
			&.form-control-custom-input {
				padding-top: 2.5em;
				font-size: 12px;
			}
		}

		.form-control-custom-input {
			height: 60px !important;
			padding: 15px 10px 0px 45px;
			color: $dark-blue;
			border-radius: 0px;
			border: 0px;
			font-weight: bold;
			font-size: $text-caption + 3;
			cursor: pointer;
		}

		.booking-label {
			position: absolute;
			top: 10px;
			left: 45px;
			font-size: $text-captio3;
			z-index: 999;
			color: $dark-gray;

			[dir="rtl"] & {
				left: auto;
				right: 45px;
			}
		}

		.fa-calendar {
			@extend %fa-icons-style;
		}

		.fa-suitcase {
			@extend %fa-icons-style;
		}

		.fa-id-card {
			@extend %fa-icons-style;
		}
		i {
			@extend %fa-icons-style;
		}
	}
}

.flight-filter-box {
	background: #fff;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid $gray-150;
	overflow: auto;
	overflow-x: hidden;
	color: #3c3c3c;
	.orange-color {
		color: #dc7542;
		font-size: 0.8rem;
	}
	.flight-filter-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $gray-150;
		padding: 0.5rem;
		.filter-reset {
			a {
				padding: 0;
				i {
					color: $gray-150;
				}
			}
		}
	} //flight-filter-title
	.airlines {
		padding: 0.5rem 0.5rem 0 1.2rem;
		h6 {
			color: #3c3c3c;
			font-size: 0.9rem;
		}
		.list-unstyled {
			padding-inline-start: 1rem;
		}
		&:last-child {
			padding-bottom: 0.5rem;
		}
		.airlines-checkbox {
			.custom-control {
				display: flex;
				align-items: center;
			}
			.custom-control {
				[dir="rtl"] & {
					padding-left: inherit;
					padding-right: 1.5rem;
				}
				.custom-control-label {
					&::before {
						[dir="rtl"] & {
							left: auto;
							right: -1.5rem;
						}
					}
					&::after {
						[dir="rtl"] & {
							left: auto;
							right: -1.5rem;
						}
					}
				}
			}

			.custom-control-label {
				margin-top: 0.2rem;
				cursor: pointer;
				i {
					color: #ffc649;
				}
				.far.fa-star {
					color: #b9bdc2;
				}
			}
		}
	} //air-line
} //flight-filter-box
.list-with-height {
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
}

.select-flight {
	.select-flight-title {
		h6 {
			font-size: initial;
		}
		p {
			font-size: 11.5px;
		}
	} //select-flight-title

	.select-flight-ul {
		display: flex;
		justify-content: flex-end;
		li {
			background: #f0eeef;
			border: 1px solid #c6cbd0;
			border-radius: 5px;
			padding: 0.7rem 3rem;
			margin: 0 0.3rem;
		}
		.active {
			background: $orange;
			h6 {
				color: $white;
			}
		}
	} //select-flight-ul

	.sorting {
		display: flex;
		justify-content: flex-end;
		margin: 1rem 0;
		align-items: center;
		span {
			font-size: $text-caption + 2;
			text-transform: uppercase;
			padding-right: 0.5rem;
			color: $dark-gray;
		}
		.toggle-sort {
			width: 100px;
			color: #4e4d4d;
			border: none;
			background: white;
			height: 30px;
			margin: 0 5px;
		}
		.toggle-sort-active {
			width: 100px;
			color: white;
			border: none;
			background: #cb9a51;
			height: 30px;
			margin: 0 5px;
		}
		select {
			cursor: pointer;
			color: $dark-gray;
			border: 1px solid #eee;
		}
	}
}

//flight-invoice-tab-wrapper
.flight-invice-tab-wrraper {
	.b2c-invoice-wrapper {
		.container {
			//	padding: 0px !important;
			.flight-info-text {
				cursor: pointer;
			}
			.invoice-detail-main-header {
				.flight-info-text {
					&::after {
						margin: 0 0.5rem;
					}
				}
			}

			.invoice-content {
				.flight-info-section-wrap {
					.flight-info-section {
						grid: none;
						display: flex;
						justify-content: space-between;
						width: 100%;
						align-items: center;
						padding: 1rem;
						.flight-way-from-to {
							padding: 0px 0px;
							width: 24%;
							.plane-flight-time {
								top: -15px;
							}
							.plane-item {
								// width: 60%;
								.fa-circle {
									&::after {
										width: 170px;
										top: 33%;
									}
								}
							}
						}
						.flight-buttons {
							.flight-price {
								text-align: center;
								.price-name {
									font-size: $text-caption + 4;
								}
								.price-number {
									font-size: $text-caption + 8;
								}
							}
							button {
								color: #fff;
								border-radius: 0;
								padding-left: 2rem;
								padding-right: 2rem;
							}
						}
					} //flight-info-section
				} //flight-info-section-wrap
			}
		}
		.show-button {
			display: flex;
			justify-content: center;
			button {
				outline: none;
			}
		}
	}
	.flight-information {
		padding: 1rem;
		.flight-info-head {
			margin-bottom: 0.5rem;
		}
		.flight-info-img {
			display: flex;
			align-items: center;
		}
		.flight-time {
			display: flex;
			padding: 1rem 0;
			.flight-line {
				position: relative;
				font-size: 25px;
				display: inline-block;
				color: #c6cbd0;
				font-weight: bold;
				margin: 0 1rem;
				&::after {
					position: absolute;
					top: 0px;
					left: -2px;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					content: "";
					background-color: #c6cbd0;
					margin: auto;
				}
				&::before {
					position: absolute;
					bottom: 0px;
					right: -2px;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					content: "";
					background-color: #c6cbd0;
					margin: auto;
				}
			}
			.flight-txt {
				display: flex;
				align-items: center;
			}
		}
	} //flight-information

	.info-box {
		background-color: #fffdf8;
		padding: 0.5rem;
		border: 1px solid #eee;
		.fa-info-circle {
			color: $goldColor;
		}
		.date-info {
			display: flex;
			justify-content: space-between;
			margin: 0.5rem;
			h6 {
				margin-bottom: 0.3rem;
			}
			p {
				font-size: 12px;
				margin: 0;
				color: #686d71;
			}
			.active {
				background: $orangColor;
				h6,
				p {
					color: #fff;
				}
			}
			> div {
				border: 1px solid #eee;
				padding: 0.5rem 1rem;
				background-color: #fff;
			}
		}
	}
} //flight-invice-tab-wrraper

//TabTwo
.hotel-detail-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0px;
	padding: 5px;
	width: 100%;
	min-height: 200px;
	border: 1px solid $gray-250;
	background-color: #fff !important;
	margin: 0 -14px !important;
	.img-box {
		min-height: 180px;
		width: 270px !important;
		height: 180px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.hotel-detail-item {
		padding: 5px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 5px;
		width: 100%;

		.hotel-header {
			width: 100% !important;
			.hotel-star-rate {
				margin-top: 5px;
				.fa-star {
					color: #ffc649;
				}
			}

			.hotel-check-in-out {
				width: 70%;
				//   background-color: goldenrod;
				padding: 3px;
				margin: 10px 0px 10px 0px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				p {
					font-size: $text-body;
					line-height: 10px;
				}
			}
		}
		p {
			font-size: 22px;
			margin: 0;
			color: #4e4d4d;
		}
	}

	.total-price-box {
		width: auto !important;
		background: transparent !important;
		height: auto !important;
		border: none !important;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: flex-end !important;
		flex-direction: column;
		margin: auto;
		padding-top: 14px !important;
		.cash-back {
			display: flex;
			align-items: flex-end;
			width: 100%;
			justify-content: center;
			color: #fff;
			height: 100%;
			p {
				color: #00b437;
				background: #d4efdc;
				width: 100%;
				padding: 0.4rem;
				font-weight: 700;
				.cash-currency {
					position: relative;
					bottom: 5px;
					left: 2px;
					font-size: $text-captio2;
					[dir="rtl"] & {
						left: auto;
						right: 0;
					}
				}
			}
		}
	}
	.details-button {
		//width: 180px;
		color: #fff;
		margin-top: 0.2rem;
		border-radius: 0;
		background: $blue;
		&:hover {
			color: #fff;
			background: #4a6572 !important;
		}
		a {
			&:hover {
				color: #fff !important;
			}
		}
	}
} //hotel-detail-section

%fa-icons-style {
	position: absolute;
	left: 0;
	top: 35%;
	margin: 0px 5px 0px 5px;
	font-size: $text-title3;
	color: $gray-250;
	[dir="rtl"] & {
		left: auto;
		right: 0;
	}
}

// price range component

/* .input-range__label--bottom-min {
	color: white !important;
	background: #cb9a51 !important;
	border-radius: 3px !important;
	font-weight: 500;
}

.input-range__label-container {
	padding: 5px !important;
	font-weight: 500;
	color: white !important;
	background: #cb9a51 !important;
} */

.input-range {
	height: 1.5rem !important;
	margin-top: 0.6em;
	margin-bottom: 0.6em;
}

.input-range {
	.input-range__label--value {
		.input-range__label {
			top: 1rem;
		}
	}
	.input-range__label--bottom-min,
	.input-range__label--bottom-max {
		direction: ltr;
		[dir="rtl"] & {
			direction: rtl;
		}
	}
	.prefix-text {
		margin: 0 !important;
	}
	.input-range__track--background {
		height: 0.5rem;
		background: #f4f4f4 0% 0% no-repeat padding-box;

		opacity: 1;
	}

	.input-range__track--active {
		background: #cb9a51 0% 0% no-repeat padding-box;
		border: 1px solid #f4f4f4;
		opacity: 1;
		height: 0.5rem;
	}
	.input-range__track {
		border-radius: 0;
	}
	.input-range__slider {
		height: 1rem;
		width: 1rem;
		top: -0.2em;
		transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;

		background: transparent
			linear-gradient(0deg, #cb9a51 0%, #cb9a51 99%, #cb9a51 100%) 0% 0%
			no-repeat padding-box;
		border: 1px solid #92846f;
		opacity: 1;
		&:hover,
		&:active {
			background: #cb9a51 !important;
		}
	}
	.input-range__label--value {
		.input-range__label-container {
			top: -0.8rem;
		}
	}
}

// gallery
.image-gallery-content {
	.image-gallery-slides {
		height: 400px;
		.image-gallery-image {
			max-height: 400px !important;
		}
	}
	.image-gallery-thumbnails-wrapper.left,
	.image-gallery-thumbnails-wrapper.right {
		width: 230px;
	}
	.image-gallery-slide-wrapper.left,
	.image-gallery-slide-wrapper.right {
		width: calc(100% - 240px);
	}
	.image-gallery-thumbnails {
		button {
			width: 100%;
			.image-gallery-thumbnail-image {
				max-height: 130px;
				border-radius: 10px;
			}
		}
	}

	.image-gallery-icon {
		color: #c5c4c4;
		&:hover {
			color: #ffffff;
		}
	}
	.image-gallery-left-nav .image-gallery-svg,
	.image-gallery-right-nav .image-gallery-svg {
		height: 30px;
		width: 30px;
		border: 1px solid;
		border-radius: 50%;
		// &:hover {
		// 	border: 2px solid;
		// }
	}
}
.input-range__slider-container:nth-child(2) {
	.input-range__label-container {
		position: relative;
		left: -30px;
		top: -11px;
		font-size: 10px;
		[dir="rtl"] & {
			left: auto;
			right: 0;
		}
	}
}

.input-range__slider-container:nth-child(3) {
	.input-range__label-container {
		position: relative;
		left: 5px;
		top: -11px;
		font-size: 10px;
	}
}
.price-text {
	font-size: 18px;
	color: #0c3b5c !important	;
	font-weight: 500;
}
.text-Nights {
	font-weight: 400;
}
.new-price-number a {
	width: 20%;
	color: #FFF !important;
}
.new-price-number a:hover {
	width: 100%;
	background-color: #D4A655 !important;
	color: #FFF !important;
	width: 20% !important;
}
.input-range__label-container {
	display: none !important;
}
.input-range-width {
	width: 80%;
	font-size: 16px !important;
}

// market view hotel map
.hotel-map {
	margin-top: -2.5rem;
	height: calc(100vh - (80px + 0.5rem));
	overflow: hidden;
}
.hotel-map-back-btn {
	position: relative;
	padding: 1rem 0;
	text-align: center;
	a {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: #000000;
		text-transform: capitalize;
		font-size: 1.2rem;
		font-weight: 700;
	}
}

.hotel-map-detail-section {
	display: flex;
	flex-direction: column;
	min-height: 200px;
	margin-bottom: 1rem;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #ededed;
	border-radius: 5px;
	overflow: hidden;
	.img-box {
		height: 150px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.hotel-map-detail-content {
		padding: 0.5rem;
		border-bottom: 1px solid #d8d7d7;
		.hotel-map-detail-title {
			color: #000000;
			font-weight: 700;
		}
		.hotel-map-detail-address {
			color: #000000;
			font-weight: 300;
			font-size: 0.85rem;
		}
	}
	.hotel-map-price {
		padding: 0.5rem;
	}
}

.hotel-map-filters,
.hotel-map-list {
	height: calc(100vh - (80px + 0.5rem));
	padding-bottom: 5rem;
	overflow: hidden;
	overflow-y: scroll;
}
.hotel-map-filters::-webkit-scrollbar,
.hotel-map-list::-webkit-scrollbar {
	width: 7px;
}

/* Track */
.hotel-map-filters::-webkit-scrollbar-track,
.hotel-map-list::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.hotel-map-filters::-webkit-scrollbar-thumb,
.hotel-map-list::-webkit-scrollbar-thumb {
	background: #d7d7d8;
	border-radius: 10px;
}

/* Handle on hover */
.hotel-map-filters::-webkit-scrollbar-thumb:hover,
.hotel-map-list::-webkit-scrollbar-thumb:hover {
	background: #b8b8b8;
}

.hotel-map .leaflet-container {
	width: 100%;
	height: calc(100% - 80px);
	// border: 0;
}
.leaflet-control-attribution {
	display: none;
}
.leaflet-popup-content {
	width: 100%!important;
	margin: 0!important;
}
.leaflet-popup-content p {
	margin: 0!important;
}
.leaflet-popup-content .hotel-map-detail-section {
	margin-bottom: 0;
	padding-bottom: .4rem;
}
.leaflet-popup {
	width: 300px;
	overflow: hidden;
}

.btn-viewMore {
	width: 81px;
	height: 32px;
	border-radius: 2px;
	padding: 0px, 20px, 0px, 20px;
	background-color: #D4A655;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFF;
	font-size: 16px;
}

.view-more-prices {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 180px;
	height: 20px;
	padding: 0.5rem;
	color: #D4A655;
	font-size: 1rem;
}

.view-more-prices:hover {
	color: #D4A655 !important;
}

.view-more-prices:focus {
	box-shadow: none !important;
}
