.List-passengers-header {
	background-color: #fafafa;
}
.insert-group-search {
	background-color: #0c3b5c;
}
.insert-group-checkbox:checked {
	accent-color: #b18849;
	color: white;
}
.online-vise-img {
	width: 20%;
}

.visa_header_title {
	width: min-content;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
}

.txt-gold-onlinevisa {
	color: #cb9a51;
}
.custom-file {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;
	top: 0;
}

.file-btn {
	position: relative;
	color: white;
	font-weight: 500;
	border: none;

	width: 25%;
	padding: 0.25rem;
	font-size: 16px;
	cursor: pointer;
}

.onlineVisa-clear-bottom {
	border: 1px solid #666666;
	padding: 10px;
}
.scan-mrz-onlineVisa {
	border-radius: 10px;
	border: 1px dashed #cb9a51;
	background-color: #f7f7f7;
}

.online-visa-noResult {
	color: #979797 !important;
	font-size: 20px !important;
}
.online-visa-noResult-tr {
	height: 25vh !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.customPaginationActive {
	background-color: #112e53 !important;
	color: white !important;
}
.custom-file-mrz {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;
	top: 0;
}

.file-btn-mrz {
	border-radius: 5px;
	position: relative;
}

.visa-add-mrz {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.6rem 1.2rem;
	background-color: #F7F7F7;
	border: 1px dashed #CC9A50;
	border-radius: 14px;
}
.visa-add-manual .btn {
	width: 100%;
	padding: .6rem;
	color: #CB9A51;
	border: 1px solid #CB9A51;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
}
.mrz-disclaimer {
	padding: .8rem 1rem;
	color: #000;
	background-color: #DAA24945;
	border: 1px solid #DAA249;
	border-radius: 10px;
	font-size: 15px;
}
.mrz-disclaimer i {
	font-size: 16px;
	color: #DAA249;
}


@media (max-width: 1200px) {
	.visa_header_title, .visa_header_price {
		padding: .4rem 1rem !important;
	}
}

@media (max-width: 992px) {
	.visa_header_title, .visa_header_price {
		width: 100%;
		padding: .4rem 1rem !important;
	}
}