.marketplace-details,
.mybooking-page-wrapper {
	// << slider section >> //
	.transport-slider-container {
		width: 100%;
		padding: 5px;

		.slider-headers {
			width: 100%;
			padding: 5px 0px;
			margin: 5px 0px;

			h5 {
				color: $dark-blue;
			}
			h6 {
				color: $gray-250;
			}
		}

		$sliderImageHeight: 400px;
		// << slider content wrapper >> //
		.slider-content-wrapper {
			width: 100%;
			min-height: $sliderImageHeight;
			max-height: $sliderImageHeight;
			// background-color: firebrick;
		}
		#vertical-slider {
			img {
				object-fit: cover;
				width: 100%;
				max-height: $sliderImageHeight;
			}
		}
	}

	.hotel-facilities {
		ul {
			//column-count: 5;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-basis: 250px;
			flex-wrap: wrap;
			li {
				//width: 25%;
				background-color: #e4e4e4;
				border: 1px grey;
				padding: 5px 7px;
				border-radius: 5px;
				margin: 2px 0;
			}
		}
	}

	.payment-polices {
		.payment-policy {
			border: 1px solid $gray-150;
			background: #ebfff1;
			padding: 0.5rem 0;
			display: flex;
			min-height: 72px;
			li {
				font-size: $text-body;
			}
			i {
				width: 5%;
				color: #00b437;
				margin: 0.5rem;
			}
		}
		.cancellation-policy {
			border: 1px solid $gray-150;
			padding: 0.5rem 0;
			background: $white;
			display: flex;
			min-height: 72px;
			li {
				font-size: $text-body;
			}
			i {
				width: 5%;
				color: $goldColor;
				margin: 0.5rem;
			}
		}
	} //payment-polices

	.similar-hotels {
		p {
			font-size: $text-caption;
			margin: 0;
			color: $dark-gray;
		}
		span {
			font-size: $text-caption;
		}
		.card-img-top {
			width: 100%;
			height: 100%;
			max-height: 240px;
			object-fit: cover;
		}

		.total-price {
			position: absolute;
			top: 10px;
			background-color: $orange;
			padding: 0.5rem;
			h6,
			p {
				color: #fff;
			}
			p {
				font-size: $text-headline;
				span {
					font-size: $text-caption - 3;
					position: relative;
					top: -7px;
				}
			}
		}
		.card-body {
			h5 {
				font-size: $text-caption + 4;
				margin-bottom: 0.3rem;
			}
			> div {
				display: flex;
				justify-content: space-between;
				span {
					position: relative;
					padding-inline-start: 1.5rem;
					&:after {
						content: "|";
						position: absolute;
						right: 0;
						[dir="rtl"] & {
							right: auto;
							left: 0;
						}
					}
					&:last-child {
						&::after {
							display: none;
						}
					}
				} //span
			}
			a.btn {
				&:hover {
					color: $white !important;
					background: #4a6572 !important;
				}
			}
		} //card-body
	} //similar-hotels

	.fa-star {
		color: $goldColor;
	}

	.available-section {
		.heading {
			font-weight: 700;
			font-size: $text-headline;
			margin: 0.5rem 0;
		}
		.available-box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-bottom: 0.5rem;
			.available-buttons {
				width: 6%;
				display: flex;
				justify-content: flex-end;
				margin: 0 0.5rem;
			}
			.available-items {
				display: flex;
				align-items: center;
				width: 100%;
				background-color: $white;
				padding: 0.25rem;
				border: 1px solid $gray-150;
				.item {
					width: 20%;
					margin: 0 0.25rem;
					font-size: $text-caption;
					select {
						height: 40px;
						background: linear-gradient(#eff0f1, #fcfcfc, #fcfcfc);
					}
					.price {
						font-size: $text-headline;
						font-weight: 700;
						color: #354f5c;
						border-bottom: 1px solid $gray-200;
						span {
							padding: 0 0.2rem;
						}
					}
				}
				.total-price {
					position: relative;
					top: 0.7rem;
				}
			}
			.added,
			.available-items {
				min-height: 115px;
				display: flex;
				align-items: center;
				justify-content: center;
				h6 {
					margin-bottom: 0.3rem;
					color: #484848;
				}
				p,
				span {
					font-size: $text-caption;
				}
			}
			.added {
				width: 20%;
				background-color: $blue;
				color: $white;
				cursor: pointer;
				.disabled {
					background: $gray-200;
					color: #acacac;
					cursor: no-drop;
				}
			}
			.plus-icon {
				background: #00b437;
				text-align: center;
				border-radius: 50%;
				color: $white;
				font-size: $text-captio3;
				margin-inline-start: 0.3rem;
				cursor: pointer;
				width: 20px;
				height: 20px;
				line-height: 20px;
			}
			.w-20 {
				width: 20%;
			}
			.w-10 {
				width: 10%;
			}
		} //available-box

		.booking-summary {
			h5 {
				font-weight: 700;
				font-size: $text-headline;
				margin: 0.5rem 0;
			}
			.summary-box {
				display: flex;
				justify-content: space-between;
				font-size: $text-caption;
				border-bottom: 1px solid #dee2e6;
				padding: 0.2rem 0;
				&:last-child {
					border: none;
				}
			} //summary-box
			.total {
				display: flex;
				justify-content: space-between;
				font-weight: 700;
				font-size: $text-headline;
				background: #e8e8e8;
				padding: 0.3rem;
				border: 1px solid #dee2e6;
				color: #3c3c3c;
			}
		}

		h6 {
			font-size: $text-caption;
			font-weight: 700;
		}
	}
	h5 {
		color: #484848;
	}
	.details-item {
		img {
			width: 100%;
			max-height: 130px;
		}
	}
	.hotel-room-count {
		//box-shadow: 6px 6px 10px 0px rgba(215, 215, 215, 0.8) !important;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.11) !important;
		border-radius: 8px;
		border: 1px solid #ebebeb;

		// overflow: hidden;
		.hotel-room-box {
			background: $white;
			padding: 0.5rem;
			// border: 1px solid $gray-250;
			// margin-bottom: 0.5rem;

			h6 {
				font-size: initial;
				color: $dark-blue;
				margin-bottom: 0.3rem;
				font-weight: 700;
				height: 1.5rem;
			}
			li {
				color: #83878b;
				font-size: $text-caption;
			}
			.free {
				color: $goldColor;
				font-weight: bold;
			}
			.old-disc {
				color: $orange;
				text-decoration: line-through;
			}
			.new-disc {
				font-weight: bold;
			}
			.hotel-facility {
				text-align: center;
				border: 1px solid #c8c7c7;
				padding: 0.5rem;
				margin-inline-end: 0.3rem;

				select {
					background: $gray-150;
					outline: none;
				}
				button {
					border-radius: 0;
					a {
						font-size: 1rem;
					}
					&:hover {
						transition: all 0.3s ease-in-out;
						background: $gray-200 !important;
					}
				}
			} //hotel-facility
			.big-img {
				img {
					width: 250px;
					//height: 120px;
					min-height: 160px;
					object-fit: cover;
				}
			} //big-img
			.thubmail-img {
				img {
					width: 125px;
					height: 60px;
					object-fit: cover;
				}
			} //thubmail-img
		} //hotel-room-box
	}

	.room-rates {
		margin: 0 auto;
		box-shadow: 6px 6px 10px 0px rgba(215, 215, 215, 0.8) !important;
		.rate {
			background: #fff;
			align-items: center;
			border: 1px solid #c8c7c7;
			border-top: 0;
			padding: 0.5rem;

			.title {
				color: #0062e6;
				font-size: 1.2rem;
				font-weight: 500;
			}

			.polices {
				color: #83878b;
			}
		}
	}
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
	border-bottom-color: #83878b !important; // default is #000;
}
.image-gallery-swipe {
	.image-gallery-image {
		min-width: 100% !important;
		height: 100% !important;
		object-fit: cover !important;
	}
}

.box-container {
	background-color: #ffffff;
	padding: 12px 12px !important;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.11);
}

.filter-showall {
	font-size: 14px;
	color: #0850ad;
	text-decoration: underline;
	cursor: pointer;
}
.filter-price-range .input-group {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.filter-price-range .control-field__append {
	padding: 0;
	border: 0;
	height: 100%;
}
.filter-price-range .form-control {
	height: 100%;
	font-size: 13px !important;
	padding: 12px 8px !important;
	color: #9a9a9a;
}
.filter-price-range .control-field__body {
	padding: 12px 8px;
}
.show-map-btn {
	background-image: url("../../../assets/images/showMapBtnBg.png");
	padding: 15px 50px;
	background-repeat: no-repeat;
	background-size: contain;
}
.show-map-btn > span {
	position: relative;
	background-color: #0C3B5C;
	color: #fff!important;
	text-transform: capitalize!important;
	padding: 0 5px;
	font-weight: 300;
	font-size: 15px !important;
	border-radius: 2px;
}
