.promo-character-count {
	flex: 1 0 auto;
	text-align: end;
}

.active-text {
	path {
		stroke: var(--blue);
	}
}

.textarea-container {
	box-shadow: 0px 3px 6px #00000029;
}

.single-promo {
	display: flex;
	flex-direction: column;
	width: 1440px;
	height: 1080px;
	background-color: white;
	margin-bottom: 16px;
}
.single-promo-portrait {
	display: flex;
	flex-direction: column;
	width: 1080px ;
	height: 1440px;
	background-color: white;
	margin-bottom: 16px;
}

.single-promo-preview-en {
	transform-origin: 0 0;
	transform: scale(0.21);
}
.single-promo-preview-ar {
	transform-origin: 250px 0;
	transform: scale(0.21);
}
.multiple-promo-preview-en {
	transform-origin: 0 0;
	transform: scale(0.285);
}
.multiple-promo-preview-ar {
	transform-origin: 250px 0;
	transform: scale(0.285);
}

.promo-cover-img {
	background-size: cover;
	flex: 1 0 auto;
	position: relative;
}
.promo-cover-img-container {
	z-index: 2;
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	padding-inline: 32px;
}
.promo-cover-img:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background: rgba(0, 0, 0, 0.34);
}

.sale {
	display: flex;
	justify-content: flex-end;
	margin-inline: 60px;
}
.promo-sale {
	background: transparent url(../../../assets/images/promo/off-bg.svg) 0% 0%
		no-repeat padding-box;
	height: 104px;
	width: 284px;
	background-size: 284px 104px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 56px;
	text-transform: uppercase;
}
.promo-content {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	justify-content: space-evenly;

	.fa-star {
		font-size: 37px;
	}
}
.promo-header {
	font-size: 37.5px;
	text-transform: uppercase;
}

.promo-hotel-name {
	font-size: 66px;
}

.promo-hotel-location {
	font-size: 37.5px;
}

.promo-hotel-city {
	font-size: 37.5px;
}

.promo-calender {
	font-size: 36px;
}

.promo-date-details {
	font-size: 40px;
}

.promo-disclaimer {
	font-size: 22.5px;
	width: 70%;
	word-break: break-all;
}

.font-underline {
	text-decoration: underline;
}

.templete-image-input-container{
	display: flex;
	gap: 16px;
	justify-content: center;
	padding: 32px;
}
.templete-image-radio {
	margin: 0 6px;
	display: inline-flex;
	width: 312px;
	align-content: center;
	justify-content: center;
	position: relative;
	border: 1px solid rgba(128, 128, 128, 0.4);
	 border-radius: 8px; 
	padding: 16px 31px;
	max-height: 270px;

	img{
		max-height: 100%;
	}

	input{
		position: absolute !important;
    left: 6px;
    top: 8px;
	}
}

.select-download-type{
	border-top: 3px dashed black;
    margin-top: 2em;
    padding-top: 16px;
}

.download-label-container {
	display: flex;
	gap: 21px;
	margin-top: 16px;
}
.select-download-label {
	display: flex;
	align-items: center;
	gap: 13px;
	flex: 1 1 auto;
	background-color: #F0F0F0;
	padding: 8px;
	border-radius: 3px;

	input[type="radio"] {
		width: 20px;
		height: 20px;
		border: 1px solid #CD9B51;
		border-radius: 50%;
		background-color: #ccc;
		color: black;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
	}
	
	input[type="radio"]:checked {
		background-color: #CD9B51;
		color: white;
	}
	
}

