.theme-7{
  .theme_about_section{
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    .bg_col{
      background-color: rgb(var(--web-page-primary-color));
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      &:has(iframe){
        padding: 0.5rem;
        iframe{
          border-radius: 12px;
        }
      }
      img{
        width: 100%;
        height: auto;
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        border-radius: 12px;
        display: inline-block;
        margin-inline-start: 6rem;
      }
    }
    .text_col{
      padding-inline-start: 8rem;
      .sub_heading{
        color: rgb(var(--web-page-primary-color));
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 1.5;
      }
      .main_heading{
        color: #000000;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.4;
        margin-top: 1rem;
      }
      .about__text{
        font-size: 1.1rem;
        line-height: 1.6;
        color: #000000;
        margin-top: 1.2rem;
      }
    }
    &:has(iframe){
      .text_col{
        padding-inline-start: 5rem;
      }
    }
    .cta{
      margin-top: 1rem;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 100%;
      gap: 1.5rem;
      .bg_col{
        padding: 0.5rem;
        border-radius: 0;
        img{
          -webkit-transform: scale(1);
          transform: scale(1);
          margin-inline-start: 0;
        }
      }
      .text_col{
        padding-right: 1rem !important;
        padding-left: 1rem !important;
      }
    }
   }
}

html{
  &:dir(rtl){
    .theme-7{
      .theme_about_section{
        .bg_col{
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
       }
    }
  }
}