.umrah-operations-wrapper{
  .phone_code {
    min-width: 120px;
  }
  
  @media screen and (min-width: 767px) {
    .os-actions-btns {
      width: auto !important;
    }
  }

}