.crm {
	.accounting {
		margin: 16px !important;
		.filter {
			display: flex;
			align-items: center;
			background-color: #fff;
			border-radius: 8px;
			padding: 16px;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
			border: rgba(221, 220, 220, 1);
			.control-field__select {
				font-size: 14px;
				color: rgba(112, 113, 112, 1);
				padding-inline: 12px;
			}
			button.applay {
				background-color: rgba(212, 166, 85, 1);
				border-radius: 6px;
				padding: 16px;
				font-size: 18px;
				color: #fff;
				width: 175px;
				padding: 9px 16px;
			}
			.row {
				margin-inline: 0 !important;
			}
		}
		.table-accounting {
			margin-top: 16px;
			border: 1px solid #dddcdc;
			border-radius: 8px;
			overflow: hidden;
			.table {
				margin-bottom: 0 !important;
				thead {
					background: #f7f6f6;
					color: #707170;
					th {
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: 1px;
					}
				}
				tbody {
					td {
						color: #707170;
						font-size: 16px;
						vertical-align: middle;
					}
					.ownerName-column {
						span {
							color: #d4a655;
							font-size: 14px;
						}
					}
					.ownerName-package {
						span {
							font-size: 14px;
						}
						.view {
							color: #d4a655;
							font-size: 14px;
						}
					}
					.btn-manage {
						border: 1px solid rgba(212, 166, 85, 1);
						border-radius: 6px;
						padding: 4px 16px;
						color: rgba(212, 166, 85, 1);
						background-color: #fff;
						font-size: 14px;
						cursor: pointer;
					}
					.no-result {
						h4 {
							color: rgba(0, 0, 0, 1);
							font-size: 28px;
							font-weight: 700;
							margin-bottom: 18px;
						}
						p {
							color: #707170;
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}
// payment Model
.accounting-payment-model {
	.modal-content {
		padding: 24px;
		.modal-title {
			color: #050001;
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 6px;
		}
	}
	.modal-body {
		.filter {
			margin-top: 16px;
			display: flex;
			align-items: center;
			background-color: #fff;
			border-radius: 8px;
			padding: 16px;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
			border: rgba(221, 220, 220, 1);
			.row {
				margin-inline: 0 !important;
			}
			.control-field__body {
				input::placeholder {
					font-size: 18px !important;
				}
			}
			button.btn {
				background-color: rgba(212, 166, 85, 1);
				border-radius: 4px;
				padding: 16px;
				font-size: 16px;
				font-weight: 700;
				color: #fff;
				width: 100px;
				padding: 5px 16px;
			}
		}
		.list {
			margin-top: 10px;
			border: 1px solid #dddcdc;
			border-radius: 8px;
			overflow: hidden;
			.table {
				margin-bottom: 0 !important;
				thead {
					background: #f7f6f6;
					color: #707170;
					th {
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: 1px;
					}
				}
				tbody {
					td {
						color: #707170;
						font-size: 16px;
						vertical-align: middle;
						.bg-light-danger {
							border-radius: 4px;
						}
					}
				}
			}
		}
	}
}
.cs-addPayment {
	min-width: 580px !important;
	.modal-body {
		padding: 8px !important;
		.Total {
			background-color: transparent !important;
			display: flex;
			flex-direction: column;
			column-gap: 5px;
			border-right: 1px solid rgba(221, 220, 220, 0.5);
			.model-lebal {
				color: rgba(10, 51, 82, 1);
				font-size: 18px;
				font-weight: 700;
			}
			.model-amount {
				color: rgba(10, 51, 82, 1);
				font-size: 16px;
				font-weight: 500;
			}
		}
		.Paid {
			background-color: transparent !important;
			display: flex;
			flex-direction: column;
			column-gap: 5px;
			border-right: 1px solid rgba(221, 220, 220, 0.5);
			.model-lebal {
				color: rgba(1, 157, 76, 1);
				font-size: 18px;
				font-weight: 700;
			}
			.model-amount {
				color: rgba(1, 157, 76, 1);
				font-size: 16px;
				font-weight: 500;
			}
		}
		.Outstanding {
			background-color: transparent !important;
			display: flex;
			flex-direction: column;
			column-gap: 5px;
			.model-lebal {
				color: rgba(212, 166, 85, 1);
				font-size: 18px;
				font-weight: 700;
			}
			.model-amount {
				color: rgba(212, 166, 85, 1);
				font-size: 16px;
				font-weight: 500;
			}
			.control-field__label{
				color: rgba(112, 113, 112, 1);
				font-size: 16px;
				font-weight: 500;
			}
			button{
				font-size: 16px;
				font-weight: 700;
				margin-top: 12px;
			}
		}
	}
}
.text-green {
	color: rgba(1, 157, 76, 1);
}
.text-yellow {
	color: #d4a655;
}
.bg-light-danger {
	background-color: rgba(234, 84, 85, 0.08);
}
