.hero-sec {
  margin-top: 75px;
  position: relative;

  img {
    width: 100%;
  }

  .detlis {
    position: absolute;
    top: 25%;
    left: 0px;
    width: 100%;
    text-align: center;

    .manth {
      color: #000;
      font-size: 40px;
      font-weight: 500;
      line-height: 15px;
    }

    .big-title {
      color: #cd9b50;
      font-size: 80px;
      font-weight: 700;
    }

    .save {
      color: #b40000;
      font-size: 27px;
      font-weight: 600;
      letter-spacing: 3px;
    }
  }
}

.check {
  text-align: center;
  font-size: 30px;
  color: #000;
  font-weight: 700;
}

.begin {
  text-align: center;
  color: #0f3460;
  font-size: 22px;
}

.download-all-offer {
	background-image: url(https://png.pngtree.com/background/20210715/original/pngtree-big-sale-special-offer-yellow-background-round-picture-image_1298362.jpg);
	background-size: cover;
	background-position: center;
	text-align: center;
	display: flex;
	align-items: center;
	h4 {
		margin: 0;
	}
}
.card-city {
	position: relative;
	width: calc(25% - 12px);
	height: 180px;

	img {
		max-width: 100%;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}

  .city-name {
    position: absolute;
    bottom: 10px;
    left: 0px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    z-index: 1;
    color: #fff;
  }

  .layer-top {
    width: 100%;
    background: linear-gradient(rgba(242, 235, 235, 0),
        rgba(242, 235, 235, 0),
        rgb(0 0 0));
    position: absolute;
    height: 100%;
    top: 0;
  }
}

.banner {
	position: relative;
	height: 370px;
	width: 100%;
	background-attachment: fixed;
	background-size: cover;
	background-position: center;

  .badge-banner {
    position: absolute;
    background-color: #000;
    color: #fff;
    right: 10%;
    top: 0px;
    width: 210px;
    z-index: 1;
    text-align: center;
    padding: 10px 10px 0px 10px;
    line-height: 1;
		height: 4rem;

    h1 {
      font-size: 50px;
      font-weight: 700;
      line-height: 1;
    }

    p {
      font-size: 24px;
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   bottom: -30px;
    //   left: 0px;
    //   width: 100%;
    //   height: 0px;
    //   border-left: 128px solid transparent;
    //   border-right: 128px solid transparent;
    //   border-top: 30px solid #000;
    // }
  }
	.off-arrow{
		width: 100%;
		position: absolute;
    right: 10%;
    top: 3rem;
		height: 4rem;
    width: 210px;
		img{
			height: 100%;
			width: 100%;

		}
	}

	.banner-name {
		font-size: 4rem;
		color: #fff;
		position: absolute;
		top: 45%;
		width: 100%;

		h1 {
			text-align: center;
			text-shadow: 0px 6px 4px #00000060;
		}
	}
}

.hotel-card {
	width: 32%;
	height: 270px;
	border-radius: 4px;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
	.plus-btn {
		
		height: 36px;
		width: 36px;
		background-color: #cd9b51;
		color: #fff;
		font-size: 20px;
		border-radius: 50%;
		border: 4px solid #fff;
		text-align: center;
	}

	.btn-opacity {
		opacity: 0.5;
	}

	img {
		width: 100%;
		height: 100%;
	}

	.details-hotel {
		margin-bottom: 10px;
		text-align: center;
		width: 90%;
		z-index: 1;
		background-color: #fff;
		border-radius: 6px;
		padding: 5px 8px;

		.row-hotel {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.text-left {
				flex: 1 1 auto;
			}

			.btn-agen {
				background-color: #cd9b51;
				color: #fff;
				font-size: 14px !important;
				padding: 2px 8px;
			}

			.btn-agen {
				background-color: #cd9b51;
				color: #fff;
				font-size: 14px !important;
				padding: 2px 8px;
			}

			.light {
				font-size: 14px;
				color: #afa6a6;
				line-height: 1;
				padding: 2px 5px;
			}

			.bolder {
				font-weight: 700;
				font-size: 14px;
			}
		}
	}
}

.deals-footer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  gap: 10px;
	padding-bottom: 10em !important;

  .white-logo {
    width: 14%;
  }

  .all-right {
    color: #bdb7b7;
  }

  .list-social {
    .social {
      display: flex;
      align-items: center;
      gap: 20px;

      li {
        width: 30px;
        height: 30px;
        background: #312d2d;
        display: flex;
        border-radius: 50%;

        a {
          color: #fff;
          text-align: center;
          width: 100%;

          i {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.flyer {
	position: fixed;
	width: 100%;
	padding-block: 2em;
	background-color: #0000008a;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
	z-index: 10;

	.btn-flyer {
		display: flex;
		align-items: center;
		height: 50px;
		border-radius: 5px;
		overflow: hidden;

		.imgs {
			display: flex;
			align-items: center;
			background-color: white;
			height: 100%;
			padding: 5px 10px;
			gap: 10px;
		}

		.create {
			background-color: #cb9a51;
			color: #fff;
			font-size: 22px;
			font-weight: 700;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 5px 30px;
		}

		.select {
			background-color: #bcbcbc;
			color: #fff;
			font-size: 22px;
			font-weight: 700;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 5px 30px;
		}
	}

	.disable-selection {
		text-decoration: underline;
		color: #fff;
		font-size: 22px;
	}
}

.hotels-page {
	width: 1080px;
	max-height: 1440px;
	height: 1440px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	border: 1px solid #eee;
	background-color: #f6f8ff;

	.header-hotels {
		width: 100%;
		position: relative;
		height: 223px;

		.title {
			color: #fff;
			position: absolute;
			top: 45%;
			width: 100%;
			font-size: 33.5px;
			text-align: center;
		}
	}

	.cards-section {
		background-color: #fff;
		padding: 20px;
		z-index: 1;
		position: relative;
		top: -5px;
		margin: auto;
		width: 95%;
		display: flex;
		gap: 20px;
		flex-direction: column;
		border: 1px solid #eee;
		font-size: 16px;
		flex: 1 0 auto;

		.tamplate-card {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex: 1 1 auto;
			.tamplate-img {
				position: relative;
				background-size: cover;
				width: 30%;
				img {
					width: 100%;
				}
				.badge-banner {
					position: absolute;
					background-color: #b40000;
					color: #fff;
					left: 10px;
					top: 0px;
					width: 40%;
					z-index: 1;
					text-align: center;
					padding: 10px 10px 0px 10px;
					line-height: 1;

					h1 {
						font-size: 25px;
						font-weight: 700;
						line-height: 1;
					}

					p {
						font-size: 24px;
					}

					&::before {
						content: "";
						position: absolute;
						bottom: -15px;
						left: 0px;
						width: 100%;
						height: 0px;
						border-left: 55px solid transparent;
						border-right: 55px solid transparent;
						border-top: 15px solid #b40000;
					}
				}
			}

			.tamplate-info {
				background: #ebebeb;
				width: 70%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.row-hotel {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-inline-start: 10px;

					.bolder {
						font-size: 1.5em;
						font-weight: 700;
					}

					.light {
						color: #959191;
					}

					.text-right {
						background-color: #fff;
						line-height: 1;
						padding: 10px 5px 10px 10px;
					}
				}
			}

			.tamplate-footer {
				background-color: #d1d1d1;
				color: #000;
				display: flex;
				align-items: center;
				justify-content: center;
				span {
					padding: 4px;
					font-size: 1.1em;
					white-space: pre;
				}
			}
		}
	}

	.footer-hotels {
		display: flex;
		align-items: center;
		padding: 10px;
		border-top: 1px solid #eee;
		box-shadow: 0px -16px 20px 0px #bdbaba;
		z-index: 1;
		background-color: white;
		position: relative;
		justify-content: space-between;
		max-height: 7em;
		min-height: 6em;
		.footer-des {
			width: 70%;
			word-break: break-all;
		}
		.footer-logo {
			display: inline-block;
			max-width: 100%;
			max-height: 100%;
			width: 28%;
			img{
				max-height: 98px;
			}
		}
	}
}




.title-packages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
	padding-inline-start: 20px;

  .discover {
    color: #0C3B5C;
    font-size: 38px;
    font-weight: 700;
  }

  .here {
    color: #484848;
    font-size: 26px;


  }

}

.package-card {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  padding: 15px;
	flex: 0 0 20%;
  height: 210px !important;

  .layer-top {
    width: 100%;
    background: linear-gradient(rgba(242, 235, 235, 0),
        rgba(242, 235, 235, 0),
        rgba(0, 0, 0, 0.705));
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;
  }

  .package-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h1 {
      font-size: 22px;
      font-weight: 700;
      color: #fff;
    }

    .cols-p {
      font-size: 13px;
      color: #fff;
      // font-weight: 700;

      p {
        padding: 4px;
      }
    }
  }

  .package-price {
    position: absolute;
    bottom: 15px;
    // right: 15px;
    color: #fff;
    font-size: 14px;
		width: 90%;
		// left: 50%;
    h1 {
      font-size: 27px;
      font-weight: 700;
      color: #d09a59;
			line-height: .8;
    }
  }
}

.badge-pac {
  background-color: #0C3B5C;
  color: #fff;
  padding: 0px 3px;
  border-radius: 5px;
}

.packages-box {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0px;
}

.slider-box-pack {
  .react-multi-carousel-list {
    button {
 
      border-radius: 5px;
      background: #fff;
      min-width: 30px;
      min-height: 30px;
      opacity: 1;
      color: #8c8c8c	
		}
		.react-multiple-carousel__arrow::before{
			color: #8c8c8c;
		}


    .react-multiple-carousel__arrow--left {
      left: calc(0% + 1px) !important;
    }

    .react-multiple-carousel__arrow--right {
      right: calc(0% + 1px) !important;
    }

    .react-multi-carousel-track {
      .pakage-slide {
        height: 501px;
        width: 324px !important;
				margin-inline-end: 15px;

        .package-card {
          width: 100%;
          height: 100% !important;

          .package-price {
            line-height: 0.5;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) and (min-width: 320px) {
  .packages-box {
    flex-direction: column;

  }
	.package-card{
		width: 100%;
		flex: 0 0 100%;
	}
}
.download-sort-container{
  display: flex;
  flex-direction: column;
  gap:16px;
}
.download-sort-card {
	display: flex;
	align-items: center;
	gap: 16px;
}

.drag-item-icon {
	flex: 1 1 auto;
}

.sort-card-content {
	flex: 1 1 auto;
	width: 100%;
}
.sort-card-img {
	background-size: cover;
	width: 137px;
	flex: 0 0 auto;
}
