.theme-5 {
  .wb-header-container {
    position: relative;
  }
  

  .wb-header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1280px;
    background-color: transparent;
    z-index: 9;

    .header-nav {
      display: flex;
      align-items: center;
      gap: 42px;

      .main-nav {
        gap: 16px;

        li {
          color: #ffffff;
        }
        .btn-secondary,
        .btn-secondary:active:focus {
          color: #fff !important;
          font-size: 16px !important;
          background-color: transparent;
          border: none;
          box-shadow: none;
        }        
      }
    }

    .logo img {
      height: auto;
      max-height: 50px;
    }

    .contact-button {
      border-radius: 40px;
      background-color: rgba(var(--web-page-primary-color));
    }

    .webBuilder-contact p,
    .text-primary-web-builder {
      color: #ffffff !important;
    }

    .call-us svg path {
      stroke: #ffffff;
    }
  }

  .wb-hero {
    margin-bottom: 40px;

    .hero-container {

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #00000082;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5858718487394958) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }

    .hero-content-container {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: 100%;
      padding: 1rem;
      text-align: center;
      z-index: 2;
    }

    .searchbar_container {
      position: absolute;
      bottom: -40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 700px;
      padding: 6px 8px;
      background-color: #ffffff;
      border: 1px solid rgba(248, 248, 248, 1);
      border-radius: 40px;
      box-shadow: -20px 0px 30px rgba(0, 0, 0, 0.1);
      gap: 40px;

      .custom-control-field {
        border: none !important;
      }

      .form_select {
        position: relative;
        padding-inline-start: 45px;
        flex-grow: 1;
        background-color: #ffffff;
        // border: 1px solid #ebebeb;
        border-radius: 8px;

        .icon {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 50%;
          transform: translateY(-50%);
          inset-inline-start: 8px;
          border-radius: 50%;
          border: 1px solid rgb(var(--web-page-primary-color));
          padding: 5px;
          width: 40px;
          height: 40px;

          svg {
            width: 85%;
          }

          path {
            stroke: rgb(var(--web-page-primary-color));
          }
        }

        .control-field {
          border: 0;
        }
      }

      .cta_btn {
        position: relative;
        padding: 14px 20px;
        text-align: center;
        border: 1px solid rgb(var(--web-page-primary-color));
        box-shadow: none;
        outline: none;
        background-color: rgb(var(--web-page-primary-color));
        color: #ffffff;
        border-radius: 40px;

        .icon {
          position: relative;
          display: inline-block;
          margin-inline-end: 0.3rem;
          width: 22px;
          height: auto;
          z-index: 1;

          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }

  .wb-our-service {
    background-color: #F8F8F8;

    .services-content {
      .service-header {
        margin-bottom: 0;
        font-size: 20px;
        color: rgb(var(--web-page-primary-color));
      }

      .service-body {
        font-size: 28px;
        font-weight: 700;
        color: #000000;
      }
    }

    .service-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      margin-top: 20px;
      gap: 16px;
    }

    .service-card {
      display: flex;
      flex-direction: column;


      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 76px;
        background-color: rgb(var(--web-page-primary-color), 0.08);
        border: solid thin rgb(var(--web-page-primary-color));
        border-radius: 50%;

        svg {
          width: 50%;
          height: 50%;
        }

        svg path {
          stroke: rgb(var(--web-page-primary-color));
        }
      }

      .title {
        margin-top: 20px;
        color: rgb(var(--web-page-primary-color));
      }

      .sub-title {
        font-size: 20px;
        font-weight: 700;
        color: #050001;
        margin-bottom: 20px;
      }

      .description {
        font-size: 16px;
        color: #707170;
      }

      .toggle-check-box {
        padding-top: 10px;
        margin-top: auto;
      }
    }
  }

  //////////////////////////////////// our packages section ////////////////////////////////////
  .wb-our-packages {
    padding: 50px 0;

    .packages-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(352px, 1fr));
      gap: 24px;
    }
  }

  //////////////////////////////////// hotels section ////////////////////////////////////
  .wb-hotels {
    position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    margin-top: 140px;

    &::before {
      content: "";
      position: absolute;
      background-color: rgb(var(--web-page-primary-color));
      top: 0;
      left: 0;
      width: 100%;
      height: 65%;
      max-height: 450px;
      z-index: 0;
    }

    .services-content {
      position: relative;
      z-index: 2;
    }

    .package-hotel-card {
      margin: .5rem .4rem;
    }

    .abstract-bg {
      position: absolute;
      -webkit-mask-repeat: repeat-x;
      mask-repeat: repeat-x;
      background-color: rgb(var(--web-page-primary-color));
      top: -88px;
      left: 0;
      height: 100px;
      width: 100%;
      z-index: 9;
    }

    .react-multi-carousel-track {
      margin-bottom: 0 !important;
    }

    .react-multiple-carousel__arrow--right {
      inset-inline-end: -30px !important;
      top: 50%;
    }

    .react-multiple-carousel__arrow--left {
      inset-inline-start: -30px !important;
      top: 50%;
    }
  }

  //////////////////////////////////// tours section ////////////////////////////////////
  .wb-tours {
    padding-top: 70px;
    padding-bottom: 70px;

    .tours-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      gap: 20px;
    }

    .tour-card {
      display: flex;
      padding: 10px;
      border: 1px solid #f8f8f8;
      border-radius: 24px;
      margin: 0;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      background: #ffffff;
      gap: 10px;
      transition: border .2s ease-in-out;
      cursor: pointer;
      overflow: hidden;

      &:hover {
        border-color: rgb(var(--web-page-primary-color));
        transition: border .35s ease-in-out;

        .card-img img {
          transform: scale(1.2);
          -webkit-transform: scale(1.2);
          transform-origin: center center;
          transition: all .5s linear;
        }
      }

      .card-img {
        width: 160px;
        height: 160px;
        border-radius: 14px;
        aspect-ratio: 1;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1);
          -webkit-transform: scale(1);
          transform-origin: center center;
          transition: all .5s linear;
          backface-visibility: visible;
        }
      }

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // image size plus gap size
        width: calc(100% - (160px + 10px));
      }

      .title {
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .price {
        padding-top: 10px;
        color: #222222;
        font-size: 16px;
        border-top: solid thin #E2E8F0;

        .amount {
          color: rgb(var(--web-page-primary-color));
          font-weight: bold;
          font-size: 26px;
          line-height: 22px;
          margin-inline-start: 0.5rem;
        }

        .currency {
          color: rgb(var(--web-page-primary-color));
          font-size: 14px;
          font-weight: 400;
        }
      }

      svg path {
        stroke: #707170;
      }
    }
  }

  //////////////////////////////////// flights section ////////////////////////////////////
  .wb-flights {
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #F8F8F8;

    .flights-cards-container {
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)) !important;
      gap: 24px;
    }

    .flight-card {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-radius: 24px;
      border: 1px solid #F8F8F8;
      // border: 1px solid rgb(var(--web-page-primary-color));
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      background: #ffffff;
      gap: 20px;
      transition: border .35s ease-in-out;
      cursor: pointer;

      &:hover {
        border-color: rgb(var(--web-page-primary-color));
        transition: border .35s ease-in-out;
      }

      .title {
        display: inline-block;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        max-width: 100%;
        color: #222222;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .destination-port {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: rgb(var(--web-page-primary-color));
        font-size: 20px;
        text-align: center;
        font-weight: 700;
        line-height: 1.2;

        .port-country {
          font-size: 18px;
          color: #222222;
          font-weight: 400;
        }
      }

      .price {
        padding-top: 10px;
        font-size: 16px;
        text-align: center;
        color: #222222;
        border-top: solid thin #E2E8F0;

        .amount {
          color: rgb(var(--web-page-primary-color));
          font-weight: bold;
          font-size: 26px;
          line-height: 22px;
          margin-inline-start: 0.5rem;
        }

        .currency {
          color: rgb(var(--web-page-primary-color));
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  //////////////////////////////////// about us section ////////////////////////////////////
  #about-us-section {
    position: relative;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: rgb(var(--web-page-primary-color));

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../../../assets/images/webBuilder/theme-5-about-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 0.05;
      z-index: 0;
    }

    .web-builder-content-about-us {
      position: relative;
      z-index: 2;
      gap: 60px;
      background-color: transparent;
    }


    .about-us-details {
      color: #ffffff !important;

      .about-us-title {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff !important;
      }

      .text-body {
        color: #ffffff !important;
        margin: 40px 0;
        white-space: pre-line
      }

      .web-builder-filled-btn {
        border-radius: 24px;
        background-color: #ffffff;
        color: rgb(var(--web-page-primary-color));
      }
    }
  }
  #about-us-section > .edit-box-actions-parent {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }

  //////////////////////////////////// faqs section ////////////////////////////////////
  .wb-faqs {
    padding: 80px 0;

    .service-body {
      display: none;
    }

    .faq-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      gap: 30px;
    }

    .faq-questions .h4 {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(117, 53, 114, 0.16)
    }

    .faqs-container-question p {
      color: #1F1F1F;
      font-weight: 700;
    }

    .faq-image {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 500px;
      border-radius: 6px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .upload-file-container {
      position: relative;
      width: 100%;
      height: 100%;

      label {
        width: 100%;
        height: 100%;
      }
    }
  }

  //////////////////////////////////// contact us section ////////////////////////////////////
  .wb-contact-us {
    .web-builder-content-contact-us {
      padding: 20px 0 80px;
    }

    .contact-us-layout {
      padding: 32px;
      background-color: #EFEFEF;
      border-radius: 20px;
    }

    .content-top-section {
      .t-body {
        font-size: 20px !important;
        color: rgb(var(--web-page-primary-color)) !important;
        margin-bottom: 0;
        font-weight: 700 !important;
      }
    }

    .contact-us-form {

      .control-field,
      .contact-us-submit-button,
      textarea {
        border-radius: 24px !important;
      }

      .contact-us-submit-button {
        padding: 12px !important;
      }
    }


  }

  //////////////////////////////////// footer section ////////////////////////////////////
  .footer-container {
    position: relative;
    margin-top: 100px;
    padding: 2rem 3rem;
    background: rgb(var(--web-page-primary-color));

    .abstract-bg {
      position: absolute;
      -webkit-mask-repeat: repeat-x;
      mask-repeat: repeat-x;
      background-color: rgb(var(--web-page-primary-color));
      top: -88px;
      left: 0;
      height: 100px;
      width: 100%;
      z-index: 9;
    }

    .footer_content {
      position: relative;
      z-index: 3;
      color: #ffffff;

      .logo_container {
        text-align: center;

        img {
          width: 200px;
          height: auto;
        }
      }

      .footer_text {
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;

        p {
          max-width: 600px;
          margin: 0 auto;
        }
      }

      .info_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 3rem;
        margin-block: 2rem;

        .social_media {
          display: flex;
          gap: 1rem;
          justify-content: flex-start;
          align-items: center;

          .text {
            font-size: 1.3rem;
            line-height: 1.5;
            font-weight: 600;
          }

          .links {
            display: flex;
            gap: 0.5rem;
            justify-content: flex-start;
            align-items: center;

            svg {
              position: relative;
              display: inline-block;
              transition: all 0.3s linear;

              // circle {
              //   fill: rgb(var(--web-page-primary-color));
              //   fill-opacity: 0.35;
              // }
              &:hover {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }
            }
          }
        }

        .contact_phone {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 0.75rem;

          .text,
          .phone {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1.5;

            a {
              display: inline-block;
              color: #ffffff;
            }
          }
        }
      }

      .footer_links {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.2rem;
        padding: 0;
        margin: 0;
        list-style: none;
        margin-top: 2rem;
        margin-bottom: 2rem;

        li {
          a {
            color: #ffffff;
            display: inline-block;
            transition: all 0.3 ease-in-out;

            &:hover {
              color: rgb(var(--web-page-primary-color), 0.6);
            }
          }
        }
      }

      .copyright_text {
        text-align: center;
        padding-top: 2rem;
        border-top: 1px solid #ffffff9e;
        max-width: 900px;
        margin-right: auto;
        margin-left: auto;

        p {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }


  // hero slider and hotels slider
  .wb-hero,
  .wb-hotels {
    .react-multiple-carousel__arrow {
      top: 45%;
      right: auto;
      left: auto;
      background-color: transparent !important;
      border: 2px solid #ffffff;

      &::before {
        display: none;
      }

      &.react-multiple-carousel__arrow--right {
        inset-inline-end: 2rem;
      }

      &.react-multiple-carousel__arrow--left {
        inset-inline-start: 2rem;
      }
    }
  }

  // shared  card in packages and hotels
  .package-hotel-card {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto !important;
    padding: 10px;
    border-radius: 24px;
    border: 1px solid #F8F8F8;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    transition: border .2s ease-in-out;
    gap: 10px;
    overflow: hidden;

    &:hover {
      border-color: rgb(var(--web-page-primary-color));
      transition: border .35s ease-in-out;

      .card-img img {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        transform-origin: center center;
        transition: all .5s linear;
      }
    }

    .card-img {
      width: 100%;
      height: 250px;
      border-radius: 14px;
      overflow: hidden;

      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        transform: scale(1);
        -webkit-transform: scale(1);
        transform-origin: center center;
        transition: all .5s linear;
        backface-visibility: visible;
      }
    }

    .card-content-top {
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
      padding: 0 10px 10px 10px;
      color: #050001;
      gap: 16px;
    }

    .title {
      display: inline-block;
      font-size: 22px;
      font-weight: 700;
      max-width: 140px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .location {
      color: #707170;
      margin-bottom: 10px;
    }

    .price {
      font-size: 26px;
      line-height: 22px;
      font-weight: 700;
      color: rgb(var(--web-page-primary-color));

      .currency {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .rate {
      position: absolute;
      display: flex;
      align-items: center;
      padding: 6px 12px;
      top: 30px;
      left: 30px;
      border-radius: 30px;
      background-color: #ffffff;
      color: #222222;
      line-height: 8px;
      z-index: 1;
      gap: 10px;

      svg path {
        stroke: #ff9f43 !important;
      }
    }

    .card-content-bottom {
      padding: 10px 10px 0;
      border-top: solid thin #E2E8F0;
      color: #707170;
    }

    svg path {
      stroke: #707170;
    }
  }


  // website builder default styles resets 
  .service-top-section {
    position: relative;
    padding: 0 0 22px;
    display: block;
    z-index: 1;
  }

  .services-content {
    text-align: start !important;
    max-width: 100% !important;

    .service-header {
      font-size: 28px;
      font-weight: 700;
      color: #000000;
    }

    .service-body {
      font-size: 16px;
      font-weight: 500;
      color: #707170;
    }
  }

  .empty-service::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(112, 113, 112, 0.7) !important;
    z-index: 2;
  }

  .add-service-btn {
    top: 50%;
    z-index: 3;
  }
  &.web-builder-page-contianer .services-content {
    display: none;
  }
}