.box-types {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.type {
		display: flex;
		align-items: center;
		column-gap: 7px;
		accent-color: #bf7f1a;

		label {
			margin-bottom: 0px;
		}
	}
	.type.active{
		background: #FDF4E3;
    padding: 5px;
    border-radius: 16px;
	}
}

.flight-search {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	padding-bottom: 20px;
	padding-inline: 16px;

	.input-group {
		width: 100%;
	}

	.fromflight {
		position: relative;

		.control-field {
			border-end-start-radius: 5px !important;
    border-start-start-radius: 5px !important;
			border-end-end-radius: 0px !important;
    border-start-end-radius: 0px !important;
		}

		.fa-exchange-alt {
			position: absolute;
			inset-inline-end: -8px;
			top: 50%;
			z-index: 1;
			color: #d19d4e;
		}
	}

	.toflight {
		.control-field {
			border-inline-start: 0 !important;
    border-end-end-radius: 5px !important;
    border-start-end-radius: 5px !important;
		border-end-start-radius: 0px !important;
    border-start-start-radius: 0px !important;
		}
	}

	.css-6q0nyr-Svg {
		display: none;
	}
}

.banner-flight {
	background-image: url(../../../assets/images/flight-banner-view.png);
	background-position: center;
	background-size: cover;
	height: 170px;
	width: 100%;
	margin-top: -32px;
	margin-inline: -32px;

	.modify {
		background-color: bisque;
		width: 100%;
		height: 120px;
		border: 1px solid #ebebeb;
		background-color: #ffffffeb;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}
}

.steps {
	width: 150px;
	height: 50px;
	padding: 10px;
	border-radius: 5px;
	background-color: #e5e5e5;
	color: #2d2d2d;
	vertical-align: middle;
}

.search-type {
	color: #1a61af;
	font-size: 20px;
}

.available-search {
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 5px;
}

.flight_item {
	background-color: #fff;
	width: 100%;

	.head-toggel {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		font-size: 14px;
		padding: 4px;

		a {
			color: #2d2d2d;

			i {
				margin-left: 4px;
			}
		}
	}

	.time-line {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		column-gap: 10px;
		padding-bottom: 10px;

		.infoAir {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			position: relative;

		/* 	.takeoff::after {
				content: "";
				position: absolute;
				padding: 3px;
				border: 1px solid #1a61af;
				border-radius: 50%;
				top: 16%;
				[dir="ltr"] & {
					right: -11px;
				}
				[dir="rtl"] & {
					left: -11px;
				}
			} */
		}

		.Landing {
			display: flex;
			flex-direction: column;
			position: relative;
			align-items: flex-start;

		/* 	.takeoff::before {
				content: "";
				position: absolute;
				padding: 3px;
				border: 1px solid #1a61af;
				border-radius: 50%;
				top: 16%;
				[dir="ltr"] & {
					left: -11px;
				}
				[dir="rtl"] & {
					right: -11px;
				}
			} */
		}

		.airport-name {
			color: #2d2d2d;
			font-size: 14px;
		}
	}

	.footer-toogle {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 5px 0;
		justify-content: space-between;
		border-top: 1px solid #ebebeb;

		.img-company {
			width: 50px;
			height: 50px;
		}
	}
}

.collapse-box {
	background-color: #f8f8f8;

	.infoAir {
		.airport-name {
			font-size: 12px;

/* 			::after {
				content: "";
				position: absolute;
				padding: 3px;
				border: 1px solid #1a61af;
				border-radius: 50%;
				top: 14%;
				[dir="ltr"] & {
					right: -11px;
				}
				[dir="rtl"] & {
					left: -11px;
				}
			} */
		}
	}

	.Landing {
		.airport-name {
			font-size: 12px;

/* 			::before {
				content: "";
				position: absolute;
				padding: 3px;
				border: 1px solid #1a61af;
				border-radius: 50%;
				top: 14%;
				[dir="ltr"] & {
					left: -11px;
				}
				[dir="rtl"] & {
					right: -11px;
				}
			} */
		}
	}

	.all-date {
		font-size: 12px;
	}
}

.takeoff {
	font-weight: bold;
	line-height: 1;
}

.blue {
	color: #1a61af;
}

.flight-select {
	background-color: #bf7f1a;
	font-weight: bold;
	color: #fff;
	margin-left: 10px;
}

.img-company {
	width: 50px;
	height: 50px;
}

.spent {
	color: #a0a0a0;
	font-size: 14px;
	padding: 5px 20px 6px 20px;
	border-top: 2px dashed #1a61af;
	position: relative;
				&::after {
				content: "";
				position: absolute;
				padding: 3px;
				border: 1px solid #1a61af;
				border-radius: 50%;
				top: -4px;
				[dir="ltr"] & {
					right: -11px;
				}
				[dir="rtl"] & {
					left: -11px;
				}
			}
				&::before {
				content: "";
				position: absolute;
				padding: 3px;
				border: 1px solid #1a61af;
				border-radius: 50%;
				top: -4px;
				[dir="ltr"] & {
					left: -11px;
				}
				[dir="rtl"] & {
					right: -11px;
				}
			}

}
.collapse-box {
	.spent{
		padding: 5px 20px 19px 20px !important;
	}
}
.stop {
	color: #a0a0a0;
	font-size: 14px;
	padding: 5px 20px 0px 20px;
}

.btn-x {
	color: #000;
	font-size: 18px;
	font-weight: bold;
}

.stop-box {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		left: -16px;
		top: -62px;
		border: 1px solid #b1b1b1;
		padding: 3px;
		border-radius: 50%;
	}

	&::before {
		content: "";
		position: absolute;
		left: -16px;
		top: 95px;
		border: 1px solid #b1b1b1;
		padding: 3px;
		border-radius: 50%;
	}

	.blue {
		&::after {
			content: "";
			position: absolute;
			left: -18px;
			top: 20%;
			border: 1px solid #0372bc;
			padding: 5px;
			border-radius: 50%;
		}

		&::before {
			content: "";
			position: absolute;
			top: 20px;
			left: -87px;
			width: 150px;
			border-bottom: 2px dashed #979797;
			transform: rotate(270deg);
		}
	}
}

.stop-box-ar {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		right: -16px;
		top: -62px;
		border: 1px solid #b1b1b1;
		padding: 3px;
		border-radius: 50%;
	}

	&::before {
		content: "";
		position: absolute;
		right: -16px;
		bottom: -58px;
		border: 1px solid #b1b1b1;
		padding: 3px;
		border-radius: 50%;
	}

	.blue {
		&::after {
			content: "";
			position: absolute;
			right: -18px;
			top: 20%;
			border: 1px solid #0372bc;
			padding: 5px;
			border-radius: 50%;
		}

		&::before {
			content: "";
			position: absolute;
			top: 20px;
			right: -87px;
			width: 150px;
			border-bottom: 2px dashed #979797;
			transform: rotate(270deg);
		}
	}
}


.info-outbound {
	display: flex;
	align-items: stretch;
	flex-direction: row;
	background-color: #fff;
	border: 1px solid #ebebeb;
	border-radius: 5px;

	.FlightNo,
	.BookingCode,
	.airports,
	.date {
		height: 70px;
		border-left: 1px solid #ebebeb;
		font-size: 16px;
		padding: 10px;
		.bold {
			font-weight: bold;
		}
	}
	.type {
		background-color: #0372bc;
		color: #fff;
		flex-grow: 1;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			font-size: 18px;
		}
	}

	.FlightNo {
		flex-grow: 1;
	}

	.BookingCode {
		flex-grow: 1;
	}

	.airports {
		flex-grow: 2;
	}

	.date {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 6;
	}
}
.summary-flight {
	.sum-type {
		background-color: #e5e5e5;
		color: #979797;
		border-radius: 5px;
		padding: 5px;
		font-weight: bold;
	}
	i {
		color: #bf7f1a;
	}
}
.box-selected {
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 49%;
	padding: 20px 10px;

	> div {
		display: flex;
		align-items: center;
	}
	label {
		margin-bottom: 0px;
	}
}
.selected {
	border: 1px solid #d29d4d;
}
.bold {
	font-weight: bold;
}

#minissue {
	accent-color: #ad7b2f;
}
.conatctBox {
	background-color: #fff;
	border-radius: 10px;
	border: 1px solid #ededed;
	padding: 20px;
}
.form-head {
	background-color: #e4e4e4;
	color: #0f3e5e;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.form-box {
	border: 1px solid #ededed;
	border-radius: 5px 5px 0px 0px;
	width: 100%;
	background-color: #fff;
	// overflow: hidden;
}

.flight-checkout-btn {
	background-color: #28a745;
	padding: 10px 70px;
	color: #fff;
}
.price-color {
	.bold {
		color: #2d2d2d;
		font-size: 14px;
	}
	p {
		color: #979797;
		font-size: small;
	}
}
.roules {
	color: #979797;
	font-size: 12px;
}
.btn-popup {
	color: #d29d4d;
	font-size: small;
	font-weight: bold;
}
.table-flights {
	th {
		padding: 10px;
		font-size: 14px;
	}
	td {
		padding: 10px;
	}
	.two-row {
		vertical-align: middle;
		border-right: 1px solid #dee2e6;
	}
}

.rules-side-bar {
	max-height: 50vh;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
	}
	ul {
		padding: 10px 0px 10px 0px;
		li {
			color: #808080;
			font-size: 14px;
			padding-left: 20px;
		}
		li:hover {
			background-color: #ebf5ff;
			border-left: 3px solid #0050a6;
			color: #0050a6;
		}
		li.active {
			background-color: #ebf5ff;
			border-left: 3px solid #0050a6;
			color: #0050a6;
		}
	}
}

.bg-tap {
	border: 1px solid #e1e1e1;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 50px;
	background-color: #f7f7f7;
}
.rules-tap {
	border: 1px solid #e1e1e1;
	display: flex;
	align-items: center;
	padding: 8px;
}
.rules-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
}
.title-rules {
	display: flex;
	flex-direction: column;
	p {
		font-size: 14px;
	}
	span {
		font-size: 10px;
		line-height: 1;
	}
}
.rules-side-bar {
	td,
	th {
		padding: 10px;
	}
	.table-container {
		border-radius: 5px;
		overflow: hidden;
	}
}
.rules-side-bar {
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #fff;
		border: 1px solid #eeee;
	}

	&::-webkit-scrollbar-thumb {
		background: #dadada;
		border-radius: 20px;
		box-shadow: #2d2d2d 0px 1px 3px;
	}

	// &::-webkit-scrollbar-thumb:hover {
	//   background: #555;
	// }
}
.phone {
	--phoneCodeWidth: 100px;
	position: relative;
}
.phone .phone_number_textfield {
	padding-inline-start: calc(var(--phoneCodeWidth) + 10px) !important;
}

.phone_code {
	position: absolute;
	width: var(--phoneCodeWidth);
	z-index: 2;
}
.phone_code .control-field {
	border: 0;
	background-color: transparent !important;
	// border: 1px solid #D1D6DD;
}

.date-of-birth .control-field {
	border-radius: 0 !important;
}

// flight actions (refund / void / change)
.flight-actions-title {
	font-size: 1.2rem;
	font-weight: bold;
}
.flight-actions-title span {
	font-size: 0.8rem;
	margin: 0 5px;
	font-weight: normal;
	color: #898989;
}

.flight-action-content {
	position: relative;
	margin: 1rem 0;
}

.flight-actions-side-steps {
	position: relative;
	width: 34px;
}
.flight-actions-side-steps span {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 34px;
	border-radius: 50%;
	aspect-ratio: 1;
	background-color: #d7a04b;
	color: #fff;
	font-size: 1.2rem;
	z-index: 2;
}

.flight-action-content
	> div:not(:last-child)
	.flight-actions-side-steps::after {
	content: "";
	position: absolute;
	width: 1px;
	height: calc(100% + 0.3rem);
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background-color: #d6d6d6;
	z-index: 1;
}

.flight-actions-passenger {
	display: flex;
	align-items: center;
	padding: 6px 12px;
	margin: 12px 0;
	font-size: 0.9rem;
	background-color: #ffffff;
	border: 1px solid #d1d6dd;
	cursor: pointer;
}

.flight-actions-passenger.selected {
	background-color: #fff9ef;
	border: 1px solid #d29d4d;
}

.flight-actions-passenger.selected .check-mark {
	color: #d29d4d;
}

.flight-actions-passenger .check-mark {
	margin-left: auto;
}
[dir="rtl"] .flight-actions-passenger .check-mark {
	margin-left: initial;
	margin-right: auto;
}

.file-upload-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 12px;
}
.file-upload-container span {
	font-size: 14px;
}

// file upload
.file-upload-control {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px 12px;
	background-color: #d29d4d;
	border: 1px solid #d1d6dd;
	border-radius: 3px;
	color: #fff;
	cursor: pointer;
	overflow: hidden;
}
.file-upload-control label {
	cursor: inherit;
}
.file-upload-input {
	position: absolute;
	left: 0;
	right: 0;
	opacity: 0;
	cursor: inherit !important;
}

.file-upload-control:has(.disabled) {
	background-color: #979797;
	cursor: not-allowed;
}
.file-upload-input.disabled {
	z-index: -1;
}

.cert-files-list {
	list-style: none;
	margin: 0.2rem 0;
}
.cert-file {
	display: flex;
	align-items: center;
}
.cert-file .file-attach {
	color: #0850ad;
	font-size: 15px;
}
.cert-file .remove-file {
	margin-top: 3px;
	font-size: 11px;
	color: #c30101;
}

.flight-refund-modal-caution {
	margin: 0 auto;
	padding: 6px 10px;
	background-color: #fff9ef;
	border: 1px solid #d29d4d;
}
.flight-refund-modal-caution i {
	color: #ffb03e;
}
.flight-refund-modal-details {
	border-spacing: 3px;
	border-collapse: separate;
	font-size: 14px;
}
.flight-refund-modal-details td {
	border: 0;
	background-color: #f7f7f7;
	border: 1px solid #ededed;
}

.radio-input {
	width: 16px;
	height: 16px;
	accent-color: #bb822d;
	color: #2d2d2d;
}

.flight-booking-nav-tabs {
	background-color: #ebebeb;
}
.flight-booking-nav-tabs .nav-link {
	padding: 1rem;
	background-color: #d2d2d2;
	color: #000;
	border-radius: 0;
}
.flight-booking-nav-tabs .nav-item .active {
	background-color: #fff;
	color: #cb9a51;
	font-weight: bold;
	& i {
		color: #cb9a51;
	}
}
.flight-requests-header {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	border: 1px solid #ededed;
	& .btn-toggel:focus,
	.btn-toggel:focus-visible {
		outline: none;
		border: 0;
		box-shadow: none;
	}
	& p {
		min-width: 190px;
	}
}
.flight-requests-type {
	display: flex;
	align-items: center;
	width: 140px;
	background-color: #f8f9fa;
	color: #10365f;
	border: 1px solid #ededed;
	font-weight: bold;
}
.flight-requests-details {
	position: relative;
	background-color: #f8f9fa;
	border: 1px solid #ededed;
	padding: 12px;
}
.flight-requests-box {
	background-color: #f4f4f4;
	border: 1px solid #ededed;
	border-radius: 3px;
	word-break: break-word;
}
.flight-requests-danger-box {
	background-color: #c3010114;
	border: 1px solid #ededed;
	border-radius: 3px;
	word-break: break-word;
}
.flight-requests-danger-box :is(h1, h2, h3, h4, h5, h6) {
	color: #c30101;
	font-weight: bold;
}

.flight-requests-change-passenger {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	// width: 95%;
	padding: 8px 6px;
	margin: 3px 0;
	background-color: #00b54517;
	border: 1px solid #ededed;
	border-radius: 3px;
	overflow: hidden;
	.text-danger {
		color: #dc3545 !important;
	}
}
.flight-requests-reject-box {
	margin-top: 8px;
	padding: 8px;
	background: #c3010114;
	.text-danger {
		color: #dc3545 !important;
	}
}

.flight-requests-refund-passenger {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	// width: 95%;
	padding: 8px 6px;
	margin: 3px 0;
	background-color: #f4f4f4;
	border: 1px solid #ededed;
	border-radius: 3px;
	overflow: hidden;
}
.flight-requests-contact {
	padding: 0 18px;
	margin: 3px 0;
	background-color: #f4f4f4;
	border: 1px solid #ededed;
	border-radius: 3px;
}

.flight-requests-refund-amount {
	background-color: #daa24927;
	border: 1px solid #ededed;
	border-radius: 3px;
}
.flight-requests-refund-amount h3 {
	color: #027b30;
}
.flight-requests-refund-actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.flight-requests-refund-actions .accept-btn {
	padding: 8px 2.6rem;
	background-color: #00b680;
	border-radius: 3px;
	box-shadow: 0px 3px 6px #00b68053;
	color: #fff;
}
.flight-requests-refund-actions .reject-btn {
	padding: 8px 2.6rem;
	background-color: #ee2548;
	border-radius: 3px;
	box-shadow: 0px 3px 6px #ee254853;
	color: #fff;
}

.download-cert {
	margin: 0;
	border: 0;
	background-color: transparent;
	color: #d7a04b;
	font-weight: bold;
	text-transform: capitalize !important;
	cursor: pointer;
}

.text-warning-status {
	color: #b17a00;
}
.text-danger-status {
	color: #ee2548;
}
.text-success-status {
	color: #00b680;
}

@media screen and (max-width: 767px) {
	.flight-actions-passenger {
		width: 100%;
	}
	.flight-requests-header {
		justify-content: flex-start;
	}
	.flight-requests-type {
		width: 100%;
	}
	.btn-toggel {
		margin-left: auto;
	}
}

.flight {
	border-radius: 5px;
	padding: 0px 5px;
	color: #fff;
}
.confirmed {
	background-color: #0c3b5c;
}
.pending {
	background-color: #d29d4d;
}
.paid {
	background-color: #027b30;
}
.segment-header {
	background-color: #354f5c;
	padding: 3px;
	position: relative;
	top: 13px;
	color: white;
	border-radius: 3px;
}

.old-value {
	position: relative;
	span {
		display: none;
	}
	i {
		color: #d60429;
		margin-inline-start: 4px;
	}
}
.old-value:hover {
	span {
		display: block;
		background-color: #d60429;
		padding: 2px 5px;
		color: white;
		position: absolute;
		inset-block-start: -24px;
		font-size: 12px;
		inset-inline-start: -36px;
		border-radius: 3px;
		white-space: nowrap;
		z-index: 1;
	}
}

