@import "variables.scss";

.main-web-builder {
	background-color: white;
}

.text-secondary-color {
	color: var(--text-secondary-color) !important;
}

.web-builder-custom-modal {
	[dir="rtl"] & {
		text-align: start;
	}

	.web-builder-custom-modal-title {
		.modal-title {
			flex: 1;
			text-align: center;
		}
	}
}

.modal {
	.modal-dialog {
		.modal-header {
			align-items: center !important;
		}
	}
}

.tools-main-upload {
	border: 3px dashed #dddcdc;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.tools-header-primary {
		color: #707170;
		text-align: center;
		font-feature-settings: "clig" off, "liga" off;
		font-family: Cairo;
		font-size: 2rem;
		font-style: normal;
		font-weight: 600;
		line-height: 4.2rem;
	}

	.tools-header-secondary {
		color: #dddcdc;
		text-align: center;
		font-feature-settings: "clig" off, "liga" off;
		font-family: Cairo;
		font-size: 1.1rem;
		font-style: normal;
		font-weight: 500;
		line-height: 25px;
	}

	.tools-header-button {
		height: 3.2rem;
		padding: 0px 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0.5rem;
		background: #292d32;
		color: #fff;
	}

	.tools-input {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		input {
			color: #dddcdc;
			font-family: Cairo;
			font-size: 1rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			width: 18.5rem;
			height: 3rem;
			padding: 0px 16px;
			align-items: center;
			gap: 0.5rem;
			border-radius: 6px;
			border: 1px solid #dddcdc;
		}
	}

	.m-modal-2 {
		margin: 8px 0;
	}

	.m-modal-1 {
		margin: 3px 0;
	}
}

.web-builder-library {
	padding: 1rem;

	.web-builder-library-images {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
	}

	.form-check {
		position: relative;
		padding: 0;
		flex-basis: calc(33.33% - 11px);
		border-radius: 2px;
		overflow: hidden;

		input {
			position: absolute;
			top: 10px;
			left: 10px;
			width: 18px;
			height: 18px;
			padding: 10px;
			filter: invert(1%) hue-rotate(192deg) brightness(1.2);
		}
	}

	.form-check-label {
		width: 100%;
		margin: 0 !important;
	}

	.gallery-img {
		width: 100%;
		height: 130px;
		object-fit: cover;
		border: 2px solid transparent;
	}

	.b-multiSelect-image {
		border: 2px solid #d4a655;
	}
}

.nav-flex-1 {
	flex: 1;
}

.fixed-height {
	height: 72px !important;
	align-items: flex-end !important;
}

.f-a-end {
	height: 56px;
}

.toolModal-tabs {
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;

	.text {
		font-feature-settings: "clig" off, "liga" off;
		color: #707170 !important;
		font-family: Cairo;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
	}
}

.w-upload-tabs {
	padding: 16px;
	gap: 8px;
	align-self: stretch;
}

.w-library-tabs {
	padding: 24px 16px;
	gap: 8px;
	align-self: stretch;
}

.search-form-select {

	div[class$="-menu"],
	div[class$="-singleValue"] {
		text-transform: capitalize;
	}
}

@media (min-width: 320px) and (max-width: 929px) {
	.w-30-tabs {
		width: 40% !important;
	}

	.tools-input {
		flex-direction: column;

		input {
			width: 100% !important;
			margin-bottom: 0.5rem !important;
		}
	}

	.f-a-end {
		width: 40% !important;
	}
}

.select-template {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
	padding-top: 36px;
	margin-top: -3rem !important;

	.grid-template {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
	}

	.template-name {
		margin-top: 1rem;
		padding: 8px 0;
		font-size: 18px;
		text-align: center;
		border-top: 1.5px solid #DDDCDC;
		color: #707170;
	}

	.template-container {
		margin-bottom: 1rem;
		border: 1px solid #dddcdc;
		border-radius: 4px;
		padding: 10px;
	}

	.selectTemplate-heading {
		color: #000;
		font-family: Cairo;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px;
	}

	.selectTemplate-text {
		color: #000;
		font-family: Cairo;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	}

	.selectTemplate-overlay {
		position: relative;
		border-radius: 4px;

		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 462px;
		display: flex;
		flex-direction: column;
		height: 610px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		padding: 1rem;

		// cursor: pointer;
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
			visibility: hidden;
			opacity: 0;
			z-index: 1;
			transition: visibility .35s, opacity .35s;
		}

		.overlay-button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding: 6px 20px;
			align-items: center;
			background-color: #d4a655;
			color: #fff;
			margin-bottom: 8px;
			visibility: hidden;
			opacity: 0;
			z-index: 2;
			transition: visibility .35s, opacity .35s;
			gap: 8px;

			svg path {
				stroke: #fff !important;
			}
		}

		&:hover::after,
		&:hover .overlay-button {
			visibility: visible;
			opacity: 1;
			transition: visibility .35s, opacity .35s;
		}

		// .selectTemplate-img {
		// 	cursor: pointer;
		// 	height: 691px;
		// }
	}
}

.addTransition {
	transition: 1s all ease-in-out;
}

@media (min-width: 320px) and (max-width: 768px) {
	.select-template {
		margin-top: 0rem !important;

		.container {
			.row {
				width: auto !important;
			}
		}
	}

	.select-template .selectTemplate-heading {
		line-height: 30px;
		text-align: center;
	}

	.m-t-2 {
		margin-top: 1.5rem !important;
	}
}

@media (min-width: 320px) and (max-width: 600px) {
	.web-builder-content .web-builder-hero .react-multiple-carousel__arrow {
		display: none;
	}

}

.search-form-web-builder {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	border-radius: 10px;
	background: rgba(255, 255, 255, 0.5);
	padding: 4px 7px;
	gap: 8px;

	.search-form-select {
		min-width: 175px;
		width: 100%;

		.control-field__body {
			border-radius: 8px;
		}
	}

	.search-form-button {
		min-width: 110px;
		color: #fff;
		text-align: center;
		font-size: 1rem;
		font-weight: 500;
		padding: 9px 16px;
		background-color: rgba(var(--web-page-primary-color));
		border-radius: 8px;
	}

	&.search-form-vertical {
		flex-wrap: wrap;

		.search-form-select {
			width: calc(50% - 8px);
		}

		.search-form-button {
			min-width: 100%;
		}
	}
}

.web-builder-languages-modal {
	.languages-list {
		display: flex;
		flex-direction: column;
		height: 200px;
		padding-inline-end: 12px;
		gap: 16px;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 4px;
			border-radius: 5px;
		}

		&::-webkit-scrollbar-track {
			background: #DDDCDC;
			border-radius: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background: #D4A655;
			border-radius: 5px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #D4A655;
		}

		.btn,
		.defualt-lang {
			font-size: 16px !important;
			font-weight: 500;
			color: #D4A655;
		}

		.default-lang {
			color: #707170;
			background: transparent !important;
			opacity: 1 !important;
		}

		.checkbox {
			display: flex;
			align-items: center;
			filter: invert(1%) hue-rotate(190deg) brightness(1.2);
			user-select: none;

			input {
				width: 16px;
				height: 16px;
				border-radius: 4px;
			}

			label {
				font-weight: 400;
				font-size: 16px !important;
				color: #050001;
			}
		}
	}


}

.modal-publish {
	.main-label {
		h3 {
			text-align: center;
			padding: 16px 0 26px;
			font-size: 16px;
			font-weight: 600;
		}

		.contact-us-fields {
			label {
				font-size: 14px;
				font-weight: 600;
				color: #707170;
				margin-bottom: 0;
			}
		}

		.company_phone_code .control-field {
			border-radius: 16px 0px 0px 16px;
			border-inline-end: none;

			.control-field__prepend {
				display: none;
			}
		}

		.overflow-hidden.number-h {
			border-radius: 0 16px 16px 0;
			border-inline-start-width: none;
			border-inline-start: none;
			position: relative;

			&::before {
				content: "";
				width: 1px;
				height: 30px;
				background: #DDDCDC;
				position: absolute;
				top: 6px;
				inset-inline-start: 0;
			}

		}

		.btn-tab {
			gap: 17px;

			.step {
				width: 110px;
				padding: 2px 18px;
				background-color: #DDDCDC;

				&.active {
					background-color: #D4A655;
				}
			}
		}
	}

	.modal-foot {
		display: flex !important;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
		margin-top: 32px;

		.primary-button-outline {
			background-color: transparent !important;
			border: 1px solid #D4A655;
			color: #D4A655;
		}

	}

	hr {
		display: none;
	}
}

.custom-editor.quill {

	strong {
		font-weight: bolder;

		em {
			font-weight: bolder;
			font-style: italic;
		}
	}

	em {
		font-style: italic;
	}

	background: white !important;
	border: 1px solid #ebebeb;
	border-radius: 8px !important;

	.ql-toolbar.ql-snow {
		border: 1px solid #ebebeb !important;
		border-radius: 8px 8px 0px 0px;
	}

	.ql-container.ql-snow {
		border: none !important;
	}
}

.custom-editor.quill.error {
	border: 1px solid #ff5f59 !important
}

.custom-editor.quill.focus {
	border: 1px solid #d4a655 !important
}