.trip-planner-view-wrapper {
  .itinerary-header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;

    .service-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      font-weight: 500;
      line-height: 26.94px;
      border: 1px solid #DBDADE;
      border-radius: 4px;
      color: #707170;
      background-color: #fff;
      cursor: pointer;
      gap: 10px;
    }
  }

  .itinerary-days {
    width: 300px;
    border: 1px solid #F7F6F6;

    .day-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      border-width: 1px 1px 1px 0px;
      border-style: solid;
      border-color: #F7F6F6;
      font-weight: 500;
      font-size: 18px;
      color: #707170;
      cursor: pointer !important;
      &.active {
        color: #050001;
        background-color: #D4A65514;
        border-inline-end: 4px solid #D4A655;
        .items-count {
          background-color: #D4A655;
          color: #fff;
        }
      }
      .items-count {
        padding: 2px 10px;
        border-radius: 30px;
        font-size: 14px;
        line-height: 16.8px;
        color: #707170;
      }
    }

    .day-activities {
      padding: 10px;
      border: 1px solid #DDDCDC3D;
      border-radius: 4px;
      background-color: #FCF8F14D;

      .activity {
        display: flex;
        align-items: center;
        padding: 6px 16px;
        background-color: #fff;
        border: 1px solid #DDDCDC3D;
        font-size: 14px;
        color: #707170;
        gap: 10px;

        .icon-wrapper {
          width: 32px;
          height: 32px;
        }
      }
    }
    .sort-btn-group{
      display: flex;
      flex-direction: column;
      .btn:disabled {
        background: transparent !important;
        i {
          color: #70717033;
        }
      }
      .btn i {
        color: #707170;
      }
    }
  }

  .itinerary-item {
    // max-width: calc(100% - 300px);
    padding: 0;
   
    .content-tabs {
      flex-grow: 1;
    }
    .content {
 
      display: none;
 
      
    }
    .active-content {
      display: block;
    }
    
    .form-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-radius: 8px 8px 0px 0px;
      background-color: #F4F4F4;
      gap: 10px;
    }
    .tab-items  {
      margin-bottom: 16px;
      h2 {
        font-size: 18px;
        font-weight:600;
        margin-inline-end: 20px;
      }
       .tabs {
          border-radius: 8px 8px 0px 0px;
          background-color: #F4F4F4;
          gap: 10px;
          border: 1px solid #F4F4F4;
          border-bottom: 0px;
          margin: 0;
          padding: 0;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #DDDCDC;
        padding: 8px;
        li { 
          .tabs-btn {
             color: #050001;
            padding: 8px   18px;
            display: inline-block;
            font-size: 18px;
            border: 1px solid transparent    ;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
          .active-tabs {
            background-color: #FAF4EB;
            border: 1px solid #EED9BA    ;
            color: #050001;
            padding: 8px   18px;
            display: inline-block;
            font-size: 18px;
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
    }
    }
    }
    .time-picker {
      .isDisabled, input[type=text]:disabled {
        background: transparent !important;
      }
    }
  }

  .itinerary-item-view-wrapper {
    border: 1px solid #F4F4F4;
    border-radius: 8px;
    background-color: #fff;

    .secondry-text {
      font-weight: 400;
      color: #707170;
      font-size: 16px;
      word-break: break-word;
    }
    
    .item-view {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
      border-radius: 8px;
      background-color: #F5F5F5;
      color: #050001;

      .item-info {
        display: flex;
        align-items: center;
        width: 90%;
        font-size: 18px;
        font-weight: 600;
        border-inline-end:  1px solid #C8D2DC;
        gap: 24px;
        cursor: pointer;
      }
      .collapse-arrow {
        color: #707170;
        margin-inline-start: auto;
        margin-inline-end: 24px;
      }
    }

    .item-data {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      gap: 16px;

      .duration {
        width: fit-content;
        padding: 0 10px;
        font-weight: 400;
        line-height: 26.94px;
        border-radius: 20px;
        color: #6A4D1B;
        background-color: #FAF4EB;
      }

      &.hotel-data {
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        color: #050001;
      }
      &.attraction-data {
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        color: #050001;
        .attraction-photos {
          display: flex;
          width: 100%;
          padding: 10px 16px;
          border: 1px dashed #DBDADE;
          border-radius: 6px;
          gap: 6px;

          img {
            width: 111px;
            height: 111px;
            object-fit: cover;
            border-radius: 6px;
            overflow: hidden;
          }
        }
      }
    }

    .item-action-btns {
      .edit-btn svg path {
        stroke: #050001;
      }
      .delete-btn svg path {
        stroke: #EA5455;
      }
    }
  }
}


.itinerary-add-activity-modal {
	.modal-header {
		padding: 16px !important;
		h5 {
			width: 100%;
			color: #050001;
			line-height: 33.73px;
			text-align: center;
		}
	}
	.activites-items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin-bottom: 1rem;
		gap: 10px;
	}

	.activity-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 16px;
		font-weight: 500;
		border: 1px solid #dbdade;
		border-radius: 4px;
		color: #707170;
		gap: 10px;
		cursor: pointer;
	}
}