.product-element {
	margin: calculateRem(15px) 0;
	background-color: $white;
	border-radius: calculateRem(5px);
	&.addBorder {
		border: 1px solid #dfdfdf !important;
	}

	&__head {
		background-color: #ebebeb;
		border-radius: calculateRem(10px);
		padding: 8px 0px;
		&.addBorder {
			border-radius: 8px 8px 1px 1px;
		}
	}

	&__body {
		padding: calculateRem(20px);
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $blue;
		color: $white;
		border-radius: 100px;
		width: calculateRem(60px);
		height: calculateRem(60px);
		margin: calculateRem(10px);
		& img {
			width: 25px;
		}

		& i {
			font-size: calculateRem(30px);
		}
	}
	.internal-transportation-body {
		.internal-body {
			padding: 16px;
			.control-field__label {
				color: #707170;
				font-size: 14px;
				font-weight: 400;
			}
			.control-field {
				width: 178px !important;
				height: 40px;
				padding: 0px 16px;
				border-radius: 8px;
				border: 1px solid #dddcdc;
			}
			.control-field__select {
				div[class$="singleValue"] {
					color: #050001;
					font-size: 16px;
					font-weight: 600;
				}
				svg {
					path {
						fill: #050001 !important;
					}
				}
			}
		}
		.delete-segment-button{
			color: #F53D3D;
			background-color: transparent;
			border: none;
			span{
				vertical-align: middle;

			}
		}
		.internal-transportation-content {
			padding: 20px 29px;
			.row {
				margin-inline: 0 !important;
				margin-bottom: 10px;
				.full-cycle{
					.control-field {
						background: #F7F6F6;

					}
				}
				.select-input {
					.control-field__label {
						color: #707170;
						font-size: 14px;
						font-weight: 400;
					}
					.control-field {
						height: 40px;
						padding: 7px 16px !important;
						border-radius: 8px;
						border: 1px solid #dddcdc;
					}

					.control-field__select {
						div[class$="singleValue"] {
							color: #050001;
							font-size: 16px;
							font-weight: 600;
						}
						svg {
							path {
								fill: #050001 !important;
							}
						}
					}
				}
				.control-field__label {
					color: #707170;
					font-size: 14px;
					font-weight: 400;
				}
				.control-field {
					// width: 178px !important;
					height: 40px;
					// padding: 0px 16px;
					border-radius: 8px;
					border: 1px solid #dddcdc;
				}
				.control-field__input {
					color: #050001;
					font-size: 16px;
					font-weight: 600;
				}

				.newFixPadding {
					// padding: 7px 0 !important;
				}
				.control-field__body {
					.react-datepicker-wrapper {
						input {
							color: #050001;
							font-size: 16px;
							font-weight: 600;
						}
					}
					.date-icon {
						top: 0 !important;
					}
				}
			}
		}
		.custom-cycle {
			hr {
				background: #dddcdc;
				margin: 25px 7px;
			}
			.add {
				display: flex;
				align-items: center;
				gap: 10px;
				cursor: pointer;
				i {
					display: inline-block;
					color: #6a4d1b;
					width: 40px;
					height: 40px;
					border-radius: 6px;
					background: #eed9ba;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
				}
				span {
					color: #050001;
					font-size: 16px;
					font-weight: 600;
				}
			}
			
		}
		.save {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			// padding: 20px 16px 10px 16px;
			margin-top: 15px;

			button {
				width: 112px;
				height: 40px;
				// padding: 0px 32px;
				border-radius: 8px;
				background: #d4a655;
				color: var(--fefeff, #fefeff);
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
}
