.web-pages-requests-wrapper {
  background-color: white;
  padding: 26px;
  border-radius: 8px;

  button {
    outline: none;
    border: none;
    background-color: transparent;
  }


  .wp-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;

    h2 {
      font-size: 24px;
      font-weight: bold;
    }

    button {
      background-color: white;
      padding: 13px 20px;
      border: 1px solid #D4A655;
      border-radius: 4px;
      outline: none;
      font-weight: bold;
      color: #D4A655;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .wp-filter {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 16px;
    margin-bottom: 20px;

    &>div:nth-child(2) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    .wp-actions {
      display: flex;
      gap: 8px;
      margin-top: 4px;
    }
  }

  .wp-apply-filter-btn {
    padding: 9px 24px;
    color: white;
    background-color: #D4A655;
    border: none;
    outline: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
  }

  .wp-clear-filter-btn {
    padding: 12px 24px;
    color: #6A4D1B;
    background-color: #FAF4EB;
    border-radius: 6px;
    border: 1px solid #F6EBDA;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 45px;
  }

  .wp-service-filter-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    li {
      button {
        outline: none;
        background-color: white;
        border: 1px solid #DDDCDC;
        color: #707170;
        padding: 10px 16px;
        border-radius: 40px;
        font-size: 18px;
        font-weight: 600;

        &.active {
          background-color: #FAF4EB;
          border: 1px solid #D4A655;
          color: #D4A655;
        }
      }
    }
  }

  .wp-table-row-checkbox {
    position: relative;

    label {
      background-color: #fff;
      border: 1px solid #DDDCDC;
      border-radius: 4px;
      cursor: pointer;
      height: 18px;
      width: 18px;
      position: absolute;
      left: 0;
      top: 0;

      &::after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 4px;
        left: 4px;
        opacity: 0;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 9px;
      }
    }

    input[type="checkbox"] {
      visibility: hidden;
    }

    input[type="checkbox"]:checked+label {
      background-color: #D4A655;
      border-color: #D4A655;
    }

    input[type="checkbox"]:checked+label:after {
      opacity: 1;
    }
  }

  .wp-table-wrapper {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 16px;

    table {
      width: 100%;
      min-width: max-content;
      border-collapse: separate;

      th:first-child {
        border-left: 1px solid #DBDADE;
        border-top-left-radius: 8px;
      }

      th:last-child {
        border-right: 1px solid #DBDADE;
        border-top-right-radius: 8px;
      }

      th {
        background-color: #F7F6F6;
        padding: 16px 20px;
        font-size: 14px;
        font-weight: 700;
        color: #6A4D1B;
        text-align: left;
        text-transform: uppercase;
        border-bottom: 1px solid #DBDADE;
        border-top: 1px solid #DBDADE;
      }

      td {
        padding: 16px 20px;
        border-bottom: 1px solid #DBDADE;
      }

      td:first-child {
        border-left: 1px solid #DBDADE;
      }

      td:last-child {
        border-right: 1px solid #DBDADE;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
      }

      tr:last-child td:last-child {
        border-bottom-right-radius: 8px;
      }

      .wp-table-request-id {
        color: #2190F5;
      }

      .wp-table-service-name,
      .wp-table-request-date {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span:nth-child(2) {
          color: #707170;
        }
      }
    }
  }

  .wp-view-request-btn {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    padding: 16px;

    .wp-filter {
      grid-template-columns: 1fr;

      &>div:nth-child(2) {
        grid-template-columns: 1fr;
      }
    }

    .wp-actions {
      &>div {
        width: 100%;
      }
    }

    .wp-apply-filter-btn,
    .wp-clear-filter-btn {
      width: 100%;
    }
  }
}

// view request
.web-pages-requests-wrapper-view {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;

  .wp-view-card {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08));

    &.right {
      height: fit-content;
      position: sticky;
      top: 90px;

      .wp-tabs-wrapper {
        padding: 8px 8px 0px 8px;
        border-bottom: 1px solid #DDDCDC52;
        margin-bottom: 20px;
      }

      .wp-tabs {
        display: flex;
        padding-inline: 30px;

        li {
          flex: 1;

          .wp-tab {
            font-weight: 600;
            color: #707170;
            padding: 12px 20px;
            border: none;
            outline: none;
            background-color: white;
            border-bottom: 1px solid transparent;
            width: 100%;

            &.active {
              border-color: #D4A655;
              color: #D4A655;
            }
          }
        }
      }
    }
  }

  .wp-view-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;

    h2 {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .wp-view-info-container {
    padding: 16px;
    border: 1px solid #EEEEEE;
    border-radius: 8px;

    .wp-view-info-container-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .wp-view-info-container-content.grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }
  }

  .wp-view-info-box {
    background-color: #FAFAFA;
    border-radius: 8px;
    padding: 12px;


    .wp-view-info-box-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
      color: #A1A1A1;
    }

    .wp-view-info-box-content {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  .wp-call-btn-group {
    display: flex;
    gap: 16px;
    margin-top: 20px;
  }

  .wp-view-btn {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    outline: none;
    border-radius: 6px;
    flex: 1;
  }

  .wp-view-inbound-btn {
    color: white;
    border: 2px solid transparent;
    background-color: #019D4C;
  }

  .wp-view-outbound-btn {
    color: #EA5455;
    border: 2px solid #EA5455;
    background-color: #EA545529;
  }

  .wp-note {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 20px;
    
    label {
      color: #707170;
    }

    textarea {
      padding: 12px;
      border: 1px solid #DDDCDC;
      border-radius: 8px;
      outline: none;
    }
  }

  .wp-save-btn {
    padding: 12px 24px;
    color: white;
    background-color: #D4A655;
    border: none;
    outline: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    font-weight: 600;
  }

  .wp-call-logs {
    margin-top: 20px;

    .wp-call-logs-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .wp-call-logs-list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .wp-call-log-item {
        display: flex;
        gap: 8px;
        
        &:not(:last-child) {
          border-bottom: 1px solid #EEEEEE;
          padding-bottom: 20px;
        }

        .wp-call-log-item-content {
          width: 100%;
        }

        .wp-call-log-item-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
        }
        
        .wp-call-log-item-header-name,
        .wp-call-log-item-header-date {
          color: #707170;
          font-size: 18px;
        }
      } 
    }
  }

  .wp-history-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .wp-history-list-item {
      display: flex;
      gap: 8px;

      .wp-history-item-content {
        width: 100%;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 20px;
      }

      .wp-history-item-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
      }

      .wp-history-item-title,
      .wp-history-item-date {
        color: #707170;
        font-size: 18px;
      }
    }
  }

  .wp-priority.low {
    color: #1E85FF;
  }

  .wp-priority.medium {
    color: #FF9F43;
  }

  .wp-priority.high {
    color: #EA5455;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;

    .wp-view-card.right {
      position: static;
    }
  }
}

.wp-reqquest-status {
  border-radius: 8px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  width: fit-content;
}

.wp-reqquest-status-accepted {
  color: #019D4C;
  background-color: #F1FFF8;
  border: 1px solid #019D4C;
}

.wp-reqquest-status-pending {
  color: #FF9F43;
  background-color: #FFF7EF;
  border: 1px solid #FF9F43;
}

.wp-reqquest-status-new {
  color: #1E85FF;
  background-color: #EBF4FF;
  border: 1px solid #1E85FF;
}

.wp-reqquest-status-cancelled {
  color: #EA5455;
  background-color: #FFF4F4;
  border: 1px solid #EA5455;
}