.about-container {
	margin-inline: 6em !important;
	background-color: #fff;
}

.about-details {
	p {
		margin-bottom: 1em;
	}
}


.process_main_container{
	background-image: url("https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2019/09/PCC_bg_img.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	padding-top: 3em;
	position: relative;
}

.process_item.span12 {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	min-height: 170px;
	padding-top: 20px;
	padding-bottom: 5px;
}

.process_item__content_wrapper {
	border-bottom: 2px solid;
	border-radius: 11px;
	padding: 25px 30px;
	background-color: #fff;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
}

.process_item__content_wrapper {
	// border-bottom: 0;
	border-color: currentColor;
}

.process_bar_container.row.process_bar_container--type-vertical {
	padding-inline: 6em !important;
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: column;
	align-content: space-around;
	overflow: hidden;
	padding-block: 5em;
	position: relative;
	z-index: 1;

	.process_item__description{
    color: #696687 !important;
	}
}

.process_item__circle_wrapp {
	height: 120px;
	width: 120px;
	flex: 0 1 auto;
	position: relative;
}

.process_item__circle_wrapp::before {
	content: "";
	display: inline-block;
	border: 10px solid;
	border-radius: 20px;
	position: absolute;
	z-index: 1;
	top: 50%;
	inset-inline-start: 50%;
	margin-top: -10px;
	margin-inline-start: -10px;
	box-shadow: 0 7px 20px 0 rgba(28, 30, 35, 0.4);
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	opacity: 1;
	border: 6px solid;
	margin-top: -6px;
	margin-inline-start: -6px;
}

.process_item__number {
	flex: 1 1 0;
	font-size: 24px;
}

.process_item__content {
	flex: 1 1 0;
}

.process_item__circle_line_before {
	width: 1px;
	background-color: rgba(104, 91, 232, 0.15);
	height: 160px;
	position: absolute;
	inset-inline-start: 60px;
	bottom: 30px;
	top: -130px;
}

.process_item__circle_line_after {
	width: 1px;
	background-color: rgba(104, 91, 232, 0.15);
	height: 200px;
	position: absolute;
	inset-inline-start: 60px;
	top: 30px;
}

.process_item__circle {
	width: 70%;
	height: 70%;
	margin-top: -35%;
	margin-inline-start: -35%;
	position: absolute;
	top: 50%;
	inset-inline-start: 50%;
}
.process_item__circle::before {
	content: "";
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	border-radius: 200px;
	border: 1px solid;
	opacity: 0.5;
	transition: opacity 0.5s;

	opacity: 0;
	box-shadow: 0 0 20px 11px currentColor;
	border: none;
	width: 1px;
	height: 1px;
	border-radius: 50%;
	top: 50%;
	inset-inline-start: 50%;
	animation-name: show_shadow;
	animation-duration: 8s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.process_item__circle::after {
	content: "";
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	border-radius: 200px;
}

.process_bar_container.row.process_bar_container--type-vertical
	> :nth-child(even) {
	flex-direction: row-reverse;
	.process_item__content_wrapper {
    /* border-bottom: 0; */
    border-inline-end: 2px solid;
		text-align: end;
}
	.process_item__number {
		text-align: start;
	}
}
.process_bar_container.row.process_bar_container--type-vertical
	> :nth-child(odd) {
		.process_item__content_wrapper {
			/* border-bottom: 0; */
			border-inline-start: 2px solid;
			text-align: start;
	}
	.process_item__number {
		text-align: end;
	}
}

.process_bar_container.row.process_bar_container--type-vertical
	> :first-child {
		color: #42cd83;
	
		.process_item__circle_line_before{
			background-image: linear-gradient(0deg, rgba(104,91,232,0.15) 0%, transparent 100%);
			background-color: rgba(0,0,0,0)!important;

		}
		.process_item__circle {
		&::before {
			animation-delay: 0s;
		}
	}
}

.process_bar_container.row.process_bar_container--type-vertical
	> :nth-child(2) {
		color: #f29111;
	
		.process_item__circle {
		&::before {
			animation-delay: 1.5s;
		}
	}
}

.process_bar_container.row.process_bar_container--type-vertical
	> :nth-child(3) {
		color: #7164ea;
	
		.process_item__circle {
		&::before {
			animation-delay: 3s;
		}
	}
}
.process_bar_container.row.process_bar_container--type-vertical
	> :nth-child(4) {
		color: #f90bad;
	
		.process_item__circle {
		&::before {
			animation-delay: 4.5s;
		}
	}
}
.process_bar_container.row.process_bar_container--type-vertical
	> :nth-child(5) {
		color: #00ccff;
	
		.process_item__circle {
		&::before {
			animation-delay: 6s;
		}
	}
}
.process_bar_container.row.process_bar_container--type-vertical
	> :nth-child(6) {
		color: #42cd83;
	
		.process_item__circle {
		&::before {
			animation-delay: 7.5s;
		}
	}
}
.process_bar_container.row.process_bar_container--type-vertical
	> :nth-child(7) {
		color: #f29111;
	
		.process_item__circle {
		&::before {
			animation-delay: 9s;
		}
	}
}
.process_bar_container.row.process_bar_container--type-vertical
	> :last-child {
		color: #7164ea;
	
		.process_item__circle_line_after{
			background-image: linear-gradient(180deg, rgba(104,91,232,0.15) 0%, transparent 100%);
			background-color: rgba(0,0,0,0)!important;

		}
		.process_item__circle {
		&::before {
			animation-delay: 10.5s;
		}
	}
}

.cloud_animated_bottom:before {
	content: "";
	background-image: url(../../../assets/images/about-us/bg-clouds_bottom.5d8f60b8.png);
	width: 100%;
	height: 191px;
	position: absolute;
	bottom: 0;
	inset-inline-start: 0;
	background-repeat: repeat;
	background-position: 0 0;
	background-size: auto 100%;
	-webkit-animation: animatedBackground 300s linear infinite;
	animation: animatedBackground 300s linear infinite;
}

@keyframes animatedBackground {
	0% {
			background-position: 0 0
	}

	to {
			background-position: -10000px 0
	}
}


@keyframes show_shadow {
	0% {
		opacity: 0;
	}

	5% {
		opacity: 1;
	}

	15% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}







@media (min-width: 320px) and (max-width: 620px) {

	.about-container{

		margin-inline: 2em !important;
	}
	 .process_bar_container.row.process_bar_container--type-vertical {
		padding-inline: 2em !important;
	}
}