.trip-planner-pricing {
	display: flex;
	flex-direction: column;
	gap: 10px;
	// padding: 0px 26px 16px 24px;

	.planner-pricing-header {
		font-size: 18px;
		font-weight: 700;
		color: #000000;
		line-height: 33.73px;
	}

	.pricing-items {
		display: flex;
		align-items: center;
		gap: 16px;
		width: 70%;

		.pricing-item {
			flex: 1;
			.control-field {
				background: #dbdade;
				border: 1px solid #dddcdc;
				.react-datepicker__input-container input {
					background: #dbdade;
					input {
						::placeholder {
							color: #050001 !important;
						}
					}
				}
			}

			.planner-pricing-changeDate {
				background: #d4a655;
				border-radius: 4px;
				padding: 7px 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				font-weight: 700;
				line-height: 30px;
				color: #fff;
			}
		}

		.pricing-item:last-child {
			margin-top: 20px;
		}
	}

	.trip-planner-main {
		border: 1px solid #f8f7f7;
		display: flex;
		// align-items: center;
		border-radius: 4px;
		gap: 20px;

	}
	.trip-dates {
		flex-basis: 33.333%;
		border: 1px solid #f7f6f6;
		min-width: 320px;

		.tripDates-header {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			padding: 10px 0;
			background: #f4f4f4;
			font-size: 20px;
			font-weight: 700;
			color: #050001;
		}

		.tripDates-main {
			display: flex;
			justify-content: center;
			align-items: center;
			border-width: 1px 4px 1px 0px;
			border-width: 1px 1px 1px 0px;
			border-style: solid;
			border-color: #f7f6f6;

			.tripDates-items {
				display: flex;
				align-items: center;
				gap: 10px;
				padding: 10px;
				flex-basis: 50%;

				.dates {
					font-size: 16px;
					font-weight: 500;
					line-height: 26.94px;
					color: #707170;
				}
			}

			.tripDates-items:first-child {
				position: relative;
				justify-content: center;

				&::after {
					content: '';
					position: absolute;
					width: 2px;
					height: 70%;
					top: 50%;
					transform: translateY(-50%);
					left: 100%;
					background: #dddcdc;
				}
			}

			// .tripDates-items:last-child {
			// 	justify-content: space-around;

			// 	.dates-actions {
			// 		display: flex;
			// 		gap: 8px;
			// 		align-items: center;
			// 	}
			// }
		}
		.hidden {
			visibility: hidden;
			opacity: 0;
		}
		.active {
			background: rgba(212, 166, 85, 0.08);
			border-right: 4px solid #d4a655;

			.dates {
				color: #050001 !important;
				font-weight: 700 !important;
			}
		}
	}

	
	.pricing-header {
		display: flex;
		align-items: center;
		// justify-content: space-between;
		flex-wrap: wrap;
		min-width: 100%;
		margin: 16px 0;
		padding: 16px 20px;
		background-color: #F7F6F6;
		gap: 26px;

		.select-type {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px;
			border-radius: 4px;
			border: 1px solid #DDDCDC;
			background-color: #FFFFFF;
			gap: 10px;

			.btn {
				padding: 10px !important;
				border: 1px solid transparent;
				border-radius: 4px;
				font-weight: 600;
				font-size: 18px;
				&.active {
					border: 1px solid #EED9BA;
					background-color: #FAF4EB;
				}
			}
		}
		


		.select-input {
			display: flex;
			align-items: center;			
			margin-inline-start: auto;
			width: 25%;
			gap: 10px;
		}
	}

	.pricing-type-wrapper {
		flex-basis: 67%;
		padding: 10px;
		margin-bottom: 16px;
		border: 1px solid #DDDCDC;
		border-radius: 10px;
		.header {
			padding: 12px 16px;
			text-align: center;
			border-radius: 4px;
			background-color: #DDDCDC;
		}
	}

	.room-pricing-table {
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 0;

		th, tr, td {
			padding: 0;
			padding-top: 8px;
			padding-inline-end: 8px;
			border: 0;
			vertical-align: middle;
			text-align: center;
		}
		th {
			font-weight: 600;
			text-align: center;
		}
		
		.room-th {
			display: block;
			padding: 12px 16px;
			border: 1px solid #7071701A;
			border-radius: 4px;
			background-color: #F7F6F6;
			width: 100%;
		}
	}
	.services-pricing-table {
		 	border-collapse: separate;
			border-spacing: 10px 12px;
		th, tr, td {
			padding: 0;
			border: 0;
			vertical-align: middle;
			text-align: center;
		}
		.t-head {
			border-radius: 4px;
    	padding: 0.75rem 10px;
			background-color: #DDDCDC;
			font-weight: 700;
		}
		.t-sub {
			border-radius: 4px;
    	padding: 0.75rem 10px;
			background-color: #F7F6F6;
		}
	}

	.room-type-count {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 6px 16px;
		border: 1px solid #DDDCDC;
		border-radius: 4px;
		background-color: #FEFEFF;
		color: #050001;
		gap: 18px;

		.control-field, .custom-control-field {
			border: 0 !important;
			padding: 0 !important;
			margin: 0;
			height: auto;
		}
		.room-type{
			width: calc(60% - 16px);
			border-inline-end: 1px solid #DDDCDC;
		}
		.room-count {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(40% - 16px);
			gap: 18px;
			.btn {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 4px 6px;
				border-radius: 4px;
				background-color: #D4A655;
				color: #FAF4EB;
			}
		}
	}

	.pricing-for-list {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #DDDCDC;
		gap: 10px;

		.item {
			padding: 16px 18px;
			font-weight: 600;
			font-size: 18px;
			color: #707170;
			cursor: pointer;

			&.active {
				color: #D4A655;
				border-bottom: 1px solid #D4A655;
				cursor: default;
			}
		}
	}

	.pricing-discount-vat-wrapper {
		display: flex;
		padding: 12px 16px;
		background-color: #FBFBFA;

		.checkBox-input {
			input {
				width: 18px;
				height: 18px;
				border-radius: 4px;
			}
			.form-check-label {
				color: #050001;
			}
		}

		.control-field {
			align-items: center;
			flex-wrap: nowrap;
		}
		.control-field__append  {
			padding: 0 !important;
			border: 0;
			font-weight: 600;
		}
	}

	.breakdown-child-price {
		position: relative;
		vertical-align: top;
		.control-field {
			position: absolute;
			top: 8px;
			left: 0;
			width: calc(100% - 8px);
			height: calc(100% - 16px)  !important;
			z-index: 2;
		}
		.no-gutters, input {
			height: 100%;
		}
		.custom-border-color {
			border-color: #ebebeb !important;
		}
	}

}
