.theme-2 {
  .header-nav {
    display: flex;
    align-items: center;
    gap: 42px;
  }
  &.web-builder-preview .wb-header {
    padding: 16px 5em;
    
  }
}