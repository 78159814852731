@use "./mixins";
@import "./hero.scss";
@import "./services.scss";
@import "./about.scss";
@import "./header.scss";
@import "./footer.scss";
@import "./packages.scss";
@import "./hotels.scss";
@import "./tours.scss";
@import "./flights.scss";
@import "./faq.scss";

html {
	&:dir(rtl) {
		.theme-7 {
			.mirror-rtl {
				-webkit-transform: scaleX(-1);
				transform: scaleX(-1);
			}
		}
	}
}

.theme-7 {
	.mirror-ltr {
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}
	.web-builder-hero-header header {
		align-items: center;
	}
	.web-builder-hero-header .logo {
		max-width: 200px;
		height: 100%;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.theme_inner_wrapper {
		padding: 1.2rem 1rem;
		@media screen and (max-width: 768px) {
			padding: 0.8rem;
		}
	}
  .theme_padding {
		padding: 0 4rem;
		@media screen and (max-width: 992px) {
			padding: 0 2.5rem;
		}
    @media screen and (max-width: 768px) {
			padding: 0 2rem;
		}
    @media screen and (max-width: 575px) {
			padding: 0 1.2rem;
		}
	}
	.section_padding__md {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.section_padding__lg {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	.theme_btn {
		position: relative;
		border: 0;
		background-color: rgb(var(--web-page-primary-color));
		color: #ffffff;
		outline: none !important;
		box-shadow: none !important;
		padding: 0.6rem 1.2rem;
		text-align: center;
		border-radius: 8px;
		transition: all 0.3s linear;
		.btn_content {
			position: relative;
			z-index: 1;
		}
		&:hover {
			background-color: rgb(var(--web-page-primary-color), 0.7);
			&::before {
				animation: theme7_bubble_btn 1s infinite ease-out;
			}
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 8px;
			background-color: rgb(var(--web-page-primary-color));
			opacity: 1;
			z-index: -1;
		}
	}
	#packages-section {
		.service-top-section {
			.services-content, .add-element-box {
				max-width: 100%;
				text-align: start;
			}
		}

		.add-service-btn {
			position: relative;
			inset: unset;
			transform: unset;
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			img {
				opacity: .3;
			}
		}
	}
	.contact-us-layout {
		background: #F5F5F5 !important;
	}
	.footer-container {
		padding-block: 1rem;
	}

	.iframe_container {
		position: relative;
		overflow: hidden;
		width: 100%;
		padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
	}
	.add-service-btn {
		z-index: 3;
	}

	.empty-service::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(112, 113, 112, 0.7) !important;
		z-index: 2;
	}	
	.contact-us-layout .content-top-section .t-body {
		color: rgb(var(--web-page-primary-color)) !important;
	}

	&.web-builder-page-contianer .services-content {
    display: none;
  }
}

@keyframes theme7_bubble_btn {
	0% {
		opacity: 1;
		z-index: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		opacity: 0;
		-webkit-transform: scaleX(1.4) scaleY(1.6);
		transform: scaleX(1.4) scaleY(1.6);
		z-index: 1;
	}
}
