.register-type-head {
	position: relative;
	font-size: 20px;
	font-weight: bold;
	border-left: 6px solid #d29d4d;
}
[dir="rtl"] .register-type-head {
	border-right: 6px solid #d29d4d;
	border-left: none;
	padding: 6px 0;
}

.pre-auth-layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: #FFFFFF;

	.header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		border: none;
		border-bottom: 1px solid #F7F6F6;
		padding: 20px 26px;
		gap: 1rem;

		.nav {
			display: flex;
			align-items: center;
			gap: 45px;
			a {
				font-size: 20px;
				font-weight: 600;
				line-height: 33.68px;
				color: #050001;
			}
		}
	}
	.content {
		// display: grid;
		// grid-template-columns: repeat(auto-fill, minmax(max(calc(50% - .5rem), 25vw), 1fr));
		padding: 20px 26px;
		// gap: 1rem;
		align-items: center;

		.banner {
			height: auto;
			h1 {
				font-weight: 700;
				font-size: 20px;
				color: #D4A655;
			}
			.about {
				font-size: 18px;
				font-weight: 400;
				line-height: 26px;
				color: #707170;
			}
			.icons {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				gap: 32px;
			}
		}

		.form-header {
			text-align: center;
			h2 {
				font-weight: 700;
				font-size: 32px;
				line-height: 170%;
			}
			h3 {
				font-weight: 600;
				font-size: 16px;
				line-height: 170%;
			}
		}
		.control-field__prepend {
			border: 0;
			span {
				background-color: transparent;
			}
		}
		
	}
	
	.btn {
		position: relative;
		padding: 10px 0;
		font-size: 18px;
		font-weight: 700;
		line-height: 28px;
		border-radius: 8px;
		cursor: pointer;
		overflow: hidden;
		
		.text {
			position: relative;
			z-index: 3;
		}
		.btn-overlay {
			position: absolute;
			top: -50%;
			left: 0;
			width: 100%;
			height: 200%;
			transform-origin: bottom;
			animation: btnAnimationOut .3s cubic-bezier(0.5, 0.4, 0.2, 0.72) forwards;			
		}
		&:not(:disabled):hover .btn-overlay {
			animation: btnAnimationIn .3s cubic-bezier(0.5, 0.4, 0.2, 0.72) forwards;			
		}
	}

	.main-btn {
		color: #FEFEFF;
		background-color: #D4A655;

		.btn-overlay {
			background-color: #EEB757;
		}
	}

	.secondary-btn {
		color: #D4A655;
		background-color: #FFFFFF;
		border: 1.5px solid #D4A655;

		.btn-overlay {
			background-color: #FFF8EC;
		}
	}
	.control-field__label {
		font-weight: 600;
		color: #707170;
	}
}

@keyframes  btnAnimationIn {
  from {
    transform: translate(-100%, 0) scaleX(0);
		border-radius: 100%;
  }
  to {
		transform: translate(0, 0) scaleX(1);
		border-radius: 0;
  }
}
@keyframes  btnAnimationOut {
	from {
		transform: translate(0, 0) scaleX(1);
		border-radius: 0;
	}
  to {
    transform: translate(-100%, 0) scaleX(0);
		border-radius: 100%;
  }
}