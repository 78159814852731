.footer-container {
	padding: 23px 24px;
	.web-builder-footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2rem;

		.footer-logo {
			width: 168px;
			height: 59px;
			.footer-photo {
				width: 170px;
				height: 70px;
				object-fit: contain;
			}
		}

		.footer-links {
			width: 100%;
			display: flex;
			justify-content: center;

			.nav-list {
				width: 40%;
				.links-list {
					display: flex;
					justify-content: space-between;
					width: 100%;

					li {
						color: #fff !important;
						text-align: center;
						font-size: 14px;
						font-style: normal;
						opacity: 0.5;
						font-weight: 400;
						line-height: 14px;
						letter-spacing: 0.28px;
						cursor: pointer;
					}
				}
			}
		}

		.footer-socialIcons {
			display: flex;
			flex-direction: column;
			align-items: center;

			.footer-text {
				color: #fff;
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 14px;
				letter-spacing: 0.28px;
			}
		}
	}
}

.footer-temp-two {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	gap: 32px;
	align-items: center;

	.footerTempTwo-logo {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 32px;

		.footer-photo {
			width: 168px;
			height: 59px;
			object-fit: contain;
		}

		.footer-socialIcons {
			.footer-text {
				color: #fff;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 14px;
				letter-spacing: 0.28px;
			}
		}
	}

	.footerTempTwo-links {
		grid-column: span 3;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 20px;
		height: 100%;

		.footer-col {
			.footerLinks-header {
				color: #fff;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 14px;
				letter-spacing: 0.28px;
				text-decoration: underline;
				margin: 10px 0;
			}

			ul {
				.text-secondary-color {
					margin: 10px 0;
					cursor: pointer;
				}
			}
		}
	}
}

@media (min-width: 320px) and (max-width: 600px) {
	.footer-container .web-builder-footer .footer-links .nav-list {
		width: 80%;
	}

	.footer-temp-two {
		grid-template-columns: 1fr;
		text-align: center;
	}

	.footerTempTwo-logo {
		grid-column: span 3;
		align-items: center;
		justify-content: center;
		margin: auto;

		.footer-photo {
			display: block;
			margin: auto;
		}
	}

	.footer-temp-two .footerTempTwo-links {
		grid-template-columns: 1fr 1fr;
	}

	.d-none-mob {
		display: none;
	}

	.footer-temp-two .footerTempTwo-links .footer-col ul .text-secondary-color,
	.footer-temp-two .footerTempTwo-links .footer-col .footerLinks-header {
		text-align: justify;
	}
}
