body{
  &:has(.welcome_lp_container){
    .container_layout {
      padding: 0;
    }
  }
}

.welcome_lp_container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 7rem 5rem;
  min-height: calc(100vh - 200px);
  .plane{
    position: absolute;
    inset-inline-end: 2rem;
    bottom: 2rem;
    width: 140px;
    height: auto;
    // animation: fly 12s ease-in-out infinite;
    opacity: 0.2;
  }
  .shape_abstract_1{
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    width: auto;
    height: 100%;
  }
  .shape_abstract_2{
    position: absolute;
    bottom: -2.5rem;
    inset-inline-end: 0;
    width: 250px;
    height: auto;
  }
  .lp_banner_container{
    width: 100%;
    position: relative;
    .banner_cta{
      margin-top: 1.6rem;
      a{
        min-width: 200px;
      }
    }
    .text_wrap{
      position: relative;
      h1{
        font-size: 2.2rem;
        font-weight: 700;
        color: #d4a655;
        line-height: 1.5;
        margin-bottom: 0.8rem;
      }
      .desc{
        p{
          margin-top: 0;
          margin-bottom: 0;
          font-size: 1.3rem;
          line-height: 1.5;
          font-weight: 500;
        }
      }
    }
    .banner_shapes_container{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10rem;
      .shape{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.4rem;
        width: 200px;
        height: 200px;
        .bg{
          width: 200px;
          height: 200px;
          background-size: 200px 200px;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          inset-inline-start: 0;
          // animation: rotateEl 8s ease-in-out 2s infinite alternate;
        }
        .icon{
          img{
            width: 66px;
            height: auto;
          }
        }
        .txt{
          margin-top: 0;
          margin-bottom: 0;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.5;
        }
        &:nth-child(3){
          position: absolute;
          inset-inline-start: 50%;
          top: 47%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      }
    }
  }
  // .howto_use_section{
  //   .heading{
  //     margin-bottom: 1.5rem;
  //     h2{
  //       font-size: 1.5rem;
  //       font-weight: 600;
  //       color: #d4a655;
  //       text-transform: capitalize;
  //     }
  //   }
  //   .inner_content{
  //     .video_card{
  //       position: relative;
  //       border: 1px solid #DDDCDC;
  //       border-radius: 6px;
  //       .image{
  //         position: relative;
  //         cursor: pointer;
  //         overflow: hidden;
  //         .play_icon{
  //           position: absolute;
  //           top: 50%;
  //           inset-inline-start: 50%;
  //           -webkit-transform: translate(-50%, -50%);
  //           transform: translate(-50%, -50%);
  //           z-index: 1;
  //           transition: all 0.4 linear;
  //         }
  //         .main_img{
  //           display: inline-block;
  //           width: 100%;
  //           height: auto;
  //           border-top-right-radius: 6px;
  //           border-top-left-radius: 6px;
  //           transition: all 0.4 linear;
  //         }
  //         &:hover{
  //           .main_img{
  //             -webkit-transform: scale(1.1);
  //             transform: scale(1.1);
  //           }
  //         }
  //       }
  //       .content{
  //         padding: 1rem;
  //         .meta{
  //           p{
  //             margin-top: 0;
  //             margin-bottom: 0;
  //             line-height: 1.4;
  //             color: #707170;
  //             font-size: 0.85rem;
  //             font-weight: 600;
  //           }
  //         }
  //         .card_title{
  //           margin-top: 0.7rem;
  //           margin-bottom: 0.7rem;
  //           h4{
  //             font-size: 1.2rem;
  //             font-weight: 700;
  //             transition: all 0.4 linear;
  //           }
  //         }
  //         .card_cta{
  //           a{
  //             color: #d4a655;
  //             transition: all 0.4 linear;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.lp_cta_btn{
  position: relative;
  display: inline-block;
  background-color: #d4a655;
  padding: 0.6rem 1rem;
  color: #ffffff;
  border-radius: 6px;
  text-align: center;
  transition: all 0.4s linear;
  .text_layer{
    position: relative;
    z-index: 2;
  }
  svg{
    position: relative;
    inset-inline-start: 0;
    transition: all 0.2s linear;
    path{
      stroke: #ffffff !important;
    }
  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    opacity: 1;
    background-color: #d4a655;
    animation: bubble-btn 1.2s infinite forwards;
  }
  &:hover{
    color: #ffffff !important;
    svg{
      inset-inline-start: 0.3rem;
    }
  }
}

@keyframes rotateEl{
  0%{
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100%{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes bubble-btn{
  0%{
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100%{
    -webkit-transform: scaleX(1.4) scaleY(1.6);
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }
}

@keyframes fly{
  0%{
    bottom: 2rem;
    inset-inline-end: 2rem;
  }
  25%{
    bottom: 100%;
    inset-inline-end: 100%;
  }
  50%{
    bottom: 0;
    inset-inline-end: 75%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  100%{
    bottom: 100%;
    inset-inline-end: 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

@media screen and (max-width: 991px) {
  .lp_cta_btn{
    &::after{
      animation-duration: 1.3s;
    }
  }
  @keyframes bubble-btn{
    0%{
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100%{
      -webkit-transform: scaleX(1.15) scaleY(1.6);
      transform: scaleX(1.15) scaleY(1.6);
      opacity: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .welcome_lp_container{
    padding: 4rem 3rem;
    .shape_abstract_2{
      width: 200px;
    }
  }
}

@media screen and (max-width: 600px) {
  .welcome_lp_container{
    padding: 4rem 2rem;
    .lp_banner_container{
      .banner_cta{
        a{
          width: 100%;
        }
      }
      .banner_shapes_container{
        gap: 1rem;
        .shape{
          width: 145px;
          height: 145px;
          .bg{
            width: 145px;
            height: 145px;
            background-size: 145px 145px;
          }
          &:nth-child(3){
            top: 44%;
          }
          .icon{
            img{
              width: 50px;
              height: 50px;
            }
          }
          .txt{
            font-size: 0.9rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .welcome_lp_container{
    padding: 4rem 1.3rem;
  }
}

html{
  &:dir(rtl){
    .welcome_lp_container {
      .shape_abstract_1{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
      .shape_abstract_2{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
      .lp_banner_container {
        .banner_shapes_container {
          .shape{
            &:nth-child(3){
              inset-inline-end: 50%;
              inset-inline-start: auto;
            }
          }
        }
      }
    }
    .lp_cta_btn{
      svg{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
  }
}