@import "./responsive.scss";


.theme-1 {
  .wb-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: #fff;
    

    .logo img {
      height: 60px;
      cursor: pointer;
    }
    .main-nav {
      display: flex;
      grid-gap: 16px;
      gap: 16px;
      align-items: center;
      color: #050001;

      li {
        color: #050001;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }

      .btn-secondary, .btn-secondary:active:focus {
        color: #050001 !important;
        font-size: 16px !important;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
      .show > .btn-secondary.dropdown-toggle {
        background-color: transparent;
        border: none;
      }
      .dropdown-menu.show {
        width: 100%;
      }
    }
    .contact-button {
      border: none;
      border-radius: var(--web-page-border-raduis);
      background-color: rgba(var(--web-page-primary-color));
      color: #fff;
      padding: 8px 32px;
    }
  }

}