$label-color: #707170;

.product-element-header {
	background-color: #f7f6f6;
	padding: 24px 16px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	.header-details {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

%flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.product-builder-attraction-container {
	label {
		font-size: 14px;
		color: $label-color;
	}
	margin-top: 20px;
	border-radius: 8px;
	border: 1px solid #dddcdc;
	.product-builder-attraction {
		padding: 16px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	.attraction-textarea {
		label {
			display: block;
			color: $label-color;
			font-size: 14px;
		}
		textarea {
			border: 1px solid #dddcdc;
			border-radius: 8px;
			width: 100%;
			padding: 10px 16px;
		}
	}

	.attraction-date-time {
		display: flex;
		gap: 8px;
		& div {
			flex: 1 1 50%;
		}
		.control-field {
			border-radius: 8px;
		}
	}
	.locations-fields {
		display: flex;
		gap: 8px;
		& div {
			flex: 1 1 50%;
		}
		.control-field {
			border-radius: 8px;
		}
	}

	.included-services {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.included-services-field {
		display: flex;
		gap: 8px;
		align-items: center;
		// input {
		// 	border: 1px solid #dddcdc;
		// 	border-radius: 8px;
		// 	padding: 8px;
		// 	flex-basis: 50%;
		// }
		button {
			border: none;
			background-color: transparent;
		}
	}

	.add-new-service {
		border: none;
		background: transparent;
		display: flex;
		align-items: center;
		color: #6a4d1b;
	}

	.save-button{
		align-self: flex-end;
		width: auto;
		padding-inline: 40px;
	}
}

.upload-file-component {
	.upload-file-label {
		font-size: 14px;
		color: $label-color;
	}
	.upload-file-component-input {
		margin-top: 10px;
		border: 1px dashed #dbdade;
		border-radius: 6px;
		padding-block: 20px;
		display: flex;
		justify-content: center;
		gap: 12px;
		.up-arrow {
			width: 32px;
			height: 32px;
			padding: 4px;
			border-radius: 8px;
			background: #ffffff;
			position: absolute;
			bottom: -9px;
			inset-inline-end: -5px;
		}
		%upload-element {
			@extend %flex-center;
			width: 80px;
			height: 80px;
			padding: 4px;
			border-radius: 8px;
			background: #f7f6f6;
		}

		.upload-button {
			@extend %upload-element;
			position: relative;
			cursor: pointer;
		}

		.uploaded-image-preview {
			@extend %upload-element;
			border: 4px solid #f7f6f6;
			background-size: cover;
			.close-icon {
				@extend %flex-center;
				width: 32px;
				height: 32px;
				padding: 4px;
				border-radius: 8px;
				background: #ffffffcc;
				display: none;
				border: none;
			}
			&:hover {
				.close-icon {
					display: flex;
				}
			}
		}
	}
}

.field-has-error {
	border-color: #ff5f59 !important;
}
.has-error {
	color: #ff5f59 !important;
}
.custom-time-field-container {
	position: relative;
	.custom-time-field {
		width: 100%;
		padding: 8px 16px;
		border-radius: 8px;
		border: 1px solid #dddcdc;
		background-color: white;
		display: flex;
		justify-content: space-between;
	}
	.time-field-row {
		margin-top: 8px;
		width: 100%;
		border-radius: 6px;
		box-shadow: 0px 4px 16px 0px #a5a3ae73;
		background-color: white;
		padding: 8px 20%;
		display: flex;
		justify-content: center;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1;
	}
	.time-details {
		@extend %flex-center;
		border: 1px solid #f7f6f6;
		padding: 4px 36px 4px 36px;
		gap: 8px;
		input {
			width: 22px;
			border: none;
		}
	}
}
