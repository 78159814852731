.theme-7 {

  .wb-hero {

    .hero-container {
      position: relative;
      margin-block: 2rem;
      border-radius: 10px;
      overflow: hidden;
    }

    .react-multiple-carousel__arrow {
      top: 45%;
      right: auto;
      left: auto;
      background-color: transparent !important;
      border: 2px solid #ffffff;

      &::before {
        display: none;
      }

      &.react-multiple-carousel__arrow--right {
        inset-inline-end: 2rem;
      }

      &.react-multiple-carousel__arrow--left {
        inset-inline-start: 2rem;
      }
    }

    .hero-content-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 80%;
      height: 100%;
      z-index: 2;
      padding: 1rem;
      text-align: center;

      .hero-content {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .searchbar_container {
        position: absolute;
        bottom: 2.6rem;
        width: 100%;
        max-width: 900px;
        padding: 0.7rem;
        border-radius: 8px;
        background-color: #ffffff87;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .form_select {
          position: relative;
          padding-inline-start: 45px;
          flex-grow: 1;
          background-color: #ffffff;
          border: 1px solid #ebebeb;
          border-radius: 8px;

          .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            inset-inline-start: 8px;

            path {
              stroke: rgb(var(--web-page-primary-color));
            }
          }

          .control-field {
            border: 0;
          }
        }

        .cta_btn {
          position: relative;
          padding: 0.7rem 1.2rem;
          text-align: center;
          border: 1px solid rgb(var(--web-page-primary-color));
          box-shadow: none;
          outline: none;
          background-color: rgb(var(--web-page-primary-color));
          color: #ffffff;
          border-radius: 8px;

          .icon {
            position: relative;
            display: inline-block;
            margin-inline-end: 0.3rem;
            width: 22px;
            height: auto;
            z-index: 1;

            path {
              stroke: #ffffff;
            }
          }

          @include mixins.closeWindow;
        }
      }
    }
    
  }



  // }

  // @media screen and (min-width: 1600px) {
  //   .theme_hero_section {
  //     min-height: 650px;

  //     .theme_hero_slider {
  //       .theme_slide {
  //         img {
  //           min-height: 650px;
  //         }
  //       }
  //     }
  //   }
  // }

  // @media screen and (max-width: 1100px) {
  //   .theme_hero_section {
  //     .theme_searchbar_container {
  //       max-width: 700px;
  //       bottom: 1.5rem;
  //       gap: 0.9rem;
  //     }
  //   }
  // }

  // @media screen and (max-width: 991px) {
  //   .theme_hero_section {
  //     .theme_hero_content {
  //       h1 {
  //         font-size: 1.5rem;
  //       }

  //       p {
  //         font-size: 1rem;
  //       }
  //     }

  //     .theme_searchbar_container {
  //       max-width: 600px;
  //     }
  //   }
  // }

  // @media screen and (max-width: 767px) {
  //   .theme_hero_section {
  //     padding-bottom: 230px;

  //     .theme_searchbar_container {
  //       flex-direction: column;
  //       max-width: 95%;

  //       .form_select,
  //       .cta {
  //         width: 100%;

  //         .cta_btn {
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  // }
}