.subscription-packages-plans {
	.text-gray {
		color: #707170 !important;
	}

	.text-black {
		color: #112233;
	}

	margin: 0 8px;
	padding: 0 20px;

	.main-title {
		font-size: 28px;
		font-weight: 700;
		line-height: 86.2px;
	}

	.title {
		text-align: center;
		font-size: 16px;
		font-weight: 600;
		color: #707170;
		padding: 10px 0;
	}

	.planCards {
		margin: 24px 0;

		.container-xl {
			max-width: 85%;

			@media (max-width: 1520px) {
				max-width: 100%;
			}
		}

		.planItem {
			background-color: #fff;
			padding: 38px 20px;
			box-shadow: 0px 0px 40px #0000000d;
			position: relative;
			overflow: hidden;
			margin: 30px 40px;
			max-width: 500px;
			border-radius: 8px;

			@media (max-width: 1320px) {
				max-width: 100%;
				margin: 20px 0;
			}

			.plan-item-header {
				padding-bottom: 18px;
				border-bottom: 1px solid #dddcdc;

				p {
					font-size: 18px;
					font-weight: 600;
				}

				h2 {
					font-size: 46px;
					font-weight: 700;
					color: #112233;

					span {
						font-size: 18px;
						font-weight: 600;
						color: #707170;
					}

					.oldPrice {
						font-size: 36px;
						text-decoration: line-through;
						color: #979797c0;
						font-weight: 400;
					}
				}
			}

			.plan-item-body {
				padding: 18px 0;
				border-bottom: 1px solid #dddcdc;
				min-height: 332px;

				.list-items li {
					margin-bottom: 10px;
					font-size: 18px;
					font-weight: 600;
					color: #050001;
					display: flex;
					align-items: center;
					gap: 10px;

					.icon {
						background-color: #d4a5552d;
						width: 24px;
						height: 24px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;

						svg {
							stroke: #d4a655;
						}
					}
				}
			}

			.plan-item-footer {
				padding: 18px 0 0 0;

				.date-footer {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;

					span {
						font-size: 18px;
						font-weight: 600;
						color: #050001;
					}
				}
			}
		}
	}

	.btn-package-plan {
		width: 100%;
		background-color: #d4a655;
		padding: 14px;
		color: #fff;
		font-size: 18px;
		font-weight: 600;
		border-radius: 6px;
		margin-top: 16px;
		cursor: pointer;
	}

	.btn-current-plan {
		width: 100%;
		padding: 14px;
		background-color: #019d4c2b;
		color: #019d4c;
		font-size: 18px;
		font-weight: 600;
		border-radius: 6px;
		margin-top: 16px;
		cursor: default;
	}

	.special-card {
		border: 2px dashed #019d4c;
	}

	.special {
		width: 170px;
		height: 170px;
		position: absolute;
		top: -45px;
		inset-inline-end: -43px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		.special-title {
			display: flex;
			justify-content: center;
			background-color: #019d4c;
			color: #fff;
			align-items: center;
			font-size: 0.8em;
			font-weight: 600;
			position: absolute;
			width: 150%;
			height: 30px;
			transform: rotate(45deg) translateY(15px);

			[dir="rtl"] & {
				transform: rotate(-45deg) translateY(15px);
			}
		}
	}

	.notFound {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 10px;
		text-align: center;

		.imgErorr {
			margin-bottom: 26px;
		}

		.textErorr {
			.title {
				font-size: 18px;
				font-weight: 600;
				color: #a8aaae;
			}

			.desc {
				font-size: 18px;
				font-weight: 600;
				color: #707170;
			}
		}

		.back {
			.btn-package-plan {
				padding: 10px 30px;
				font-size: 14px;
			}
		}
	}
}

.subscription-plan-v2 {
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;

	.active-plan {
		width: 100%;
		border: 2px #21965366 solid;
		padding: 16px;
		background-color: #ffffff;
		border-radius: 8px;
		display: flex;
		gap: 1rem;
	}

	.active-plan-title {
		width: 15%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		position: relative;

		.title {
			font-size: 22px;
			font-weight: 700;
		}
	}

	.active-plan-title::after {
		content: "";
		width: 1px;
		height: 100%;
		color: #dbdade;
		background-color: #dbdade;
		z-index: 10;
		position: absolute;
		inset-inline-end: 0;
	}

	.plan-status {
		width: fit-content;
		background-color: #019d4c33;
		padding: 0px 14px 0px 14px;
		border-radius: 30px;
		font-size: 14px;
		font-weight: 400;
		color: #078343;
	}

	.active-plan-content {
		width: 85%;
		display: flex;
		gap: 1rem;
	}

	.active-plan-item {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 12px;
		border-inline-end: 1px solid #dbdade;
		gap: 12px;
		&:last-child {
			border: none;
		}

		.title {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 6px;
		}

		.content {
			width: 100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			column-gap: 2rem;
			row-gap: 1rem;
			bottom: 0;
		}

		.item-title {
			font-size: 16px;
			font-weight: 400;
		}
	}

	// .active-plan-item:not( :last-child)::after {
	//   content: "";
	//   width: 1px;
	//   height: 100%;
	//   color: #DBDADE;
	//   background-color: #DBDADE;
	//   z-index: 10;
	//   position: absolute;
	//   right: 0;

	// }

	.subscription-plans {
		--plan-gap: 24px;
		--items-per-row: 4;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: var(--plan-gap);

		.plan {
			flex: 0 0
				calc(100% / var(--items-per-row) - calc(var(--plan-gap) / 4 * 3));
			min-width: 350px;
			max-width: 100%;
			display: flex;
			flex-direction: column;
			border-radius: 8px;
			background-color: #ffffff;
			padding: 16px;
			gap: 16px;

			hr {
				border-top: 1px solid #e2e2e2;
				width: 100%;
				margin-top: auto;
				margin-bottom: 0;
			}

			.plan-info {
				position: relative;
				padding-bottom: 16px;
				border-bottom: 1px solid #e2e2e2;
			}

			.text-muted {
				font-size: 16px;
			}

			.btn {
				width: 100%;
				border-radius: 4px;
				font-size: 16px;
				font-weight: 600;
				border: none;
				padding: 12px 16px 12px 16px;
				// margin-top: auto;
			}

			.subscribed-btn {
				position: relative;
				background-color: #019d4c33;
				color: #019d4c;
				border: 1px #019d4c solid;
				cursor: default;
			}

			.subscribe-btn {
				position: relative;
				background-color: #d4a655;
				color: #fbfbfa;
			}
		}

		.plan-title {
			font-size: 22px;
			font-weight: 700;
		}

		.plan-price {
			font-size: 36px;
			font-weight: 700;
		}

		.old-price {
			text-decoration: line-through;
			margin-left: 10px;
		}

		.plan-content {
			// height: 294px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			position: relative;
			padding-bottom: 16px;
      .hexagon-alt-wrapper {
        width: 36px;
        height: 43px;
      }
		}

		// .plan-content ::after {
		//   content: "";
		//   position: absolute;
		//   width: 100%;
		//   height: 1px;
		//   background-color: #e2e2e2;
		//   left: 0;
		//   bottom: 0;
		// }

		.plan-content-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.info {
			width: 100%;
			display: flex;
			gap: 8px;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			.info-text {
				color: #d4a655;
				font-size: 14px;
			}
		}
	}
}

.checkout {
	padding: 44px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
	align-items: baseline;
  gap: 16px;

  .payment-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 0px 30px 0px #0000000D;

    .title {
      font-size: 20px;
      font-weight: 700;
      color: #707170;
    }
		.dropdown-list {
			top: 0;
    	left: -47px;
			bottom: unset;
		}  
  }

	.payments-methods {
		.method-item {
			display: flex;
			align-items: center;
      justify-content: space-between;
			gap: 8px;
		}
    .online-method, .wallet-method {
      padding: 16px;
      border: 1px solid #DDDCDC;
      border-radius: 6px;
    }
    .active-method {
      border: 1px solid #D4A655;
    }
    .method-input {
      display: flex;
			align-items: center;
			gap: 8px;
    }
    .wallet-balance {
      color: #707170;
    }
    input {
      width: 18px;
      height: 18px;
      accent-color: #bf7f1a;
    }
		input#pay-wallet {
			border-radius: 4px;
		}
		input[type=radio] {
			border-radius: 50%;
		}
    .form-check-label {
      font-weight: 600;
      font-size: 16px !important;
      color: #d4a655 !important;
      margin: 0 !important;
    }
	}

  .due-amount-container {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 25px;
    font-size: 16px;
    color: #050001;

    .title {
      font-size: inherit;
      color: inherit;
    }
    .amount {
      font-weight: 700;
      font-size: 34px;
    }

    .caution {
      font-weight: 400;
      color: #707170;
    }
  }
  .pay-btn {
    width: 100%;
    background-color: #D4A655;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    border-radius: 8px;
  }
  .checkout-summary {
    display: flex;
    flex-direction: column;
    gap: 16px;

		.bundle-info, .total-amount {
			padding: 24px;
			border-radius: 8px;
			border: 1px solid #EEEEEE;
			box-shadow: 0px 0px 30px 0px #0000000D;
		}
		.title {
			font-weight: 700;
			font-size: 22px;
			color: #050001;
		}
		.text-primary {
			color: #D4A655 !important;
		}

    .package-module-list {
			display: flex;
			flex-direction: column;
			gap: 10px;
      padding: 0 16px;
			.hexagon-alt-wrapper {
				width: 36px;
				height: 44px;
			}
      li {
        display: flex;
        align-items: center;
        gap: 8px;
				padding-bottom: 8px;
			
				&:not(:last-child) {
					border-bottom: 1px solid #EEEEEE;
				}
      }
    }

		.total {
			padding: 16px;
			background-color: #FBFBFA;
			font-weight: 700;
			font-size: 32px;
			line-height: 24px;
			color: #D4A655;
			text-align: center;
		}
  }
}

.hexagon-alt-wrapper {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
	background-color: #FAF4EB;
	width: 52px;
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		flex:0 0 85%;
    path {
      stroke: #D4A655;
    }
	}
}

@media (max-width: 768px) {
  .checkout  {
    grid-template-columns: 1fr;
  }
}