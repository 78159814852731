.crm-details-wrapper {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background: rgba(221, 220, 220, 0.2);
	grid-gap: 1.5rem;
	gap: 1.5rem;

	// ** Actions
	.actions-wrapper {
		padding: 1rem;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
		display: flex;
		justify-content: space-between;
		align-items: center;

		.items-wrapper {
			display: flex;
			align-items: center;
			gap: 1rem;

			.item {
				display: flex;
				grid-gap: 6px;
				font-size: 18px;
				gap: 6px;
				align-items: center;
				justify-content: center;
				font-weight: 600;
				color: #707170;
				border-right: 1px solid rgba(221, 220, 220, 0.5);

				&:last-child {
					border-right: none;
				}
			}

			.active {
				background-color: #d4a655;
				color: #fff;
				font-size: 1rem;
				font-weight: 700;
			}
		}
	}

	.crm-tabs-wrapper {
		display: flex;
		border-radius: 8px;
		gap: 8px;


		.priority-wrapper {
			top: -16px;
		}

		.crm-tabs-left {
			flex: 2;
			box-shadow: 0px 2px 12px 0px #00000014;
			background-color: #fff;
			padding: 8px;

			.request-date {
				border-right: 1px solid rgba(221, 220, 220, 0.32);
				width: 50%;
				grid-gap: 5px;
			}

			.status {
				grid-gap: 5px;
				.new {
					background-color: #fffbf6;
					color: #ff9f43;
					border: 1px solid #ff9f43;
					border-radius: 8px;
					width: 118px;
					height: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		.crm-tabs-right {
			flex: 1;
			box-shadow: 0px 2px 12px 0px #00000014;
			background-color: #fff;
			padding: 8px;

			.call-log {
				padding: 0px 14px;
				display: flex;
				flex-direction: column;
				grid-gap: 10px;

				.call-log-buttons {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 5px;
					.btn-item {
						flex-grow: 1;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 9px 20px;
						gap: 5px;
						opacity: .65;
					}

					.btn-ingoing {
						color: #019d4c;
						border: 1px solid #019d4c;
						background-color: #019d4c29;
						svg path {
							stroke: #019d4c;
						}
						&.active {
							background-color: #019d4c;
							color: #fff;
							opacity: 1;
							svg path {
								stroke: #fff;
							}
						}
					}

					.btn-outgoing {
						background-color: rgba(234, 84, 85, 0.16);
						border: 1px solid #ea5455;
						color: #ea5455;
						&.active {
							background-color: #ea5455;
							color: #fff;
							opacity: 1;
							svg path {
								stroke: #fff;
							}
						}
					}
				}
				.save-log-btn {
					padding: 9px 32px;
					font-weight: 700;
					font-size: 16px;
					line-height: 30px;
					border-radius: 6px;
					background-color: #D4A655;
					color: #fff;
				}
				.call-log-note {
					margin-top: 1rem;
					label {
						font-weight: 500;
						color: #707170;
					}
					textarea {
						border: 1px solid #DDDCDC;
						&::placeholder {
							font-weight: 600;
							font-size: 14px;
							color: #DDDCDC;
						}
					}
				}
			}
		}
	}
}

.crm-details-text-primary {
	font-size: 18px;
	color: rgb(112, 113, 112);
	font-weight: 600;
}

.crm-details-text-secondary {
	font-size: 16px;
	color: rgb(5, 0, 1);
	font-weight: 400;
}

.text-totalPrice {
	color: #0A3352 !important;
}

.text-payment-success {
	color: #019d4c;
}

.text-payment-orange {
	color: #d4a655;
}

.tab-borders {
	border-top: 1px solid rgba(221, 220, 220, 0.32);
	border-bottom: 1px solid rgba(221, 220, 220, 0.32);
	gap: 10px;
	margin: 10px 0px;
	border: none !important;

	li:first-child {
		border-bottom: none !important;
		padding: 6px 0 !important;
	}

	li {
		padding: 16px 24px !important;
	}
}

.active-text {
	color: #d4a655;
	font-size: 1rem;
	font-weight: 700;
}

.crm-tabs-m {
	padding: 10x 0px;
}

.summery-details {
	padding: 0px 24px;
	.summery-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h3 {
			font-size: 1.5rem;
			font-weight: 700;
		}

		.active {
			background-color: #d4a655;
			color: #fff;
			font-size: 1rem;
			font-weight: 700;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 3px;
			width: 100px;
			height: 48px;
		}
	}

	.details {
		display: flex;
		// align-items: center;
		padding: 1rem;
		border-radius: 8px;
		box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
		margin: 1rem 0;

		.details-items {
			display: flex;
			flex-direction: column;
			grid-gap: 5px;
			// align-items: center;
			// justify-content: center;
			flex: 1;
			border-right: 1px solid rgba(221, 220, 220, 0.32);

			.text-red {
				color: #ea5455;
			}
		}
		.details-items:last-child {
			border-right: none;
		}

		.display-border {
			border-right: 1px solid rgba(221, 220, 220, 0.32) !important;
		}

		.w-12 {
			width: 12.5% !important;
		}
	}

	.flex-wrap-custom {
		flex-wrap: wrap;

		.details-items {
			margin: 1rem 0;
			flex: none;
			width: 25%;
		}

		.details-items:nth-child(4) {
			border-right: none;
		}
	}

}
.crm-details-payment {
	padding: 1rem;
	background-color: #fff;
	box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.08);
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;

	.item-special {
		padding: 10px;
		box-shadow: 0px 1px 12px 0px #00000014;
		border-left: 4px solid #D4A655;

		.btn-text-addNew {
			color: #d4a655;
			font-size: 18px;
			background-color: transparent;
			font-weight: 600;
		}
	}

	.items-guests-per-pax {
		display: flex;
		flex-direction: column;
		gap: 10px;
		// padding: 10px;

		.item-guests-per-pax {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.guests-row {
		display: flex;
		grid-gap: 10px;
		gap: 20px;
		// width: 70%;

		.guests-item {
			flex: 1;
		}

		.guests-item-flex-2 {
			flex: 2 !important;
		}
	}

	.guests-actions {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.btn-text-addNew {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			gap: 8px;
			font-size: 16px;
			font-weight: 600;
			background-color: transparent;
			border-radius: 6px;
			color: #d4a655;
		}

		.btn-active {
			background-color: #d4a655;
			color: #fff;
			padding: 11px 32px;
			border-radius: 4px;
			font-size: 16px;
			font-weight: 700;
		}
	}

	.payment-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.btn:disabled {
		background: transparent !important;
		color: #707170;
		cursor: not-allowed;
		svg path {
			stroke: #707170;
		}
	}
}
.call-logs-history {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.call-log-box {
		display: flex;
		flex-direction: column;
		padding-bottom: 16px;
		gap: 4px;
		border-bottom: 1px solid #DDDCDC52;
	
		.call-log-info {
			display: flex;
			justify-content: space-between;
			gap: 6px;
			font-weight: 600;
			font-size: 17px;
			color: #707170;
		}
		.text-secondary {
			font-weight: 400;
			font-size: 16px;
		}
		.call-note {
			padding: 0 2rem;
			font-weight: 400;
			font-size: 14px;
			color: #050001;
		}
	}
}

.item-addPax {
	display: flex;
	grid-gap: 6px;
	font-size: 18px;
	gap: 6px;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	color: #707170;
	border-right: 1px solid rgba(221, 220, 220, 0.5);
}

.active-addPax {
	background-color: #d4a655;
	color: #fff;
	font-size: 1rem;
	font-weight: 700;
	width: 100px;
	height: 48px;
	border-radius: 4px;
}

.crm-search-wrapper {
	padding: 1rem;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
}

.extra-service-wrapper {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background: #fff;
	grid-gap: 1.5rem;
	gap: 1.5rem;

	.custom-input-search {
		.control-field {
			width: 340px;
		}
	}
	.table .thead-light th {
		font-size: 14px;
		color: #707170;
		font-weight: 700;
	}
	.table-bordered th,
	.table-bordered td {
		border: none !important;
		vertical-align: middle;
	}
}

.available-packages {
	.summery-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h3 {
			font-size: 1.5rem;
			font-weight: 700;
		}

		.active {
			background-color: #d4a655;
			color: #fff;
			font-size: 1rem;
			font-weight: 700;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 3px;
			width: 100px;
			height: 48px;
		}
	}

	.details {
		display: flex;
		align-items: center;
		padding: 1rem;
		border-radius: 8px;
		box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
		margin: 1rem 0;

		.details-items {
			display: flex;
			flex-direction: column;
			grid-gap: 5px;
			align-items: center;
			justify-content: center;
			flex: 1;
			border-right: 1px solid rgba(221, 220, 220, 0.32);

			.text-red {
				color: #ea5455;
			}
		}
		.details-items:last-child {
			border-right: none;
		}

		.display-border {
			border-right: 1px solid rgba(221, 220, 220, 0.32) !important;
		}

		.w-12 {
			width: 12.5% !important;
		}
	}

	.flex-wrap-custom {
		flex-wrap: nowrap;

		.details-items {
			margin: 1rem 0;
			flex: none;
			width: 25%;
		}

		.details-items:nth-child(4) {
			border-right: none;
		}
	}

	.crm-details-payment {
		padding: 1rem;
		background-color: #fff;
		box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.08);
		display: flex;
		flex-direction: column;
		grid-gap: 0.5rem;

		.payment-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

.current-package-actions {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;

	.active {
		background-color: #d4a655;
		color: #fff;
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3px;
	}

	.view-btn {
		background-color: rgba(212, 166, 85, 0.08);
	}
}

.crm-button-add {
	background-color: #d4a655;
	color: #FFF;
	border-radius: 4px;
	font-size: 16px;
	padding: 11px 0px;
	font-weight: 700;
}

.payment-prices-items {
	grid-gap: 10px;
	padding: 0px 0px 0px 16px;
	border-right: 1px solid rgba(221, 220, 220, 0.5)
}