.custom-main {
	position: absolute;
	top: 100%;
	left: 45%;
	right: 45%;
	z-index: 100;
	width: 175px;
}
.custom-notification {
	min-width: 45px;
	height: 45px;
	border-radius: 50%;
	border: 1px solid #EFEFEF;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.TechnicalSupport {
	background-color: #00a042;
	color: white;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	text-align: center;
	height: 15vh;
	writing-mode: vertical-lr;
	transform: rotate(180deg);
	

}

.TechnicalSupport:hover {
	color: white;
}
.TechnicalSupport-width {
	width: 30px;
	height: 15vh;
	top: 60%;
	position: absolute;
	z-index: 999999;
}
