:root {
	--text-primary: rgb(0 0 0 / 87%);
	--text-secondary: rgb(0 0 0 / 56%);
}

.hotel-accommodation-container {
	justify-content: space-evenly;
	// margin-top: -35px !important;
	padding-bottom: 12px !important;

	//////////// Shared Style ////////////
	.room-type-badge {
		border: 1px solid #eed9ba;
		border-radius: 18px;
		background-color: #faf4eb;
		color: #d4a655;
		padding: 0px 8px;
		height: 24px;
		display: flex;
		align-items: center;
	}
	.sections-container {
		background-color: white;
		border: 8px;
		padding: 16px 24px;
	}

	.person-icon {
		min-width: 32px;
	}
	.traveler-type {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-inline-start: 8px;
		flex: 1;
	}
	.person-type {
		flex: 1 0 auto;
		color: var(--text-secondary);
		font-size: 14px;
	}
	.traveler-name {
		flex: 1 0 100%;
		font-size: 16px;
	}

	////////////////// Travelers-Accommodation //////////////////
	.travelers-accommodation {
		//
		.travelers-head {
			font-size: 24px;
			line-height: 16px;
			font-weight: 600;
			color: var(--text-primary);
			margin-top: 16px;
		}
		//
		.travelers-search {
			margin-top: 30px;
		}
		//
		.travelers-search-head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: var(--text-secondary);
		}
		//
		.traveler-search-input {
			margin-top: 20px;
			display: flex;
			border: 1px solid #dddcdc;
			border-radius: 8px;
			padding: 10px;
			input {
				border: none;
				width: 100%;
				margin-inline-start: 8px;
			}
		}
		//
		.traveler-details {
			margin-top: 8px;
			padding: 12px 8px;
			display: flex;
			background-color: #fbfbfa;
			align-items: center;

			.drag-icon {
				min-width: 24px;
			}
		}
	}
	////////////////// Hotel Details //////////////////

	.hotel-accommodation-details {
		.meal-type {
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				margin-inline-end: 8px;
				color: var(--text-secondary);
			}
			.meal-type-input {
				width: 130px;
			}
		}

		.hotel-taps-container {
			display: flex;
			overflow: auto;
			.hotel-tap {
				padding: 10px 16px;
				color: #707170;
				line-height: 29.98px;
				flex-shrink: 0;

				span {
					margin-inline-start: 8px;
				}
			}
			.active {
				border: 1px solid #eed9ba;
				border-radius: 4px;
				background-color: #faf4eb;
				color: #6a4d1b;
				path {
					stroke: #6a4d1b;
				}
			}
		}
		//////
		.hotel-details-container {
			margin-top: 2em;
			display: grid;
			grid-template-columns: repeat(3, 1fr);

			.separator {
				position: relative;
				padding-inline-start: 8px;
				&::before {
					content: "";
					position: absolute;
					top: 0;
					inset-inline-start: -8px;
					width: 2px;
					height: 100%;
					background-color: #d8d8d8;
				}
			}
			.hotel-key-value {
				display: flex;
				margin-top: 1em;
				.key {
					flex: 1 auto;
					margin-inline-start: 6px;
					color: var(--text-secondary);
				}
				.value {
					flex: 1 auto;
					color: var(--text-primary);
				}
			}
		}
		.btn-duplicate {
			background-color: #d4a655;
			color: #fff;
			border-radius: 8px;
			font-size: 16px;
			font-weight: 600;
		}

		//////
		.hotel-accommodation-filter {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.room-type-filter {
				display: flex;
				gap: 8px;
				.filter-type-badge {
					border: 1px solid #dddcdc;
					border-radius: 18px;
					background-color: #fefeff;
					color: var(--text-secondary);
					padding: 0px 16px;
					font-size: 18px;
				}
				.active-filter-badge {
					border: 1px solid #d4a655;
					background-color: #faf4eb;
					color: #d4a655;
				}
			}
		}
		///////
		.room-container {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;
			margin-top: 1em;
			max-height: 235px;
			overflow-y: auto;
			.room-card {
				border: 1px solid #f7f6f6;
				border-radius: 4px;
				display: flex;
				flex-direction: column;

				.room-number {
					font-size: 16px;
					line-height: 20px;
					font-weight: 600;
					cursor: pointer;
				}
				.room-name {
					font-size: 14px;
					line-height: 16px;
					cursor: pointer;
				}
				.room-info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					input {
						border: none;
						width: 100%;
					}
					.input-check {
						box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1294117647);
						padding: 0 4px;
						border-radius: 3px;
						color: #d4a655;
						margin-inline-start: 8px;
					}
				}
				.room-details {
					flex: 1 auto;
				}
				.room-card-header {
					border-radius: 4px 4px 0 0;
					display: flex;
					align-items: center;
					gap: 12px;
					background-color: #f7f6f6;
					padding: 10px;
				}
				.room-card-body {
					margin-top: 8px;
					padding: 16px;
					align-items: center;
					flex: 1 0 auto;
				}
				.traveler-details {
					display: flex;
					align-items: center;
					padding: 12px 0;
				}
				.room-card-footer {
					padding: 10px;
					hr {
						background-color: #f7f6f6;
					}
				}
			}
		}
		//////////
		.hotel-accommodation-button {
			padding: 4px 16px;
			border: 1px solid #d4a655;
			border-radius: 8px;
			color: #d4a655;
			span {
				padding-inline-start: 8px;
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 0em;
			}
		}

		.hotel-accommodation-actions {
			width: 100%;
			margin-top: 2em;
			display: flex;
			justify-content: flex-end;
			gap: 8px;
			flex-wrap: wrap;
			.hotel-accommodation-button {
				padding: 4px 16px;
				border: 1px solid #d4a655;
				border-radius: 8px;
				color: #d4a655;
				width: 32%;
				span {
					padding-inline-start: 8px;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: 0em;
				}
			}
		}
	}
}

.duplicate-model {
	p {
		color: #667085;
		font-size: 16px;
		font-weight: 400;
	}
	h6 {
		color: #101828;
	}
	.hotel-item {
		margin-top: 10px;
		display: flex;
		align-items: center;
		gap: 12px;
		input {
			width: 18px;
			height: 18px;
		}
		label {
			color: #707170;
			margin-bottom: 0;
		}
	}
	.btn-dupl {
		background-color: #d4a655;
		border-radius: 8px;
		padding: 10px 0;
		color: #fefeff;
		font-size: 16px;
		font-weight: 600;
	}
	.text-header {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		color: #050001;
		font-weight: 600;
		font-size: 18px;
		padding: 10px 0px 10px 0px;
	}
}
@media print {
	.hotel-accommodation-container .hotel-taps-container .hotel-tap {
		display: none !important;
	}
	.hotel-accommodation-container
		.hotel-accommodation-details
		.hotel-taps-container
		.active {
		display: block !important;
	}
	// visibility: hidden;
	.accommodation-hotel-travelers,
	.hotel-accommodation-actions {
		display: none !important;
	}
	.hotel-accommodation-container {
		width: 100% !important;
	}
	.accommodation-hotel-details {
		min-width: 90% !important;
	}
}
