.safa-text-primary{
  color: #D4A655 !important;
}

.gap-xs{
  gap: 0.325rem;
}

.gap-sm{
  gap: 0.5rem;
}

.fs-xxl{
  font-size: 1.8rem !important;
}
.fs-xl{
  font-size: 1.5rem !important;
}
.fs-lg{
  font-size: 1.2rem !important;
}
.fs-md{
  font-size: 1rem !important;
}
.fs-sm{
  font-size: 0.9rem !important;
}
.fs-xs{
  font-size: 0.825rem !important;
}

.py-sm{
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.fw-500{
  font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

.rounded-md{
  border-radius: 6px;
}

.safa-badge {
  display: inline-block;
  padding: 0.325rem 0.9em;
  font-size: 0.85rem;
  font-weight: 600;
  color: #333; /* Text color */
  background: linear-gradient(145deg, #f0f0f0, #ffffff); /* Light gradient */
  border-radius: 6px; /* Rounded corners */
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1), -2px -2px 6px rgba(255, 255, 255, 0.8); /* Soft shadow */
  text-align: center;
  user-select: none; /* Prevent text selection */
  &.badge-primary {
    background: linear-gradient(145deg, #e0ecf9, #f0f8ff); /* Light blue gradient */
    color: #0056b3; /* Text color */
  }
  &.badge-success {
    background: #D8EADE; /* Light green gradient */
    color: #128E29; /* Text color */
  }
  &.badge-danger {
    background: linear-gradient(145deg, #f8d7da, #fff5f5); /* Light red gradient */
    color: #721c24; /* Text color */
  }
  &.badge-warning {
    background: #FFEFDD; /* Light yellow gradient */
    color: #FC8B09; /* Text color */
  }
  &.badge-info {
    background: linear-gradient(145deg, #d1ecf1, #effbfd); /* Light teal gradient */
    color: #0c5460; /* Text color */
  }
}

.css-spinner {
  width: 35px;
  height: 35px;
  border: 4px solid #ffffff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: spinning 1s linear infinite;
  &.dark {
    border-color: #000000;
    border-bottom-color: transparent;
  }
  &.sm {
    width: 15px;
    height: 15px;
    border-width: 2px;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}