@import "../../base/variables";

#sideNav {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100%;
	width: 70px;
	top: 0;
	left: 0;
	background-color: #fff;
	text-align: center;
	box-shadow: 0px 2px 12px #0000003e;
	z-index: 1001;
	transition: 0.7s;
}

[dir="rtl"] #sideNav {
	right: 0;
	text-align: right;
}

#sideNav.opend {
	width: $opendNav;
	max-width: $opendNavMax;
}

.sidenav_list {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	text-align: left;
	// height: 80vh;
	// overflow: auto;
}

.sidenav_header {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	font-weight: bold;
	font-size: 1rem;
	padding: 0 1rem;
	transition: 0.4s;
	transition-delay: 0.2s;
	text-align: start;
}

[dir="rtl"] .closed .sidenav_header {
	padding: 0;
}

.sidenav_item {
	position: relative;
	display: block;
	// margin: 6px;
}

.sidenav_link {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 42px;
	margin: 0.25rem 0;
	color: $gray-900;
}

.sidenav_item .dash-text {
	margin-inline-start: 1rem;
	color: $gray-900;
	text-align: start;
}

.sidenav_icon {
	flex: 0 0 22px;
	// width: 22px;
	width: 35px;
	display: flex;
	.upgrade-subscription-icon{
    position: absolute;
    top: -3px;
    right: 12px;
		[dir= "rtl"] & {
			left: 12px;
			right: unset;
		}
	}
}
.upgrade-subscription-icon-in-text{
	svg{
		width: 40px;
    height: 22px;
	}

}
.opend .sidenav_dropdown .dropdown_item .active .upgrade-subscription-icon-in-text {
	font-size: 12px;
	font-weight: 400;
	svg{

		fill:none !important
	}

}
.dropdown_link_subscription_icon{
	display: flex;
    justify-content: space-between;
		.upgrade-subscription-icon-in-text{
			display: flex;
    font-size: 12px;
    align-items: center;
    gap: 3px;
    background: #EA545533;
    padding: 0px 6px;
    border-radius: 40px;
    color: #EA5455;
		}
}
.sidenav_icon.fill svg,
.sidenav_arrow svg {
	width: 100%;
	fill: $gray-900;
}

.sidenav_arrow svg path {
	stroke: $gray-900;
}

.sidenav_arrow {
	position: absolute;
	inset-inline-end: 10px;
	width: 7px !important;
}
.subnav_item {
	justify-content: center !important;
}

[dir="rtl"] .sidenav_arrow {
	transform: scale(-1, 1);
}

.dash-text {
	// display: none;
	// visibility: hidden;
	// opacity: 0;
	// width: 0;
	// transition: .4s;
	// font-weight: 700;
}
.sidenav_arrow {
	transform: rotate(0deg);
	transition: 0.1s;
}
///////////////////////////////////// if the nav opend  ///////////////////////////////////////////
.opend .sidenav_link {
	justify-content: flex-start;
}

.opend .sidenav_link {
	padding: 1rem;
}

.opend .dash-text {
	// display: block;
	visibility: visible;
	opacity: 1;
	width: auto;
	transition: 0.4s;
	transition-delay: 0.2s;
}
.opend {
	.sidenav_dropdown {
		padding: 0 calc(1rem + 22px);
		.dropdown_item {
			position: relative;
			padding: 5px 0;
			.dropdown_link {
				display: flex;
				align-items: center;
				color: $gray-900;
				transition: 0.2s;
				&:hover {
					color: $orange-500;
					fill: $orange-500;
					transition: 0.2s;
				}
				& svg {
					width: 20px;
					display: block;
				}
			}
			.active {
				color: $orange-500;
				font-weight: bold;
				& svg {
					color: $orange-500;
					fill: $orange-500;
				}
			}
		}
	}
	.toggled {
		transform: rotate(90deg);
		transition: 0.1s;
	}
}

///////////////////////////////////// if the nav closed  ///////////////////////////////////////////
.closed {
	.sidenav_item {
		&:hover {
			width: $opendNav;
			transition: none;
			background-color: #fff;
			box-shadow: 0px 2px 12px #0000003e;

			.dash-text {
				visibility: visible;
				opacity: 1;
				width: 100%;
				transition: none;
				box-shadow: none;
				display: block;
				padding: 0rem;
				width: 100%;
				display: block;
				color: #727272;
				
			}

			.sidenav_dropdown {
				position: absolute;
				display: flex;
				justify-content: center;
				flex-direction: column;
				background-color: #fff;
				inset-inline-start: 70px;
				width: calc(100% - 70px);
				box-shadow: 5px 10px 12px #0000003e;

				.dropdown_item {
					position: relative;
					padding: 10px;
					.sidenav_arrow {
						visibility: visible;
						opacity: 1;
						display: block;
						top: 50%;
						transform: translateY(-50%);
					}

					.active {
						color: $orange-500 !important;
						font-weight: bold;
						& svg {
							color: $orange-500;
							fill: $orange-500;
						}
						&::after {
							content: "";
							position: absolute;
							height: 70%;
							top: calc(50% + 3px);
							transform: translateY(-50%);
							background-color: $orange-500;
							width: 3px;
							inset-inline-end: 0;
						}
					}
					.dropdown_link {
						width: 100%;
						margin: 0.25rem 0;
						color: #2d2d2d;
						display: flex;
						align-items: center;
						transition: 0.4s;
						.upgrade-subscription-icon-in-text svg{
							fill:none !important;

						}
						&:hover {
							color: $orange-500;
							fill: $orange-500;
							transition: 0.4s;
							.upgrade-subscription-icon-in-text svg{
								fill:none !important;

							}
						}
						& svg {
							width: 20px;
							display: block;
						}
					}
					&:hover {
						.sidenav_arrow svg path {
							stroke: $orange-500;
							transition: 0.4s;
						}
					}
				}
			}
		}
	}

	.sidenav_link {
		padding-inline-start: 24px;

		.dash-text {
			display: none;
		}

		&:hover {
			transition: none;
		}

		.sidenav_icon,
		.sidenav_icon svg {
			box-shadow: none;
			width: 22px;
		}
	}

	.sidenav_dropdown,
	.sidenav_arrow {
		display: none;
		// visibility: hidden;
		// opacity: 0;
		// width: 0;
	}

	.sidenav_header {
		text-align: center;
	}
}

// active
.sidenav_link.active {
	position: relative;
	font-weight: bold;
}

// .sidenav_link.active::after {
// 	content: "";
// 	position: absolute;
// 	height: 70%;
// 	top: calc(50% + 3px);
// 	transform: translateY(-50%);
// 	background-color: $orange-500;
// 	width: 3px;
// 	inset-inline-end: 0;
// }
.sidenav_link.active,
.sidenav_link.active .fill svg,
.sidenav_link.active .dash-text {
	color: $orange-500 !important;
	fill: $orange-500 !important;
	background: #FAF4EC;
}

.sidenav_link.active .stroke svg path {
	color: $orange-500 !important;
	stroke: $orange-500 !important;
	fill: #fff;
}

.fix-color .active svg path{

	color: $orange-500 !important;
	stroke: $orange-500 !important;
	background: #FAF4EC;
}
.fill-color .sidenav_link.active svg path{

	color: $orange-500 !important;
	stroke: none !important;
	fill: $orange-500 !important;
	background: #FAF4EC;
}
.sidenav_link.active .sidenav_arrow svg path {
	stroke: $orange-500;
}
.stroke .dropdown_link.active {
	svg {
		fill: #fff !important;
		path  {
			stroke: $orange-500 !important;
			transition: 0.4s;
		}
	}
}

.stroke .dropdown_link:hover { 
	svg {
		fill: #fff !important;
		path  {
			stroke: $orange-500 !important;
			transition: 0.4s;
		}
	}
}


// #sideNav {
//   position: fixed;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   min-height: 100%;
//   width: 70px;
//   top: 0;
//   left: 0;
//   background-color: #FFF;
//   text-align: center;
//   box-shadow: 0px 2px 12px #0000003E;
//   z-index: 1001;
//   transition: .7s;
// }
// [dir="rtl"] #sideNav {
//   right: 0;
//   text-align: right;
// }

// #sideNav.opend {
//   width: $opendNav;
//   max-width: $opendNavMax;
// }
// .sidenav_list {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   width: 100%;
//   text-align: left;
// }

// .sidenav_header {
//   margin-top: 2.5rem;
//   margin-bottom: 1rem;
//   font-weight: bold;
//   font-size: 1rem;
//   padding: 0 1rem;
//   transition: .4s;
//   transition-delay: .2s;
//   text-align: start;
// }

// .sidenav_item {
//   position: relative;
//   display: block;
// }

// .sidenav_link {
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   width: 100%;
//   height: 50px;
//   margin: .25rem 0;
//   color: $gray-900;
// }

// .sidenav_item .dash-text{
//   margin-inline-start: 1rem;
//   color: $gray-900;
// }

// .sidenav_icon {
//   flex: 0 0 22px;
//   width: 22px;
// }

// .sidenav_icon svg,
// .sidenav_arrow svg{
//   width: 100%;
//   fill: $gray-900;
// }

// .sidenav_arrow svg  path {
//   stroke: $gray-900;
// }

// .sidenav_arrow {
//   position: absolute;
//   inset-inline-end: 10px;
//   width: 7px !important;
// }
// [dir="rtl"] .sidenav_arrow  {
//   transform: scale(-1, 1);
// }

// .dash-text {
//   // display: none;
//   visibility: hidden;
//   opacity: 0;
//   width: 0;
//   transition: .4s;
//   font-weight: 700;
// }

//  [dir="rtl"] .sidenav_link .sidenav_dropdown .dash-text{
//   text-align: start !important;
//  }

// ///////////////////////////////////// if the nav opend  ///////////////////////////////////////////
// .opend .sidenav_link {
//   justify-content: flex-start;
// }
// .opend .sidenav_link {
//   padding: 1rem;
// }
// .opend .dash-text {
//   // display: block;
//   visibility: visible;
//   opacity: 1;
//   width: auto;
//   transition: .4s;
//   transition-delay: .2s;
// }
// .opend .sidenav_link:hover,
// .opend .sidenav_link:hover svg,
// .opend .sidenav_link:hover .dash-text {
//   color: $orange-500;
//   fill: $orange-500 !important;
//   transition: .4s;
//   transition-delay: .2s;
// }
// .opend .sidenav_link:hover .sidenav_arrow svg  path {
//   stroke: $orange-500;
//   transition: .4s;
//   transition-delay: .2s;
// }
// ///////////////////////////////////// if the nav closed  ///////////////////////////////////////////
// .closed .sidenav_dropdown,
// .closed .sidenav_arrow  {
//   // display: none;
//   visibility: hidden;
//   opacity: 0;
//   width: 0;
// }

// .closed .sidenav_item:hover {
//   width: $opendNav;
// }
// .closed .sidenav_link {
//   padding-inline-start: 20px;
// }
// .closed .sidenav_link:hover .sidenav_dropdown {
//   position: absolute;
//   display: flex;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   background-color: #fff;
//   box-shadow: 0px 2px 12px #0000003E;
//   left: 0;
//   z-index: -1;

//   visibility: visible;
//   opacity: 1;
//   width: 100%;
// }
// .closed .sidenav_link:hover .dash-text {
//   // display: block;
//   visibility: visible;
//   opacity: 1;
//   padding: 0 2.5rem;
//   width: 100%;
//   transition: none;
// }

// .closed .sidenav_link:hover .sidenav_arrow {
//   // display: block;
//   visibility: visible;
//   opacity: 1;
//   width: auto;
// }
// .closed .sidenav_header {
//   text-align: center;
// }
// // active
// .sidenav_link.active {
//   position: relative;
// }
// .sidenav_link.active::after {
//   content: "";
//   position: absolute;
//   height: 70%;
//   top: calc(50% + 3px) ;
//   transform: translateY(-50%);
//   background-color: $orange-500;
//   width: 3px;
//   inset-inline-end: 0;
// }
// .sidenav_link.active ,
// .sidenav_link.active svg,
// .sidenav_link.active .dash-text {
//   color: $orange-500;
//   fill: $orange-500 !important;
// }
// .sidenav_link.active .sidenav_arrow svg path{
//   stroke: $orange-500;
// }

.main-nav-container {
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 8px 16px;
	background-color: #112233;
	color: #fff;
	overflow-x: overlay;
	.item {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 6px 16px;
		color: #fff;
		cursor: pointer;
		text-transform: capitalize;
		text-wrap: nowrap;
		&.active {
			border-radius: 4px;
			background-color: #D4A655;
			color: #fff;
		}
	}
}

.subnav-contianer {
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 16px 36px 0;
	background-color: #fff;
	color: #fff;
	border-bottom: 1px solid #DDDCDC;
	text-transform: capitalize;
		overflow-x: overlay;
	.sub-item {
		display: block;
		padding-bottom: 12px;
		color: #050001;
		text-transform: capitalize;
		text-wrap: nowrap;
		border-bottom: 3px solid transparent;

		&.active {
			display: block;
			color: #D4A655;
			border-bottom: 3px solid #D4A655;
		}
	}
}

