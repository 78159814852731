.title-page {
	border-left: 4px solid #cc9a50;
	display: flex;
	padding: 0px 0px 0px 10px;
	font-size: 22px;
	margin: 20px 0px;
	align-items: center;
	.bold-sup {
		font-weight: 700;
		color: #cc9a50;
		margin-left: 5px;
	}
}
.Subscription-box {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 15px;

	.Subscription_Item {
		background-color: #ffffff;
		box-shadow: 0px 0px 2px #00000029;
		border-radius: 5px;
		width: 350px;
		height: 575px;
		.sub-header {
			padding: 20px;
			border-left: #cc9a50 7px solid;
			border-radius: 6px;
			margin: 20px 0px 10px 0px;
			.sub-name {
			}
			.sub-price {
				color: #cc9a50;
				font-size: x-large;
				font-weight: 700;
				.currence {
					font-size: 14px;
					font-weight: 400;
				}
			}
		}
		.sub-body {
			padding: 0px 20px 20px 20px;
		}
		.det-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #434343;
			border-bottom: 1px solid #e5e5e5;
			padding: 2px 0px;
		}
		.note_box {
			background-color: #d29d4d15;
			padding: 5px;
			border-radius: 5px;
			font-size: 12px;
			margin: 20px 0px;

			.warning-text {
				color: #8e5806;
				font-weight: 700;
			}
		}
		.Suscribe-btn {
			background-color: #fff;
			border: 1px solid #cc9a50;
			color: #cc9a50;
			font-size: 18px;
			font-weight: 700;
			padding: 6px 0px;
		}
		.Suscribe-btn:hover {
			background-color: #cc9a50;
			color: #ffffff;
		}
	}
}

.boxs-payment {
	display: flex;
	flex-direction: column;
	gap: 10px;

	.item-payment {
		display: flex;
		flex-direction: column;
		border: 1px solid #ebebeb;
		border-radius: 6px;
		padding: 10px 10px;
		.raido-label {
			display: flex;
			align-items: center;
			input {
				accent-color: #c37500;
				width: 20px;
				margin-inline-end: 15px;
			}
			label {
				font-weight: bold;
				font-size: 14px;
				margin-bottom: 0px;
			}
		}
	}
	.pay-active {
		border: 1px solid #cb9a51;
		background-color: #f7f1e9;
	}
	.newpay-btn {
		background-color: #d29d4d;
		border-radius: 8px;
		color: #fff;
		padding: 10px 5px;
		font-size: large;
	}
}
.fake-select {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	position: relative;
	background-color: #ffffff;
	border-radius: 5px;
	height: 48px;
	border: 1px solid #ebebeb;
	.amount {
		background-color: #3b4b5d;
		color: #fff;
		padding: 0px 5px;
		border-radius: 30px;
	}

}
.drop-select {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background-color: #ffffff;
	border-bottom: 1px solid #ebebeb;
	border-radius: 5px;
	.amount {
		background-color: #3b4b5d;
		color: #fff;
		padding: 5px 5px;
		border-radius: 30px;
	}
}
.dropdown-list{
	position: absolute;
	bottom: -47px;
	left: -47px;
	width: 345px;
}