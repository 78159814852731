.market-search {
	display: flex !important;
	justify-content: center !important;
	align-items: flex-start !important;
	background: $gray-100;
	//height: 100vh;

	.button-collapse {
		height: 44px !important;
		background: #fff;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		// background-color: #fff !important;
		// color: #cb9a51 !important;
		background: rgb(222, 162, 55);
		background: $main-bg-sbs;
		color: #fff;
		border: none !important;
		font-weight: 600 !important;
	}
	.heading,
	.tab-content,
	.nav-tabs {
		width: 100%;
		margin: 0;
	}
	.nav-tabs {
		background-color: #ebebeb;
	}
	.mybooking-input-wrapper {
		margin-bottom: 0.5rem;
		.fa-plane {
			transform: translate(25%, 0%) rotate(-45deg);
		}
	}

	.main-tabs {
		// min-height: 51vh;
		.main-nav {
			.nav-item {
				min-width: auto;
				min-height: 59px;
				position: relative;
				margin: 0;
				background: #cbcbcb;
			}
		}
	}

	.form-group {
		// margin-bottom: 0px !important;
		position: relative;

		.form-control-custom-input {
			height: 60px !important;
			padding: 15px 10px 0px 40px;
			border-radius: 0px;
			border: 0px;
			font-weight: bold;
			font-size: $text-caption;
			cursor: pointer;
		}

		.booking-label {
			position: absolute;
			top: 10px;
			// #{$left}: 45px;
			left: 45px;
			font-size: 11px;
			color: #acacac;
			[dir="rtl"] & {
				left: auto;
				right: 45px;
			}
		}

		.fa-calendar {
			@extend %fa-icons-style;
		}

		.fa-suitcase {
			@extend %fa-icons-style;
		}

		.fa-id-card {
			@extend %fa-icons-style;
		}

		i {
			@extend %fa-icons-style;
		}
	}

	%fa-icons-style {
		position: absolute;
		left: 2px;
		top: 35%;
		margin: 0px 0px 0px 5px;
		font-size: 20px;
		color: $gray-300;
		[dir="rtl"] & {
			left: auto;
			right: 0;
		}
	}

	.global-input {
		height: auto !important;
		padding: 15px !important;
		color: #354f5c;
		border-radius: 0px;
		border: 0px;
		font-weight: bold;
		font-size: 15px;
		cursor: pointer;
		width: 100%;
		border: 1px solid #dee2e6;
	}
}

.transport-market-search {
	align-items: stretch !important;
}

.collapse-content {
	display: flex;
	justify-content: space-around;
	align-items: center;
	.room-collapse {
		display: flex;
		justify-content: space-around;
		margin-bottom: 1rem;
		span {
			width: 33.333%;
			text-align: center;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.full-input {
		width: 70px;
	}
}

.input-border {
	border: 1px solid #dee2e6;
}

.market-search-conatainer {
	position: relative;
	.main-tabs1 {
		.main-nav1 {
			//left: 10%;
			display: flex;
			flex-direction: column;

			.nav-item {
				a {
					background-color: #fff;
					color: #324356;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					border-radius: 4px;
					border-color: #d8d8d8;
					height: 124px;
					width: 154px;
					transition: 0.5s;
					margin-right: 10px;
					i {
						display: none;
					}
				}
			}

			.nav-item {
				a.active {
					background-color: #faf2e8;
					color: #cb9a51;
					position: relative;
					display: inline-grid;
					align-items: center;
					justify-content: center;
					border-color: #cb9a51;
					z-index: 151;
					padding: 10px 10px;
					transition: 0.5s;
					margin-right: 10px;
					[dir="rtl"] & {
						margin-left: 10px;
					}
					p {
						font-weight: 700;
					}
					i {
						display: block;
						position: absolute;
						right: -6px;
						top: 10px;
						font-size: 16px;
						[dir="rtl"] & {
							right: unset;
							left: -6px;
							transform: rotate(180deg) !important;
						}
					}
				}
			}
		}
	}
}
.market-search-conatainer .main-tabs1 .main-nav1 .nav-item a.active {
	text-align: center;
}

.nav-tabs .nav-item.show .nav-link::after,
.nav-tabs .nav-link.active::after {
	display: none !important;
}

.search-conatiner {
	padding: 25px;
	background-color: red;
}
// .tab-content {
// 	background: white;
// 	padding: 15px;
// }
// .nav-tabs {
// 	background: white;
// 	padding: 15px;
// 	margin: 0rem auto !important;
// }

.market-search-conatainer .main-tabs1 .main-nav-contant {
	height: 100% !important;
}
.main-tabs {
	padding: 0.5rem;
	background: #fff;
	border-radius: 8px !important;
	border: 1px solid #dedede;
}
.main-tabs .main-nav .nav-item .nav-link {
	/* border: none; */
	// color: #324356;
	color: #343434;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 59px;
	min-width: 169px;
	border: 1px solid #dedede;
}
.search-title {
	font-size: 20px;
	font-weight: 700;
	color: #cb9a51;
}
.vacation-content.sub-tabs-vacation {
	.sub-tabs-content {
		background-color: #ebebeb;
		border-radius: 3px;

		.TAPactive {
			width: 50%;
			padding: 7px 5px;
			color: #979797;
			cursor: pointer;
			text-align: center;
		}
		.TAPactive.active {
			background-color: #faf2e8;
			color: #cb9a51;
			border-radius: 3px;
			font-weight: 600;
		}
	}
}
// .k-market-search-container {
// 	font-family: "Almarai", sans-serif!important;
// }
.k-market-search-container .soon {
	background: rgb(222, 162, 55);
	background: $main-bg-sbs;
	padding: 2px 10px;
	font-size: 12px;
	position: absolute;
	top: 5px;
	inset-inline-start: 5px;
	color: #fff;
	border-radius: 5px;
}
.k-market-search-container .market-search {
	border-radius: 10px;
}
.k-market-search-container .main-tabs1 .main-nav1 .nav-item a.active {
	background-color: transparent;
	background: rgb(222, 162, 55);
	background: $main-bg-sbs;
	color: #fff;
	background-repeat: no-repeat;
	background-size: contain;
}
.k-market-search-container .main-tabs1 .main-nav1 .nav-item a.active i {
	color: #ca7327;
}
.k-market-search-container .main-tabs1 .main-nav1 .nav-item a {
	border-radius: 10px;
}
.k-market-search-container .vacation-content.sub-tabs-vacation {
	.sub-tabs-content {
		background-color: #ebebeb;
		border-radius: 3px;
		margin-bottom: 0.5rem;

		.TAPactive {
			color: #343434;
			text-transform: uppercase;
		}
		.TAPactive.active {
			background: rgb(222, 162, 55);
			background: $main-bg-sbs;
			color: #fff;
		}
	}
}
.k-market-search-container
	.custom-control-input:checked
	~ .custom-control-label::before,
.k-market-search-container .custom-control-input:focus {
	background-color: rgb(222, 162, 55) !important;
	border-color: rgb(222, 162, 55) !important;
	box-shadow: none;
	outline: none;
}
.marketsearch-tours-modify [class*="col-"] {
	max-width: 100%;
	flex: 100% 0 0;
}
.marketsearch-tours-modify .adults,
.marketsearch-tours-modify .chidlren {
	max-width: 50%;
	flex: 50% 0 0;
}
.marketsearch-tours-modify .children_ages [class*="col-"] {
	max-width: 20%;
	flex: 20% 0 0;
}
[dir="rtl"] .marketsearch-tours-modify .children_ages {
	text-align: right;
}

.visa_search_result span {
	color: #28a745;
	font-weight: bold;
	font-size: 18px;
}
[dir="rtl"] .edit-passenger-arrow {
	transform: scale(-1, 1);
}
.visa-destinations-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	min-height: 550px;
	padding: 3rem 12px;
}

.visa-destinations {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
}
.visa-destinations-services{
	justify-content: center !important
}

.visa-destination-card {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 240px;
	border-radius: 10px;
	flex: 1;
	min-width: calc(50% - 20px);
	max-width: 50%;
	padding: 1rem;
	color: #fff;
	overflow: hidden;
	cursor: pointer;
}
.visa-destination-services{
	background-color: #fff;
	border:1px solid #D0D0D0;
	height: auto !important;
	display: flex;
	justify-content: center;
	// align-items: center;
	border-radius: 0;
	min-width: calc(30% - 20px);
	max-width: 30%;
	padding: .5rem .7rem;
	h5{
		color:#000;
	}

}
.visa-filter-collapse-btn{
	display: none;
}
.visa-filter-collapse{
	display: flex;
}
.visa-filter-collapse-btn-container{
	display: flex;
}
.visa-destination-services.active{
	border:1px solid #D29D4D;
	box-shadow: 0px 0px 6px #D29D4D4A;

	.visa-radio-input {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 2px solid #D29D4D !important;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.visa-radio-input.active::after {
		content: "";
	}
	
	.visa-radio-input::after {
		position: absolute;
		width: 14px;
		height: 14px;
		background-color: #D29D4D;
		border-radius: 50%;
	}

}
.visa-selected-container .visa-destination-card {
	height: 130px;
	flex: 0;
	min-width: none;
	max-width: none;
}

.visa-radio-input {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 2px solid #ffffff !important;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.visa-radio-input.active::after {
	content: "";
}

.visa-radio-input::after {
	position: absolute;
	width: 14px;
	height: 14px;
	background-color: white;
	border-radius: 50%;
}
.visa-radio-selected {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 2px solid white !important;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.visa-radio-selected.active::after {
	content: "";
}
.visa-radio-selected.active{
		border: 2px solid #c37500 !important;

}
.visa-radio-selected::after {
	position: absolute;
	width: 14px;
	height: 14px;
	background-color: #c37500;
	border-radius: 50%;
}

.visa-card-header {
	font-size: 1rem;
	font-weight: 700;
	// text-shadow: 0px 3px 6px #00000083;
}
.visa-card-content {
	position: absolute;
	width: max-content;
	display: flex;
	flex-direction: column;
	bottom: 0;
	inset-inline-end: 10px;
}
.visa-card-start-price {
	font-size: 1.4rem;
	font-weight: 900;
	color: #d19d4e;
}

.visa-container .btn-white-border-gray {
	color: #666666;
	font-size: 14px;
}

.visa-types-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 15px;
	// margin-top: 1rem;
	font-size: 100%;
	align-items: stretch;
}

.visa-type-card {
	position: relative;
	border-radius: 10px;
	flex: 1;
	min-width: calc(25% - 20px);
	max-width: calc(25% - 20px);
	padding: 1rem 0;
	overflow: hidden;
	z-index: 1;
}
.visa-type-content {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	z-index: 2;
	height: 100%;
}

.visa-type-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	mix-blend-mode: luminosity;
	border: 1px solid #cbcbcb;
	opacity: 0.1;
	z-index: 0;
}

.visa-type-card .control-field,
.visa-type-card .control-field select:not(:checked) {
	height: auto;
	background-color: #0c3b5c;
	color: #fff !important;
	border-radius: 6px;
	padding: 0 1px;
	font-size: 1rem;
}
.visa-type-card .control-field select option {
	color: #0c3b5c;
	background-color: #fff;
}
.visa-type-card .control-field small {
	display: none !important;
}

.visa-type-time {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px 0;
	background-color: #fff;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	font-size: 0.9rem;
	line-height: 1rem;
}

.visa-type-price {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	font-size: 0.9rem;
	line-height: 0.8rem;
	background-color: #0c3b5c;
	padding: .7em;
	box-shadow: 0px 3px 6px #00000029;
}
.visa-type-pax {
	background-color: white;
	padding: 8px 12px;
	border-radius: 4px;
	font-weight: bold;
}
.visa-type-pax-services{
	background-color: #F3F3F3;
	color: #000000;
	font-size: 14px;
	padding: 5px;
  border-radius: 7px;

}
.select-visa-btn {
	width: 100%;
	background-color: #fff;
	border: 1px solid #d29d4d;
	border-radius: 3px;
	color: #d19d4e;
	margin-top: auto;
}

.select-visa-btn:hover {
	width: 100%;
	background-color: #d29d4d;
	border: 1px solid #d29d4d;
	border-radius: 3px;
	color: #fff;
	margin-top: auto;
}
.filter-check-box {
	background-color: #fff;
   
    -webkit-margin-end: 8px;
    margin-inline-end: 8px;
    -webkit-padding-end: 8px;
    padding-inline-end: 8px;
    padding-block: 8px;
    border-radius: 4px;
		    padding-right: 1.7rem;
				padding-left: 10px !important;
				.custom-control-label::before {
					left: unset;
					right:  -1.2rem
				}
				.custom-control-label::after{
					left: unset;
					right:  -1.2rem
				}

		
}
.visa-details-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	min-height: 205px;
	padding: 10px 15px;
	// margin-top: -50px;
	color: #fff;
}
[dir="rtl"] .visa-info-container .arrow-icon {
	transform: scale(-1, -1);
}
.visa-info-container .bg-gray {
	font-size: 16px !important;
	line-height: 18px !important;
	height: 100%;
}

.visa-include-container .bg-gray {
	font-size: 16px !important;
}

.text-brown {
	color: #69614f !important;
}

.orange-color {
	color: #d19d4e;
}

.passport-image::before {
	content: "";
	position: absolute;
	width: 80%;
	height: 13%;
	background: #006c0066;
	inset-block-end: 4%;
	inset-inline-start: 10%;
}

.mrz-warning {
	display: flex;
	border: 1px solid #eeaa17;
	background-color: rgb(238 170 23 / 23%);
	border-radius: 4px;
	padding: 10px;
	margin-bottom: 8px;
	align-items: center;
}

.residence-section {
	background-color: #e5e5e5;
	padding: 1.5em;
	margin: 0;
}
.upload-residence {
	border: 1px dashed #eeaa17;
	background-color: rgba(238, 170, 23, 0.153);
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 4px;

}
.upload-residence-button{
	padding: 5px 16px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.border-danger-1 {
	border: 1px solid red !important;
}
.border-danger {
	border: 1px solid red !important;
}
@media (max-width: 992px) {
	.visa-type-card {
		min-width: calc(50% - 5px);
	}
}
@media (max-width: 767px) {
.visa-filter-collapse-btn-container{
	width: 100%;
	display: flex;
	align-items: center;
	h5{
		flex: 1 1 auto;
	}
}
	.visa-filter-collapse-btn{
		display: block;
	}
	.visa-filter-collapse{
		display: flex;
		flex-direction: column;
	}
	.visa-destination-card {
		min-width: calc(100% - 20px);
	}
	.visa-type-card {
		min-width: calc(100% - 5px);
	}
}
