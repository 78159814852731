.nav-deals{
  position: fixed;
  width: 100%;
  top: 0px;
  background-color: #fff;
  z-index: 11;
  height: 80px;
}
.btn-type{
  font-size: 16px;
}
.btn-type:hover{
  font-size: 16px;
  border-bottom: 2px solid #CD9B51;
  border-radius: 0px;
  font-weight: bold;
  color: #CD9B51;
}
.btn-agent{
  background-color: #CD9B51;
  color: #fff;
  font-size: 16px;
}