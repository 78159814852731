@media screen and (max-width: 1220px) {
	.crm-wrapper .filter-wrapper .filter-row {
		flex-direction: column;
		gap: 12px;
	}
}


@media screen and (max-width: 992px) {
	.crm-wrapper .actions-wrapper {
		flex-direction: column;
		justify-content: space-between;
		gap: 1rem;
		align-items: flex-start;

		.crm-wrapper .actions-wrapper .items-wrapper {
			justify-content: center;
			width: 100%;
		}
	}

	.flex-wrap-custom {
		flex-wrap: wrap !important;
	}

	-w12 {
		width: 50% !important;
	}
}

@media screen and (max-width: 767px) {
	.crm-wrapper .filter-wrapper .filter-row .filter-items .control-field {
		width: auto;
	}

	.w-350 {
		svg {
			display: none;
		}
	}
}

@media screen and (max-width: 640px) {
	.crm-wrapper .actions-wrapper .items-wrapper .item {
		flex-wrap: wrap;
		flex-basis: 50%;
	}
	.crm-wrapper .filter-wrapper .filter-row .item {
		padding: 0.5rem;
	}
}