.service-builder-holder {
	background: #fff;
	border-radius: 8px;
	padding: 24px;

	@media screen and (max-width: 768px) {
		padding: 12px;
	}

	.header-holder {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 16px;

		.header-title {
			font-weight: 700;
			font-size: 24px;
		}

		.service-action-btn {
			background-color: #d4a655;
			padding: 0px 20px;
			height: 46px;
			border: none;
			color: #fff;
			font-weight: 700;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;
		}
	}

	.filter-inputs-holder {
		display: flex;
		align-items: center;
		gap: 12px;

		.clear-btn {
			height: 44px;
			margin-bottom: 4px;
			align-self: end;
			background-color: #eed9ba;
			color: #6a4d1b;
			border: 1px solid #eed9ba;
			border-radius: 6px;
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 18px;
			font-weight: 600;
		}

		.control-field__input {
			position: absolute !important;
		}
	}

	.filter-tags-holder {
		display: flex;
		align-items: end;
		flex-wrap: wrap;
		gap: 10px;
		height: 100%;

		.tag {
			padding: 10px 16px;
			border-radius: 40px;
			border: 1px solid #dddcdc;
			font-size: 18px;
			font-weight: 600;
			color: #707170;
			cursor: pointer;
		}

		.active-tag {
			@extend .tag;
			background-color: #faf4eb;
			border: 1px solid #d4a655;
			color: #d4a655;
			cursor: pointer;
		}
	}

	.main-tabs-holder {
		display: flex;
		justify-content: flex-start;
		gap: 8px;
		flex-wrap: wrap;
		padding-bottom: 16px;
		border-bottom: 1px solid #f7f6f6;

		.tab-box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 80px;
			border: 1px solid #eeeeee;
			border-radius: 6px;
			padding: 8px;
			cursor: pointer;
		}

		.active-tab-box {
			@extend .tab-box;
			background: #d4a65514;
			border: 1px solid #d4a655;
		}

		.tab {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
		}
	}

	.services-list-holder {
		.overlay-text {
			font-weight: 600;
			color: #ea5455;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
		}

		td p {
			font-size: 16px;
			max-width: 200px;
			overflow-wrap: break-word;
		}

		.langs-holder {
			display: flex;
			align-items: center;
			gap: 8px;
			flex-wrap: wrap;
			max-width: 200px;

			.lang-item {
				padding: 4px 10px 4px 10px;
				border-radius: 4px;
				background: #f5f5f5;
				color: #050001;
				font-size: 15px;
				font-weight: 600;
			}
		}
	}

	.form-title {
		font-size: 18px;
		font-weight: 700;
		margin-top: 24px;
		margin-bottom: 16px;
	}

	.form-holder {
		padding: 12px;
		background-color: #fafafa;
		border: 1px solid #f0f0f0;
		border-radius: 8px;
		align-items: center;

		.fix_place_arrow {
			right: 0px;
		}

		.no-gutters {
			height: 100%;
		}

		.images-holder {
			.service-img {
				width: 120px;
				height: 120px;
				border-radius: 6px;
			}

			.upload-button {
				width: 110px;
				height: 110px;
			}

			.remove-img,
			.close-icon {
				display: flex;
				position: absolute;
				right: 0px;
				top: 0px;
				background: #11223378;
				color: #ffffff;
				border-radius: 0px 6px 0px 6px;
				padding: 6px;
				cursor: pointer;
			}

			.close-icon svg path {
				stroke: #fff;
			}

			.upload-file-component {
				flex-grow: 1;
			}

			.upload-file-label {
				font-weight: 500;
				font-size: 14px;
				color: #c8c8c8 !important;
			}

			.upload-file-component-input {
				position: relative;
				justify-content: flex-start;
				padding: 16px;
				gap: 16px;
				border-radius: 6px;
				border: 1px solid #eeeeee;
				background: #ffffff;
			}

			.uploaded-image-preview {
				position: relative;
				width: 110px;
				height: 110px;
			}
		}

		.tag {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #f3f3f3;
			padding: 4px 6px 4px 6px;
			gap: 10px;
			border-radius: 3px;
		}

		.tour-tab {
			height: 48px;
			padding: 12px 16px;
			gap: 8px;
			border-radius: 4px;
			border: 1px solid #7071701a;
			background: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			font-size: 16px;
			font-weight: 600;
			color: #707170;
		}

		.active-tour-tab {
			@extend .tour-tab;
			background: #d4a65514;
			border: 1px solid #d4a655;
			color: #d4a655;
		}

		.control-field__body {
			.control-field {
				border: none;
				height: 100% !important;
				padding: 11px !important;
				border: 1px solid #ebebeb;
				margin: 0px !important;
			}
		}

		.control-field__label,
		.inner-label {
			font-size: 14px !important;
			color: #c8c8c8 !important;
			// padding: 8px 8px 0px;
			font-weight: 500 !important;
			line-height: 15px;
		}

		.custom-field {
			.control-field__body {
				// background-color: #ffffff;
				// border: 1px solid #ebebeb;
				border-radius: 0.3125rem;
				height: 100% !important;
				font-size: 12px;

				.custom-control-field {
					border: none !important;
				}

				.control-field__label {
					font-size: 14px;
					color: #c8c8c8;
					padding: 8px 8px 0px;
				}

				.control-field {
					padding: 4px 8px !important;
					// border: none;
					border: 1px solid #ebebeb;
					height: 100%;
					margin: 0px !important;
				}

				.control-field__feedback.error-message {
					padding: 0px 8px !important;
				}

				// .control-field--danger {
				// 	// border: none !important;
				// }
			}
		}

		.error-field {
			.control-field__body {
				border-color: #ff5f59 !important;
			}
		}

		.custom-height {
			height: 57px;
		}

		.violation-cost-input {
			height: 96%;
		}

		.control-field {
			height: 100% !important;
			border: none;
			margin: 0px !important;
			border: 1px solid #ebebeb;
		}

		.control-field__feedback.error-message {
			padding: 0px 8px !important;
			margin-top: 3px !important;
		}

		.fix_place_arrow {
			display: none;
		}

		.align-self-baseline {
			label.control-field__label {
				padding: 0px 8px !important;
			}

			// to edit select filed in refundable input hotel form
			&> :first-child {
				height: 100% !important;

				:first-child {
					height: 100% !important;

					:first-child {
						height: 100% !important;
					}
				}
			}
		}

		.langs-holder {
			display: flex;
			gap: 8px;
			align-items: center;
			margin: 12px 0px;

			.lang-item {
				padding: 8px;
				border-radius: 4px;
				color: #354f7e99;
				background: #2541751a;
				cursor: pointer;
			}

			.active-lang {
				@extend .lang-item;
				background: #d4a655;
				color: #fff;
				font-weight: 700;
			}

			.translate-btn {
				margin-left: auto;
				outline: none;
				border-radius: 6px;
				border: 1px solid #d4a655;
				padding: 9px 12px;
				display: flex;
				gap: 6px;
				align-items: center;
				background-color: #fff;
				color: #254175;
			}
		}
	}

	.submit-btn {
		background: #d4a655;
		width: 185px;
		border-radius: 6px;
		height: 48px;
		color: white;
		border: none;
	}

	.pricing-holder {
		.taxes-holder {
			@extend .form-holder;
			border-radius: 3px;
			margin: 0px !important;

			.control-field {
				margin: 0px 0px !important;
			}
		}

		.taxes-type {
			background-color: #f7f6f6;
			border: 1px solid #7071701a;
			padding: 10px 16px 10px 16px;
			gap: 8px;
			border-radius: 4px;
			margin-bottom: 0px !important;
			cursor: pointer;

			.taxes-type-value {
				color: #707170;
			}
		}

		.taxes-currency {
			padding: 0px 12px;
			border-left: 1px solid #7071701a;
			color: #ababab;

			[dir="rtl"] & {
				border-right: 1px solid #7071701a;
				border-left: unset;
			}
		}

		.price-inputs-holder {
			padding: 16px;
			gap: 16px;
			border-radius: 3px;
			border: 1px solid;
			background: #fafafa;
			border: 1px solid #f0f0f0;

			.inner-label {
				font-size: 12px;
				color: #c8c8c8;
				font-weight: 500 !important;
			}
		}
	}

	.add_btn {
		border: none;
		background-color: transparent;
		color: #d4a655;
		display: flex;
		justify-content: center;
		gap: 6px;
		font-size: 14px;
		font-weight: 700;
	}

	.next-btn {
		@extend .submit-btn;
		background-color: #fff;
		border: 1px solid #d4a655;
		color: #d4a655;
	}

	.collapse-title {
		font-size: 18px;
		font-weight: 700;
		color: #707170;
	}

	.violation-cost-select {
		position: absolute;
		top: 0;
		right: 6px;
		width: 70px;

		[dir="rtl"] & {
			right: unset !important;
			left: 12px !important;
		}

		div .control-field {
			margin: 0px 0px !important;
		}

		.violation-cost-wrapper,
		.col-12 {
			padding-left: 0px !important;
			padding-right: 0px !important;
			// [dir="rtl"] & {
			// 	.violation-cost-input input {
			// 		// padding-inline-end: 60px;
			// 		padding-right: unset;
			// 	}
			// }
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		accent-color: #d4a655 !important;
		width: 16px;
		height: 16px;
	}

	input[type="radio"] {
		/* remove standard background appearance */
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		/* create custom radiobutton appearance */
		display: inline-block;
		width: 20px;
		height: 20px;
		/* background-color only for content */
		border: 1px solid #bcbcbc;
		background-clip: content-box;
		background-color: white;
		border-radius: 50%;
	}

	/* appearance for checked radiobutton */
	input[type="radio"]:checked {
		background-color: #d4a655;
		border: 1px solid #d4a655;
		padding: 4px;
	}

	// MultiTagsTextField
	.control-field-wrapper {
		background-color: #ffffff;
		border: 1px solid #ebebeb;
		border-radius: 0.3125rem;
		font-size: 12px;
		position: relative;

		.control-field {
			@extend .control-field-wrapper;
			border: none;
			height: auto !important;
		}

		.control-field__label {
			padding: 3px 10px;
			font-size: 14px;
		}

		.add-new-btn {
			position: absolute;
			top: 50%;
			right: 12px;
			transform: translateY(-50%);
			cursor: pointer;

			[dir="rtl"] & {
				right: unset !important;
				left: 12px !important;
			}
		}
	}

	.sub-tabs-holder {
		gap: 16px;
		// padding: 20px 0px;
		border-bottom: 1px solid #dddcdc;

		.sub-tab {
			padding: 22px 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			color: #707170;
			font-size: 18px;
			font-weight: 600;
		}

		.active-sub-tab {
			@extend .sub-tab;
			border-bottom: 2px solid #d2ab67;
			color: #d2ab67;

			span svg path {
				stroke: #d2ab67;
			}
		}
	}

	.submit-addNew-btn {
		@extend .submit-btn;
		background-color: #fff;
		border: 1px solid #d4a655;
		color: #d4a655;
	}

	.title {
		font-weight: 700;
		font-size: 24px;
	}

	.tag-v2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #f3f3f3;
		padding: 4px 6px 4px 6px;
		gap: 10px;
		border-radius: 3px;
		background: #eeeeee !important;
		overflow-wrap: anywhere;
	}

	.service-info {
		padding: 12px;
		border-radius: 6px;
		background: #eeeeee;
		font-size: 17px;
		font-weight: 500;
		color: #050001;
		overflow-wrap: break-word;
	}

	// .custom-editor.quill {
	// 	background: white !important;
	// 	border: 1px solid #ebebeb  ;
	// 	border-radius: 8px !important;

	// 	.ql-toolbar.ql-snow {
	// 		border: 1px solid #ebebeb !important;
	// 		border-radius: 8px 8px 0px 0px;
	// 	}

	// 	.ql-container.ql-snow {
	// 		border: none !important;
	// 	}
	// }

	// .custom-editor.quill.error {
	// 	border: 1px solid #ff5f59 !important
	// }

	// .custom-editor.quill.focus {
	// 	border: 1px solid #d4a655 !important
	// }
}

.service-translate-holder {
	background: #fafafa !important;
}