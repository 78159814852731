.booking-engine {
  margin-top: -32px;
  margin-inline: -32px;
  
  .booking-engine-description-part {
    width: 100%;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .description-part {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 44px 100px;
    background-color: #FFF;
  }
  .description-text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .title{
      font-size: 44px;
      font-weight: 700;
      color: #D4A655;
    }
    .outline-btn {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 16px;
      border: 1px solid #D4A655;
      color: #D4A655;
    }
    .filled-btn {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      background-color: #D4A655;
      color: #fff;
    }
    
  }
  .system-screens {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(295px,1fr));
    padding: 10px 100px 40px 50px;
    transition: transform 2s;
  }
  .toggle-btn {
    width: fit-content;
    color: #D4A655;
    text-decoration: underline;
    display: flex;
    gap: 10px;
    align-items: center;
    border: none;
    font-size: 18px;
    background-color: #FFF;
  }
  .package-include {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 40px 80px 40px 80px;
    justify-content: center;
    .title{
      font-size: 32px;
      font-weight: 700;
      text-align: center;
    }
    .package-list-items{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
      gap: 20px;
    }
    .package-item{
      display: flex;
      gap: 25px;
      align-items: center;
      padding: 16px 20px;
      background-color: #FFFFFF;
      border-radius: 8px;
      position: relative;
      min-width: 305px;
      .item-name{
        font-size: 18px;
        font-weight: 500;
      }
      svg {
        width: 52px;
        height: 62px;
      }
      .hexagon-alt-wrapper svg {
        width: 34px;
        height: 34px;
        path {
          stroke: #D4A655;
        }
      }
      .plan-status {
        border-radius: 0px 8px 0px 8px;
        padding: 8px 12px 8px 12px;
        background-color: #C4EAD6;
        color: #019D4C;
        font-size: 12px;
        font-weight: 500;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  
  .package-features {
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 44px 80px 44px 80px;
    @media screen and (max-width: 1024px) {
      padding-right: 30px;
      padding-left: 30px;
    }
    gap: 2rem;
    .title{
      font-size: 32px;
      font-weight: 700;
    }
    .all-fetaures{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .feature {
      display: flex;
      gap: 16px;
      margin-bottom: 30px;
      svg {
        min-width: 52px;
      }
    }
    .feature-signification {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
      // width: 90%;
    }
    .feature-name {
      font-size: 20px;
      font-weight: 700;
    }

    .feature-description {
      font-size: 16px;
      font-weight: 400;
    }
    
    .subscribe{
      display: flex;
      align-items: center;
      gap: 20px;
      // width: 88%;
      background-image: url("../../../assets/images/dashboard/hero-tech-wave.png");
      background-position: 90%;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #112233;
      padding: 60px 40px 60px 40px;
      border-radius: 8px;
      .subscribe-content{
        padding-left: 80px;
        width: 79%;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .title{
        font-size: 42px;
        font-weight: 700;
        color: #D4A655;
      }
      .sales-text{
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
      }
      .subscribe-btn{
        font-weight: 500;
        font-size: 20px;
        background-color: #D4A655;
        color: #fff;
        padding: 10px 20px;
        border-radius: 8px;
      }
    }
  }

  .website_features_section{
    position: relative;
    padding: 44px 30px 44px 30px;
    @media screen and (max-width: 767px) {
      padding-right: 12px;
      padding-left: 12px;
    }
    .controlled_col{
      padding-inline-start: 4rem;
      @media screen and (max-width: 767px) {
        padding-inline-start: 1rem;
      }
    }
    .image{
      img{
        width: 100%;
        height: auto;
      }
    }
    .heading{
      margin-bottom: 2rem;
      h4{
        font-size: 2rem;
        line-height: 1.5;
        font-weight: 900;
        margin-bottom: 0;
        @media screen and (max-width: 767px) {
          font-size: 1.6rem;
        }
      }
    }
    .website_feature_item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.75rem;
      margin-bottom: 1.1rem;
      padding-inline-end: 1.6rem;
      &:last-of-type{
        margin-bottom: 0;
      }
      svg, img{
        min-width: 24px;
        height: 24px;
      }
      .txt{
        font-size: 1.3rem;
        line-height: 1.5;
        font-weight: 600;
        max-width: 410px;
        @media screen and (max-width: 767px) {
          font-size: 1.1rem;
        }
      }
    }
  }

  .reports-and-statistics {
    padding-top: 5rem;
    padding-left: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    height: fit-content;
  }
  .reports-items {
    width: 100%;
    background-color: #F8F8F8;
    padding: 3rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .reports-title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 2rem;
    color: Reports & Statistics;
  }
  .report-name {
    font-size: 18px;
    font-weight: 500;
  }
  
}

.login-safa-modal {
  text-align: start;
  .pre-auth-layout {
    min-height: unset;
  }
  .modal-header {
    border-bottom: none !important;
  }
  .modal-title {
		width: 100%;
		text-align: center;

    svg {
      width: 70px;
      height: 70px;
    }
  }
  .head {
    font-weight: 700;
    font-size: 28px;
    line-height: 52.47px;
    color: #050001;
  }
  .sub-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 29.98px;
    color: #050001;
  }
  .close {
    opacity: 1;
  }
}

html{
  &:dir(rtl){
    .website_features_section{
      .heading{
        h4{
          font-size: 1.8rem;
        }
      }
      .website_feature_item{
        .txt{
          font-size: 1.1rem;
        }
      }
    }
  }
}