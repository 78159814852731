.dashboard-v2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .dashboard-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(316px, 1fr));
    gap: 1rem;

    .card-item {
      padding: 16px;
      border: 1px solid #EEEEEE;
      border-radius: 8px;
      background-color: #fff;
      min-height: 180px;
    }

    .card-item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
    .unit-count {
      font-weight: 700;
      font-size: 24px;
      line-height: 44.98px;
    }
    
    .total-balance {

      .balance-percentage {
        border-radius: 4px;
        padding: 8px 16px;
        background-color: #dbdbdb;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #707170;
      }
      .increased {
        background-color: #DEF3E8;
        color: #05793D;
      }
      .decreased {
        background-color: #ffdcdc;
        color: #d63e3e;
      }
    }

    .consumption {
      border-radius: 20px;
      background-color: white;

      .consumpton-remained {
        height: 6px;
        border-radius: 20px;
        background-image: linear-gradient(to right, #9E6C14, #E9CB96, #D4A655);
      }
    }
    
    .current-bundle {
      position: relative;
      background-image: linear-gradient(to bottom right, #EDD7B1, #FFFFFF);

      .bundle-name {
        font-size: 16px;
        color: #705424;
        font-weight: 600;
      }

      .bundle-remaining-time {
        font-size: 14px;
        font-weight: 400;
        color: #705424;
      }

      .bundle-remaining-percent {
        font-size: 14px;
        font-weight: 700;
        color: #D4A655;
      }

      .bundle-expiry-date {
        font-size: 14px;
        font-weight: 400;
        color: #A9997C;
      }
      .card-item-header svg rect {
        fill: #EFD8AF;
      }
      .arrow {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        background-color: #112233;
        color: #fff;
        z-index: 9;

        &.right {
          inset-inline-end: 0;
          rotate: 180deg;
        }
        &.left {
          inset-inline-end: 24px;
        }
      }
    }

    .complete-profile {
      background-image: linear-gradient(to bottom right, #292D32, #3D4C5F);
      .complete-profile-title {
        font-size: 18px;
        font-weight: 600;
        color: #FFFFFF;
      }
      .completion-message {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
      }
      .card-item-header svg rect {
        fill: #717171;
      }
    }
  }

  .filled-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 600;
    font-size: 14px;
    background-color: #D4A655;
    color: #fff;
  }

  .outline-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid #D4A655;
    color: #D4A655;
  }

  .circle {
    min-width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #019D4C;
  }

  .dashboard-main-section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: baseline;
    gap: 24px;

    .solution-plans, .daily-activities {
      padding: 24px;
      border-radius: 16px;
      background-color: #FFFFFF;
      max-height: 100%;
      overflow: hidden;
    }
    .logs-list {
      height: 384px;
      overflow-y: auto;
    }

    .title {
      color: #112233;
      font-weight: 700;
      font-size: 20px;
      line-height: 37.48px;
      margin-bottom: 24px ;
    }

    .solution-plans {
      width: 100%;
      overflow: hidden;
    }

    .react-multi-carousel-list {
      border-radius: 12px;
      box-shadow: 0px 0px 20px 0px #D4A65533 inset;
    }

    .react-multi-carousel-item {
      padding: 24px;
    }

    .custom-dot {
      width: 12px;
      height: 12px;
      border-radius: 50px;
      margin: 3px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .custom-dot-active {
      background-color: #D4A655;
      cursor: default;
    }

    .react-multi-carousel-dot-list {
      right: unset;
      left: unset;
      inset-inline-end: 24px;
      inset-block-end: 24px;
    }

    .done-icon{
      width: 17px;
      height: 17px;
      border-radius: 50px;
      background-color: #D4A655;
      display: flex;
      justify-content: center;
      align-items: center;
      svg path {
        fill: #fff;
      }
    }

    
    .activitiy-log-item {
      position: relative;
      display: flex;
      align-items: flex-start;
      padding-bottom: 2rem;
      gap: 10px;
      z-index: 1;
      overflow: hidden;
      // &:nth-last-child(2) {
      //   overflow: hidden;
      // }
      .circle {
        position: relative;
        top: 6px;
        background-color: #D4A655;
      }

      
      &:not(:last-child) .circle::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 10rem;
        left: calc(50% - 1px);
        background-color: #DCDCDC;
        z-index: -1;
      }
    }
  
  }

  .plan-description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

}

@media screen and (max-width: 767px) {
  .dashboard-v2 {
    .dashboard-main-section {
    grid-template-columns: 1fr;
      .solution-plans, .daily-activities {
        max-height: unset;
      }
      .logs-list {
        height: auto;
      }
    }
  }
}