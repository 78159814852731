.pagination {
	display: flex;
	font-size: $text-caption;
	gap: 4px;
	// flex-flow: row-reverse;

	span,
	.page-link {
		padding: .6rem .8rem;
		line-height: 1;
		font-weight: normal;
		transition: all 0.3s ease-in-out;
		color: #707170;
		border-radius: 6px;
		background-color: #F7F6F6;
		color: #707170;
		border: none;
		font-size: 15px;
		.icx {
			transition: all 0.3s ease-in-out;
		}
		&:hover {
			background: rgba(5, 0, 1, 0.16);
			// background: transparent;
			color: #707170;
			.icx {
				color: $white;
				transition: all 0.3s ease-in-out;
				outline: none;
			}
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem rgba(0, 105, 217, 0.25);
		}
	}
	span{
		&:hover{
			background-color: transparent;
		}
	}
	.active{
		.page-link{
			background: var(--d-4-a-655, #D4A655);
			color: #fff;
			border: none;

		}
	}
}
