.theme-7 {
  .wb-header {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem 3rem !important;
    background-color: #ffffff;
    box-shadow: 0 0 5px #e7e7e7;
    font-size: 1rem;

    .logo img {
      width: 180px !important;
      height: auto;
    }


    .header-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;

      li {
        a {
          display: inline-block;
          color: #000000;
          font-weight: 600;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: rgb(var(--web-page-primary-color));
          }
        }
      }
    }

    .contact-button {
      position: relative;
      padding: 0.5rem 1.4rem;
      background-color: rgb(var(--web-page-primary-color));
      border: 1px solid rgb(var(--web-page-primary-color));
      color: #ffffff;
      border-radius: 10px;
      transition: all 0.3s linear;
      overflow: hidden;
      font-size: 1rem;
      line-height: 1.5;
      text-align: center;

      span {
        position: relative;
        z-index: 1;
      }

      &:hover {
        background-color: transparent;

        &::before {
          inset-inline-start: 0;
        }

        &::after {
          inset-inline-end: 0;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        inset-inline-start: -50%;
        width: 50%;
        height: 100%;
        background-color: rgb(var(--web-page-primary-color), 0.8);
        transition: all 0.3s linear;
        z-index: 0;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        inset-inline-end: -50%;
        width: 50%;
        height: 100%;
        background-color: rgb(var(--web-page-primary-color), 0.8);
        transition: all 0.3s linear;
        z-index: 0;
      }
    }

    .mobile_menu_cta {
      display: none;

      .mobile_menu_toggler {
        background-color: transparent;
        border: 0;
        outline: none;
        box-shadow: none;
        padding: 0;
        line-height: 1.5;
      }
    }

    .theme_header_contact_wrap{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      .call-us{
        svg{
          margin-inline-end: 0.3rem;
          path {
            stroke: rgb(var(--web-page-primary-color));
          }
          border: 1px solid rgb(var(--web-page-primary-color));
          width: 40px;
          height: 40px;
          padding: 6px;
          border-radius: 50%;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
      gap: 1rem;

      .logo {
        a {
          img {
            width: 150px;
          }
        }
      }

      .main_links {
        display: none;

        ul {
          gap: 1rem;
        }
      }

      .cta_container {
        gap: 0.75rem;

        .lets_talk_btn {
          padding: 0.4rem 1rem;
          font-size: 0.9rem;
        }

        .language_btn {
          .toggler {
            border: 0;
            color: #000000;
            padding: 0;
            line-height: 2;

            .caret_down {
              display: none;
            }

            .lang__txt {
              display: none;
            }

            .lang_svg {
              fill: #000000;
              margin-inline-end: 0;
            }

            &:hover {
              &::before {
                display: none;
              }

              &::after {
                display: none;
              }
            }
          }

          .lang_dropdown {
            min-width: 130px;
          }
        }
      }

      .mobile_menu_cta {
        display: block;
      }
    }

    @media screen and (max-width: 576px) {
      .logo {
        a {
          img {
            width: 120px;
          }
        }
      }
    }
  }



}