.requestTaps {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	top: 1px;

	.tap {
		background-color: #d1d6dd;
		border: 1px solid #e4e6e9;
		padding: 4px 10px;
		color: #7c929a;
		cursor: pointer;
	}

	.active {
		background-color: #354f5c;
		color: #fff;
	}
}

.summarybox {
	background-color: #fff;
	border: 1px solid #d1d6dd;
	border-radius: 0 5px 5px 5px;
	padding: 20px;
}

.summaryhead {
	font-weight: bold;
	color: #000;
	font-size: 20px;
	margin-bottom: 20px;
}

.info-row {
	display: flex;
	flex-direction: row;
	padding: 4px 10px;
	.label {
		text-align: start;
	}
}

.label {
	font-weight: bold;
	color: #595e62;
	text-align: end;
	width: 35%;
}

.info {
	color: #000000;
	padding-inline-start: 10px;
	width: 60%;
}

.add-pass {
	background-color: #cb9a51;
	box-shadow: 0px 3px 6px #cb9a515f;
	border-radius: 5px;
	color: #fff;
}

.totalBox {
	background-color: #e5e5e5;
	box-shadow: 0px 3px 6px #adadad80;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
}

.total {
	font-weight: bold;
	font-size: 14px;
}

.darknum {
	color: #112e53;
}

.greennum {
	color: #027b30;
}

.yellownum {
	color: #b17a1f;
}
.bg-yellow-request {
	background-color: #d29d4d;
}

.side-col {
	background-color: white;
	border: 1px solid #d1d6dd;
	border-radius: 5px;
	margin-inline-end: 20px;

	.tap {
		width: 46%;
		text-align: center;
		padding: 5px 10px;
		border-radius: 5px 0px 0px 5px;
		font-weight: bold;
	}

	.tap:last-child {
		border-radius: 0px 5px 5px 0px;
		[dir="rtl"] & {
			border-radius: 5px 0px 0px 5px;
		}
	}
	.tap:first-child {
		[dir="rtl"] & {
			border-radius: 0px 5px 5px 0px;
		}
	}

	.requestTaps {
		margin-bottom: 20px;
		padding: 20px;
		justify-content: center;
	}

	.Inbound {
		background-color: #709a47;
		color: #fff;
		padding: 5px 10px;
	}

	.Outbound {
		background-color: #f04129;
		color: #fff;
		padding: 5px 10px;
	}

	textarea {
		border: 1px solid #d1d6dd;
		border-radius: 3px;
	}

	textarea::placeholder {
		color: #d1d6dd;
	}
}

.btn-color {
	background-color: #b17a1f;
}

.history {
	border-top: 1px solid #d1d6dd;

	h1 {
		font-weight: bold;
		color: #000;
		font-size: 18px;
	}
}

.head {
	color: #7c929a;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
}

.descrip {
	color: #000;
	font-size: 12px;
}

.guests {
	background-color: #f2eee7;
	border: 1px solid #d7a04b;
	padding: 20px;
	border-radius: 10px;
	margin-top: 20px;

	.header {
		// padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-inline-start: 0px;
		margin-bottom: 1rem;
	}

	.title {
		font-weight: bold;
		font-size: 18px;
	}

	.actions,
	.btn {
		font-size: 0.95rem;
		padding: 0;
		margin: 0 3px;
		text-transform: capitalize !important;
		color: #d7a04b;
	}

	.row-g {
		display: flex;
		align-items: center;
	}

	.label {
		color: #000;
		font-weight: normal;
		width: 200px;
		text-align: start;
	}

	.our-filed {
		background-color: #fff;
		border: 1px solid #eef0f4;
		padding: 10px;
		border-radius: 10px;
		width: 200px;

		.control-field,
		.isDisabled {
			background-color: #fff !important;
			border: 0;
			padding-inline-start: 0px !important;
			margin: 0 !important;
		}
		.isDisabled .css-1fhf3k1-control {
			background-color: #fff !important;
		}

		.custom-select-text {
			color: #000 !important;
		}

		.custom-control-field {
			border: 0px !important;
		}

		.css-1phpsrg-singleValue,
		.css-1vt9d9y-placeholder,
		.css-tlfecz-indicatorContainer {
			color: #0c3b5c;
			font-size: 18px;
			margin-left: 0;
		}
	}

	.edit-row {
		.label {
			width: 100px;
		}
		.our-filed {
			padding-bottom: 0px;
			padding-inline-start: 10px;
		}
	}

	.label-type {
		font-weight: bold;
		text-transform: capitalize;
		font-size: 14px;
	}
	.btn-guests {
		background-color: #e5e5e5;
		border-radius: 5px;
		color: #656565;
		padding: 8px 0px;
		font-size: 14px;
		width: 136px;
	}
}

.btn-guests {
	background-color: #e5e5e5;
	border-radius: 5px;
	color: #656565;
	padding: 8px 0px;
	font-size: 14px;
	width: 136px;
}

.room-prices th {
	position: relative;
	margin: 0 5px;
	border: 0 !important;
}

.room-prices thead td {
	padding: 5px 10px;
	color: #fff;
	border: 1px solid #ededed;
	border-radius: 10px 10px 0 0;
	text-align: center;
}

.room-prices .adult {
	background-color: #062840;
}
.room-prices .child {
	background-color: #0f3e5e;
}
.room-prices .infant {
	background-color: #14527c;
}
.room-prices thead td::after {
	content: "";
	position: absolute;
	bottom: -5px;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid inherit;
	border-color: inherit;
}

.request-guest-info {
	position: relative;
	display: flex;
	align-items: center;
	height: 56px;
	padding: 7px 14px;
	border-radius: 0px 6px 6px 0px;
	border: 1px solid #dbdade;
	border-left: 8px solid #d7a04b;
	background-color: #fff;
	color: #050001;
}
.guest-room-prices {
	thead td {
		border: 0;
	}
	.room-head {
		display: flex;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		padding: 12px 16px;
		border-radius: 8px 8px 0px 0px;
		border: 1px solid rgba(20, 82, 124, 0.1);
		font-size: 14px !important;
		font-weight: 600;
		overflow: hidden;
		vertical-align: middle;

		&.total {
			border-radius: 8px 8px 0px 0px;
			border: 1px solid rgba(20, 82, 124, 0.1);
			background: rgba(20, 82, 124, 0.1);
		}
	}
}

.gbox {
	background-color: #fff;
	border: 1px solid #d1d6dd;
	border-radius: 10px;
	margin-top: 20px;
	.right-items-container {
		width: 100% !important;
	}
	.product-content-items {
		width: 100%;
		padding: 8px 12px !important;
		.product-arrival {
			width: 160px;
		}
		.product-country-from,
		.product-country-to {
			width: calc(50% - 90px);
		}
		.product-country-from *,
		.product-country-to *,
		.blue-txt-item {
			font-size: 15px !important;
		}
	}
}

.btn-model {
	background-color: #daa249;
	color: #fff;
	font-weight: bold;
	margin: 10px 0px;
	font-size: 16px;
}
.btn-model:hover {
	color: #fff;
}
.waiting,
.Accepted {
	margin-bottom: 0px;
	margin-left: 5px;
}
.waiting {
	color: #b17a1f;
}
.Accepted {
	color: #027b30;
}
.boxwaiting {
	accent-color: #b17a1f;
}
.boxAccepted {
	accent-color: #027b30;
}

.payment-model {
	.model-lebal {
		width: 50%;
		border: 1px solid #ebebeb;
		color: #fff;
		text-align: center;
		padding: 8px;
		border-radius: 5px 0px 0px 5px;
		font-size: 16px;
		font-weight: bold;
		[dir="rtl"] & {
			border-radius: 0px 5px 5px 0px;
		}
	}
	.model-amount {
		width: 50%;
		background-color: #e5e5e5;
		border: 1px solid #ebebeb;
		text-align: center;
		padding: 8px;
		border-radius: 0px 5px 5px 0px;
		font-size: 16px;
		font-weight: bold;
		[dir="rtl"] & {
			border-radius: 5px 0px 0px 5px;
		}
	}

	.Total {
		color: #354f5c;
		.model-lebal {
			background-color: #354f5c;
			color: #fff;
		}
	}
	.Paid {
		color: #027b30;
		.model-lebal {
			background-color: #027b30;
			color: #fff;
		}
	}
	.Outstanding {
		color: #b17a1f;
		.model-lebal {
			background-color: #b17a1f;
		}
	}
}


.extra-service-container{
	padding: 24px;
	background-color: white;
	.extra-service-header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		p{
			font-weight: bold;
			font-size: 20px;
		}
		button{
			color: white;
			background-color: #D4A655;
			padding: 8px;
			border-radius: 4px;
			border: none;

		}
	}
	table{
		th{
			border-block: none;
			font-weight: 700;
		}
	}
}

.extra-service-modal{
	padding: 24px;
	&-header{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.control-field__label {
    margin-top: 9px;
}
}
.opacity-50{
	opacity: .5;
}
.application-form {
	text-align: start;
	.application-pax {
		.box {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	
		.pax-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #d4a655;
			background-color: #fff;
			font-size: 16px;
			border: none;
			padding: 6px;
	
			&.disabled {
				color: #dddcdc;
				background-color: transparent !important;
				cursor: default;
			}
			// &:focus {
			// 	box-shadow: 0 0 0 0.2rem rgba(212, 166, 85, 0.25);
			// 	border-radius: 6px;
			// }
		}
	}
	.control-field__label {
		color: #707170;
	}
	.modal-title {
		width: 100%;
		text-align: center;
	}
}

.add_edit_passenger_modal {
	.upload_box {
    overflow: hidden;

		.upload_box_input {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #ffffff;
			border: 1px solid #ebebeb;
			border-radius: 0.3125rem;
			height: 44px;
			padding: 10px;

			input {
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
			i {
				color: #d29d4d;
			}
		}
	}
	.preview_img {
		position: relative;
		width: 100%;
		height: 150px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&:hover .delete_img_btn {
			opacity: 1;
			visibility: visible;
		}
		.delete_img_btn {
			position: absolute;
			width: 32px;
			height: 32px;
			padding: 4px;
			border-radius: 8px;
			background: #ffffffcc;
			border: none;
			opacity: 0;
			visibility: hidden;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}
	}
}