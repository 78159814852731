.gap-1\/2 {
	gap: 0.5rem;
}

.gap-1 {
	gap: 1rem;
}

.gap-2 {
	gap: 2rem;
}

.gap-3 {
	gap: 3rem;
}

.h-44 {
	height: 44px;
}

.mb-1\/4 {
	margin-bottom: 0.25rem;
}

.min-w-fit-content {
	min-width: fit-content;
}

.min-w-max-content {
	min-width: max-content;
}

.umrah-operations-wrapper {
	border-radius: 8px;
	border: 1px solid #f7f6f6;
	background-color: #fff;
	.btn {
		font-size: 1rem;
	}

	.close-filter-btn {
		background-color: transparent;
		border: 1px solid #797272;
		color: #000000;
		outline: none;
		cursor: pointer;
		margin-left: auto;
		border-radius: 50%;
		width: 24px;
		height: 24px;
		padding: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.close-btn {
		padding: 10px 20px;
		border-radius: 4px;
		background-color: rgba(203, 203, 203, 0.38);
		color: #707170;
		font-weight: 700;
		font-size: 16px;
	}

	table {
		min-width: max-content !important;
	}

	.quick-date-search {
		display: flex;
		gap: 8px;
		span {
			background-color: #efeeee;
			cursor: pointer;
			padding: 6px 8px;
			border-radius: 6px;
			display: flex;
			align-items: center;
			&:hover {
				background-color: #d4a655 !important;
			}
		}
		.active-date {
			background-color: #d4a655;
			color: white;
		}
	}

	.quick-date-search {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
		.statistic-item {
			display: flex;
			justify-content: center;
			align-items: center;
			height: fit-content;
			&:hover {
				background-color: #d4a655 !important;
			}
		}
		.active-statistic-item {
			@extend .statistic-item;
			border-color: #d4a655;
			background-color: #faf4eb;
			.statistic-text {
				color: #d4a655;
			}
			.statistic-value {
				background: #d4a655;
			}
		}
	}

	.statistics-wrapper {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
		.statistic-item {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 40px;
			width: max-content;
			padding: 8px 16px 8px 16px;
			gap: 8px;
			border: 1px solid #dddcdc;
			cursor: pointer;
			.statistic-text {
				font-weight: 600;
				color: #707170;
			}
			.statistic-value {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0px 9px;
				border-radius: 34px;
				background: #112233;
				color: #fff;
				font-size: 14px;
			}
		}
		.active-statistic-item {
			@extend .statistic-item;
			border-color: #d4a655;
			background-color: #faf4eb;
			.statistic-text {
				color: #d4a655;
			}
			.statistic-value {
				background: #d4a655;
			}
		}
	}

	.reset-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 16px;
		margin-top: 20px;
		border-radius: 6px;
		border: 1px solid #eed9ba;
		background-color: #faf4eb;
		color: #6a4d1b;
		font-size: 18px;
		font-weight: 600;
		line-height: 16px;
	}
	.outline-btn,
	.filled-btn {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding: 6px 12px;
		border-radius: 4px;
		border: 1px solid #d4a655;
		background-color: #fff;
		color: #d4a655;
		font-size: 16px;
		font-weight: 700;
		&:focus {
			box-shadow: 0 0 0 0.15rem rgba(212, 166, 85, 0.25);
		}
		svg {
			transform: scale(1, 1);
		}
		[dir="rtl"] & {
			display: flex;
			flex-direction: row-reverse;
		}
	}
	.filled-btn {
		background-color: #d4a655;
		color: #fff;
		border-color: transparent;
	}

	.reset-btn {
		align-self: flex-start;
		margin-top: 27px;
		padding: 9px 16px;
		&:focus {
			box-shadow: 0 0 0 0.2rem rgba(250, 244, 235, 0.8);
		}
	}
	.search-btn {
		align-self: flex-start;
		margin-top: 28px;
		padding: 8px 16px;
		&:focus {
			box-shadow: 0 0 0 0.15rem rgba(212, 166, 85, 0.25);
		}
	}

	.table-container {
		border: 1px solid#DBDADE;
		border-radius: 6px;
		margin-top: 16px;
		// overflow: hidden;

		.table thead {
			background-color: #f7f6f6;
		}

		.table th {
			border-top: 0;
			padding: 16px 14px;
			text-transform: uppercase;
			color: #707170;
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		.table td {
			vertical-align: middle;
			padding: 16px 14px;
			color: #707170;
		}
		.text-black-900 {
			color: #050001 !important;
			font-size: 16px;
			font-weight: 600;
		}
		.text-grey-500 {
			color: #707170 !important;
			font-size: 16px;
		}
		.text-primary {
			color: #d4a655 !important;
			font-size: 16px;
			font-weight: 600;
		}

		.table-actions {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}

	.operation-movements-table {
		overflow-x: auto !important;
		border-radius: 10px;
		border: 1px solid #dbdade;
		height: 600px;

		.table {
			margin: 0;
			width: max-content;
			min-width: 100%;
		}

		.table thead {
			background-color: #f7f6f6;
			position: sticky;
			top: 0;
		}
		.table tbody tr:hover,
		.table tbody tr:nth-of-type(odd) {
			background-color: transparent !important;
		}

		.table thead th {
			padding: 16px 10px;
			color: #707170;
			font-size: 14px;
			font-weight: 700;
			line-height: 16px;
			letter-spacing: 1px;
			text-transform: uppercase;
			border: 0;
			vertical-align: middle;
			position: sticky;
			top: 0;
		}

		.table tbody td {
			vertical-align: middle;
			font-size: 14px;
			color: #241f20;
			background-color: #fff;

			.indecator {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				flex: 0 0 10px;
			}

			&.arrival {
				background-color: rgba(142, 198, 0, 0.1);
				.indecator {
					background-color: #8ec600;
				}
			}
			&.internal_movement {
				background-color: rgba(225, 64, 132, 0.1);
				.indecator {
					background-color: #e14084;
				}
			}
			&.attraction {
				background-color: rgba(179, 109, 60, 0.1);
				.indecator {
					background-color: #b36d3c;
				}
			}
			&.departure {
				background-color: rgba(41, 105, 255, 0.1);
				.indecator {
					background-color: #2969ff;
				}
			}
			.approved {
				background-color: #219653;
			}
			.draft {
				background-color: #9c9fab;
			}

			&.from-color {
				background-color: rgba(33, 150, 83, 0.1);
			}
			&.to-color {
				background-color: rgba(234, 84, 85, 0.1);
			}
			.add-item-btn {
				display: flex;
				justify-content: center;
				align-items: baseline;
				padding: 2px 8px;
				margin-top: 8px;
				border-radius: 6px;
				border: 1px solid #d4a655;
				color: #d4a655;
				font-weight: 600;
				font-size: 14px;
				gap: 5px;
			}

			.flash-btn {
				display: flex;
				justify-content: center;
				align-items: baseline;
				padding: 2px 8px;
				margin-top: 8px;
				border-radius: 6px;
				border: 2px solid transparent;
				color: #d4a655;
				font-weight: 600;
				font-size: 14px;
				gap: 5px;
				animation: flash-border 1s infinite;
			}

			.show-more-drivers-btn {
				width: fit-content;
				margin: 0 auto;
				text-align: center;
				padding: 2px 8px;
				color: #1e85ff;
				font-weight: 600;
				.shown svg {
					transform: rotate(-180deg);
				}
			}
			.vehicles svg path {
				stroke: #292d32;
			}
			.status {
				display: flex;
				padding: 3px 8px;
				align-items: center;
				justify-content: center;
				gap: 10px;
				border-radius: 4px;
				font-weight: 400;

				&.unexecuted {
					background-color: rgba(251, 136, 30, 0.16);
					color: #fb881e;
				}
				&.executed {
					background-color: rgba(1, 157, 76, 0.16);
					color: #019d4c;
				}
			}

			.make-execute-btn {
				display: flex;
				align-items: center;
				padding: 0;
				gap: 3px;
				color: #019d4c;
				font-weight: 600;
				font-size: 14px;

				&:focus {
					box-shadow: 0 0 0 0.15rem rgba(1, 157, 76, 0.25);
				}
				svg path {
					stroke: #219653;
				}
			}
			.direction-initial {
				direction: initial !important;
			}
			.no-drivers {
				color: #98a2b3;
				font-size: 16px;
				font-weight: 400;
			}
		}
		.font-light {
			font-size: 11px;
			font-weight: 400;
			color: #707170;
		}
	}

	.excuted-btn {
		display: flex;
		align-items: center;
		border-radius: 4px;
		background-color: #019d4c;
		color: #fff;
		font-size: 16px;
		gap: 8px;
		svg {
			width: 16px;
			height: 12px;
		}
	}

	.unexcuted-btn {
		border-radius: 4px;
		background-color: #dddcdc;
		color: #707170;
		gap: 10px;
	}
}
.umrah-operation-breadcrump {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	color: #707170;
	padding: 24px 0;
	.link {
		color: #d4a655;
	}
}
.breadcrump {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	color: #707170;
	padding: 24px 0;
	.link {
		color: #d4a655;
	}
}
.export-excel-btn {
	display: flex;
	align-items: center;
	padding: 6px 20px;
	align-items: center;
	border-radius: 4px;
	border: 1.5px solid #219653;
	background-color: #fff;
	color: #219653;
	font-size: 16px;
	font-weight: 700;
	&:hover {
		background-color: #219653;
		color: #fff;
		svg path {
			stroke: #fff;
		}
	}
}

.operations-agents {
	.table-container {
		border: 1px solid#DBDADE;
		border-radius: 6px;
		margin-top: 16px;
		overflow: hidden;

		.table thead {
			background-color: #f7f6f6;
		}

		.table th {
			border-top: 0;
			padding: 16px 14px;
			text-transform: uppercase;
			color: #707170;
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		.agent-name {
			color: #050001;
			font-size: 16px;
			font-weight: 600;
		}
	}

	.agents-filter {
		.control-field-inner {
			height: 45px !important;
			align-items: center;
		}
	}
	.add-edit-agent {
		.add-edit-form {
			display: flex;
			flex-direction: column;
			gap: 24px;
			padding: 32px 26px;
			border-radius: 8px;
			border: 1px solid#F7F6F6;

			.control-field__label {
				color: #707170;
				font-size: 14px;
			}
			::placeholder {
				color: #dddcdc;
			}
		}

		.back-btn {
			display: flex;
			align-items: center;
			padding: 6px 12px;
			border-radius: 4px;
			background: rgba(203, 203, 203, 0.38);
			color: #707170;
			font-size: 16px;
			font-weight: 700;
		}
		.view-agent {
			color: #050001;
			.type {
				padding-inline: 5px;
				margin: 0;
				color: #707170;
			}
		}
	}
	.pagination .active .page-link {
		color: #fff;
	}
}

.operation-statement-container {
	.operation-statement-filter {
		display: flex;
		padding: 24px 26px;
		align-items: center;
		gap: 20px;
		border-radius: 8px;
		border: 1px solid#F7F6F6;
		background-color: #fafafa;
		gap: 12px;

		@media screen and (max-width: 768px) {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 9999;
			display: flex;
			padding: 16px;
			flex-direction: column;
			gap: 20px;
			border-radius: 8px;
			border: 1px solid #f7f6f6;
			background-color: #fafafa;
			max-width: 400px;
			width: 100%;
			height: 100vh;
			overflow-y: auto;
			overscroll-behavior: contain;
			will-change: transform;
			transform: translateX(-100%);
			padding: 0;
			transition: transform 0.5s ease;

			&.open-aside {
				transform: translateX(0);
			}

			&.close-aside {
				transform: translateX(-100%);
			}
		}

		.row {
			row-gap: 16px;
		}
		.control-field__label {
			font-size: 14px;
			color: #707170;
		}
		.fix_place_arrow {
			right: 7px;
		}

		.control-field__select [class$="-ValueContainer"] {
			max-height: initial !important;
		}
		.control-field__select div[class$="-control"] {
			max-height: initial !important;
		}

		.multi-select {
			.control-field {
				min-height: 43px;
				height: auto !important;
				padding: 3.5px 6px !important;
			}
		}
		.input-group {
			flex-wrap: nowrap;
		}
		.dropdown-container {
			border-color: #ebebeb;
			padding: 2px 3px !important;
			&:focus-within {
				box-shadow: none;
				border-color: #cb9a51;
			}
		}

		.reset-btn {
			align-self: flex-start;
			margin-top: 27px;
			padding: 9px 16px;
		}
		.search-btn {
			align-self: flex-start;
			margin-top: 28px;
			padding: 8px 16px;
		}
	}
	.operation-statement-list {
		.status-filter {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			li {
				display: flex;
				padding: 24px 16px;
				align-items: center;
				border-bottom: 1px solid#DDDCDC;
				font-size: 18px;
				font-weight: 600;
				color: #707170;
				border-bottom: 2px solid transparent;
				cursor: pointer;

				@media screen and (max-width: 768px) {
					min-width: fit-content;
					justify-content: center;
				}

				.badge {
					padding: 4px 8px;
					border-radius: 14px;
					background-color: #dddcdc;
					font-size: 14px;
					color: #707170;
					&.new {
						background-color: #ea5455;
						color: #fff;
					}
				}

				&.active {
					color: #d4a655;
					border-bottom: 2px solid#D4A655;
					cursor: default;
				}
			}
		}

		.table-wrapper {
			overflow-x: auto;
			width: 100%;
		}

		.table {
			display: flex;
			flex-direction: column;
			gap: 12px;
			margin-top: 12px;
			overflow-x: auto;

			.table-row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px 12px;
				border: 1px solid #f7f6f6;
				border-radius: 8px;
				background-color: #fff;
				font-size: 14px;
				font-weight: 400;
				gap: 2rem;
				transition: background-color 0.5s ease;

				& > div:not(.statement-status) {
					flex: 1;
					min-width: fit-content;
				}

				& > .statement-info:last-child{
					align-items: end;
				}

				&:hover {
					background-color: #fbf6ee;
					transition: background-color 0.5s ease;
				}

				.status {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #cbcbcb;
					margin-inline-end: 12px;
					&.approved {
						background-color: #219653;
					}
					&.rejected {
						background-color: #ea5455;
					}
				}
				.statement-info {
					display: flex;
					flex-direction: column;

					.elements {
						position: relative;
						display: flex;
						align-items: center;

						.ele-icon {
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							min-width: 32px;
							height: 32px;
							// padding: 5px 1px;
							aspect-ratio: 1;
							border-radius: 50%;
							border: 1px solid #fff;
							background-color: #f2f2f5;
							svg {
								width: 16px;
								height: 16px;
							}
							margin-right: 3px;
						}

						.ele-icon.active {
							background-color: #d4a655 !important;
						}
						.count {
							background-color: #babdc1;
							z-index: 1;
						}
						.hidden-elements {
							position: absolute;
							padding: 5px;
							width: 180px;
							top: 34px;
							z-index: 5;
							background-color: #fff;
							border: 1px solid rgba(25, 59, 103, 0.05);
							border-radius: 4px;
							opacity: 0;
							visibility: hidden;
							transform: translateY(-10px);
							transition: opacity 0.5s ease, visibility 0.5s ease,
								transform 0.5s ease;
							font-size: 16px;
							box-shadow: 0px 2px 6px -1px rgba(28, 55, 90, 0.12),
								0px 8px 24px -4px rgba(28, 50, 79, 0.19);
						}

						&:hover .hidden-elements {
							transform: translateY(0);
							opacity: 1;
							visibility: visible;
							transition: opacity 0.5s ease, visibility 0.5s ease,
								transform 0.5s ease;
						}
					}
				}
				.agent-info {
					display: flex;
					flex-direction: column;
					font-size: 12px;
					font-weight: 500;
					color: #778294;
					width: 120px;

					.name {
						font-size: 14px;
						color: #050001;
						font-weight: 600;
					}

					.country {
						color: #707170;
						font-size: 13px;
						font-weight: 400;
					}
				}
				.statement-category-card {
					width: 200px;
				}

				.actions-list {
					position: absolute;
					display: flex;
					flex-direction: column;
					width: 300px;
					padding: 12px 0px;
					top: 30px;
					inset-inline-end: 0;
					border-radius: 8px;
					border: 1px solid #e6e5e5;
					background: #fff;
					box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.03);
					z-index: 3;
					display: none;
					max-height: 24rem;
					overflow-y: auto;
					// opacity: 0;
					// visibility: hidden;
					// transform: translateY(-10px);
					// transition: opacity .5s ease, visibility .5s ease, transform .5s ease;

					&.show {
						display: flex;
						// transform: translateY(0);
						// opacity: 1;
						// visibility: visible;
						// transition: opacity .5s ease, visibility .5s ease, transform .5s ease;
					}

					.item {
						padding: 4px 16px;
						font-size: 16px;
						font-weight: 400;
						color: #050001;
						&.stroke svg path {
							stroke: #292d32;
						}
					}

					h5 {
						padding: 8px 16px;
						font-size: 12px;
						color: #777;
						font-weight: 500;
						text-transform: uppercase;
					}
					hr {
						margin: 8px 0;
					}
				}

				.action-label {
					font-size: 12px;
					font-weight: 500;
					color: #778294;
					margin-bottom: 16px;
				}
				.edit-draft-btn {
					display: flex;
					align-items: center;
					padding: 6px 10px;
					border-radius: 6px;
					border: 1px solid #9c9fab;
					background-color: #fff;
					color: #9c9fab;
					font-weight: 600;
					font-size: 18px;
				}
			}
		}

		.accounting-table {
			display: table;
			min-width: max-content;
			overflow-x: auto;
		}

		.statement-table {
			min-width: max-content;
			border-collapse: separate;
			border-spacing: 0 20px;
			width: 100%;

			tr {
				td:first-child {
					border-left: 1px solid #f7f6f6;
					border-radius: 8px 0 0 8px;
				}

				td:last-child {
					border-right: 1px solid #f7f6f6;
					border-radius: 0 8px 8px 0;
				}

				transition: background-color 0.5s ease;

				td {
					padding: 12px;
					border-block: 1px solid #f7f6f6;
				}

				&:hover {
					background-color: #fbf6ee;
					transition: background-color 0.5s ease;
				}

				.status {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #cbcbcb;
					margin-inline-end: 12px;
					&.approved {
						background-color: #219653;
					}
					&.rejected {
						background-color: #ea5455;
					}
				}

				.statement-info {
					display: flex;
					flex-direction: column;

					.elements {
						position: relative;
						display: flex;
						align-items: center;

						.ele-icon {
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							min-width: 32px;
							height: 32px;
							// padding: 5px 1px;
							aspect-ratio: 1;
							border-radius: 50%;
							border: 1px solid #fff;
							background-color: #f2f2f5;
							svg {
								width: 16px;
								height: 16px;
							}
							margin-right: 3px;
						}

						.ele-icon.active {
							background-color: #d4a655 !important;
						}
						.count {
							background-color: #babdc1;
							z-index: 1;
						}
						.hidden-elements {
							position: absolute;
							padding: 5px;
							width: 180px;
							top: 34px;
							z-index: 5;
							background-color: #fff;
							border: 1px solid rgba(25, 59, 103, 0.05);
							border-radius: 4px;
							opacity: 0;
							visibility: hidden;
							transform: translateY(-10px);
							transition: opacity 0.5s ease, visibility 0.5s ease,
								transform 0.5s ease;
							font-size: 16px;
							box-shadow: 0px 2px 6px -1px rgba(28, 55, 90, 0.12),
								0px 8px 24px -4px rgba(28, 50, 79, 0.19);
						}

						&:hover .hidden-elements {
							transform: translateY(0);
							opacity: 1;
							visibility: visible;
							transition: opacity 0.5s ease, visibility 0.5s ease,
								transform 0.5s ease;
						}
					}
				}

				.agent-info {
					display: flex;
					flex-direction: column;
					font-size: 12px;
					font-weight: 500;
					color: #778294;

					.name {
						font-size: 14px;
						color: #050001;
						font-weight: 600;
					}

					.country {
						color: #707170;
						font-size: 13px;
						font-weight: 400;
					}
				}

				.actions-list {
					position: absolute;
					display: flex;
					flex-direction: column;
					width: 300px;
					padding: 12px 0px;
					top: 30px;
					inset-inline-end: 0;
					border-radius: 8px;
					border: 1px solid #e6e5e5;
					background: #fff;
					box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.03);
					z-index: 3;
					display: none;
					max-height: 24rem;
					overflow-y: auto;
					// opacity: 0;
					// visibility: hidden;
					// transform: translateY(-10px);
					// transition: opacity .5s ease, visibility .5s ease, transform .5s ease;

					&.show {
						display: flex;
						// transform: translateY(0);
						// opacity: 1;
						// visibility: visible;
						// transition: opacity .5s ease, visibility .5s ease, transform .5s ease;
					}

					.item {
						padding: 4px 16px;
						font-size: 16px;
						font-weight: 400;
						color: #050001;
						&.stroke svg path {
							stroke: #292d32;
						}
					}

					h5 {
						padding: 8px 16px;
						font-size: 12px;
						color: #777;
						font-weight: 500;
						text-transform: uppercase;
					}
					hr {
						margin: 8px 0;
					}
				}

				.action-label {
					font-size: 12px;
					font-weight: 500;
					color: #778294;
					margin-bottom: 16px;
				}

				.edit-draft-btn {
					display: flex;
					align-items: center;
					padding: 6px 10px;
					border-radius: 6px;
					border: 1px solid #9c9fab;
					background-color: #fff;
					color: #9c9fab;
					font-weight: 600;
					font-size: 18px;
				}

				.statement-status {
					width: fit-content;
				}
			}

			.w-icons-mob {
				width: auto !important;
			}
		}
	}
	.pagination .active .page-link {
		color: #fff;
	}
	.pagination .page-link {
		padding: 10px 14px;
	}
}

.operation-statement-numbers-model {
	.modal-header {
		padding-block: 20px !important;
	}
	.modal-title {
		width: 100%;
		text-align: center;
	}
	.modal-body {
		padding-inline: 0 !important;
	}
	.modal-content {
		padding: 20px;
	}
	.row {
		margin-inline: 0 !important;
	}
	.control-field__prepend {
		border-inline-end: none !important;
		span {
			background-color: transparent !important;
		}
		.form-control {
			padding: 0.6rem 0.5rem !important;
		}
	}
	.flex-prepend {
		padding: 12px 5px;
	}
	.control-field__label,
	label {
		color: #707170;
		font-size: 14px;
		font-weight: 400;
	}
	.or {
		width: 38%;
		text-align: center;
		border-bottom: 1px solid #a9a9a9;
		line-height: 0.1em;
		margin: 39px 0;
		vertical-align: middle;

		@media screen and (max-width: 767px) {
			margin: 26px 0;
		}

		span {
			background: #fff;
			padding: 0 3px;
			vertical-align: middle;
			color: #707170;
			margin-bottom: 3px;
			display: inline-block;
		}
	}

	.company_phone_code {
		.control-field__label {
			width: 130px;
		}
	}
}
.operating-view-container {
	.fix_place_arrow {
		right: 7px;
	}
}
.time-field {
	.time-label {
		display: block !important;
	}
	.custom-time-field {
		border: 1px solid #ebebeb;
		border-radius: 0.3125rem;
	}
}
.send-company-model {
	.modal-content {
		padding: 0 20px !important ;
	}
	.modal-header {
		padding-top: 35px !important ;
		padding-bottom: 20px !important;
	}
	.modal-body {
		padding-bottom: 0 !important;
	}
	.upload-images {
		height: 90px !important;
	}
	.btn-nxt {
		a {
			color: #fff;
			text-decoration: none !important;
		}
	}
	.control-field__append {
		padding: 0;
		.btn {
			font-size: 1rem;
			height: 100%;
		}
		.text-headline {
			height: 100%;
			padding: 0 !important;
		}
	}
	.or-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		.text {
			position: relative;
			padding: 0 1rem;
			font-size: 20px;
			font-weight: 700;
			line-height: 37.48px;
			z-index: 2;
			background-color: #fff;
		}
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			border: 1px solid #0000001a;
			top: calc(50% - 1px);
			z-index: 1;
		}
	}
	.send-whatsapp-btn {
		padding: 12px 16px;
		background-color: #019d4c;
		color: #fff;
		font-size: 16px;
		border-radius: 8px;
	}
	.text-link {
		text-decoration: underline;
		color: #2969ff;
	}
}
.operation-statement-view {
	background-color: #fff;

	.operation-statement-header {
		display: flex;
		overflow-x: auto;
		align-items: center;
		padding: 16px 24px;
		gap: 18px;
		border: 1px solid #f4f4f7;

		& > div {
			padding-inline: 12px;

			& > div {
				min-width: max-content;
			}
		}

		// .category-item {
		// 	width: calc(20% - 18px);
		// }
	}
	.visa-item {
		padding: 16px;
		background-color: #f7f6f6;
		margin-top: 16px;
		.control-field {
			height: 49px;
			align-items: center;
		}
		.input-container {
			// margin-top: 0;
			padding: 13px 6px;
			border: 1px solid #ebebeb !important;
		}
		.label-pax {
			display: block;
			margin-bottom: 4px;
		}
	}
	.control-field__label {
		color: #707170;
		font-size: 14px;
	}
	.control-field__label--danger {
		color: #ff5f59;
	}

	.item-row {
		display: flex;
		flex-direction: column;
		gap: 8px;
		border-radius: 8px;
		border: 1px solid#DDDCDC;

		.header {
			padding: 16px;
			border-radius: 8px 8px 0px 0px;
			background-color: #f7f6f6;
			font-size: 18px;
			font-weight: 400;
			color: #707170;
		}
		.label {
			width: 100%;
			padding: 8px 10px;
			text-align: center;
			border-radius: 4px;
			background-color: #dddcdc;
			color: #050001;
			font-size: 18px;
			font-weight: 600;
		}
	}

	.save-btn {
		display: flex;
		padding: 10px 30px;
		justify-content: center;
		align-items: center;
		gap: 6px;
		border-radius: 8px;
		background-color: #d4a655;
		color: #fefeff;
		font-weight: 700;
		font-size: 1rem;
	}

	.reject-btn {
		display: flex;
		align-items: center;
		padding: 10px 16px;
		border-radius: 8px;
		background-color: #ea5455;
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		border: none;
		[dir="rtl"] svg {
			transform: scale(1, 1) !important;
		}
	}
	.accept-btn {
		display: flex;
		align-items: center;
		padding: 10px 16px;
		border-radius: 8px;
		background-color: #219653;
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		border: none;
		[dir="rtl"] svg {
			transform: scale(1, 1) !important;
		}
		transition: none !important;
	}

	.checkbox-container input {
		width: 18px;
		height: 18px;
		border-radius: 4px;
	}
}
.operation-statistics {
	background-color: #fbfbfa !important;
	h2 {
		color: rgba(75, 70, 92, 1);
		font-size: 24px;
		font-weight: 700;
		padding-inline: 20px;
		padding-top: 8px;
	}
	.general {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 32.33% 32.33% 32.33%;
		color: #444;
		padding: 20px;
		.box {
			background-color: rgba(255, 255, 255, 1);
			color: #222;
			border-radius: 8px;
			padding: 20px 15px;
			box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, 0.08);
			display: flex;
			gap: 12px;
			align-items: center;
			.text {
				h3 {
					font-size: 24px;
					font-weight: 600;
					color: rgba(44, 48, 46, 1);
					line-height: 2;
				}
				p {
					color: rgba(112, 113, 112, 1);
					font-size: 16px;
					font-weight: 500;
				}
			}
		}
		.box-operation {
			background-color: rgba(255, 255, 255, 1);
			color: #222;
			border-radius: 8px;
			padding: 8px;
			box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, 0.08);
			//display: flex;
			// gap: 12px;
			// align-items: center;
		}

		.agent {
			grid-column: 1;
			grid-row: 1;
		}
		.pilgrimage {
			grid-column: 2;
			grid-row: 1;
		}
		.operation {
			grid-column: 3;
			grid-row: 1;
		}
		.arrival-today {
			grid-column: 1;
			grid-row: 2;
			height: 160px !important;
		}
		.arrival-tomorrow {
			grid-column: 2;
			grid-row: 2;
			height: 160px !important;
		}
		.num-operation {
			grid-column: 3;
			grid-row: 2 / 4;
		}
		.deparure-today {
			grid-column: 1;
			grid-row: 3;
			height: 160px !important;
		}
		.no-arrival {
			grid-column: 2;
			grid-row: 3;
			height: 160px !important;
		}
		.num-operation {
			.num-operation-head {
				display: flex;
				justify-content: space-between;
				align-content: center;
				h6 {
					color: rgba(75, 70, 92, 1);
					font-size: 20px;
					font-weight: 600;
				}
				select {
					padding: 0px 8px 0px 8px;
					border-radius: 8px;
					border: 1px solid rgba(221, 220, 220, 1);
					font-size: 18px;
					font-weight: 600;
					color: rgba(75, 70, 92, 1);
				}
			}
			.recharts-responsive-container {
				width: 77% !important;
				max-height: 200px;
			}
			.recharts-surface {
				width: 200px !important;
				height: 200px !important;
				transform: scale(2); //2.5
				.recharts-layer.recharts-pie {
					transform: scale(1.1); //1.2
				}
			}
			.recharts-legend-wrapper {
				left: 85% !important;
				//100% !important;
				width: 100px !important;
				.recharts-surface {
					width: 10px;
					height: 10px;
				}
			}
			.recharts-wrapper {
				[dir="rtl"] & {
					text-align: left !important;
					right: 25% !important;
				}
			}
		}
		[dir="rtl"] & {
			overflow: hidden !important;
		}
	}
	.private {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 32.33% 32.33% 32.33%;
		color: #444;
		padding: 20px;
		.box {
			background-color: rgba(255, 255, 255, 1);
			color: #222;
			border-radius: 8px;
			padding: 8px;
			box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, 0.08);
			min-height: 370px;
		}
		.line-chart {
			grid-column: 1/3;
			grid-row: 1;
			.recharts-responsive-container {
				width: 77% !important;
				max-height: 200px;
			}
			.recharts-surface {
				width: auto !important;
				height: 250px !important;
				.recharts-layer.recharts-pie {
					transform: scale(1.1); //1.2
				}
			}
			.recharts-legend-wrapper {
				left: 100% !important;
				width: 100px !important;
				.recharts-surface {
					width: 10px;
					height: 10px;
				}
			}
			.recharts-text.recharts-cartesian-axis-tick-value {
				font-size: 13px !important;
				fill: rgba(151, 151, 151, 1) !important;
				font-weight: 400 !important;
			}
			.recharts-cartesian-grid-horizontal,
			.recharts-cartesian-grid-vertical {
				line {
					stroke: rgba(221, 220, 220, 0.4) !important;
				}
			}
		}
		.pie {
			grid-column: 3;
			grid-row: 1;
			.recharts-responsive-container {
				width: 100% !important;
				max-height: 200px;
			}
			.recharts-surface {
				width: 200px !important;
				height: 200px !important;
				// transform: scale(2); //2.5
				transform: translateX(50%) scale(2.3);
				.recharts-layer.recharts-pie {
					transform: scale(1.1); //1.2
				}
			}
			.recharts-legend-wrapper {
				left: 0 !important;
				//95% !important;
				width: 100% !important;
				height: 10px !important;
				.recharts-default-legend {
					margin-top: 20px !important;
				}
				.recharts-surface {
					width: 10px;
					height: 10px;
				}
			}
		}
		.private-head {
			display: flex;
			justify-content: space-between;
			align-content: center;
			h6 {
				color: rgba(75, 70, 92, 1);
				font-size: 20px;
				font-weight: 600;
			}
			select {
				padding: 0px 8px 0px 8px;
				border-radius: 8px;
				border: 1px solid rgba(221, 220, 220, 1);
				font-size: 18px;
				font-weight: 600;
				color: rgba(75, 70, 92, 1);
			}
		}
		.recharts-wrapper {
			[dir="rtl"] & {
				text-align: left !important;
			}
		}
	}
	.external-agent,
	.payment {
		padding: 16px;
		background-color: #fff;
		border-radius: 8px;
		margin-inline: 20px;
		box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
		border: 1px solid rgba(255, 255, 255, 0.1);
		.head {
			display: flex;
			justify-content: space-between;
			h6 {
				color: rgba(75, 70, 92, 1);
				font-size: 20px;
				font-weight: 600;
			}
			select {
				padding: 0px 8px 0px 8px;
				border-radius: 8px;
				border: 1px solid rgba(221, 220, 220, 1);
				font-size: 18px;
				font-weight: 600;
				color: rgba(75, 70, 92, 1);
			}
			.gap-10 {
				gap: 10px;
			}
			.view-all {
				background-color: rgba(212, 166, 85, 1);
				// padding: 10px 16px 10px 16px				;
				border-radius: 8px;
				font-size: 16px;
				font-weight: 600;
				color: #fff;
			}
		}
		.content {
			// padding: 16px;
			background-color: #fff;
			border-radius: 8px;
			.table-statistics {
				margin-top: 8px;
				border: 1px solid #dddcdc;
				border-radius: 8px;
				overflow: hidden;
				.table {
					margin-bottom: 0 !important;
					thead {
						background: #f7f6f6;
						color: #707170;
						th {
							font-size: 14px;
							font-weight: 700;
							text-transform: uppercase;
							letter-spacing: 1px;
							border-block: none !important;
						}
					}
					tbody {
						td {
							color: #050001;
							font-size: 16px;
							vertical-align: middle;
						}
					}
				}
				.text-yellow {
					color: rgba(212, 166, 85, 1);
					font-size: 16px;
					font-weight: 700;
				}
			}
		}
	}
	.payment {
		margin-block: 16px;
		.payment-tab {
			ul {
				display: flex;
				gap: 12px;
				border-bottom: 1px solid rgba(221, 220, 220, 1);
				li {
					font-size: 20px;
					font-weight: 600;
					color: rgba(75, 70, 92, 1);
					cursor: pointer;
					padding-inline: 14px;
				}
				li.active {
					font-size: 20px;
					font-weight: 600;
					color: rgba(212, 166, 85, 1);
					border-bottom: 2px solid rgba(212, 166, 85, 1);
				}
			}
		}
		.text-light-gary {
			color: rgba(112, 113, 112, 1);
		}
		.text-green {
			color: rgba(1, 157, 76, 1);
		}
	}
	.legend-line {
		height: 8px;
		width: 30px;
		border-radius: 5px;
		display: inline-block;
	}
	.recharts-default-legend .recharts-legend-item svg {
		display: none !important;
	}
}
.bg-gray-50 {
	background-color: #f7f6f6 !important;
}
.gap-24 {
	gap: 24px;
}
.gap-10 {
	gap: 10px;
}
.col-md-4:has(> .pax-input),
.col-md-2:has(> .pax-input) {
	min-width: 195px;
}
.pax-input {
	color: #707170;
	font-size: 14px;
	font-weight: 400;
	min-width: 185px;
	&.hasError {
		.input-container {
			border: 1px solid #ff5f59;
		}
		.label-pax {
			color: #ff5f59;
		}
	}
	.input-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px 6px;
		margin-top: 7px;
		gap: 6px;
		flex: 1 0 0;
		border-radius: 6px;
		border: 1px solid #dbdade;
		background-color: #fff;

		.pax-type {
			display: flex;
			align-items: center;
			color: #050001;
		}
		svg {
			width: 20px;
			height: 20px;
		}
		.btn {
			&:focus {
				box-shadow: 0 0 0 0.2rem rgba(112, 113, 112, 0.15);
			}
			&:disabled {
				background: transparent !important;
				svg path {
					stroke: #dcdcdc;
				}
			}
		}

		.action-btns {
			display: flex;
			align-items: center;
		}

		.number-input {
			border: 0;
			width: 25px;
			text-align: center;
		}
	}
}

.statement-category-card {
	display: flex;
	align-items: center;
	gap: 10px;
	min-width: 200px;

	.category-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 36px;
		padding: 4px;
		border-radius: 100%;

		border: 1px solid;
	}
	.info-container {
		display: flex;
		flex-direction: column;

		.category-type {
			font-size: 12px;
			font-weight: 500;
			color: #778294;
		}

		.category-service-name {
			font-size: 14px;
			color: #050001;
			font-weight: 600;
			line-height: 22px;
		}
		.category-sub-data {
			font-size: 13px;
			font-weight: 400;
			line-height: 20px;
			color: #707170;
		}
	}
}

.operation-tabs-wrapper {
	position: relative;
}

.operation-tabs {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	gap: 18px;
	color: #707170;
	overflow-x: auto;
	// overflow-y: visible;
	border-bottom: 1px solid #dddcdc;
	padding: 0;

	.item {
		position: relative;
		min-width: fit-content;
		display: flex;
		// flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 16px 0;
		font-size: 20px;
		font-weight: 500;
		line-height: 100%;
		// white-space: pre;
		gap: 10px;
		border-bottom: 2px solid transparent;
		cursor: pointer;

		&:hover .sub-tabs {
			display: flex;
		}

		&.active {
			// display: flex;
			// flex-direction: column;
			border-bottom: 2px solid #d2ab67 !important;
			color: #d2ab67;
			cursor: default;

			svg path {
				stroke: #d2ab67;
			}
		}

		> div {
			min-width: fit-content;
		}
	}
	.sub-tabs {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 8px;
		top: calc(100% + 2px);
		z-index: 2;
		min-width: 200px;
		width: fit-content;
		left: 0;
		right: 0;
		border-radius: 8px;
		border: 1px solid #e6e5e5;
		background-color: #fff;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.03);
		display: none;

		&.opened {
			display: flex;
		}

		.sub-tab-item {
			padding: 8px 0;
			color: #050001;
			font-size: 16px;
			font-weight: 400;
			cursor: pointer;
			white-space: nowrap;

			svg path {
				stroke: #292d32;
			}

			&.active {
				border-bottom: 0;
				color: #d2ab67;
				cursor: default;

				svg path {
					stroke: #d2ab67;
				}
			}
		}
	}
	.upgrade-subscription-icon {
		svg path {
			stroke: rgb(234, 84, 85) !important;
		}
	}
}

.vertical-content-tabs .item {
	flex-direction: column;
	align-items: start;
}

.operations-center-container {
	.wrapper {
		padding: 0 16px 16px;
		border-radius: 12px;
		border: 1px solid #f7f6f6;
		background-color: #fff;
		.page-link {
			padding: 0.5rem 0.8rem;
		}
		.active .page-link {
			color: #fff;
		}
	}
	.filter-container {
		padding: 24px 26px;
		border-radius: 8px;
		border: 1px solid#F7F6F6;
		background-color: #fafafa;

		@media screen and (max-width: 768px) {
			margin-top: 0 !important;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 9999;
			display: flex;
			padding: 16px;
			flex-direction: column;
			gap: 20px;
			border-radius: 8px;
			border: 1px solid #f7f6f6;
			background-color: #fafafa;
			max-width: 400px;
			width: 100%;
			height: 100vh;
			overflow-y: auto;
			overscroll-behavior: contain;
			will-change: transform;
			transform: translateX(-100%);
			padding: 12px;
			transition: transform 0.5s ease;

			&.open-aside {
				transform: translateX(0);
			}

			&.close-aside {
				transform: translateX(-100%);
			}
		}

		.filter-collapse-btn {
			display: flex;
			align-items: center;
			width: fit-content;
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
			color: #050001;
			gap: 3px;
			&:focus {
				box-shadow: none;
			}
		}
		.control-field__label {
			font-size: 14px;
			font-weight: 400;
			color: #707170;
		}
		.inputs-container {
			row-gap: 16px;
		}
	}

	.types-fitler {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;

		li {
			padding: 5px 16px;
			border-radius: 40px;
			border: 1px solid #dddcdc;
			background-color: #fefeff;
			color: #707170;
			font-size: 18px;
			font-weight: 600;
			cursor: pointer;
			display: flex;
			justify-content: center;
			flex: 1;
			min-width: fit-content;
		}
		li.active {
			border: 1px solid#D4A655;
			background-color: #faf4eb;
			color: #d4a655;
		}
	}

	.city-type-input {
		width: 200px;

		@media screen and (max-width: 768px) {
			width: 100%;
		}

		.control-field {
			display: flex;
			align-items: center;
			padding: 7px 14px !important;
			border-radius: 52px;
			background-color: #faf4eb;
			border-color: #dbdade;

			div[class$="placeholder"],
			div[class$="singleValue"] {
				color: #050001;
				font-size: 15px;
				font-weight: 600;
				line-height: 24px;
			}
		}
	}
}

[dir="rtl"] .table .btn svg {
	transform: scale(1, 1);
}
.operation-modal {
	.modal-title {
		width: 100%;
		padding: 16px 0;
		text-align: center;
		color: #050001;
		font-size: 16px;
		font-weight: 600;
	}
	.close {
		color: #050001;
		opacity: 1;
	}
	.control-field__label {
		color: #707170;
		font-size: 14px;
		font-weight: 400;
	}
	.save-btn {
		display: flex;
		padding: 12px 0;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 16px;
		gap: 8px;
		border-radius: 8px;
		background-color: #d4a655;
		color: #fefeff;
		font-weight: 700;
		font-size: 16px;
	}
	.control-field__prepend {
		border: 0;
		span {
			background-color: transparent;
			color: #050001;
			font-weight: 600;
		}
	}
	.fix_place_arrow {
		display: none;
	}
	.textarea-container {
		display: flex;
		flex-direction: column;
		box-shadow: none;

		textarea {
			padding: 8px 16px;
			border: 1px solid #dddcdc;
			border-radius: 6px;
		}
	}
	.text-gray-500 {
		color: #707170;
	}
	.fileUpload-wrapper p {
		color: #707170;
		font-size: 14px;
	}

	.checkbox-container input {
		width: 18px;
		height: 18px;
		border-radius: 4px;
	}
	.back-btn {
		background: rgba(203, 203, 203, 0.38);
		color: #707170;
	}
}
/*** Start Timing Settings ***/
.operations-settings {
	.item-content-wrapper {
		overflow-x: auto;
		padding-inline: 4px;
	}

	.item-row {
		border: 1px solid #eae9e9;
		border-radius: 8px;
		padding: 20px 26px 32px 26px;
		margin-block: 26px;
		flex: 1;

		@media screen and (max-width: 767px) {
			padding: 12px;
		}

		.header-title {
			color: #050001;
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 24px;
		}

		.item-content {
			border: 1px solid #dddcdc;
			border-radius: 8px;
			padding: 8px;
			min-width: max-content;
			.row {
				margin-inline: 0 !important;
			}
			.header-tabs {
				display: flex;
				justify-content: space-between !important;
				.tab {
					width: 32%;
					background-color: #dddcdc;
					padding: 12px 16px 12px 16px;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: center;

					label,
					p {
						color: #050001;
						font-size: 18px;
						font-weight: 600;
						text-align: center;
						line-height: 1;
						margin-bottom: 0 !important;
					}
					span {
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
			.content-tabs {
				display: flex;
				justify-content: space-between;
				flex-wrap: nowrap;
				.tab {
					width: 32%;
					margin-top: 8px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					align-items: center !important;
					gap: 12px;
				}
				.time-feild {
					width: 80%;
					display: inline-block;
					.control-field {
						margin-block: 0 !important;
					}
				}
				span {
					font-size: 16px;
					font-weight: 600;
					color: #000;
				}
				.label-data {
					background-color: #f7f6f6;
					// border: 1px solid #707170;
					border-radius: 4px;
					// padding:12px 16px 12px 16px;
					display: flex;
					align-items: center;
					// justify-content: center;
					height: 44px;
					padding-inline: 16px;
				}
			}
		}
	}
	.bus-row {
		.content-tabs-bus,
		.header-tabs-bus {
			display: flex;
			gap: 30px;
			flex-wrap: nowrap;
			flex: 1;
			min-width: max-content;
			.tab {
				width: 47% !important;
				span {
					margin-inline: 8px;
				}
			}
		}
	}
	.attraction-row {
		.tab {
			width: 23% !important;
			display: flex;
			align-items: center;
		}
		.tab-blank {
			background-color: #fff !important;
		}
	}
	.port-durations-row {
		.tab {
			width: 19% !important;
			display: flex;
			align-items: center;
		}
	}

	.movement-row {
		.header-tabs-move {
			.tab-65 {
				width: 60% !important;
			}
			.tab-30 {
				width: 35% !important;
			}
		}
		.content-tabs-move {
			.all-label-data {
				width: 60% !important;
				display: flex;
				gap: 4px;

				.label-data {
					width: 100% !important;
					gap: 4px;
					flex-direction: column;
					justify-content: center;
					align-items: start !important;

					p {
						line-height: 1;
						font-size: 12px;
					}
				}
			}
			.tab {
				width: 35% !important;
				display: flex;
				align-items: flex-start !important;
			}
		}
	}

	.settings-wrapper {
		display: flex;
		padding: 24px 26px;
		flex-direction: column;
		gap: 24px;
		border-radius: 8px;
		border: 1px solid #eae9e9;
		background-color: #fff;
		margin-bottom: 26px;

		@media screen and (max-width: 767px) {
			padding: 12px;
		}

		.header-title {
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 18px;
			font-weight: 600;
			line-height: 24px;
			color: #050001;

			svg {
				stroke: #292d32;
			}
		}
		.control-field__label {
			color: #707170;
			font-size: 14px;
			font-weight: 400;
			white-space: nowrap;
			i {
				color: #d2ab67;
			}
		}
	}

	.setting-serial-bank {
		display: flex;
		flex-wrap: wrap;
		gap: 26px;
	}
	.bank-info {
		flex-basis: calc(75% - 13px);
		flex-grow: 1;
	}
	.operating-serial {
		flex-basis: calc(25% - 13px);
		flex-grow: 1;
	}
	.department-details {
		.management-title {
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 190px;
			padding: 12px 0;
			border-radius: 6px;
			background-color: rgba(203, 203, 203, 0.38);
			color: #707170;
			font-size: 14px;
			font-weight: 400;
			text-align: center;
		}
		.control-field__prepend {
			border: 0;
			span {
				background-color: transparent !important;
			}
		}
	}
	.setting-header,
	.setting-footer {
		flex-basis: calc(50% - 12px);
		flex-grow: 1;
	}

	.image-input-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		max-height: 200px;
		margin-top: 8px;
		padding: 16px 14px;
		gap: 12px;
		border-radius: 4px;
		border: 2px dashed #eae9e9;
		overflow: hidden;

		&.image-banner {
			width: 100%;
			height: 100%;
			justify-content: center;
			.preview {
				flex-basis: 100%;
				max-width: 100% !important;
			}
		}
		&.image-left {
			justify-content: flex-start;
		}
		&.image-right {
			justify-content: flex-end;
		}
		[dir="rtl"] &.image-right {
			justify-content: flex-start;
		}
		[dir="rtl"] &.image-left {
			justify-content: flex-end;
		}

		.input {
			position: absolute;
			width: calc(100% - 14px);
			height: calc(100% - 16px);
			left: 7px;
			opacity: 0;
			overflow: hidden;
		}
		.preview {
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 50%;
			height: 100%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.textarea {
		padding: 8px 16px;
		border-radius: 6px;
		border: 1px solid #dddcdc;
		background-color: #fefeff;
	}
	.react-datepicker-wrapper,
	.react-datepicker__input-container,
	.react-datepicker__input-container input {
		width: 90%;
	}
	.react-datepicker__close-icon::before,
	.react-datepicker__close-icon::after {
		background-color: red;
	}
	.react-datepicker__close-icon::after {
		height: 18px !important;
		width: 18px !important;
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		line-height: 0 !important;
		padding: 0;
	}
}

/*** End Timing Settings ***/

/*** start accounting tab ***/

.operation-accounting-edit {
	--boxes-height: 64px;

	.control-field__input {
		text-align: center;
	}
	.operating-btn-back:disabled,
	.operating-btn-next:disabled {
		background-color: #eae8e9 !important;
		cursor: no-drop !important;
	}

	.accounting-item {
		.header-title {
			color: #d4a655;
			font-size: 1.125rem;
			font-weight: 700;
			line-height: 1.25rem;
		}
		.content {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			color: #050001;
			font-weight: 400;

			.label {
				color: #707170;
				font-size: 1rem;
				font-weight: 600;
				text-align: inherit;
				width: auto;
			}
		}
	}

	.accounting-item-wrapper {
		display: flex;
		flex-direction: column;
		padding: 20px;
		gap: 16px;
		border-radius: 6px;
		border: 1px solid #dddcdc;
		background-color: #fff;
	}
	.pricing-inputs-wrapper {
		width: 70%;
		margin: 0 auto;
		gap: 10px;
	}

	.total-pricing {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 16px 24px;
		border: 1px solid #f4f4f7;
		background-color: #fff;

		.header {
			font-size: 1.125rem;
			font-weight: 600;
			color: #050001;
		}
	}

	.pricing-inputs {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: center;
		margin-bottom: 20px;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;

		@media screen and (max-width: 767px) {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 20px;
			width: 100%;
			justify-content: center;
			margin-bottom: 20px;
		}

		.item {
			width: calc(25% - 15px);
			padding: 8px 16px;
			border-radius: 4px;
			font-size: 1.125rem;
			font-weight: 600;
			text-align: center;
			margin-bottom: 10px;

			@media screen and (max-width: 767px) {
				padding: 8px 16px;
				border-radius: 4px;
				font-size: 1.125rem;
				font-weight: 600;
				text-align: center;
				margin-bottom: 10px;
				min-width: 130px;
				width: 130px;
			}
		}
		.pax {
			color: #707170;
			background-color: #dddcdc;
		}
		.buying {
			background-color: #1e85ff;
			color: #fbfbfa;
		}
		.selling {
			background-color: #219653;
			color: #fbfbfa;
		}
		.profit {
			background-color: #d4a655;
			color: #fbfbfa;
		}
		.pax-count {
			background-color: #f7f6f6;
			color: #050001;
		}
		.total-profit {
			border: 1px solid #f7f6f6;
			background-color: #fbf6ee;
			font-weight: 700;
			color: #d4a655;
		}
	}

	.view-actions {
		button {
			@media screen and (max-width: 767px) {
				flex: 1;
				min-width: fit-content;
			}
		}
	}

	.text-black-600 {
		color: #050001;
		font-weight: 600;
	}

	.apply-discount input {
		accent-color: #d4a655;
	}

	.price-with-taxes {
		display: flex;
		flex-direction: column;
		font-size: 1.125rem;
		line-height: 160.9%;

		.price-amount {
			color: #d4a655;
			font-size: 2.125rem;
			font-weight: 700;
		}

		.price-currency {
			font-size: 1.25rem;
			font-weight: 400;
		}
	}

	.arrival-departure-cost {
		display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    overflow: hidden;

		.cost-table {
			padding: 20px;
			border: .5px solid #dddcdc;
			flex-basis: 50%;
			overflow-x: auto;

			h5 {
				display: flex;
				align-items: center;
				gap: 8px;
				font-size: 0.95rem;
				font-weight: 600;
			}

			table {
				text-align: center;
				border-collapse: separate;
				border-spacing: 10px;
				margin: 0 -20px;
				min-width: auto !important;

				@media screen and (max-width: 767px) {
					min-width: max-content !important;
				}

				th,
				td {
					text-align: center;
				}

				tbody tr td:first-child {
					font-size: 1rem;
					font-weight: 600;
					color: #050001;
				}
				thead tr th:not(:first-child) {
					padding: 7px 12px;
					border-radius: 4px;
					font-size: 1.125rem;
				}
				input {
					text-align: center;
				}

				.control-field:focus-within {
					// border: 1px solid #D4A655 !important;
				}

				.custom-border-color {
					// border-color: rgba(112, 113, 112, 0.1) !important;
				}
			}

			.pax-count-row td:not(:first-child) {
				padding: 8px 12px;
				border-radius: 4px;
				border: 1px solid rgba(112, 113, 112, 0.1);
				background-color: #f7f6f6;
			}
			.total-amount {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 14px 16px;
			}
		}

		.buying {
			border-radius: 6px 0px 0px 0px;
			h5 {
				color: #1e85ff;
			}
			thead tr th:not(:first-child) {
				background-color: #1e85ff;
				color: #fbfbfa;
			}
			.total {
				color: #1e85ff !important;
				font-weight: 600;
			}
			.total-amount {
				border-radius: 4px;
				border: 1px solid rgba(112, 113, 112, 0.1);
				background-color: rgba(30, 133, 255, 0.1);
			}
		}
		.selling {
			border-radius: 0px 6px 0px 0px;
			h5 {
				color: #219653;
			}
			thead tr th:not(:first-child) {
				background-color: #219653;
				color: #fbfbfa;
			}
			.total {
				color: #219653 !important;
				font-weight: 600;
			}
			.total-amount {
				border-radius: 4px;
				border: 1px solid rgba(112, 113, 112, 0.1);
				background: rgba(33, 150, 83, 0.1);
			}
		}
	}

	.arrival-departure-profit {
		display: flex;
		align-items: center;
		padding: 20px;
		gap: 16px;
		border-radius: 0px 0px 6px 6px;
		border: 0.5px solid #dddcdc;

		.box {
			padding: 14px 20px;
			border-radius: 4px;
			border: 1px solid rgba(112, 113, 112, 0.1);
			background: #d4a655;
			color: #fbfbfa;
			font-weight: 600;
		}

		.total-profit {
			width: 100%;
			padding: 14px 16px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid rgba(112, 113, 112, 0.1);
			background-color: #fbf6ee;
		}
	}

	.pricing-type {
		margin: 24px 0;
		padding: 16px 24px;
		background-color: rgba(212, 166, 85, 0.08);

		.header {
			margin-bottom: 8px;
			font-size: 18px;
			font-weight: 600;
			color: #050001;
		}
	}

	.attractions .pricing-type {
		padding: 16px 0;
		margin: 0;
		background-color: transparent;

		label {
			color: #707170;
		}
	}

	.attraction-item {
		display: flex;
		flex-direction: column;
		padding: 16px;
		gap: 10px;
		background-color: #f7f6f6;
	}

	.delete-pricing-btn {
		display: flex;
		align-items: center;
		width: fit-content;
		padding: 0;
		margin-top: 1rem;
		gap: 6px;
		font-size: 1rem;
		font-weight: 600;
		color: #ea5455;
		svg path {
			stroke: #f53d3d;
		}
	}

	.btn-plus-operation {
		display: flex;
		align-items: center;
		gap: 6px;

		span {
			margin: 0;
		}
	}

	.transportations-info {
		padding: 16px;
		background-color: #f7f6f6;

		.movements-table {
			margin-top: 24px;
			border: 1px solid #dbdade;
			overflow-x: auto;

			thead th {
				padding: 16px 20px;
				border-bottom: 1px solid #dbdade;
				color: #707170;
				font-weight: 700;
				font-size: 0.875rem;
				background-color: #f7f6f6;
			}
			tbody td {
				padding: 12px 20px;
				color: #050001;
				font-weight: 400;
				background-color: #fff;
				vertical-align: middle;
			}
		}
	}

	.hotel-pricing,
	.visa-pricing {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.pricig-row {
			display: flex;
			text-align: center;
			gap: 12px;

			.buying {
				display: flex;
				flex-direction: column;
				gap: 8px;
				flex-grow: 1;
				color: #fff;

				.row-head,
				.row-sub-head {
					border: 1px solid rgba(112, 113, 112, 0.1);
					background-color: #1e85ff;
				}
			}
			.selling {
				display: flex;
				flex-direction: column;
				gap: 8px;
				flex-grow: 1;
				color: #fff;

				.row-head,
				.row-sub-head {
					border: 1px solid rgba(112, 113, 112, 0.1);
					background-color: #219653;
				}
			}
			.buying-content,
			.selling-content {
				display: flex;
				flex-direction: column;
				gap: 8px;
				flex-grow: 1;
				color: #fff;
				.row-sub-head {
					border: none;
					background-color: transparent;
					padding: 0 !important;
					height: 44px;
					.input-group {
						margin: 0 !important;
						.control-field__input {
							text-align: center;
						}
					}
				}
			}
			.row-head,
			.row-sub-head {
				border-radius: 4px;
				padding: 8px 12px;
			}
			.row-sub-head {
				flex-basis: 50%;
			}
			.pax-count {
				background-color: #f7f6f6;
				color: #050001;
			}
			.row-data {
				display: flex;
				justify-content: center;
				gap: 10px;
				flex-grow: 1;
			}
			.col-text {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 8px 12px;
				border-radius: 4px;
				font-size: 1.125rem;
				font-weight: 600;
				line-height: 88.89%;
			}
		}
		.pricig-row.profit {
			.col-text {
				background-color: #d4a655;
				color: #fff;
			}
			.profit-amount {
				background: #fbf6ee;
				padding: 8px 12px;
				border: 1px solid #f7f6f6 !important;
				color: #d4a655;
				font-weight: 700;
			}
		}

		.pricing-label,
		.pax {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			font-size: 1.125rem;
			font-weight: 600;
			background-color: #dddcdc;
			color: #707170;
		}
		.pricing-label {
			background-color: #f7f6f6 !important;
			color: #050001 !important;
		}
		.Profit {
			.pax {
				background-color: #d4a655;
				color: #fff;
			}
			.profit-amount {
				background: #fbf6ee;
				padding: 8px 12px;
				border: 1px solid #f7f6f6 !important;
				color: #d4a655;
				font-weight: 700;
			}
		}

		.total {
			.col-text {
				background-color: #dddcdc;
				color: #707170;
			}
			.buying-amount,
			.selling-amount {
				padding: 8px 12px;
				font-size: 1.125rem;
				font-weight: 600;
				border-radius: 4px;
				background-color: #fff;
				flex-basis: 50%;
			}
			.buying-amount {
				border: 1px solid #1e85ff !important;
				color: #1e85ff;
			}
			.selling-amount {
				border: 1px solid #219653 !important;
				color: #219653;
				.control-field__input {
					text-align: center;
					background: #fbf6ee;
					color: #d4a655;
					font-size: 18px;
					font-weight: 600;
				}
			}
		}

		.sales,
		.Profit {
			.col-33 {
				width: calc(33.33333333% + 10px) !important;
			}
			.col-50 {
				width: calc(50% + 24px) !important;
			}
		}
		.sales .buying-content {
			.input-group {
				border: 1px solid #1e85ff !important;
				background: #fff;
				.control-field__input {
					background: #fff;
					color: #1e85ff;
					font-size: 18px;
					font-weight: 600;
				}
			}
		}
		.sales .selling-content {
			.input-group {
				border: 1px solid #219653 !important;
				background: #fff;
				.control-field__input {
					background: #fff;
					color: #219653;
					font-size: 18px;
					font-weight: 600;
				}
			}
		}
		.input-group {
			.no-gutters {
				padding-block: 3px !important;
			}
		}
	}
}
.accounting-agent-profile {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 26px 24px;
	gap: 2rem;
	background-color: #ffffff;
	border: 1px solid #f7f6f6;
	border-radius: 12px;

	.item {
		display: flex;
		flex-direction: column;
		min-width: 180px;
		padding: 7px 14px;
		gap: 10px;

		.unit {
			line-height: 20px;
			color: #707170;
		}
		.value {
			font-size: 24px;
			line-height: 22px;
			color: #050001;
		}
		.name {
			border-inline-end: 1px solid #f7f6f6;
		}
	}
}
/*** end accounting tab ***/

.radio-group-container {
	.item {
		position: relative;
		display: flex;
		align-items: center;

		&.disabled {
			background-color: transparent !important;
			opacity: 0.4;

			label {
				cursor: not-allowed !important;
			}
		}

		label {
			position: relative;
			margin-bottom: 0;
			padding: 0 24px;
			font-size: 16px;
			font-weight: 600;
			line-height: 168.4%;
			color: #050001;
			cursor: pointer;
			z-index: 2;
		}

		input {
			position: absolute;
			opacity: 0;
			height: 0;
			width: 0;
		}

		.checkmark {
			position: absolute;
			top: 4px;
			left: 0;
			[dir="rtl"] & {
				right: 0;
			}
			height: 18px;
			width: 18px;
			background-color: transparent;
			border: 1px solid #dddcdc;
			border-radius: 50%;
			cursor: pointer;
			overflow: hidden;
		}

		input:checked ~ .checkmark {
			border: 1px solid #d4a655;
		}

		input:checked ~ .checkmark:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 75%;
			height: 75%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			background-color: #d4a655;
		}
	}
}
.checkbox-container {
	display: flex;
	align-items: center;
	filter: invert(1%) hue-rotate(190deg) brightness(1.2);
	user-select: none;
}

.change-pricing-Modal {
	.modal-content {
		width: 90%;
		border: none;
		border-radius: 12px;
		box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
			0px 20px 24px -4px rgba(16, 24, 40, 0.08);
		[dir="rtl"] & {
			text-align: right;
		}
	}

	.modal-body {
		padding: 20px;
	}

	.header {
		color: #101828;
		font-size: 18px;
		font-weight: 600;
		line-height: 155.56%;
	}

	.icon {
		display: flex;
		width: 48px;
		height: 48px;
		padding: 4px;
		justify-content: center;
		align-items: center;
		border-radius: 28px;
		border: 8px solid #fef3f2;
		background-color: #fee4e2;
	}

	.info-text {
		color: #667085;
		font-size: 14px;
		font-weight: 400;
		line-height: 142.86%;
	}
	.reject-btn,
	.confirm-btn {
		display: flex;
		padding: 10px 18px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		flex-grow: 1;
		border-radius: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
	}
	.reject-btn {
		border: 1px solid #d0d5dd;
		background-color: #fff;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		color: #344054;
	}
	.confirm-btn {
		border: 1px solid #d92d20;
		background: #ea5455;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		color: #fff;
	}
}

// download-app-acreen
.download-app-screen {
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	margin: 0;
}
.downloadApp-container {
	width: 50%;
	height: 50%;
	background-color: #fff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;

	.screen-title {
		color: #219653;
		font-size: 28px;
		font-weight: bold;
	}

	.Download-btn {
		display: flex;
		align-items: center;
		background-color: #daa249;
		color: white;
		gap: 10px;

		.download-txt {
			font-size: 20px;
		}
	}
}

/*** Orders Violations  ***/
.orders-violations-container {
	background-color: #f7f6f6;
}

.umrah-operations-wrapper:has(.orders-violations-container) {
	padding: 0 !important;
}

.orders-violations-wrapper {
	margin-top: 2rem;
	padding: 26px;
	border: 1px solid #f7f6f6;
	border-radius: 12px;
	background-color: #ffffff;

	@media screen and (max-width: 767px) {
		padding: 16px;
	}

	.export-excel-btn,
	.orders-violations-content-addNewButton {
		min-width: fit-content;

		@media screen and (max-width: 767px) {
			flex: 1;
		}
	}

	.order-violation-filter {
		display: flex;
		flex-wrap: wrap;

		.filter-field {
			flex: 1;
			min-width: 130px;
		}

		.filter-field:has(.reset-btn) {
			align-self: end;
			margin-bottom: 0.25rem;
			height: 44px;
		}

		.reset-btn {
			margin: 0;
		}
	}
}

.orders-violations-searchInputWrapper {
	display: flex;
	align-items: center;
	border: 1px solid #e0e0e0;
	border-radius: 10px;
	padding: 4px 8px;
	gap: 8px;
	cursor: pointer;
	align-self: start;
}

.orders-violations-searchInput {
	outline: none;
	border: none;
	font-size: 22px;
	width: 100%;
}

.orders-violations-content-actiosnGroup {
	display: flex;
	gap: 4px;
	min-width: fit-content;
}

.orders-violations-content-addNewButton {
	border: none;
	outline: none;
	background-color: #d4a655;
	color: white;
	padding: 8px 26px;
	border-radius: 4px;
}

.violation-cost-wrapper {
	position: relative;

	.violation-cost-input input {
		padding-inline-end: 60px;
	}

	.violation-cost-select {
		position: absolute;
		top: 0;
		right: 6px;
		width: 70px;

		.control-field {
			border: none;
			background-color: transparent;
		}

		* [class$="menu"] {
			width: 250px;
		}
		[dir="rtl"] & {
			left: 0px !important;
			right: unset !important;
		}
	}
}

.fileUpload-wrapper {
	cursor: pointer;

	p {
		text-wrap: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&.error {
		color: #ff5f59;
		.fileUpload-input {
			border: 1px solid #ff5f59;
		}
	}
	.has-error {
		font-size: 12px;
		position: relative;
		top: -4px;
	}

	.fileUpload-input {
		cursor: pointer;
		padding: 9px 10px;
		border-radius: 5px;
		border: 1px solid #ebebeb;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		div {
			text-wrap: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&.empty {
			color: #dddcdc;
		}
	}
}

.text-field-with-closeBtn {
	position: relative;

	button {
		padding: 0;
		background-color: transparent;
		border: none;
		outline: none;
	}

	input {
		padding-right: 20px;
	}

	.remove-icon {
		position: absolute;
		right: 6px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.add-passport-btn {
	margin-top: 8px;
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 0;
	background-color: transparent;
	border: none;
	outline: none;
	font-weight: 600;
	font-size: 16px;

	.icon-wrapper {
		padding: 8px;
		background-color: #eed9ba;
		border-radius: 6px;
	}
}

.save-btn-wrapper {
	display: flex;

	button {
		margin-left: auto;
		border: none;
		outline: none;
		background-color: #d4a655;
		color: white;
		font-weight: 600;
		border-radius: 4px;
		padding: 12px 40px;
	}
}

.flag {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	margin-right: 8px;
}

/*** Official Movement ***/
.official-movement-container {
	.tabs {
		display: flex;
		justify-content: center;
		gap: 0;
		.btn {
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding: 10px 16px;
			background-color: #f4f4f4;
			gap: 10px;
			transition: none;

			// border-radius: 10px;
			&.selected {
				background-color: #d4a655;
				color: #fff;
				svg path {
					stroke: #fff;
				}
			}
			&.movement-manage {
				border-radius: 8px 0 0 8px;
				[dir="rtl"] & {
					border-radius: 0 8px 8px 0;
				}
				
			}
			&.movement-group {
				border-radius: 0 8px 8px 0;
				[dir="rtl"] & {
					border-radius: 8px 0 0 8px;
				}
			}
		}

		@media screen and (max-width: 767px) {
			gap: 10px;
			.btn {
				&.movement-manage,
				&.movement-group {
					border-radius: 8px;
					[dir="rtl"] & {
						border-radius: 8px;
					}
				}
			}
		}
	}

	.tab-content {
		margin-top: 2rem;
		padding: 26px;

		@media screen and (max-width: 767px) {
			padding: 16px;
		}

		border: 1px solid #f7f6f6;
		border-radius: 12px;
		background-color: #ffffff;
	}
	.add-btn {
		display: flex;
		align-items: center;
		border-radius: 4px;
		background-color: #d4a655;
		color: #fff;
		gap: 10px;
	}
	@media screen and (max-width: 767px) {
		.add-btn {
			flex-grow: 1 !important;
		}
	}

	.switch-container {
		position: relative;
		display: flex;
		align-items: center;
	}

	.switch {
		display: none;
	}

	.switch-label {
		width: 40px;
		height: 20px;
		background-color: #ccc;
		border-radius: 15px;
		position: relative;
		cursor: pointer;
		transition: background-color 0.3s;
	}

	.switch-label::after {
		content: "";
		width: 17px;
		height: 18px;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		top: 1px;
		left: 1px;
		transition: left 0.3s;
	}

	.switch:checked + .switch-label {
		background-color: #daa249;
	}

	.switch:checked + .switch-label::after {
		left: 22px;
	}

	.table-container td {
		vertical-align: middle;
	}
	.delete-btn svg path {
		stroke: #ea5455;
	}
}
/*** guide requests ***/
.guide-requests-container {
	padding-inline: 8px !important;

	.table-container {
		overflow-x: auto;
	}

	.add-btn {
		display: flex;
		align-items: center;
		border-radius: 4px;
		background-color: #d4a655;
		color: #fff;
		gap: 10px;
		height: 44px;
	}

	.add-official-btn {
		display: flex;
		align-items: center;
		border-radius: 4px;
		color: #9c9fab;
		gap: 10px;
	}

	.guide-requests-filter {
		div:nth-child(2) {
			span,
			button {
				height: 44px !important;
				margin-bottom: 0.25rem;
			}
		}
	}
}

@media screen and (max-width: 1100px) {
	.operations-settings {
		.operating-serial {
			flex-basis: calc(35% - 13px);
		}
		.bank-info {
			flex-basis: calc(65% - 13px);
		}
	}
	.operation-tabs .item {
		font-size: 1.125rem;
	}
}

@media screen and (max-width: 767px) {
	.operations-agents .add-edit-agent .add-edit-form {
		padding: 16px 8px;
	}

	.operations-settings {
		.setting-header,
		.setting-footer,
		.operating-serial,
		.bank-info {
			flex-basis: calc(100% - 13px);
		}
	}

	.operation-accounting-edit .arrival-departure-cost .cost-table {
		flex-basis: 100%;
	}
}

@media screen and (max-width: 600px) {
	.m-mob {
		margin-bottom: 1rem !important;
	}

	.m-mob button {
		width: auto !important;
	}
}

.action-btn {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 26px;
	.btn-yellow {
		border-radius: 8px;
		background: #d4a655;
		padding: 0px 16px;
		height: 40px;
		color: #fff;
		font-size: 16px;
		font-weight: 700;
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;
		margin-bottom: 26px;
	}
}

.custom-timing-settings {
	.custom-time-field-container .time-details {
		padding: 0 !important;
		border: none !important;
	}
	label {
		display: none !important;
	}
}

.w-icons-mob {
	width: 14rem !important;
}
.m-icon-1 {
	span {
		margin-right: 3px;
		display: inline-block;
		width: 24px;
		height: 24px;
		background: #dfbcbc30;
		border-radius: 50%;
		text-align: center;

		svg {
			height: 15px;
			width: 15px;
		}
	}
	.active {
		background-color: #d4a655 !important;
	}
}

.account-status {
	.unpaid {
		color: #ff3131;
		background: rgba(255, 49, 49, 0.16) !important;
		padding: 5px 20px;
	}

	.paid {
		color: #019d4c;
		background: rgba(1, 157, 76, 0.16) !important;
		padding: 5px 20px;
	}

	.new {
		color: #fb881e;
		background: rgba(251, 136, 30, 0.16) !important;
		padding: 5px 20px;
		flex-grow: 0;
	}
}

.actions-list {
	.payment-text {
		overflow: hidden;
		color: var(--light-copy-text-muted, #777);
		text-overflow: ellipsis;
		white-space: nowrap;
		font-family: Cairo;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 133.333% */
		text-transform: uppercase;
	}
	.payment-row {
		display: flex;
		align-items: center;
		.payment-icon {
			margin-right: 10px;
		}
		.payment-text-label {
			color: var(--050001, #050001);
			font-family: Cairo;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
}

.fontStyleAccounting {
	color: #778294;
	font-size: 12px;
	font-weight: 500;
}

.font-style-dark {
	font-size: 14px;
	color: #000;
}

.heading-accounting {
	font-size: 24px;
	font-weight: 600;
	color: #d4a655;
}

.c-grey {
	color: #313131;
}

.text-filed-visibility-hidden {
	.control-field {
		visibility: hidden !important;
	}
	button {
		visibility: hidden !important;
	}
}

.accounting-border-bottom {
	width: 100%;
	border-bottom: 1px solid #dddcdc;
}

.dropdown-menu-accounting {
	display: none;
	position: absolute;
	right: 100%;
	width: 100%;
	background: #fff;
	top: 50%;
	border-radius: 8px;
}

.list-menu:hover .dropdown-menu-accounting {
	display: block;
	z-index: auto;
}

@keyframes flash-border {
	0% {
		border-color: #ff0000; /* Red */
		color: #ff0000;
	}
	50% {
		border-color: #d4a655;
		color: #d4a655;
	}
	100% {
		border-color: #ff0000;
		color: #ff0000;
	}
}

/* Add Movement */

.form-title {
	font-size: 24px;
	font-weight: 700;
	color: #112233;
}
.movement-form {
	.phone_code {
		width: 115px !important;
	}

	.movements-wrapper {
		width: 100% !important;
		padding: 16px;
		border-radius: 8px;
		background: #f7f7f7;
		.custom-timing-settings {
			.time-label {
				display: block !important;
			}
			.custom-time-field {
				border: 1px solid #ebebeb;
				border-radius: 0.3125rem;
			}
		}
		.delete-btn {
			width: 141px;
			height: 46px;
			display: flex;
			gap: 8px;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			border: 1px solid #fef2f2;
			border: 1px solid #f0d4d4;
			background-color: #fef2f2;
			color: #ea5455;
		}
	}

	.submit-btn {
		background: #d4a655;
		width: 162px;
		border-radius: 6px;
		height: 48px;
		color: white;
		border: none;
		font-weight: 700;
	}

	.btn-overlay {
		position: absolute;
		top: -50%;
		left: 0;
		width: 100%;
		height: 200%;
		transform-origin: bottom;
		animation: btnAnimationOut 0.3s cubic-bezier(0.5, 0.4, 0.2, 0.72) forwards;
	}

	&:not(:disabled):hover .btn-overlay {
		animation: btnAnimationIn 0.3s cubic-bezier(0.5, 0.4, 0.2, 0.72) forwards;
	}
}
.floating-filter-btn {
	position: fixed;
	bottom: 107px;
	right: 4px;
	z-index: 1000;

	button {
		background-color: #dbd9d9;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.live-chat-holder {
	bottom: 50%;
	left: -40px;
	position: fixed;
	z-index: 4;
	display: flex;
	flex-direction: column;
	background-color: #219653;
	transform: rotate(-90deg);
	border-radius: 0px 0px 4px 4px;
	padding: 10px 8px 10px 8px;
	color: #fff;
	[dir="rtl"] & {
		border-radius: 4px 4px 0px 0px;
		right: -40px;
		left: unset;
	}
	.live-chat {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 6px;
		color: #fff;
		svg {
			transform: rotate(90deg);
			width: 30px;
			height: 30px;
			circle,
			polygon,
			line {
				stroke: #fff;
			}
		}
	}
}

.agent-accounting-table-wrapper {
	overflow-x: auto;
	table {
		min-width: max-content;
	}
}
.movements-cols {
	@media screen and (max-width: 767px) {
		.col-md15 {
			width: 100% !important;
			max-width: 100% !important;
			flex: 0 0 100% !important;

		}
	}
}
