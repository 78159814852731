.control-field {
	background-color: $white;
	// padding: calculateRem(10px);
	// overflow: hidden;
	border: 1px solid #ebebeb;
	border-radius: calculateRem(5px);
	// max-height: 53px;
	font-size: $text-caption;
	// margin: calculateRem(10px) 0;
	// border-color: red;
	height: 44px;

	&--danger {
		border: 1px solid $red !important;
	}

	&--success {
		border: 1px solid #cb9a51;
	}

	&--warning {
		border: 1px solid $orange;
	}

	&__label {
		font-size: 16px;
		font-weight: $font-weight-medium;
		color: #2d2d2d;
		margin-bottom: 0;
		width: 100%;
		// text-align: var(--left);
		text-align: var(--left);

		&--danger {
			color: $red;
		}

		&--success {
			color: #cb9a51 !important;
		}

		&--warning {
			color: $orange;
		}
	}

	&__prepend {
		// padding: 0.3125rem 0.625rem 0.625rem 0.625rem;
		// border-#{var(--right)}: 1px solid $gray-200;
		border-inline-end: 1px solid $gray-200;

		span {
			background-color: #ebebeb;
			height: 100%;
		}
		&--danger {
			// border-#{var(--right)}: 1px solid $red;
			border-inline-end: 1px solid $red;
		}

		&--success {
			// border-#{var(--right)}: 1px solid $green;
			border-inline-end: 1px solid #cb9a51;
		}

		&--warning {
			// border-#{var(--right)}: 1px solid $orange;
			border-inline-end: 1px solid $orange;
		}
	}

	&__append {
		padding: calculateRem(10px);
		// border-#{var(--left)}: 1px solid $gray-200;
		border-inline-start: 1px solid $gray-200;
		&--danger {
			// border-#{var(--left)}: 1px solid $red;
			border-inline-start: 1px solid $red;
		}

		&--success {
			// border-#{var(--left)}: 1px solid $green;
			border-inline-start: 1px solid $green;
		}

		&--warning {
			// border-#{var(--left)}: 1px solid $orange;
			border-inline-start: 1px solid $orange;
		}
	}

	&__body {
		padding: 12px 18px;
		flex: 1 1 auto;
		border-radius: 5px;
		.date-icon {
			position: absolute;
			// #{var(--right)}: 7px;
			right: 0px;
			left: unset;
			bottom: 3px;
			font-size: $text-headline;
			color: $gray-300;
			font-weight: normal;
			top: 0px;
			z-index: 1;
			[dir="rtl"] & {
				right: unset;
				left: 0px;
			}
		}
	}

	&__input {
		border: 0;
		font-size: calculateRem(14px);
		width: 90%;
		color: #2d2d2d;
	}

	&__select {
		display: inline-block;
		width: 100%;
		height: auto;
		padding: 0;
		font-size: $text-caption;
		vertical-align: middle;
		background: #fff url("../../../assets/images/svg/down-chevron.svg")
			no-repeat var(--right) 0 center/10px 10px;
		border: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	&__feedback {
		font-size: $text-caption;
		display: none;
		color: $dark-blue;
		width: 100%;
		text-align: var(--left);
		margin-top: calculateRem(5px);

		&--danger {
			color: $red;
		}

		&--success {
			color: $green;
		}

		&--warning {
			color: $orange;
		}

		&.error-message {
			// margin-block-start: -8px;
			// padding-inline-start: 5px;
			color: $red;
			// border: 1px solid $red !important;
		}
	}

	// input placeholder
	::-webkit-input-placeholder {
		/* Edge */
		color: $gray-300;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: $gray-300;
	}

	::placeholder {
		color: $gray-300;
		font-size: 14px;
	}

	//select box
	select option {
		color: $dark-blue;
	}
	select:not(:checked) {
		color: $gray-300;
	}

	&__results-list {
		position: absolute;
		width: 100%;
		z-index: 999999;
		top: calculateRem(65px);
		background: $white;
		border: 1px solid $gray-150;
		overflow: scroll;
		max-height: calculateRem(200px);
		border-radius: calculateRem(5px);
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
		-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	}

	&__result {
		font-size: calculateRem(16px);
		padding: calculateRem(10px);
		cursor: pointer;
		&:not(:last-child) {
			border-bottom: 1px solid $gray-150;
		}
		&:hover {
			background: $blue;
			color: $white;
		}
	}
}
.control-field-inner{
    height: 52px !important;
}
.errorField {
	border: 1px solid $red !important;
}
.custom-border-color {
	border-color: #cb9a51;
}

.custom-select-text {
	color: #cb9a51;
}
.custom-control-field {
	border: 1px solid #cb9a51 !important;
}
.text-gray-100 {
	color: #d8d8d8 !important;
}
.text-19 {
	font-size: 19px !important;
}

.phoneField {
	 
	.control-field__body {
		.control-field {
			width: 90px;
			border-radius: 0.3125rem 0 0 0.3125rem;
			border-inline-end: none;
		}
	}
	.phoneNumber {
		.control-field {
			border-radius: 0.3125rem 0 0 0.3125rem;
			border-inline-start: none;
			position: relative;
			&::before {
				content: "";
				width: 1px;
				height: 30px ;
				background-color: #d8d8d8;
				position: absolute;
				inset-inline-start: 0;
				top: 7px;
 			}
		}
		.custom-border-color {
		 border-color: #ebebeb !important	;
		}

	}
}