@media (min-width: 320px) and (max-width: 576px) {
	td,
	th {
		white-space: nowrap;
	}
	.pagination-container {
		flex-direction: column;
	}
	.marketplace-details {
		.mybooking-search-wrapper.search-bar {
			padding: 0;
			.container {
				padding: 0;
			}
		}
		.select-flight .sorting {
			flex-direction: column-reverse;
			align-items: baseline;

			.select-flight-title.hotel-found,.show-map-btn{
				white-space: nowrap;
			}
		}
		.sort-filter {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 5px;
			width: 100% !important;
			& > span {
				flex: 1 0 100%;
			}
			.toggle-sort {
				flex: 0 1 30%;
				width: 100% !important;
				margin-bottom: 2px;
			}
		}
		.hotel-detail-section {
			padding: 0px;
			border-radius: 6px;
			overflow: hidden;
			.img-box {
				overflow: hidden;
				width: 100% !important;
			}
			.img-box img {
				object-fit: fill;
			}
			.hotel-header {
				display: flex;
				align-items: flex-start;
				margin-bottom: 20px;
				flex-direction: column;
			}
		}
		.new-price-number {
			width: auto;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		.titleHotelDescription {
			display: none;
		}
		.available-section {
			.details-item {
				overflow-x: auto;
			}
		}
		.hotel-amenities-new ul li,
		.hotel-amenities-new ul li {
			width: 50%;
		}
		.hotel-name{
			flex-direction: column;
		}
	}
	.boxCancellation {
		margin-bottom: 8px;
	}
	.responsive-padding-container {
		padding-left: 30px;
		padding-right: 30px;
		.cost-title-container {
			justify-content: center !important;
		}
		strong{
			display: block;
		}
	}
	.payModal {
		.radios {
			flex-direction: column-reverse;
			.box-item {
				width: auto;
			}
			.box-item:first-child {
				margin-top: 8px;
			}
		}
	}
	.tab-content{
		.product-header-list{
			flex-direction: column;
			padding: 4px 0 !important;
			.sm-taps{
				width: 55% !important;

			}
			p{
				padding: 0px;
			}	
		}
	}
	
}
